/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment OrganisationCommon on Organisation {\n    id\n    name\n    humanIdentifier\n    emailDomains\n    isSynthaceInternal\n    notes\n  }\n": types.OrganisationCommonFragmentDoc,
    "\n  fragment EnvironmentCommon on Environment {\n    id\n    hostname\n    baseURL\n    notes\n  }\n": types.EnvironmentCommonFragmentDoc,
    "\n  fragment FeatureToggleCommon on Environment {\n    featureToggles {\n      name\n      enabled\n      description\n      requiresReload\n      kind\n      config {\n        override\n      }\n    }\n  }\n": types.FeatureToggleCommonFragmentDoc,
    "\n  fragment OnlineMigrationAdminStateCommon on OnlineMigrationAdminState {\n    backfillEntitiesWritten\n    backfillLockedUntil\n    backfillVersionCompleted\n    backfillVersionProgress\n    codeVersion\n    doubleReadFraction\n    doubleReadFractionOverride\n    entityCount\n    name\n    stage\n    stageOverride\n  }\n": types.OnlineMigrationAdminStateCommonFragmentDoc,
    "\n  fragment OrganisationEnvironmentCommon on OrganisationEnvironment {\n    environmentId\n    organisationId\n    auth0ClientId\n    isEnabled\n    notes\n    environment {\n      ...EnvironmentCommon\n    }\n  }\n": types.OrganisationEnvironmentCommonFragmentDoc,
    "\n  fragment UserCommon on User {\n    id\n    givenName\n    familyName\n    email\n    blocked\n  }\n": types.UserCommonFragmentDoc,
    "\n  fragment AdminUserCommon on AdminUser {\n    id\n    email\n    unseenNotificationsCount\n  }\n": types.AdminUserCommonFragmentDoc,
    "\n  fragment NotificationCommon on Notification {\n    id\n    message\n    isRead\n    isSeen\n    url\n    createdAt\n  }\n": types.NotificationCommonFragmentDoc,
    "\n  fragment ReleaseArtifactCommon on ReleaseArtifact {\n    filename\n    sizeBytes\n    downloadURL\n    architecture\n    type\n  }\n": types.ReleaseArtifactCommonFragmentDoc,
    "\n  fragment ReleaseCommon on Release {\n    version\n    releaseNotesURL\n    createdAt\n    artifacts {\n      ...ReleaseArtifactCommon\n    }\n  }\n": types.ReleaseCommonFragmentDoc,
    "\n  query getEnvironments {\n    environments {\n      ...EnvironmentCommon\n    }\n  }\n": types.getEnvironmentsDocument,
    "\n  query getEnvironmentsWithHealthcheck {\n    environments {\n      ...EnvironmentCommon\n      canAuthenticateWithAppserver\n      anthaHubAutoUpdateEnabled\n      anthaHubReleaseVersion\n    }\n  }\n": types.getEnvironmentsWithHealthcheckDocument,
    "\n  query getLatestAnthaHubReleases {\n    latestAnthaHubReleases {\n      ...ReleaseCommon\n    }\n  }\n": types.getLatestAnthaHubReleasesDocument,
    "\n  query getLatestDeviceDriverReleases {\n    latestDeviceDriverReleases {\n      ...ReleaseCommon\n    }\n  }\n": types.getLatestDeviceDriverReleasesDocument,
    "\n  query getAllReleasesForProject(\n    $projectName: String!\n    $includePreReleaseVersions: Boolean!\n  ) {\n    allReleasesForProject(\n      projectName: $projectName\n      includePreReleaseVersions: $includePreReleaseVersions\n    ) {\n      ...ReleaseCommon\n    }\n  }\n": types.getAllReleasesForProjectDocument,
    "\n  mutation addEnvironment($domainPrefix: String!) {\n    addEnvironment(input: { domainPrefix: $domainPrefix }) {\n      ...EnvironmentCommon\n    }\n  }\n": types.addEnvironmentDocument,
    "\n  mutation setAnthaHubReleaseVersion(\n    $environmentId: String!\n    $anthaHubReleaseVersion: String!\n  ) {\n    setAnthaHubReleaseVersion(\n      input: {\n        environmentId: $environmentId\n        anthaHubReleaseVersion: $anthaHubReleaseVersion\n      }\n    ) {\n      ...EnvironmentCommon\n    }\n  }\n": types.setAnthaHubReleaseVersionDocument,
    "\n  mutation setDeviceDriverReleaseVersion(\n    $environmentId: String!\n    $deviceDriverReleaseVersion: String!\n    $deviceClass: String!\n  ) {\n    setDeviceDriverReleaseVersion(\n      input: {\n        environmentId: $environmentId\n        deviceDriverReleaseVersion: $deviceDriverReleaseVersion\n        deviceClass: $deviceClass\n      }\n    ) {\n      ...EnvironmentCommon\n    }\n  }\n": types.setDeviceDriverReleaseVersionDocument,
    "\n  query getOnlineMigrationAdminStates {\n    environments {\n      ...EnvironmentCommon\n      onlineMigrationAdminStates {\n        ...OnlineMigrationAdminStateCommon\n      }\n    }\n  }\n": types.getOnlineMigrationAdminStatesDocument,
    "\n  query getOrganisations {\n    organisations {\n      ...OrganisationCommon\n      environmentConfigs {\n        ...OrganisationEnvironmentCommon\n      }\n    }\n  }\n": types.getOrganisationsDocument,
    "\n  query getOrganisation($humanIdentifier: String!) {\n    organisation(humanIdentifier: $humanIdentifier) {\n      ...OrganisationCommon\n\n      auth0ConnectionId\n      auth0ConnectionName\n      auth0ConnectionStrategy\n      environmentConfigs {\n        ...OrganisationEnvironmentCommon\n      }\n      hasUserDatabase\n      users {\n        ...UserCommon\n        lastLogin\n        loginsCount\n        addedBy\n      }\n    }\n  }\n": types.getOrganisationDocument,
    "\n  query getEnvironment($hostname: String!) {\n    environment(hostname: $hostname) {\n      id\n      hostname\n      baseURL\n      additionalURLs\n      notes\n      anthaHubReleaseVersion\n      anthaHubAutoUpdateEnabled\n      deviceDriverVersions {\n        deviceClass\n        version\n        autoUpdateEnabled\n      }\n      enabledOrganisationEnvironments {\n        organisation {\n          ...OrganisationCommon\n        }\n      }\n    }\n  }\n": types.getEnvironmentDocument,
    "\n  query getOrganisationEnvironment($orgHumanIdentifier: String!, $envHostname: String!) {\n    organisationEnvironment(\n      orgHumanIdentifier: $orgHumanIdentifier\n      envHostname: $envHostname\n    ) {\n      ...OrganisationEnvironmentCommon\n      allowedElementPaths\n      organisation {\n        ...OrganisationCommon\n      }\n    }\n    supportConnection {\n      id\n      enabledClients\n    }\n  }\n": types.getOrganisationEnvironmentDocument,
    "\n  mutation updateConnection(\n    $connectionId: String!\n    $name: String\n    $strategy: String\n    $enabledClients: [String!]\n  ) {\n    updateConnection(\n      input: {\n        id: $connectionId\n        name: $name\n        strategy: $strategy\n        enabledClients: $enabledClients\n      }\n    ) {\n      id\n      enabledClients\n    }\n  }\n": types.updateConnectionDocument,
    "\n  query getAuth0Connections {\n    auth0Connections {\n      id\n      name\n      strategy\n      enabledClients\n    }\n  }\n": types.getAuth0ConnectionsDocument,
    "\n  query getElements($commitHash: String!, $commitBranch: String!, $commitDate: String!) {\n    elements(commitHash: $commitHash, commitBranch: $commitBranch) {\n      name\n      currentConfiguration(\n        commitHash: $commitHash\n        commitBranch: $commitBranch\n        commitDate: $commitDate\n      ) {\n        id\n        spec\n      }\n      configurations {\n        id\n        configurationType\n      }\n    }\n  }\n": types.getElementsDocument,
    "\n  query getElement($elementName: String!) {\n    element(elementName: $elementName) {\n      name\n      configurations {\n        id\n        spec\n        commitHash\n        commitDate\n        commitBranch\n        configurationType\n        createdAt\n        createdBy\n        lastModifiedAt\n        lastModifiedBy\n      }\n    }\n  }\n": types.getElementDocument,
    "\n  query getElementVersion($elementName: String!, $commitHash: String!) {\n    element(elementName: $elementName) {\n      version(commitHash: $commitHash)\n    }\n  }\n": types.getElementVersionDocument,
    "\n  query getElementDefaults($elementName: String!) {\n    element(elementName: $elementName) {\n      defaults\n    }\n  }\n": types.getElementDefaultsDocument,
    "\n  query getCommits {\n    commits {\n      commitHash\n      commitDate\n      commitBranch\n    }\n  }\n": types.getCommitsDocument,
    "\n  mutation saveElementConfiguration(\n    $id: String!\n    $elementName: String!\n    $spec: ElementConfigurationSpecBlob!\n    $commitHash: String!\n    $commitDate: DateTime!\n    $commitBranch: String!\n    $configurationType: ConfigurationType!\n  ) {\n    saveElementConfiguration(\n      input: {\n        id: $id\n        elementName: $elementName\n        spec: $spec\n        commitHash: $commitHash\n        commitDate: $commitDate\n        commitBranch: $commitBranch\n        configurationType: $configurationType\n      }\n    ) {\n      id\n    }\n  }\n": types.saveElementConfigurationDocument,
    "\n  mutation deleteElementConfiguration($id: String!) {\n    deleteElementConfiguration(input: { id: $id }) {\n      id\n    }\n  }\n": types.deleteElementConfigurationDocument,
    "\n  mutation synchronizeElementConfigurations($elementName: String!) {\n    synchronizeElementConfigurations(input: { elementName: $elementName }) {\n      elementName\n    }\n  }\n": types.synchronizeElementConfigurationsDocument,
    "\n  query getTypeNames($commit: CommitInputFields) {\n    typeConfigurations(commit: $commit) {\n      typeName\n    }\n  }\n": types.getTypeNamesDocument,
    "\n  query getConfigurationsForType($typeName: String) {\n    typeConfigurations(typeName: $typeName) {\n      id\n      spec\n      commitHash\n      commitDate\n      commitBranch\n      configurationType\n      createdAt\n      lastModifiedAt\n      lastModifiedBy\n    }\n  }\n": types.getConfigurationsForTypeDocument,
    "\n  query getAllCurrentTypeConfigurations(\n    $commit: CommitInputFields\n    $returnLatestConfigurationOnly: Boolean\n  ) {\n    typeConfigurations(\n      commit: $commit\n      returnLatestConfigurationOnly: $returnLatestConfigurationOnly\n    ) {\n      typeName\n      id\n      spec\n      commitBranch\n      commitDate\n      commitHash\n      configurationType\n      createdAt\n      lastModifiedAt\n      lastModifiedBy\n    }\n  }\n": types.getAllCurrentTypeConfigurationsDocument,
    "\n  mutation saveTypeConfiguration(\n    $id: String!\n    $typeName: String!\n    $spec: TypeConfigurationSpecBlob!\n    $commitHash: String!\n    $commitDate: DateTime!\n    $commitBranch: String!\n    $configurationType: ConfigurationType!\n  ) {\n    saveTypeConfiguration(\n      input: {\n        id: $id\n        typeName: $typeName\n        spec: $spec\n        commitHash: $commitHash\n        commitDate: $commitDate\n        commitBranch: $commitBranch\n        configurationType: $configurationType\n      }\n    ) {\n      id\n    }\n  }\n": types.saveTypeConfigurationDocument,
    "\n  mutation deleteTypeConfiguration($id: String!) {\n    deleteTypeConfiguration(input: { id: $id }) {\n      id\n    }\n  }\n": types.deleteTypeConfigurationDocument,
    "\n  mutation enableOrg($orgId: String!, $envId: String!) {\n    enableOrganisationInEnvironment(\n      input: { environmentId: $envId, organisationId: $orgId }\n    ) {\n      ...OrganisationEnvironmentCommon\n    }\n  }\n": types.enableOrgDocument,
    "\n  mutation disableOrg($orgId: String!, $envId: String!) {\n    disableOrganisationInEnvironment(\n      input: { environmentId: $envId, organisationId: $orgId }\n    ) {\n      ...OrganisationEnvironmentCommon\n    }\n  }\n": types.disableOrgDocument,
    "\n  mutation setAllowedElementPaths(\n    $organisationId: String!\n    $environmentId: String!\n    $allowedElementPaths: [String!]!\n  ) {\n    setAllowedElementPaths(\n      input: {\n        organisationId: $organisationId\n        environmentId: $environmentId\n        allowedElementPaths: $allowedElementPaths\n      }\n    ) {\n      ...OrganisationEnvironmentCommon\n      allowedElementPaths\n    }\n  }\n": types.setAllowedElementPathsDocument,
    "\n  mutation updateConnections(\n    $organisation: OrganisationType!\n    $newAuth0Connection: ConnectionType!\n  ) {\n    updateConnections(\n      input: { organisation: $organisation, newAuth0Connection: $newAuth0Connection }\n    ) {\n      newAuth0Connection {\n        id\n        name\n        strategy\n      }\n    }\n  }\n": types.updateConnectionsDocument,
    "\n  mutation addOrganisation(\n    $name: String!\n    $humanIdentifier: String!\n    $emailDomains: [String!]!\n    $isSynthaceInternal: Boolean!\n    $auth0ConnectionName: String\n  ) {\n    addOrganisation(\n      input: {\n        name: $name\n        humanIdentifier: $humanIdentifier\n        emailDomains: $emailDomains\n        isSynthaceInternal: $isSynthaceInternal\n        auth0ConnectionName: $auth0ConnectionName\n      }\n    ) {\n      ...OrganisationCommon\n    }\n  }\n": types.addOrganisationDocument,
    "\n  mutation renameOrganisation($organisationId: String!, $newName: String!) {\n    renameOrganisation(input: { organisationId: $organisationId, newName: $newName }) {\n      ...OrganisationCommon\n    }\n  }\n": types.renameOrganisationDocument,
    "\n  mutation addUser(\n    $orgId: String!\n    $givenName: String!\n    $familyName: String!\n    $email: String!\n    $roleId: String\n  ) {\n    addUser(\n      input: {\n        organisationId: $orgId\n        roleId: $roleId\n        user: { givenName: $givenName, familyName: $familyName, email: $email }\n      }\n    ) {\n      ...UserCommon\n    }\n  }\n": types.addUserDocument,
    "\n  mutation blockUser($orgId: String!, $email: String!) {\n    blockUser(input: { organisationId: $orgId, email: $email }) {\n      ...UserCommon\n    }\n  }\n": types.blockUserDocument,
    "\n  mutation unblockUser($orgId: String!, $email: String!) {\n    unblockUser(input: { organisationId: $orgId, email: $email }) {\n      ...UserCommon\n    }\n  }\n": types.unblockUserDocument,
    "\n  query getNotifications($after: String) {\n    me {\n      ...AdminUserCommon\n      notifications(includeRead: true, after: $after) {\n        items {\n          ...NotificationCommon\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n": types.getNotificationsDocument,
    "\n  mutation markAllNotificationsAsSeen {\n    markAllNotificationsAsSeen {\n      ...AdminUserCommon\n    }\n  }\n": types.markAllNotificationsAsSeenDocument,
    "\n  mutation markNotificationAsRead($notificationId: String!) {\n    markNotificationAsRead(input: { notificationId: $notificationId }) {\n      ...NotificationCommon\n    }\n  }\n": types.markNotificationAsReadDocument,
    "\n  query featureTogglesForAllEnvs {\n    environments {\n      id\n      hostname\n      ...FeatureToggleCommon\n    }\n  }\n": types.featureTogglesForAllEnvsDocument,
    "\n  query featureTogglesForEnv($hostname: String!) {\n    environment(hostname: $hostname) {\n      id\n      hostname\n      ...FeatureToggleCommon\n    }\n  }\n": types.featureTogglesForEnvDocument,
    "\n  mutation updateFeatureToggle(\n    $environmentId: String!\n    $name: String!\n    $override: FeatureToggleOverride!\n  ) {\n    updateFeatureToggle(\n      input: { environmentId: $environmentId, name: $name, override: $override }\n    ) {\n      enabled\n      config {\n        override\n      }\n    }\n  }\n": types.updateFeatureToggleDocument,
    "\n  query plateTypes($hostname: String!, $orgHumanIdentifier: String!) {\n    plateTypes(hostname: $hostname, orgHumanIdentifier: $orgHumanIdentifier) {\n      type\n      name\n    }\n  }\n": types.plateTypesDocument,
    "\n  mutation copyPlateTypes(\n    $sourceHostname: String!\n    $sourceOrgHumanIdentifier: String!\n    $destinationHostname: String!\n    $destinationOrgHumanIdentifier: String!\n    $plateTypes: [String!]!\n  ) {\n    copyPlateTypes(\n      input: {\n        sourceHostname: $sourceHostname\n        sourceOrgHumanIdentifier: $sourceOrgHumanIdentifier\n        destinationHostname: $destinationHostname\n        destinationOrgHumanIdentifier: $destinationOrgHumanIdentifier\n        plateTypes: $plateTypes\n      }\n    ) {\n      type\n      name\n    }\n  }\n": types.copyPlateTypesDocument,
    "\n  fragment RedirectCommon on Redirect {\n    id\n    shortcut\n    description\n    urlPattern\n  }\n": types.RedirectCommonFragmentDoc,
    "\n  query getRedirects {\n    redirects {\n      ...RedirectCommon\n    }\n  }\n": types.getRedirectsDocument,
    "\n  mutation addRedirect($shortcut: String!, $description: String, $urlPattern: String!) {\n    addRedirect(\n      input: { shortcut: $shortcut, description: $description, urlPattern: $urlPattern }\n    ) {\n      ...RedirectCommon\n    }\n  }\n": types.addRedirectDocument,
    "\n  mutation deleteRedirect($id: String!) {\n    deleteRedirect(input: { id: $id }) {\n      deleted\n    }\n  }\n": types.deleteRedirectDocument,
    "\n  mutation setOrganisationNotes($organisationId: String!, $notes: String!) {\n    setOrganisationNotes(input: { organisationId: $organisationId, notes: $notes }) {\n      ...OrganisationCommon\n    }\n  }\n": types.setOrganisationNotesDocument,
    "\n  mutation setEnvironmentNotes($environmentId: String!, $notes: String!) {\n    setEnvironmentNotes(input: { environmentId: $environmentId, notes: $notes }) {\n      ...EnvironmentCommon\n    }\n  }\n": types.setEnvironmentNotesDocument,
    "\n  mutation setOrganisationEnvironmentNotes(\n    $organisationId: String!\n    $environmentId: String!\n    $notes: String!\n  ) {\n    setOrganisationEnvironmentNotes(\n      input: {\n        organisationId: $organisationId\n        environmentId: $environmentId\n        notes: $notes\n      }\n    ) {\n      ...OrganisationEnvironmentCommon\n    }\n  }\n": types.setOrganisationEnvironmentNotesDocument,
    "\n  query getUserRoles($id: String!) {\n    userRole(id: $id) {\n      internalRoles {\n        id\n        name\n        description\n      }\n      customerRole {\n        id\n        name\n        description\n      }\n    }\n  }\n": types.getUserRolesDocument,
    "\n  query getUser($id: String!) {\n    user(id: $id) {\n      id\n      givenName\n      familyName\n      email\n    }\n  }\n": types.getUserDocument,
    "\n  query getAvailableRoles {\n    availableRoles {\n      internalRoles {\n        id\n        name\n        description\n      }\n      customerRoles {\n        id\n        name\n        description\n      }\n    }\n  }\n": types.getAvailableRolesDocument,
    "\n  mutation UpdateCustomerRole($input: UpdateCustomerRoleInput!) {\n    updateCustomerRole(input: $input) {\n      id\n      customerRole {\n        id\n        name\n      }\n    }\n  }\n": types.UpdateCustomerRoleDocument,
    "\n  mutation deleteOrganisation($organisationId: String!) {\n    deleteOrganisation(input: { organisationId: $organisationId }) {\n      ...OrganisationCommon\n    }\n  }\n": types.deleteOrganisationDocument,
    "\n  mutation deleteEnvironment($environmentId: String!) {\n    deleteEnvironment(input: { environmentId: $environmentId }) {\n      ...EnvironmentCommon\n    }\n  }\n": types.deleteEnvironmentDocument,
    "\n  query getExampleActions($names: [String!], $pagingInfo: PagingInfo) {\n    actions(names: $names, pagingInfo: $pagingInfo) {\n      items {\n        id\n        resultType\n        responseBody\n        userEmail\n        occurredAt\n      }\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n": types.getExampleActionsDocument,
    "\n  mutation publishTutorials($targetEnvironmentBaseURLs: [String!]) {\n    publishTutorials(input: { targetEnvironmentBaseURLs: $targetEnvironmentBaseURLs }) {\n      successEnvs\n      failureEnvs {\n        envURL\n        message\n      }\n    }\n  }\n": types.publishTutorialsDocument,
    "\n  mutation publishExampleWorkflows($targetEnvironmentBaseURLs: [String!]) {\n    publishExampleWorkflows(\n      input: { targetEnvironmentBaseURLs: $targetEnvironmentBaseURLs }\n    ) {\n      sourceEnvURL\n      successEnvs {\n        envURL\n        supportedWorkflowIds\n        unsupportedWorkflowIds {\n          id\n          reason\n          details\n        }\n      }\n      failureEnvs {\n        envURL\n        message\n      }\n    }\n  }\n": types.publishExampleWorkflowsDocument,
    "\n  query GetTutorialsTargetEnvironments {\n    getTutorialsTargetEnvironments {\n      environmentURLs\n    }\n  }\n": types.GetTutorialsTargetEnvironmentsDocument,
    "\n  query GetExampleWorkflowsTargetEnvironments {\n    getExampleWorkflowsTargetEnvironments {\n      environmentURLs\n    }\n  }\n": types.GetExampleWorkflowsTargetEnvironmentsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OrganisationCommon on Organisation {\n    id\n    name\n    humanIdentifier\n    emailDomains\n    isSynthaceInternal\n    notes\n  }\n"): (typeof documents)["\n  fragment OrganisationCommon on Organisation {\n    id\n    name\n    humanIdentifier\n    emailDomains\n    isSynthaceInternal\n    notes\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment EnvironmentCommon on Environment {\n    id\n    hostname\n    baseURL\n    notes\n  }\n"): (typeof documents)["\n  fragment EnvironmentCommon on Environment {\n    id\n    hostname\n    baseURL\n    notes\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FeatureToggleCommon on Environment {\n    featureToggles {\n      name\n      enabled\n      description\n      requiresReload\n      kind\n      config {\n        override\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment FeatureToggleCommon on Environment {\n    featureToggles {\n      name\n      enabled\n      description\n      requiresReload\n      kind\n      config {\n        override\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OnlineMigrationAdminStateCommon on OnlineMigrationAdminState {\n    backfillEntitiesWritten\n    backfillLockedUntil\n    backfillVersionCompleted\n    backfillVersionProgress\n    codeVersion\n    doubleReadFraction\n    doubleReadFractionOverride\n    entityCount\n    name\n    stage\n    stageOverride\n  }\n"): (typeof documents)["\n  fragment OnlineMigrationAdminStateCommon on OnlineMigrationAdminState {\n    backfillEntitiesWritten\n    backfillLockedUntil\n    backfillVersionCompleted\n    backfillVersionProgress\n    codeVersion\n    doubleReadFraction\n    doubleReadFractionOverride\n    entityCount\n    name\n    stage\n    stageOverride\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OrganisationEnvironmentCommon on OrganisationEnvironment {\n    environmentId\n    organisationId\n    auth0ClientId\n    isEnabled\n    notes\n    environment {\n      ...EnvironmentCommon\n    }\n  }\n"): (typeof documents)["\n  fragment OrganisationEnvironmentCommon on OrganisationEnvironment {\n    environmentId\n    organisationId\n    auth0ClientId\n    isEnabled\n    notes\n    environment {\n      ...EnvironmentCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UserCommon on User {\n    id\n    givenName\n    familyName\n    email\n    blocked\n  }\n"): (typeof documents)["\n  fragment UserCommon on User {\n    id\n    givenName\n    familyName\n    email\n    blocked\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AdminUserCommon on AdminUser {\n    id\n    email\n    unseenNotificationsCount\n  }\n"): (typeof documents)["\n  fragment AdminUserCommon on AdminUser {\n    id\n    email\n    unseenNotificationsCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment NotificationCommon on Notification {\n    id\n    message\n    isRead\n    isSeen\n    url\n    createdAt\n  }\n"): (typeof documents)["\n  fragment NotificationCommon on Notification {\n    id\n    message\n    isRead\n    isSeen\n    url\n    createdAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ReleaseArtifactCommon on ReleaseArtifact {\n    filename\n    sizeBytes\n    downloadURL\n    architecture\n    type\n  }\n"): (typeof documents)["\n  fragment ReleaseArtifactCommon on ReleaseArtifact {\n    filename\n    sizeBytes\n    downloadURL\n    architecture\n    type\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ReleaseCommon on Release {\n    version\n    releaseNotesURL\n    createdAt\n    artifacts {\n      ...ReleaseArtifactCommon\n    }\n  }\n"): (typeof documents)["\n  fragment ReleaseCommon on Release {\n    version\n    releaseNotesURL\n    createdAt\n    artifacts {\n      ...ReleaseArtifactCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getEnvironments {\n    environments {\n      ...EnvironmentCommon\n    }\n  }\n"): (typeof documents)["\n  query getEnvironments {\n    environments {\n      ...EnvironmentCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getEnvironmentsWithHealthcheck {\n    environments {\n      ...EnvironmentCommon\n      canAuthenticateWithAppserver\n      anthaHubAutoUpdateEnabled\n      anthaHubReleaseVersion\n    }\n  }\n"): (typeof documents)["\n  query getEnvironmentsWithHealthcheck {\n    environments {\n      ...EnvironmentCommon\n      canAuthenticateWithAppserver\n      anthaHubAutoUpdateEnabled\n      anthaHubReleaseVersion\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getLatestAnthaHubReleases {\n    latestAnthaHubReleases {\n      ...ReleaseCommon\n    }\n  }\n"): (typeof documents)["\n  query getLatestAnthaHubReleases {\n    latestAnthaHubReleases {\n      ...ReleaseCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getLatestDeviceDriverReleases {\n    latestDeviceDriverReleases {\n      ...ReleaseCommon\n    }\n  }\n"): (typeof documents)["\n  query getLatestDeviceDriverReleases {\n    latestDeviceDriverReleases {\n      ...ReleaseCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAllReleasesForProject(\n    $projectName: String!\n    $includePreReleaseVersions: Boolean!\n  ) {\n    allReleasesForProject(\n      projectName: $projectName\n      includePreReleaseVersions: $includePreReleaseVersions\n    ) {\n      ...ReleaseCommon\n    }\n  }\n"): (typeof documents)["\n  query getAllReleasesForProject(\n    $projectName: String!\n    $includePreReleaseVersions: Boolean!\n  ) {\n    allReleasesForProject(\n      projectName: $projectName\n      includePreReleaseVersions: $includePreReleaseVersions\n    ) {\n      ...ReleaseCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation addEnvironment($domainPrefix: String!) {\n    addEnvironment(input: { domainPrefix: $domainPrefix }) {\n      ...EnvironmentCommon\n    }\n  }\n"): (typeof documents)["\n  mutation addEnvironment($domainPrefix: String!) {\n    addEnvironment(input: { domainPrefix: $domainPrefix }) {\n      ...EnvironmentCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation setAnthaHubReleaseVersion(\n    $environmentId: String!\n    $anthaHubReleaseVersion: String!\n  ) {\n    setAnthaHubReleaseVersion(\n      input: {\n        environmentId: $environmentId\n        anthaHubReleaseVersion: $anthaHubReleaseVersion\n      }\n    ) {\n      ...EnvironmentCommon\n    }\n  }\n"): (typeof documents)["\n  mutation setAnthaHubReleaseVersion(\n    $environmentId: String!\n    $anthaHubReleaseVersion: String!\n  ) {\n    setAnthaHubReleaseVersion(\n      input: {\n        environmentId: $environmentId\n        anthaHubReleaseVersion: $anthaHubReleaseVersion\n      }\n    ) {\n      ...EnvironmentCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation setDeviceDriverReleaseVersion(\n    $environmentId: String!\n    $deviceDriverReleaseVersion: String!\n    $deviceClass: String!\n  ) {\n    setDeviceDriverReleaseVersion(\n      input: {\n        environmentId: $environmentId\n        deviceDriverReleaseVersion: $deviceDriverReleaseVersion\n        deviceClass: $deviceClass\n      }\n    ) {\n      ...EnvironmentCommon\n    }\n  }\n"): (typeof documents)["\n  mutation setDeviceDriverReleaseVersion(\n    $environmentId: String!\n    $deviceDriverReleaseVersion: String!\n    $deviceClass: String!\n  ) {\n    setDeviceDriverReleaseVersion(\n      input: {\n        environmentId: $environmentId\n        deviceDriverReleaseVersion: $deviceDriverReleaseVersion\n        deviceClass: $deviceClass\n      }\n    ) {\n      ...EnvironmentCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getOnlineMigrationAdminStates {\n    environments {\n      ...EnvironmentCommon\n      onlineMigrationAdminStates {\n        ...OnlineMigrationAdminStateCommon\n      }\n    }\n  }\n"): (typeof documents)["\n  query getOnlineMigrationAdminStates {\n    environments {\n      ...EnvironmentCommon\n      onlineMigrationAdminStates {\n        ...OnlineMigrationAdminStateCommon\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getOrganisations {\n    organisations {\n      ...OrganisationCommon\n      environmentConfigs {\n        ...OrganisationEnvironmentCommon\n      }\n    }\n  }\n"): (typeof documents)["\n  query getOrganisations {\n    organisations {\n      ...OrganisationCommon\n      environmentConfigs {\n        ...OrganisationEnvironmentCommon\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getOrganisation($humanIdentifier: String!) {\n    organisation(humanIdentifier: $humanIdentifier) {\n      ...OrganisationCommon\n\n      auth0ConnectionId\n      auth0ConnectionName\n      auth0ConnectionStrategy\n      environmentConfigs {\n        ...OrganisationEnvironmentCommon\n      }\n      hasUserDatabase\n      users {\n        ...UserCommon\n        lastLogin\n        loginsCount\n        addedBy\n      }\n    }\n  }\n"): (typeof documents)["\n  query getOrganisation($humanIdentifier: String!) {\n    organisation(humanIdentifier: $humanIdentifier) {\n      ...OrganisationCommon\n\n      auth0ConnectionId\n      auth0ConnectionName\n      auth0ConnectionStrategy\n      environmentConfigs {\n        ...OrganisationEnvironmentCommon\n      }\n      hasUserDatabase\n      users {\n        ...UserCommon\n        lastLogin\n        loginsCount\n        addedBy\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getEnvironment($hostname: String!) {\n    environment(hostname: $hostname) {\n      id\n      hostname\n      baseURL\n      additionalURLs\n      notes\n      anthaHubReleaseVersion\n      anthaHubAutoUpdateEnabled\n      deviceDriverVersions {\n        deviceClass\n        version\n        autoUpdateEnabled\n      }\n      enabledOrganisationEnvironments {\n        organisation {\n          ...OrganisationCommon\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getEnvironment($hostname: String!) {\n    environment(hostname: $hostname) {\n      id\n      hostname\n      baseURL\n      additionalURLs\n      notes\n      anthaHubReleaseVersion\n      anthaHubAutoUpdateEnabled\n      deviceDriverVersions {\n        deviceClass\n        version\n        autoUpdateEnabled\n      }\n      enabledOrganisationEnvironments {\n        organisation {\n          ...OrganisationCommon\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getOrganisationEnvironment($orgHumanIdentifier: String!, $envHostname: String!) {\n    organisationEnvironment(\n      orgHumanIdentifier: $orgHumanIdentifier\n      envHostname: $envHostname\n    ) {\n      ...OrganisationEnvironmentCommon\n      allowedElementPaths\n      organisation {\n        ...OrganisationCommon\n      }\n    }\n    supportConnection {\n      id\n      enabledClients\n    }\n  }\n"): (typeof documents)["\n  query getOrganisationEnvironment($orgHumanIdentifier: String!, $envHostname: String!) {\n    organisationEnvironment(\n      orgHumanIdentifier: $orgHumanIdentifier\n      envHostname: $envHostname\n    ) {\n      ...OrganisationEnvironmentCommon\n      allowedElementPaths\n      organisation {\n        ...OrganisationCommon\n      }\n    }\n    supportConnection {\n      id\n      enabledClients\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateConnection(\n    $connectionId: String!\n    $name: String\n    $strategy: String\n    $enabledClients: [String!]\n  ) {\n    updateConnection(\n      input: {\n        id: $connectionId\n        name: $name\n        strategy: $strategy\n        enabledClients: $enabledClients\n      }\n    ) {\n      id\n      enabledClients\n    }\n  }\n"): (typeof documents)["\n  mutation updateConnection(\n    $connectionId: String!\n    $name: String\n    $strategy: String\n    $enabledClients: [String!]\n  ) {\n    updateConnection(\n      input: {\n        id: $connectionId\n        name: $name\n        strategy: $strategy\n        enabledClients: $enabledClients\n      }\n    ) {\n      id\n      enabledClients\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAuth0Connections {\n    auth0Connections {\n      id\n      name\n      strategy\n      enabledClients\n    }\n  }\n"): (typeof documents)["\n  query getAuth0Connections {\n    auth0Connections {\n      id\n      name\n      strategy\n      enabledClients\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getElements($commitHash: String!, $commitBranch: String!, $commitDate: String!) {\n    elements(commitHash: $commitHash, commitBranch: $commitBranch) {\n      name\n      currentConfiguration(\n        commitHash: $commitHash\n        commitBranch: $commitBranch\n        commitDate: $commitDate\n      ) {\n        id\n        spec\n      }\n      configurations {\n        id\n        configurationType\n      }\n    }\n  }\n"): (typeof documents)["\n  query getElements($commitHash: String!, $commitBranch: String!, $commitDate: String!) {\n    elements(commitHash: $commitHash, commitBranch: $commitBranch) {\n      name\n      currentConfiguration(\n        commitHash: $commitHash\n        commitBranch: $commitBranch\n        commitDate: $commitDate\n      ) {\n        id\n        spec\n      }\n      configurations {\n        id\n        configurationType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getElement($elementName: String!) {\n    element(elementName: $elementName) {\n      name\n      configurations {\n        id\n        spec\n        commitHash\n        commitDate\n        commitBranch\n        configurationType\n        createdAt\n        createdBy\n        lastModifiedAt\n        lastModifiedBy\n      }\n    }\n  }\n"): (typeof documents)["\n  query getElement($elementName: String!) {\n    element(elementName: $elementName) {\n      name\n      configurations {\n        id\n        spec\n        commitHash\n        commitDate\n        commitBranch\n        configurationType\n        createdAt\n        createdBy\n        lastModifiedAt\n        lastModifiedBy\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getElementVersion($elementName: String!, $commitHash: String!) {\n    element(elementName: $elementName) {\n      version(commitHash: $commitHash)\n    }\n  }\n"): (typeof documents)["\n  query getElementVersion($elementName: String!, $commitHash: String!) {\n    element(elementName: $elementName) {\n      version(commitHash: $commitHash)\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getElementDefaults($elementName: String!) {\n    element(elementName: $elementName) {\n      defaults\n    }\n  }\n"): (typeof documents)["\n  query getElementDefaults($elementName: String!) {\n    element(elementName: $elementName) {\n      defaults\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getCommits {\n    commits {\n      commitHash\n      commitDate\n      commitBranch\n    }\n  }\n"): (typeof documents)["\n  query getCommits {\n    commits {\n      commitHash\n      commitDate\n      commitBranch\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation saveElementConfiguration(\n    $id: String!\n    $elementName: String!\n    $spec: ElementConfigurationSpecBlob!\n    $commitHash: String!\n    $commitDate: DateTime!\n    $commitBranch: String!\n    $configurationType: ConfigurationType!\n  ) {\n    saveElementConfiguration(\n      input: {\n        id: $id\n        elementName: $elementName\n        spec: $spec\n        commitHash: $commitHash\n        commitDate: $commitDate\n        commitBranch: $commitBranch\n        configurationType: $configurationType\n      }\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation saveElementConfiguration(\n    $id: String!\n    $elementName: String!\n    $spec: ElementConfigurationSpecBlob!\n    $commitHash: String!\n    $commitDate: DateTime!\n    $commitBranch: String!\n    $configurationType: ConfigurationType!\n  ) {\n    saveElementConfiguration(\n      input: {\n        id: $id\n        elementName: $elementName\n        spec: $spec\n        commitHash: $commitHash\n        commitDate: $commitDate\n        commitBranch: $commitBranch\n        configurationType: $configurationType\n      }\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteElementConfiguration($id: String!) {\n    deleteElementConfiguration(input: { id: $id }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation deleteElementConfiguration($id: String!) {\n    deleteElementConfiguration(input: { id: $id }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation synchronizeElementConfigurations($elementName: String!) {\n    synchronizeElementConfigurations(input: { elementName: $elementName }) {\n      elementName\n    }\n  }\n"): (typeof documents)["\n  mutation synchronizeElementConfigurations($elementName: String!) {\n    synchronizeElementConfigurations(input: { elementName: $elementName }) {\n      elementName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTypeNames($commit: CommitInputFields) {\n    typeConfigurations(commit: $commit) {\n      typeName\n    }\n  }\n"): (typeof documents)["\n  query getTypeNames($commit: CommitInputFields) {\n    typeConfigurations(commit: $commit) {\n      typeName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getConfigurationsForType($typeName: String) {\n    typeConfigurations(typeName: $typeName) {\n      id\n      spec\n      commitHash\n      commitDate\n      commitBranch\n      configurationType\n      createdAt\n      lastModifiedAt\n      lastModifiedBy\n    }\n  }\n"): (typeof documents)["\n  query getConfigurationsForType($typeName: String) {\n    typeConfigurations(typeName: $typeName) {\n      id\n      spec\n      commitHash\n      commitDate\n      commitBranch\n      configurationType\n      createdAt\n      lastModifiedAt\n      lastModifiedBy\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAllCurrentTypeConfigurations(\n    $commit: CommitInputFields\n    $returnLatestConfigurationOnly: Boolean\n  ) {\n    typeConfigurations(\n      commit: $commit\n      returnLatestConfigurationOnly: $returnLatestConfigurationOnly\n    ) {\n      typeName\n      id\n      spec\n      commitBranch\n      commitDate\n      commitHash\n      configurationType\n      createdAt\n      lastModifiedAt\n      lastModifiedBy\n    }\n  }\n"): (typeof documents)["\n  query getAllCurrentTypeConfigurations(\n    $commit: CommitInputFields\n    $returnLatestConfigurationOnly: Boolean\n  ) {\n    typeConfigurations(\n      commit: $commit\n      returnLatestConfigurationOnly: $returnLatestConfigurationOnly\n    ) {\n      typeName\n      id\n      spec\n      commitBranch\n      commitDate\n      commitHash\n      configurationType\n      createdAt\n      lastModifiedAt\n      lastModifiedBy\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation saveTypeConfiguration(\n    $id: String!\n    $typeName: String!\n    $spec: TypeConfigurationSpecBlob!\n    $commitHash: String!\n    $commitDate: DateTime!\n    $commitBranch: String!\n    $configurationType: ConfigurationType!\n  ) {\n    saveTypeConfiguration(\n      input: {\n        id: $id\n        typeName: $typeName\n        spec: $spec\n        commitHash: $commitHash\n        commitDate: $commitDate\n        commitBranch: $commitBranch\n        configurationType: $configurationType\n      }\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation saveTypeConfiguration(\n    $id: String!\n    $typeName: String!\n    $spec: TypeConfigurationSpecBlob!\n    $commitHash: String!\n    $commitDate: DateTime!\n    $commitBranch: String!\n    $configurationType: ConfigurationType!\n  ) {\n    saveTypeConfiguration(\n      input: {\n        id: $id\n        typeName: $typeName\n        spec: $spec\n        commitHash: $commitHash\n        commitDate: $commitDate\n        commitBranch: $commitBranch\n        configurationType: $configurationType\n      }\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteTypeConfiguration($id: String!) {\n    deleteTypeConfiguration(input: { id: $id }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation deleteTypeConfiguration($id: String!) {\n    deleteTypeConfiguration(input: { id: $id }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation enableOrg($orgId: String!, $envId: String!) {\n    enableOrganisationInEnvironment(\n      input: { environmentId: $envId, organisationId: $orgId }\n    ) {\n      ...OrganisationEnvironmentCommon\n    }\n  }\n"): (typeof documents)["\n  mutation enableOrg($orgId: String!, $envId: String!) {\n    enableOrganisationInEnvironment(\n      input: { environmentId: $envId, organisationId: $orgId }\n    ) {\n      ...OrganisationEnvironmentCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation disableOrg($orgId: String!, $envId: String!) {\n    disableOrganisationInEnvironment(\n      input: { environmentId: $envId, organisationId: $orgId }\n    ) {\n      ...OrganisationEnvironmentCommon\n    }\n  }\n"): (typeof documents)["\n  mutation disableOrg($orgId: String!, $envId: String!) {\n    disableOrganisationInEnvironment(\n      input: { environmentId: $envId, organisationId: $orgId }\n    ) {\n      ...OrganisationEnvironmentCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation setAllowedElementPaths(\n    $organisationId: String!\n    $environmentId: String!\n    $allowedElementPaths: [String!]!\n  ) {\n    setAllowedElementPaths(\n      input: {\n        organisationId: $organisationId\n        environmentId: $environmentId\n        allowedElementPaths: $allowedElementPaths\n      }\n    ) {\n      ...OrganisationEnvironmentCommon\n      allowedElementPaths\n    }\n  }\n"): (typeof documents)["\n  mutation setAllowedElementPaths(\n    $organisationId: String!\n    $environmentId: String!\n    $allowedElementPaths: [String!]!\n  ) {\n    setAllowedElementPaths(\n      input: {\n        organisationId: $organisationId\n        environmentId: $environmentId\n        allowedElementPaths: $allowedElementPaths\n      }\n    ) {\n      ...OrganisationEnvironmentCommon\n      allowedElementPaths\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateConnections(\n    $organisation: OrganisationType!\n    $newAuth0Connection: ConnectionType!\n  ) {\n    updateConnections(\n      input: { organisation: $organisation, newAuth0Connection: $newAuth0Connection }\n    ) {\n      newAuth0Connection {\n        id\n        name\n        strategy\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateConnections(\n    $organisation: OrganisationType!\n    $newAuth0Connection: ConnectionType!\n  ) {\n    updateConnections(\n      input: { organisation: $organisation, newAuth0Connection: $newAuth0Connection }\n    ) {\n      newAuth0Connection {\n        id\n        name\n        strategy\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation addOrganisation(\n    $name: String!\n    $humanIdentifier: String!\n    $emailDomains: [String!]!\n    $isSynthaceInternal: Boolean!\n    $auth0ConnectionName: String\n  ) {\n    addOrganisation(\n      input: {\n        name: $name\n        humanIdentifier: $humanIdentifier\n        emailDomains: $emailDomains\n        isSynthaceInternal: $isSynthaceInternal\n        auth0ConnectionName: $auth0ConnectionName\n      }\n    ) {\n      ...OrganisationCommon\n    }\n  }\n"): (typeof documents)["\n  mutation addOrganisation(\n    $name: String!\n    $humanIdentifier: String!\n    $emailDomains: [String!]!\n    $isSynthaceInternal: Boolean!\n    $auth0ConnectionName: String\n  ) {\n    addOrganisation(\n      input: {\n        name: $name\n        humanIdentifier: $humanIdentifier\n        emailDomains: $emailDomains\n        isSynthaceInternal: $isSynthaceInternal\n        auth0ConnectionName: $auth0ConnectionName\n      }\n    ) {\n      ...OrganisationCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation renameOrganisation($organisationId: String!, $newName: String!) {\n    renameOrganisation(input: { organisationId: $organisationId, newName: $newName }) {\n      ...OrganisationCommon\n    }\n  }\n"): (typeof documents)["\n  mutation renameOrganisation($organisationId: String!, $newName: String!) {\n    renameOrganisation(input: { organisationId: $organisationId, newName: $newName }) {\n      ...OrganisationCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation addUser(\n    $orgId: String!\n    $givenName: String!\n    $familyName: String!\n    $email: String!\n    $roleId: String\n  ) {\n    addUser(\n      input: {\n        organisationId: $orgId\n        roleId: $roleId\n        user: { givenName: $givenName, familyName: $familyName, email: $email }\n      }\n    ) {\n      ...UserCommon\n    }\n  }\n"): (typeof documents)["\n  mutation addUser(\n    $orgId: String!\n    $givenName: String!\n    $familyName: String!\n    $email: String!\n    $roleId: String\n  ) {\n    addUser(\n      input: {\n        organisationId: $orgId\n        roleId: $roleId\n        user: { givenName: $givenName, familyName: $familyName, email: $email }\n      }\n    ) {\n      ...UserCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation blockUser($orgId: String!, $email: String!) {\n    blockUser(input: { organisationId: $orgId, email: $email }) {\n      ...UserCommon\n    }\n  }\n"): (typeof documents)["\n  mutation blockUser($orgId: String!, $email: String!) {\n    blockUser(input: { organisationId: $orgId, email: $email }) {\n      ...UserCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation unblockUser($orgId: String!, $email: String!) {\n    unblockUser(input: { organisationId: $orgId, email: $email }) {\n      ...UserCommon\n    }\n  }\n"): (typeof documents)["\n  mutation unblockUser($orgId: String!, $email: String!) {\n    unblockUser(input: { organisationId: $orgId, email: $email }) {\n      ...UserCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getNotifications($after: String) {\n    me {\n      ...AdminUserCommon\n      notifications(includeRead: true, after: $after) {\n        items {\n          ...NotificationCommon\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getNotifications($after: String) {\n    me {\n      ...AdminUserCommon\n      notifications(includeRead: true, after: $after) {\n        items {\n          ...NotificationCommon\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation markAllNotificationsAsSeen {\n    markAllNotificationsAsSeen {\n      ...AdminUserCommon\n    }\n  }\n"): (typeof documents)["\n  mutation markAllNotificationsAsSeen {\n    markAllNotificationsAsSeen {\n      ...AdminUserCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation markNotificationAsRead($notificationId: String!) {\n    markNotificationAsRead(input: { notificationId: $notificationId }) {\n      ...NotificationCommon\n    }\n  }\n"): (typeof documents)["\n  mutation markNotificationAsRead($notificationId: String!) {\n    markNotificationAsRead(input: { notificationId: $notificationId }) {\n      ...NotificationCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query featureTogglesForAllEnvs {\n    environments {\n      id\n      hostname\n      ...FeatureToggleCommon\n    }\n  }\n"): (typeof documents)["\n  query featureTogglesForAllEnvs {\n    environments {\n      id\n      hostname\n      ...FeatureToggleCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query featureTogglesForEnv($hostname: String!) {\n    environment(hostname: $hostname) {\n      id\n      hostname\n      ...FeatureToggleCommon\n    }\n  }\n"): (typeof documents)["\n  query featureTogglesForEnv($hostname: String!) {\n    environment(hostname: $hostname) {\n      id\n      hostname\n      ...FeatureToggleCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateFeatureToggle(\n    $environmentId: String!\n    $name: String!\n    $override: FeatureToggleOverride!\n  ) {\n    updateFeatureToggle(\n      input: { environmentId: $environmentId, name: $name, override: $override }\n    ) {\n      enabled\n      config {\n        override\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateFeatureToggle(\n    $environmentId: String!\n    $name: String!\n    $override: FeatureToggleOverride!\n  ) {\n    updateFeatureToggle(\n      input: { environmentId: $environmentId, name: $name, override: $override }\n    ) {\n      enabled\n      config {\n        override\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query plateTypes($hostname: String!, $orgHumanIdentifier: String!) {\n    plateTypes(hostname: $hostname, orgHumanIdentifier: $orgHumanIdentifier) {\n      type\n      name\n    }\n  }\n"): (typeof documents)["\n  query plateTypes($hostname: String!, $orgHumanIdentifier: String!) {\n    plateTypes(hostname: $hostname, orgHumanIdentifier: $orgHumanIdentifier) {\n      type\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation copyPlateTypes(\n    $sourceHostname: String!\n    $sourceOrgHumanIdentifier: String!\n    $destinationHostname: String!\n    $destinationOrgHumanIdentifier: String!\n    $plateTypes: [String!]!\n  ) {\n    copyPlateTypes(\n      input: {\n        sourceHostname: $sourceHostname\n        sourceOrgHumanIdentifier: $sourceOrgHumanIdentifier\n        destinationHostname: $destinationHostname\n        destinationOrgHumanIdentifier: $destinationOrgHumanIdentifier\n        plateTypes: $plateTypes\n      }\n    ) {\n      type\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation copyPlateTypes(\n    $sourceHostname: String!\n    $sourceOrgHumanIdentifier: String!\n    $destinationHostname: String!\n    $destinationOrgHumanIdentifier: String!\n    $plateTypes: [String!]!\n  ) {\n    copyPlateTypes(\n      input: {\n        sourceHostname: $sourceHostname\n        sourceOrgHumanIdentifier: $sourceOrgHumanIdentifier\n        destinationHostname: $destinationHostname\n        destinationOrgHumanIdentifier: $destinationOrgHumanIdentifier\n        plateTypes: $plateTypes\n      }\n    ) {\n      type\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment RedirectCommon on Redirect {\n    id\n    shortcut\n    description\n    urlPattern\n  }\n"): (typeof documents)["\n  fragment RedirectCommon on Redirect {\n    id\n    shortcut\n    description\n    urlPattern\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getRedirects {\n    redirects {\n      ...RedirectCommon\n    }\n  }\n"): (typeof documents)["\n  query getRedirects {\n    redirects {\n      ...RedirectCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation addRedirect($shortcut: String!, $description: String, $urlPattern: String!) {\n    addRedirect(\n      input: { shortcut: $shortcut, description: $description, urlPattern: $urlPattern }\n    ) {\n      ...RedirectCommon\n    }\n  }\n"): (typeof documents)["\n  mutation addRedirect($shortcut: String!, $description: String, $urlPattern: String!) {\n    addRedirect(\n      input: { shortcut: $shortcut, description: $description, urlPattern: $urlPattern }\n    ) {\n      ...RedirectCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteRedirect($id: String!) {\n    deleteRedirect(input: { id: $id }) {\n      deleted\n    }\n  }\n"): (typeof documents)["\n  mutation deleteRedirect($id: String!) {\n    deleteRedirect(input: { id: $id }) {\n      deleted\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation setOrganisationNotes($organisationId: String!, $notes: String!) {\n    setOrganisationNotes(input: { organisationId: $organisationId, notes: $notes }) {\n      ...OrganisationCommon\n    }\n  }\n"): (typeof documents)["\n  mutation setOrganisationNotes($organisationId: String!, $notes: String!) {\n    setOrganisationNotes(input: { organisationId: $organisationId, notes: $notes }) {\n      ...OrganisationCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation setEnvironmentNotes($environmentId: String!, $notes: String!) {\n    setEnvironmentNotes(input: { environmentId: $environmentId, notes: $notes }) {\n      ...EnvironmentCommon\n    }\n  }\n"): (typeof documents)["\n  mutation setEnvironmentNotes($environmentId: String!, $notes: String!) {\n    setEnvironmentNotes(input: { environmentId: $environmentId, notes: $notes }) {\n      ...EnvironmentCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation setOrganisationEnvironmentNotes(\n    $organisationId: String!\n    $environmentId: String!\n    $notes: String!\n  ) {\n    setOrganisationEnvironmentNotes(\n      input: {\n        organisationId: $organisationId\n        environmentId: $environmentId\n        notes: $notes\n      }\n    ) {\n      ...OrganisationEnvironmentCommon\n    }\n  }\n"): (typeof documents)["\n  mutation setOrganisationEnvironmentNotes(\n    $organisationId: String!\n    $environmentId: String!\n    $notes: String!\n  ) {\n    setOrganisationEnvironmentNotes(\n      input: {\n        organisationId: $organisationId\n        environmentId: $environmentId\n        notes: $notes\n      }\n    ) {\n      ...OrganisationEnvironmentCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getUserRoles($id: String!) {\n    userRole(id: $id) {\n      internalRoles {\n        id\n        name\n        description\n      }\n      customerRole {\n        id\n        name\n        description\n      }\n    }\n  }\n"): (typeof documents)["\n  query getUserRoles($id: String!) {\n    userRole(id: $id) {\n      internalRoles {\n        id\n        name\n        description\n      }\n      customerRole {\n        id\n        name\n        description\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getUser($id: String!) {\n    user(id: $id) {\n      id\n      givenName\n      familyName\n      email\n    }\n  }\n"): (typeof documents)["\n  query getUser($id: String!) {\n    user(id: $id) {\n      id\n      givenName\n      familyName\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAvailableRoles {\n    availableRoles {\n      internalRoles {\n        id\n        name\n        description\n      }\n      customerRoles {\n        id\n        name\n        description\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAvailableRoles {\n    availableRoles {\n      internalRoles {\n        id\n        name\n        description\n      }\n      customerRoles {\n        id\n        name\n        description\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateCustomerRole($input: UpdateCustomerRoleInput!) {\n    updateCustomerRole(input: $input) {\n      id\n      customerRole {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCustomerRole($input: UpdateCustomerRoleInput!) {\n    updateCustomerRole(input: $input) {\n      id\n      customerRole {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteOrganisation($organisationId: String!) {\n    deleteOrganisation(input: { organisationId: $organisationId }) {\n      ...OrganisationCommon\n    }\n  }\n"): (typeof documents)["\n  mutation deleteOrganisation($organisationId: String!) {\n    deleteOrganisation(input: { organisationId: $organisationId }) {\n      ...OrganisationCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteEnvironment($environmentId: String!) {\n    deleteEnvironment(input: { environmentId: $environmentId }) {\n      ...EnvironmentCommon\n    }\n  }\n"): (typeof documents)["\n  mutation deleteEnvironment($environmentId: String!) {\n    deleteEnvironment(input: { environmentId: $environmentId }) {\n      ...EnvironmentCommon\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getExampleActions($names: [String!], $pagingInfo: PagingInfo) {\n    actions(names: $names, pagingInfo: $pagingInfo) {\n      items {\n        id\n        resultType\n        responseBody\n        userEmail\n        occurredAt\n      }\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query getExampleActions($names: [String!], $pagingInfo: PagingInfo) {\n    actions(names: $names, pagingInfo: $pagingInfo) {\n      items {\n        id\n        resultType\n        responseBody\n        userEmail\n        occurredAt\n      }\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation publishTutorials($targetEnvironmentBaseURLs: [String!]) {\n    publishTutorials(input: { targetEnvironmentBaseURLs: $targetEnvironmentBaseURLs }) {\n      successEnvs\n      failureEnvs {\n        envURL\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation publishTutorials($targetEnvironmentBaseURLs: [String!]) {\n    publishTutorials(input: { targetEnvironmentBaseURLs: $targetEnvironmentBaseURLs }) {\n      successEnvs\n      failureEnvs {\n        envURL\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation publishExampleWorkflows($targetEnvironmentBaseURLs: [String!]) {\n    publishExampleWorkflows(\n      input: { targetEnvironmentBaseURLs: $targetEnvironmentBaseURLs }\n    ) {\n      sourceEnvURL\n      successEnvs {\n        envURL\n        supportedWorkflowIds\n        unsupportedWorkflowIds {\n          id\n          reason\n          details\n        }\n      }\n      failureEnvs {\n        envURL\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation publishExampleWorkflows($targetEnvironmentBaseURLs: [String!]) {\n    publishExampleWorkflows(\n      input: { targetEnvironmentBaseURLs: $targetEnvironmentBaseURLs }\n    ) {\n      sourceEnvURL\n      successEnvs {\n        envURL\n        supportedWorkflowIds\n        unsupportedWorkflowIds {\n          id\n          reason\n          details\n        }\n      }\n      failureEnvs {\n        envURL\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetTutorialsTargetEnvironments {\n    getTutorialsTargetEnvironments {\n      environmentURLs\n    }\n  }\n"): (typeof documents)["\n  query GetTutorialsTargetEnvironments {\n    getTutorialsTargetEnvironments {\n      environmentURLs\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetExampleWorkflowsTargetEnvironments {\n    getExampleWorkflowsTargetEnvironments {\n      environmentURLs\n    }\n  }\n"): (typeof documents)["\n  query GetExampleWorkflowsTargetEnvironments {\n    getExampleWorkflowsTargetEnvironments {\n      environmentURLs\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;