import { StyleRules } from '@mui/styles';

export const previewStyles: StyleRules = {
  preview: {
    fontSize: '14px',
    lineHeight: '1.4',
    '& h1': {
      fontSize: '32px',
      padding: '0px',
      border: 'none',
      fontWeight: 700,
      margin: '32px 0',
    },
    '& h2': {
      fontSize: '24px',
      padding: '0px 0',
      border: 'none',
      fontWeight: 700,
      margin: '24px 0',
    },
    '& h3': {
      fontSize: '18px',
      margin: '18px 0',
      padding: '0px 0',
      border: 'none',
    },
    '& p': {
      margin: '8px 0',
    },
    '& a': {
      color: '#0052d9',
    },
    '& a:hover': {
      textDecoration: 'none',
    },
    '& strong': {
      fontWeight: 700,
    },
    '& ol, & ul': {
      paddingLeft: '36px',
    },
    '& li': {
      marginBottom: '4px',
    },
    '& hr': {
      marginTop: '20px',
      marginBottom: '20px',
      border: '0',
      borderTop: '1px solid #eee',
    },
    '& pre': {
      display: 'block',
      backgroundColor: '#f5f5f5',
      padding: '20px',
      lineHeight: '28px',
      borderRadius: '0',
      overflowX: 'auto',
      wordBreak: 'break-word',
    },
    '& code': {
      backgroundColor: '#f5f5f5',
      borderRadius: '0',
      padding: '3px 0',
      margin: '0',
      overflowX: 'auto',
      wordBreak: 'normal',
    },
    '& code:after, & code:before': {
      letterSpacing: 0,
    },
    '& blockquote': {
      position: 'relative',
      margin: '16px 0',
      padding: '5px 8px 5px 30px',
      background: 'none repeat scroll 0 0 rgba(102, 128, 153, 0.05)',
      border: 'none',
      color: '#333',
      borderLeft: '10px solid #d6dbdf',
    },
    '& img, & video': {
      maxWidth: '100%',
    },
    '& table': {
      lineHeight: '1.7',
      maxWidth: '100%',
      overflow: 'auto',
      border: '1px solid #f6f6f6',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      boxSizing: 'border-box',
    },
    '& table td, & table th': {
      wordBreak: 'break-all',
      wordWrap: 'break-word',
      whiteSpace: 'normal',
    },
    '& table tr': {
      border: '1px solid #efefef',
    },
    '& table tr:nth-child(2n)': {
      backgroundColor: 'transparent',
    },
    '& table th': {
      textAlign: 'center',
      fontWeight: 700,
      border: '1px solid #efefef',
      padding: '10px 6px',
      backgroundColor: '#f5f7fa',
      wordBreak: 'break-word',
    },
    '& table td': {
      border: '1px solid #efefef',
      textAlign: 'left',
      padding: '10px 15px',
      wordBreak: 'break-word',
      minWidth: '60px',
    },
  },
};
