import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import {
  GET_FEATURE_TOGGLES_FOR_ONE_ENV,
  UPDATE_FEATURE_TOGGLE_OVERRIDE,
} from 'admin-client/app/api/gql/queries';
import {
  ArrayElement,
  FeatureToggleCommonFragment,
  FeatureToggleOverride,
} from 'admin-client/app/gql';
import Switch from 'common/ui/components/Switch';

type FeatureToggle = ArrayElement<FeatureToggleCommonFragment['featureToggles']>;

type FeatureToggleSwitchProps = {
  featureToggle: FeatureToggle;
  environmentId: string;
  hostname: string;
};

export default React.memo(function (props: FeatureToggleSwitchProps) {
  const { featureToggle, environmentId, hostname } = props;
  const [updateFeatureToggleMutation] = useMutation(UPDATE_FEATURE_TOGGLE_OVERRIDE);

  const handleChange = useCallback(
    async (_, checked: boolean) => {
      const overrideValue = checked
        ? FeatureToggleOverride.ENABLED
        : FeatureToggleOverride.DISABLED;
      const result = await updateFeatureToggleMutation({
        variables: {
          environmentId,
          name: featureToggle.name,
          override: overrideValue,
        },
        refetchQueries: [
          { query: GET_FEATURE_TOGGLES_FOR_ONE_ENV, variables: { hostname } },
        ],
      });

      if (!result) {
        console.error('could not update feature toggle');
      }
    },
    [environmentId, featureToggle.name, hostname, updateFeatureToggleMutation],
  );
  return (
    <Switch onChange={handleChange} checked={featureToggle.enabled} color="primary" />
  );
});
