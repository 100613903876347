import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { LazyQueryResult, useLazyQuery, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormLabel from '@mui/material/FormLabel';

import { GET_ELEMENT_VERSION, GET_ELEMENTS } from 'admin-client/app/api/gql/queries';
import { chooseInitialCommit } from 'admin-client/app/components/ElementConfiguration/chooseInitialCommit';
import { ConfigurationCommitSelector } from 'admin-client/app/components/ElementConfiguration/ConfigurationCommitSelector';
import ParameterChangeList from 'admin-client/app/components/ElementConfiguration/ParameterChangeList';
import replaceParameterNames from 'admin-client/app/components/ElementConfiguration/replaceParameterNames';
import { useCommits } from 'admin-client/app/components/ElementConfiguration/useCommits';
import { useElementConfigurations } from 'admin-client/app/components/ElementConfiguration/useElementConfigurations';
import {
  Exact,
  getElementVersionQuery as GraphQLElementVersion,
} from 'admin-client/app/gql';
import { isMasterBranch, isRelengBranch } from 'admin-common/src/parseCommits';
import { APIElement } from 'common/types/api';
import { Commit, ConfigurationType } from 'common/types/commonConfiguration';
import { ElementConfigurationSpec, ElementName } from 'common/types/elementConfiguration';
import { DialogProps } from 'common/ui/components/DialogManager';
import Autocomplete from 'common/ui/filaments/Autocomplete';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type ChooseCommitDialogResult = {
  configurationType: ConfigurationType;
  commit: Commit;
  elementVersion: APIElement;
  updatedConfig?: ElementConfigurationSpec;
};

type Props = {
  elementName: ElementName;
  initialConfigurationType?: ConfigurationType;
  initialCommit?: Commit | null;
  dialogTitle?: string;
  onClose: (result: ChooseCommitDialogResult | null) => void;
  isNewConfiguration: boolean;
  initialSpec?: ElementConfigurationSpec;
} & DialogProps;

/**
 * In this dialog user selects when the configuration should apply. They can choose between
 * global and single-branch options.
 *
 * When user chooses 'global', they choose since which commit the configurations valid.
 *
 * To make the UI simple, the user can only choose from releng branches. That basically says that from
 * releng branch X we use this configuration. In the background we find parent master commit for
 * selected releng branch and apply configuration since this commit. User doesn't need to deal with
 * this implementation detail.
 *
 * When the user chooses 'single-branch', they then select any non-releng/non-master branch. Configuration
 * is then applied to the first commit in that branch. Again, to make things simpler for them, we
 * automatically choose the first commit in the branch, even though backend supports choosing any commit.
 *
 * If at some point the user is dealing with an existing configuration, it may be the case that they
 * want to apply it to the element after its name has been changed, so if there is an issue finding
 * an element with the initial name on the selected branch, we provide the option for them to select
 * the element's new name. Similarly with parameter names, if parameter names have changed, we will also
 * provide the option to select new ones that exist in the destination element.
 */
export function ChooseElementConfigurationCommitDialog({
  elementName,
  initialConfigurationType,
  isNewConfiguration,
  initialCommit = null,
  dialogTitle,
  onClose,
  initialSpec,
}: Props) {
  const classes = useStyles();
  const { commits } = useCommits();
  const initialSelectedCommit = useMemo(
    () => chooseInitialCommit(commits, initialCommit),
    [commits, initialCommit],
  );
  const [selectedCommit, setSelectedCommit] = useState<Commit | null>(
    initialSelectedCommit,
  );
  const [selectedElementName, setSelectedElementName] = useState(elementName);
  const [oldParameterNameToNewName, setOldParameterNameToNewName] = useState<{
    [key: string]: string;
  }>({});

  const isInitialCommitNonGlobal =
    selectedCommit && !isMasterBranch(selectedCommit) && !isRelengBranch(selectedCommit);

  initialConfigurationType =
    isInitialCommitNonGlobal || isNewConfiguration
      ? ConfigurationType.SINGLE_BRANCH
      : ConfigurationType.GLOBAL;

  const [configurationType, setConfigurationType] = useState<ConfigurationType>(
    initialConfigurationType,
  );

  const { configurations } = useElementConfigurations(selectedElementName);

  const [loadElementVersion, elementVersion] = useLazyQuery(GET_ELEMENT_VERSION);

  const canChangeElement =
    (!isNewConfiguration &&
      (elementName !== selectedElementName ||
        (elementVersion.error && !elementVersion.error.networkError))) ??
    false;

  useEffect(() => {
    if (!selectedCommit) {
      return;
    }
    const { commitHash } = selectedCommit;
    void loadElementVersion({
      variables: { elementName: selectedElementName, commitHash },
    });
  }, [selectedElementName, selectedCommit, loadElementVersion]);

  const onSelectCommit = (commit: Commit | null) => {
    setSelectedCommit(commit);
    if (commit) {
      void loadElementVersion({
        variables: {
          elementName: selectedElementName,
          commitHash: commit.commitHash,
        },
      });
    }
  };

  const onConfigurationTypeChange = useCallback(
    (configurationType: ConfigurationType) => {
      setConfigurationType(configurationType);
    },
    [],
  );

  const onParameterChange = (newName: string, oldName: string) => {
    setOldParameterNameToNewName(prevVal => ({
      ...prevVal,
      [oldName]: newName,
    }));
  };

  const canConfirm =
    !elementVersion.loading && elementVersion.data && selectedCommit !== null;

  const onConfirm = useCallback(() => {
    const updatedConfig = initialSpec
      ? replaceParameterNames(oldParameterNameToNewName, initialSpec, elementName)
      : undefined;
    if (elementVersion.data) {
      onClose({
        commit: selectedCommit,
        configurationType,
        elementVersion: elementVersion.data.element.version,
        updatedConfig,
      });
    }
  }, [
    initialSpec,
    oldParameterNameToNewName,
    elementName,
    onClose,
    selectedCommit,
    configurationType,
    elementVersion,
  ]);

  const onCancel = useCallback(() => onClose(null), [onClose]);

  const { data: elementsData } = useQuery(GET_ELEMENTS, {
    variables: {
      commitHash: selectedCommit?.commitHash ?? '',
      commitBranch: selectedCommit?.commitBranch ?? '',
      commitDate: selectedCommit?.commitDate.toISOString() ?? '',
    },
    skip: !selectedCommit,
  });

  const elementOptions = useMemo(
    () => elementsData?.elements.map(e => ({ label: e.name, value: e.name })) ?? [],
    [elementsData],
  );

  const onElementNameChange = (name?: string) => {
    if (!name) {
      return;
    }
    setSelectedElementName(name);
  };

  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle>{dialogTitle ?? 'Choose commit'}</DialogTitle>
      <DialogContent>
        <ConfigurationCommitSelector
          configurations={configurations}
          initialCommit={selectedCommit}
          configurationType={configurationType}
          isNewConfiguration={isNewConfiguration}
          onSelectCommit={onSelectCommit}
          onBranchTypeChange={onConfigurationTypeChange}
        />

        <div className={classes.elementVersionInfo}>
          <ElementVersionInfo
            elementName={selectedElementName}
            elementVersion={elementVersion}
            canRenameElement={canChangeElement}
          />
        </div>

        {canChangeElement && elementsData?.elements && (
          <Box my={1}>
            <FormLabel component="legend">New element name</FormLabel>
            <Autocomplete
              valueLabel={selectedElementName}
              options={elementOptions}
              onChange={onElementNameChange}
            />
          </Box>
        )}
        {initialSpec &&
          elementVersion.data?.element?.version &&
          !elementVersion.loading && (
            <ParameterChangeList
              element={elementVersion.data?.element.version}
              spec={initialSpec}
              onChange={onParameterChange}
            />
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm} color="primary" disabled={!canConfirm}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Renders information about element in currently selected commit.
 */
function ElementVersionInfo({
  elementName,
  elementVersion,
  canRenameElement,
}: {
  elementName: ElementName;
  elementVersion: LazyQueryResult<
    GraphQLElementVersion,
    Exact<{ elementName: string; commitHash: string }>
  >;
  canRenameElement: boolean;
}) {
  if (elementVersion.loading) {
    return <div>Loading element...</div>;
  }
  if (elementVersion.data) {
    return null;
  }
  if (elementVersion.error?.networkError) {
    return <div>Network error</div>;
  }
  if (elementVersion.error) {
    return (
      <div>
        Element {elementName} was not found in the selected branch. Please choose a
        different branch{' '}
        {canRenameElement ? 'or the new name of the element below.' : '.'}
      </div>
    );
  }
  return null;
}

const useStyles = makeStylesHook({
  elementVersionInfo: { marginTop: '2rem' },
});
