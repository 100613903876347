import React, { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';

import ElementConfigurationActionsEditor from 'admin-client/app/components/ElementConfiguration/Card/rules/actions/ActionsEditor';
import { ConditionEditor } from 'admin-client/app/components/ElementConfiguration/Card/rules/conditions/ConditionEditor';
import { APIElement } from 'common/types/api';
import {
  ElementConfigurationAction as Action,
  ElementConfigurationCondition as Condition,
  ElementConfigurationRule as Rule,
} from 'common/types/elementConfiguration';
import { TypeConfigurationSpec } from 'common/types/typeConfiguration';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export function RuleCard(props: {
  rule: Rule;
  ruleIndex: number;
  defaultParameterName?: string;
  element: APIElement;
  typeConfigurations: Record<string, TypeConfigurationSpec>;
  onRemoveRule: (ruleIndex: number) => void;
  onRuleChange: (rule: Rule, ruleIndex: number) => void;
}) {
  const classes = useStyles();
  const {
    rule,
    ruleIndex,
    defaultParameterName,
    element,
    typeConfigurations,
    onRuleChange,
    onRemoveRule,
  } = props;
  const onRemove = useCallback(() => onRemoveRule(ruleIndex), [onRemoveRule, ruleIndex]);
  const onConditionChange = useCallback(
    (condition: Condition) => {
      onRuleChange({ ...rule, condition }, ruleIndex);
    },
    [onRuleChange, rule, ruleIndex],
  );

  const onActionsChange = useCallback(
    (actions: Action[]) => onRuleChange({ ...rule, actions }, ruleIndex),
    [onRuleChange, rule, ruleIndex],
  );

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.header}
        titleTypographyProps={{ variant: 'body1' }}
        title={`Rule #${ruleIndex + 1}`}
        action={
          <IconButton onClick={onRemove} size="large">
            <DeleteIcon className={classes.headerIcon} />
          </IconButton>
        }
      />
      <CardContent>
        <ConditionEditor
          condition={props.rule.condition}
          defaultParameterName={defaultParameterName}
          index={0}
          isRoot
          element={element}
          typeConfigurations={typeConfigurations}
          onConditionChange={onConditionChange}
        />

        <ElementConfigurationActionsEditor
          actions={props.rule.actions}
          element={element}
          onChange={onActionsChange}
        />
      </CardContent>
    </Card>
  );
}

const useStyles = makeStylesHook({
  card: {
    marginBottom: '1rem',
  },
  headerIcon: {
    color: 'white',
  },
  header: {
    color: 'white',
    backgroundColor: '#2196f3', // same as MuiAppBar-colorPrimary
  },
  actionsLabel: {
    marginTop: '1rem',
  },
});
