import React from 'react';

import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';

import { ElementConfigurationTable } from 'admin-client/app/components/ElementConfiguration/ElementConfigurationTable';
import { Commit } from 'common/types/commonConfiguration';
import { ElementName } from 'common/types/elementConfiguration';

type Props = RouteComponentProps<{ elementName: ElementName }>;

/**
 * Renders list of element configurations for a selected element
 */
export function ViewElementConfigurations({ match, location }: Props) {
  const { elementName } = match.params;
  const commitParams = new URLSearchParams(location.search);
  const commitDateParam = commitParams.get('commitDate');
  const commitDate = commitDateParam ? moment(commitDateParam) : undefined;
  const commitHash = commitParams.get('commitHash');
  const commitBranch = commitParams.get('commitBranch');

  let commit: Commit | null = null;
  if (commitDate && commitHash && commitBranch) {
    commit = {
      commitDate,
      commitHash,
      commitBranch,
    };
  }
  return <ElementConfigurationTable elementName={elementName} initialCommit={commit} />;
}
