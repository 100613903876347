import React, { useCallback, useMemo } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MenuIcon from '@mui/icons-material/Menu';
import WarningIcon from '@mui/icons-material/Warning';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';

import {
  checkIfTypeHasChanged,
  ParameterEditorDialog,
} from 'admin-client/app/components/ElementConfiguration/Card/ParameterEditorDialog';
import { useAllCurrentTypeConfigurations } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useAllCurrentTypeConfigurations';
import { isParameterUsedInRule } from 'common/rules/isParameterUsedInRule';
import { APIElement } from 'common/types/api';
import { Commit } from 'common/types/commonConfiguration';
import { ParameterConfigurationSpec } from 'common/types/elementConfiguration';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

/**
 * Renders editor for single parameter configuration
 */
export function ParameterEditor({
  element,
  parameterName,
  parameterType,
  spec,
  currentCommit,
  onSpecChange,
}: {
  element: APIElement;
  parameterName: string;
  parameterType: 'input' | 'output';
  spec: ElementConfigurationSpec;
  currentCommit: Commit;
  onSpecChange: (spec: ElementConfigurationSpec) => void;
}) {
  const classes = useStyles();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: parameterName,
  });

  const apiParameter = useMemo(
    () => [...element.in_ports, ...element.out_ports].find(p => p.name === parameterName),
    [element.in_ports, element.out_ports, parameterName],
  );

  const { typeConfigurations } = useAllCurrentTypeConfigurations(currentCommit);

  const [editDialog, openEditDialog] = useDialog(ParameterEditorDialog);
  const onClickEdit = useCallback(async () => {
    if (!apiParameter) {
      throw new Error(
        `Attempted to open the ParameterEditorDialog to edit a non-existent parameter named ${parameterName} on element ${element.name}.`,
      );
    }
    const newSpec = await openEditDialog({
      element,
      initialSpec: spec,
      parameterType,
      parameterName,
      anthaType: apiParameter.type,
      currentCommit,
      typeConfigurations,
    });
    if (newSpec) {
      onSpecChange(newSpec);
    }
  }, [
    apiParameter,
    openEditDialog,
    element,
    spec,
    parameterType,
    parameterName,
    currentCommit,
    typeConfigurations,
    onSpecChange,
  ]);

  if (!apiParameter) {
    return (
      <p>
        Error: Could not render the editor for a parameter named {parameterName} as it
        does not exist on this element.
      </p>
    );
  }

  const parameterConfig = spec.parameters[parameterName];
  if (!parameterConfig) {
    return null;
  }

  const hasRule = spec.rules?.some(rule => isParameterUsedInRule(rule, parameterName));

  const hasChanges = hasParameterChanged(element, parameterConfig, parameterName);

  const typeConfiguration = typeConfigurations[apiParameter.type];
  const hasEditorTypeChanged = checkIfTypeHasChanged(
    apiParameter.type,
    parameterConfig,
    typeConfiguration,
  );

  return (
    <>
      <Card
        ref={setNodeRef}
        variant="outlined"
        classes={{ root: classes.root }}
        style={{
          transform: CSS.Transform.toString(transform),
          transition: transition ?? undefined,
        }}
      >
        <CardHeader
          avatar={<MenuIcon {...attributes} {...listeners} />}
          title={
            <div className={classes.cardContent} onClick={onClickEdit}>
              <span className={classes.name}>
                {parameterConfig.displayName}
                {parameterName !== parameterConfig.displayName
                  ? ` (${parameterName})`
                  : null}
              </span>
              {hasEditorTypeChanged && (
                <Tooltip title="The type of this parameter has either changed in the element code, or the existing editor type has been removed as an option from the type configuration, and this could result in unexpected behaviour when using this parameter in the UI. Open the parameter and confirm a new editor type">
                  <WarningIcon className={classes.errorIcon} />
                </Tooltip>
              )}
              {hasRule && (
                <Tooltip title="Has associated rule(s)">
                  <ListAltIcon className={classes.icon} />
                </Tooltip>
              )}
              {hasChanges && (
                <Tooltip title="Parameter name and/or description has been changed">
                  <ChangeHistoryIcon className={classes.icon} />
                </Tooltip>
              )}
              <div className={classes.parameterType}>{apiParameter?.type}</div>
            </div>
          }
        />
      </Card>
      {editDialog}
    </>
  );
}

/**
 * Checks if the parameter configuration has changed from the original
 * element from the commit. Right now, we just check the description and
 * name is different (as that is all we are changing from the original).
 * @param element
 * @param configuration
 * @param originalParameterName
 */
function hasParameterChanged(
  element: APIElement,
  configuration: ParameterConfigurationSpec,
  parameterName: string,
) {
  const originalSpec = element.in_ports
    .concat(element.out_ports)
    .find(port => port.name === parameterName);
  if (!originalSpec) {
    return false;
  }
  return (
    originalSpec.description !== configuration.displayDescription ||
    originalSpec.name !== configuration.displayName
  );
}

const useStyles = makeStylesHook({
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    // required to remove default padding-bottom from material-ui Card
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  parameterType: {
    marginLeft: 'auto',
    color: Colors.GREY_60,
  },
  icon: {
    color: Colors.GREY_60,
  },
  errorIcon: {
    color: Colors.ERROR,
  },
  root: {
    marginBottom: '4px',
    '&:hover': {
      backgroundColor: Colors.GREY_20,
      cursor: 'pointer',
    },
  },
  name: {
    marginRight: '8px',
  },
});
