import React, { useMemo } from 'react';

import PlateTypesTable from 'admin-client/app/components/PlateTypes/PlateTypesTable';
import { getOrganisationsQuery } from 'admin-client/app/gql';
import { indexBy } from 'common/lib/data';
import Autocomplete from 'common/ui/filaments/Autocomplete';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type PlateTypesForOrgAndEnvProps = {
  orgData: getOrganisationsQuery;
  orgHumanIdentifier: string;
  changeOrg: (org: string | undefined) => void;
  envHostName: string;
  changeEnv: (env: string | undefined) => void;
  descriptionPrefix: string;
  selectedPlateTypes?: string[];
  selectPlateTypes?: (plateType: string) => void;
};

type AutocompleteOptions = {
  label: string;
  value: string;
};

export default React.memo(function PlateTypesForOrgAndEnv(
  props: PlateTypesForOrgAndEnvProps,
) {
  const { orgData, orgHumanIdentifier, envHostName, descriptionPrefix } = props;
  const classes = useStyles();

  const orgOptions = orgData.organisations.map(org => ({
    label: org.name,
    value: org.humanIdentifier,
  }));
  const orgOptionsMap = indexBy(orgOptions, 'value');

  const { orgName, envOptions } = useMemo(() => {
    let orgName;
    let envOptions: AutocompleteOptions[] = [];

    if (orgHumanIdentifier) {
      orgName = orgOptionsMap[orgHumanIdentifier].label;
      const currentOrg = orgData.organisations.find(
        org => org.humanIdentifier === orgHumanIdentifier,
      );
      envOptions = currentOrg
        ? currentOrg.environmentConfigs.map(envConfig => ({
            label: envConfig.environment.hostname,
            value: envConfig.environment.hostname,
          }))
        : [];
    }
    return { envOptions, orgName };
  }, [orgData.organisations, orgHumanIdentifier, orgOptionsMap]);

  return (
    <div className={classes.container}>
      <div className={classes.input}>
        <Autocomplete
          valueLabel={orgName}
          options={orgOptions}
          onChange={props.changeOrg}
          fullWidth
          label={`${descriptionPrefix} organisation`}
        />
        <div className={classes.input}>
          <Autocomplete
            valueLabel={envHostName}
            options={envOptions}
            onChange={props.changeEnv}
            fullWidth
            label={`${descriptionPrefix} environment`}
          />
        </div>
        {orgHumanIdentifier && envHostName && (
          <PlateTypesTable
            envHostName={envHostName}
            orgHumanIdentifier={orgHumanIdentifier}
            selectedPlateTypes={props.selectedPlateTypes}
            selectPlateTypes={props.selectPlateTypes}
          />
        )}
      </div>
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  container: {
    padding: theme.spacing(5),
  },
  input: {
    margin: 'auto',
    width: '95%',
  },
}));
