import React, { useCallback, useMemo } from 'react';

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';

import {
  addInputGroup,
  deleteInputGroup,
  moveInputGroup,
  moveInputParam,
  resetInputGroups,
  setInputGroupDescription,
  setInputGroupName,
} from 'admin-client/app/components/ElementConfiguration/Card/input-mutators';
import { ParameterEditor } from 'admin-client/app/components/ElementConfiguration/Card/ParameterEditor';
import { EditorElementConfiguration } from 'admin-client/app/components/ElementConfiguration/editorTypes';
import ResetButton from 'admin-client/app/components/ElementConfiguration/ResetButton';
import { APIElement } from 'common/types/api';
import {
  ElementConfigurationSpec,
  ParameterGroupConfigurationSpec,
} from 'common/types/elementConfiguration';
import { InlineTextEditor } from 'common/ui/components/InlineTextEditor';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  element: APIElement;
  configuration: EditorElementConfiguration;
  setConfiguration: React.Dispatch<React.SetStateAction<EditorElementConfiguration>>;
};

/**
 * Renders content of tab "Inputs"
 */
export function TabInputParameters({ element, configuration, setConfiguration }: Props) {
  const classes = useStyles();
  const { spec } = configuration;

  const onSpecChange = useCallback(
    (newSpec: ElementConfigurationSpec) =>
      setConfiguration(configuration => ({ ...configuration, spec: newSpec })),
    [setConfiguration],
  );

  const currentCommit = {
    commitBranch: configuration.commitBranch,
    commitDate: configuration.commitDate,
    commitHash: configuration.commitHash,
  };

  const onGroupDeleteClick = useCallback(
    (groupIndex: number) => onSpecChange(deleteInputGroup(spec, groupIndex)),
    [onSpecChange, spec],
  );

  const onGroupNameChange = useCallback(
    (groupIndex: number, newName: string) =>
      onSpecChange(setInputGroupName(spec, groupIndex, newName)),
    [onSpecChange, spec],
  );

  const onGroupDescriptionChange = useCallback(
    (groupIndex: number, newDescription: string) =>
      onSpecChange(setInputGroupDescription(spec, groupIndex, newDescription)),
    [onSpecChange, spec],
  );

  const getGroupNameError = useCallback(
    (groupIndex: number, groupName: string) => {
      const isNameAlreadyUsed = spec.inputGroups.some(
        (g, index) => groupIndex !== index && g.groupName === groupName,
      );
      if (isNameAlreadyUsed) {
        return `Name "${groupName}" has already been used. Please use a unique name.`;
      } else {
        return null;
      }
    },
    [spec.inputGroups],
  );

  const findGroupIndex = useCallback(
    (id: string) =>
      spec.inputGroups.findIndex(
        group => id === group.groupName || group.parameterNames.includes(id),
      ),
    [spec.inputGroups],
  );

  const findParameterIndex = useCallback(
    (id: string) => {
      const groupIndex = findGroupIndex(id);
      return groupIndex === -1
        ? -1
        : spec.inputGroups[groupIndex].parameterNames.indexOf(id);
    },
    [findGroupIndex, spec.inputGroups],
  );

  const onDragEnd = useCallback(
    (result: DragEndEvent) => {
      if (!result.active || !result.over) {
        return;
      }

      const originalGroupIndex = findGroupIndex(String(result.active.id));
      const originalParameterIndex = findParameterIndex(String(result.active.id));
      const newGroupIndex = findGroupIndex(String(result.over.id));
      const newParameterIndex = findParameterIndex(String(result.over.id));

      if (originalParameterIndex !== -1) {
        onSpecChange(
          moveInputParam(
            spec,
            originalGroupIndex,
            originalParameterIndex,
            newGroupIndex,
            newParameterIndex !== -1 ? newParameterIndex : 0,
          ),
        );
      } else {
        onSpecChange(moveInputGroup(spec, originalGroupIndex, newGroupIndex));
      }
    },
    [findGroupIndex, findParameterIndex, onSpecChange, spec],
  );

  const onAddGroupClick = useCallback(
    () => onSpecChange(addInputGroup(spec)),
    [onSpecChange, spec],
  );

  const onResetGroups = useCallback(
    () => onSpecChange(resetInputGroups(spec, element)),
    [element, onSpecChange, spec],
  );

  const groupNames = useMemo(
    () => spec.inputGroups?.map(({ groupName }) => groupName) ?? [],
    [spec.inputGroups],
  );

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext items={groupNames} strategy={verticalListSortingStrategy}>
        {spec.inputGroups?.map((groupSpec, groupIndex) => (
          <InputGroup
            key={groupSpec.groupName}
            groupSpec={groupSpec}
            validateName={getGroupNameError.bind(null, groupIndex)}
            onNameChange={onGroupNameChange.bind(null, groupIndex)}
            onDescriptionChange={onGroupDescriptionChange.bind(null, groupIndex)}
            onDelete={onGroupDeleteClick.bind(null, groupIndex)}
          >
            <SortableContext
              items={groupSpec.parameterNames}
              strategy={verticalListSortingStrategy}
            >
              {groupSpec.parameterNames.map(parameterName => (
                <ParameterEditor
                  key={`key:${parameterName}`}
                  parameterType="input"
                  element={element}
                  parameterName={parameterName}
                  spec={spec}
                  currentCommit={currentCommit}
                  onSpecChange={onSpecChange}
                />
              ))}
            </SortableContext>
            {groupSpec.parameterNames.length === 0 && (
              <Typography className={classes.dragParametersMessage} variant="body2">
                Drag parameters here
              </Typography>
            )}
          </InputGroup>
        ))}

        <Button onClick={onAddGroupClick} variant="outlined">
          Add group
        </Button>
        <ResetButton
          onClick={onResetGroups}
          resetObjectName="groups and group order"
          additionalMessage="This cannot be undone unless you Cancel the parameter changes."
        />
      </SortableContext>
    </DndContext>
  );
}

function InputGroup({
  groupSpec,
  validateName,
  onNameChange,
  onDescriptionChange,
  onDelete,
  children,
}: {
  groupSpec: ParameterGroupConfigurationSpec;
  children: React.ReactNode;
  validateName: (name: string) => string | null;
  onNameChange: (name: string) => void;
  onDescriptionChange: (description: string) => void;
  onDelete: () => void;
}) {
  const classes = useStyles();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: groupSpec.groupName,
  });

  return (
    <Card
      ref={setNodeRef}
      className={classes.card}
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition ?? undefined,
      }}
    >
      <CardHeader
        avatar={<MenuIcon {...attributes} {...listeners} />}
        title={
          <InlineTextEditor
            value={groupSpec.groupName}
            renderValue={value => value || 'Default group'}
            getError={validateName}
            onChange={onNameChange}
          />
        }
        action={
          groupSpec.parameterNames.length === 0 ? (
            <IconButton onClick={onDelete} size="large">
              <DeleteIcon />
            </IconButton>
          ) : null
        }
      />
      <CardContent>
        <GroupDescriptionEditor
          description={groupSpec.groupDescription}
          onDescriptionChange={onDescriptionChange}
        />
        <Box paddingTop="20px">{children}</Box>
      </CardContent>
    </Card>
  );
}

function GroupDescriptionEditor({
  description,
  onDescriptionChange,
}: {
  description?: string;
  onDescriptionChange: (name: string) => void;
}) {
  const classes = useStyles();
  const [showEditor, setShowEditor] = React.useState(!!description);

  const changeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = DOMPurify.sanitize(event.target.value ?? '');
    onDescriptionChange(newValue);
  };
  const removeDescription = () => {
    setShowEditor(false);
    onDescriptionChange('');
  };

  return (
    <div className={classes.groupDescription}>
      {!showEditor ? (
        <Button
          className={classes.btnAddGroupDescription}
          variant="outlined"
          onClick={() => setShowEditor(true)}
        >
          Add group description
        </Button>
      ) : (
        <>
          <TextField
            placeholder="Please, input group description here..."
            multiline
            fullWidth
            value={description ?? ''}
            onChange={changeDescription}
            inputProps={{ style: { fontSize: '14px' } }}
          />
          <IconButton onClick={removeDescription}>
            <ClearIcon />
          </IconButton>
        </>
      )}
    </div>
  );
}

const useStyles = makeStylesHook({
  card: { marginBottom: '1rem' },
  dragParametersMessage: {
    fontStyle: 'italic',
    textAlign: 'center',
  },
  groupDescription: {
    width: '100%',
    display: 'flex',
  },
  btnAddGroupDescription: {
    fontSize: '12px',
  },
});
