import { useCallback } from 'react';

import {
  ChooseCommitDialogResult,
  ChooseTypeConfigurationCommitDialog,
} from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/ChooseTypeConfigurationCommitDialog';
import { Commit, ConfigurationType } from 'common/types/commonConfiguration';
import { TypeName } from 'common/types/typeConfiguration';
import { useDialogManager } from 'common/ui/components/DialogManager';
/**
 * Helper hook to choose a commit for given type.
 */
export function useChooseTypeConfigurationCommitDialog() {
  const dialogManager = useDialogManager();

  const onSelectCommit = useCallback(
    async ({
      typeName,
      initialConfigurationType,
      initialCommit,
      dialogTitle,
      onCommitSelected,
      isNewConfiguration,
      isOpen,
    }: {
      typeName: TypeName | null;
      initialConfigurationType?: ConfigurationType;
      initialCommit?: Commit | null;
      dialogTitle?: string;
      onCommitSelected: (result: ChooseCommitDialogResult) => void;
      isNewConfiguration: boolean;
      isOpen: boolean;
    }) => {
      const result = await dialogManager.openDialogPromise(
        'CHOOSE_COMMIT',
        ChooseTypeConfigurationCommitDialog,
        {
          typeName,
          initialCommit,
          initialConfigurationType,
          isNewConfiguration,
          dialogTitle,
          isOpen,
        },
      );
      if (result) {
        onCommitSelected(result);
      }
    },
    [dialogManager],
  );

  return onSelectCommit;
}
