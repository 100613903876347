import { Commits, convertGraphQLCommit, GraphQLCommit } from 'admin-common/src/commit';
import { groupBy, indexBy, unique } from 'common/lib/data';
import { mapObject } from 'common/object';
import { Commit } from 'common/types/commonConfiguration';
import { isNotNull } from 'common/utils';

/**
 * Helper method that creates Commits data structure from raw GraphQL data.
 * It returns:
 *
 *   masterCommits (all),
 *   latestCommits (last commit for each branch),
 *   relengCommits (first for each releng branch, with parent master commit attached).
 *   allCommits: (commits indexed by branch and then hash)
 *
 * This format is easier to work with in the UI.
 */
export function parseCommits(rawCommits: GraphQLCommit[]): Commits {
  const commits = rawCommits.map(convertGraphQLCommit);

  const allRelengCommits = commits.filter(isRelengBranch);
  const masterCommits = commits
    .filter(isMasterBranch)
    // Sort commits from earliest to latest commitDate
    .sort((a, b) => a.commitDate.valueOf() - b.commitDate.valueOf());

  const relengCommits = getFirstCommitForEachBranch(allRelengCommits)
    .map(commit => {
      const parentMasterCommit = masterCommits
        .filter(masterCommit => isSameOrBefore(masterCommit, commit))
        .reduce(reduceToLaterCommit, null);

      if (!parentMasterCommit) {
        return null;
      }
      return {
        commit,
        parentMasterCommit,
      };
    })
    .filter(isNotNull);

  const latestCommits = getLastCommitForEachBranch(commits);

  const groupedCommits = groupBy(commits, 'commitBranch');
  const allCommits = mapObject(groupedCommits, (_branch, commits) => {
    return indexBy(
      unique(commits, c => c.commitHash),
      'commitHash',
    );
  });

  return { allCommits, relengCommits, latestCommits, masterCommits };
}

const RELENG_BRANCH_REGEX = /^releng\/.*/;
const MASTER_BRANCH_REGEX = /^master$/;

export function isMasterBranch(commit: Commit): boolean {
  return MASTER_BRANCH_REGEX.test(commit.commitBranch);
}

export function isRelengBranch(commit: Commit): boolean {
  return RELENG_BRANCH_REGEX.test(commit.commitBranch);
}

/**
 * Since commitDate is now only inferred from element_set.last_modified_at, it can
 * be slightly different in each env. Commit chosen in admin tool is from ninja, but
 * the same commit in production env may have a bit different date.
 *
 * To partially mitigate this we can compare commit hashes for equality. Ultimately
 * we will get rid of this and use stable commit date (T1501).
 */
function isSameOrBefore(c1: Commit, c2: Commit): boolean {
  return c1.commitHash === c2.commitHash || c1.commitDate.isSameOrBefore(c2.commitDate);
}

/**
 * Returns first commit for each branch.
 */
function getFirstCommitForEachBranch(commits: Commit[]): Commit[] {
  return reduceCommitsForEachBranch(commits, reduceToEarlierCommit);
}

/**
 * Returns last commit for each branch.
 */
function getLastCommitForEachBranch(commits: Commit[]): Commit[] {
  return reduceCommitsForEachBranch(commits, reduceToLaterCommit);
}

/**
 * Returns single commit for each branch. When multiple commits on one branch
 * are encountered, reducer is called to decide which one to keep.
 *
 * This is equivalent to
 *
 *   return Object.values(
 *     mapObject(
 *       groupBy(commits, 'commitBranch),
 *       branchCommits => branchCommits.reduce(reducer),
 *     )
 *   );
 *
 * or with |> operator (that we don't yet have)
 *
 *   return commits
 *     |> groupBy($$, 'commitBranch')
 *     |> mapObject($$, branchCommits => branchCommits.reduce(reducer))
 *     |> Object.values($$);
 *
 * but more efficient as it only creates one temporary object.
 *
 * @param commits
 * @param reducer
 */
function reduceCommitsForEachBranch(
  commits: Commit[],
  reducer: (prev: Commit | null, current: Commit) => Commit,
): Commit[] {
  const map: { [commitBranch: string]: Commit } = {};
  for (const commit of commits) {
    map[commit.commitBranch] = reducer(map[commit.commitBranch], commit);
  }
  return Object.values(map);
}

/**
 * Reducer function that will pick commit with earlier commit date. If `prev` is not
 * present, `current` is picked. Signature of this function is designed to fit calls
 * of Array.reduce(...)
 */
function reduceToEarlierCommit(prev: Commit | null, current: Commit): Commit {
  return prev && isSameOrBefore(prev, current) ? prev : current;
}

/**
 * Reducer function that will pick commit with later commit date. If `prev` is not
 * present, `current` is picked. Signature of this function is designed to fit calls
 * of Array.reduce(...)
 */
function reduceToLaterCommit(prev: Commit | null, current: Commit): Commit {
  return prev && !isSameOrBefore(prev, current) ? prev : current;
}
