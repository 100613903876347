import React, { useCallback, useReducer } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {
  COPY_PLATE_TYPES,
  GET_ORGANISATIONS,
  GET_PLATE_TYPES,
} from 'admin-client/app/api/gql/queries';
import PlateTypesForOrgAndEnv from 'admin-client/app/components/PlateTypes/PlateTypesForOrgAndEnv';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type EnvState = {
  orgHumanName: string;
  envHostName: string;
  selectedPlateTypes: string[];
};

type EnvAction = {
  type: string;
  value: string;
};

function reducer(state: EnvState, action: EnvAction) {
  switch (action.type) {
    case 'SET_ORGANISATION':
      return {
        orgHumanName: action.value,
        envHostName: '',
        selectedPlateTypes: [],
      };
    case 'SET_ENVIRONMENT':
      return {
        ...state,
        envHostName: action.value,
        selectedPlateTypes: [],
      };
    case 'SELECT_PLATE_TYPE': {
      const selectedIndex = state.selectedPlateTypes.indexOf(action.value);

      let newSelection: string[] = [];
      if (selectedIndex === -1) {
        // add to the list
        newSelection = newSelection.concat(state.selectedPlateTypes, action.value);
      } else {
        // remove from the list
        newSelection = newSelection.concat(
          state.selectedPlateTypes.slice(0, selectedIndex),
          state.selectedPlateTypes.slice(selectedIndex + 1),
        );
      }
      return {
        ...state,
        selectedPlateTypes: newSelection,
      };
    }
    default:
      throw new Error(`unknown action type ${action.type}`);
  }
}

export default React.memo(function PlateTypesContainer() {
  const snackbarManager = useSnackbarManager();
  const classes = useStyles();

  // retrieve all organisations, this will return their associated environments
  const orgs = useQuery(GET_ORGANISATIONS);

  const [sourceState, setSourceState] = useReducer(reducer, {
    envHostName: '',
    orgHumanName: '',
    selectedPlateTypes: [],
  });
  const [destinationState, setDestinationState] = useReducer(reducer, {
    envHostName: '',
    orgHumanName: '',
    selectedPlateTypes: [],
  });

  const commonMutationOptions = {
    onError: (error: ApolloError) => {
      console.error(error);
      snackbarManager.showError(error.message);
    },
  };
  const [copyPlateTypes] = useMutation(COPY_PLATE_TYPES, commonMutationOptions);
  const handleCopyPlateTypes = useCallback(async () => {
    if (!sourceState.selectedPlateTypes || sourceState.selectedPlateTypes.length === 0) {
      return;
    }

    await copyPlateTypes({
      variables: {
        sourceHostname: sourceState.envHostName,
        sourceOrgHumanIdentifier: sourceState.orgHumanName,
        destinationHostname: destinationState.envHostName,
        destinationOrgHumanIdentifier: destinationState.orgHumanName,
        plateTypes: sourceState.selectedPlateTypes,
      },
      refetchQueries: [
        // refresh the destination org+env
        {
          query: GET_PLATE_TYPES,
          variables: {
            hostname: destinationState.envHostName,
            orgHumanIdentifier: destinationState.orgHumanName,
          },
        },
      ],
    });
  }, [copyPlateTypes, sourceState, destinationState]);

  return (
    <div>
      <Typography variant="h2" gutterBottom>
        Plate Types
      </Typography>
      <Typography>
        Use this page to change the copy plate types between organisations and
        environments.
      </Typography>
      {orgs.loading && <Typography>Loading...</Typography>}
      {orgs.error && <Typography color="error">Error: {orgs.error.message}</Typography>}
      {orgs.data && (
        <div className={classes.flexContainer}>
          <div className={classes.plateTypesContainer}>
            <PlateTypesForOrgAndEnv
              orgData={orgs.data}
              orgHumanIdentifier={sourceState.orgHumanName}
              changeOrg={(org?: string) =>
                setSourceState({
                  type: 'SET_ORGANISATION',
                  value: org ?? '',
                })
              }
              envHostName={sourceState.envHostName}
              changeEnv={(env?: string) =>
                setSourceState({
                  type: 'SET_ENVIRONMENT',
                  value: env ?? '',
                })
              }
              descriptionPrefix="source"
              selectedPlateTypes={sourceState.selectedPlateTypes}
              selectPlateTypes={(plateType: string) =>
                setSourceState({
                  type: 'SELECT_PLATE_TYPE',
                  value: plateType,
                })
              }
            />
          </div>
          <div className={classes.plateTypesContainer}>
            <PlateTypesForOrgAndEnv
              orgData={orgs.data}
              orgHumanIdentifier={destinationState.orgHumanName}
              changeOrg={(org?: string) =>
                setDestinationState({
                  type: 'SET_ORGANISATION',
                  value: org ?? '',
                })
              }
              envHostName={destinationState.envHostName}
              changeEnv={(env?: string) =>
                setDestinationState({
                  type: 'SET_ENVIRONMENT',
                  value: env ?? '',
                })
              }
              descriptionPrefix="destination"
            />
          </div>
        </div>
      )}
      <div className={classes.flexContainerCentered}>
        <Button variant="contained" color="primary" onClick={handleCopyPlateTypes}>
          Copy selected plate types
        </Button>
      </div>
    </div>
  );
});

const useStyles = makeStylesHook(_theme => ({
  flexContainer: {
    display: 'flex',
  },
  flexContainerCentered: {
    display: 'flex',
    justifyContent: 'center',
  },
  plateTypesContainer: {
    width: '50%',
    padding: '10px',
  },
}));
