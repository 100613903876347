import React, { useCallback } from 'react';

import 'react-markdown-editor-lite/lib/index.css';

import MdEditor from 'react-markdown-editor-lite';

import NameLink from 'admin-client/app/components/Markdown/plugins/NameLink';
import { previewStyles } from 'admin-client/app/components/Markdown/previewStyles';
import { Markdown, renderMarkdownUnsafe } from 'common/lib/markdown';
import { Commit } from 'common/types/commonConfiguration';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  value: Markdown;
  currentCommit?: Commit;
  onChange: (markdown: Markdown) => void;
};

const EDITOR_STYLE = { minHeight: '200px', maxHeight: '80vh', width: '100%' };
const EDITOR_CONFIG = {
  view: {
    menu: true,
    md: true,
    html: true,
  },
  table: {
    maxRow: 5,
    maxCol: 6,
  },
  syncScrollMode: ['leftFollowRight', 'rightFollowLeft'],
};

export function MarkdownEditor({ value, onChange, currentCommit }: Props) {
  const classes = useStyles();
  const htmlClass = classes['preview']; // From preview.css
  const onEditorChange = useCallback(
    (data: { text: string; html: string }) => onChange(data.text as Markdown),
    [onChange],
  );
  const renderHTML = useCallback(
    (text: string) => renderMarkdownUnsafe(text as Markdown),
    [],
  );

  /* We must explicitly pass in the plugins we want to use for the editor. Not doing this
   * results in multiple instances of our custom nameLink plugin.
   *
   * Note: 'fonts' is an umbrella term for all the default formatting plugins that exist
   * in the editor (e.g 'font-bold', 'font-italic') and we only need to pass this in to
   * include all of them.
   */
  const plugins = [
    'header',
    'fonts',
    'table',
    'image',
    'link',
    'clear',
    'logger',
    'mode-toggle',
    'full-screen',
  ];

  if (currentCommit) {
    plugins.push('nameLink');
    MdEditor.use(NameLink, {
      currentCommit,
    });
  }

  return (
    <MdEditor
      value={value}
      renderHTML={renderHTML}
      style={EDITOR_STYLE}
      config={{ ...EDITOR_CONFIG, htmlClass: htmlClass }}
      onChange={onEditorChange}
      plugins={plugins}
    />
  );
}

const useStyles = makeStylesHook({
  ...previewStyles,
});
