import React, { useCallback, useMemo } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import { ActionDiscriminant } from 'common/rules/types';
import { APIElement } from 'common/types/api';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/** Takes a union type of Actions as its type argument */
export type ActionTypeOption<Action extends ActionDiscriminant> = {
  label: string;
  type: Action['type'];
  editor: React.ComponentType<{
    /** Should be of the specific type matching the type property */
    action: any;
    element: APIElement;
    onChange: (action: Action) => void;
  }>;
  /**
   * prevAction is passed as argument so some of the settings can be
   * preserved when changing the action type
   */
  getDefaultAction: (prevAction: Action | null) => Action;
};

export type Props<Action extends ActionDiscriminant> = {
  action: Action;
  options: ActionTypeOption<Action>[];
  index: number;
  element: APIElement;
  onActionChange: (action: Action, index: number) => void;
  onActionRemove: (index: number) => void;
};

export function ActionEditor<Action extends ActionDiscriminant>(props: Props<Action>) {
  const classes = useStyles();
  const { action, options, index, element, onActionChange, onActionRemove } = props;
  const selectedOption = useMemo(
    () => options.find(option => option.type === action.type)!,
    [action.type, options],
  );

  const onActionTypeChange = useCallback(
    (_event: any, option: ActionTypeOption<Action> | null) => {
      if (!option) {
        return;
      }
      onActionChange(option.getDefaultAction(action), index);
    },
    [action, index, onActionChange],
  );

  const onChange = useCallback(
    (action: Action) => onActionChange(action, index),
    [index, onActionChange],
  );

  const onRemoveClick = useCallback(() => onActionRemove(index), [index, onActionRemove]);

  const Editor = selectedOption.editor;

  return (
    <>
      <Autocomplete
        options={options}
        value={selectedOption}
        renderInput={params => <TextField {...params} variant="outlined" />}
        getOptionLabel={getOptionLabel}
        disableClearable
        onChange={onActionTypeChange}
        size="small"
        className={classes.typeSelector}
      />

      <Editor action={action} element={element} onChange={onChange} />

      <IconButton onClick={onRemoveClick} title="Remove action" size="large">
        <DeleteIcon />
      </IconButton>
    </>
  );
}

function getOptionLabel<T extends ActionDiscriminant>(option: ActionTypeOption<T>) {
  return option.label;
}

const useStyles = makeStylesHook({
  typeSelector: {
    minWidth: '250px',
    marginRight: '1rem',
  },
  root: {
    marginBottom: '1rem',
  },
});
