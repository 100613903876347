import React, { useCallback } from 'react';

import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  useMutation,
} from '@apollo/client';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import {
  GET_AUTH0_CONNECTIONS,
  GET_ORGANISATION,
  UPDATE_CONNECTIONS,
} from 'admin-client/app/api/gql/queries';
import { GraphQLConnection } from 'admin-client/app/components/Connections/utils';
import {
  ConnectionType,
  Exact,
  getOrganisationQuery,
  OrganisationType,
} from 'admin-client/app/gql';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import useDialog from 'common/ui/hooks/useDialog';

type ConnectionsEditorProps = {
  connections: GraphQLConnection[];
  orgUpdate: OrganisationType | getOrganisationQuery['organisation'];
  mutationOptions?: MutationHookOptions<
    any,
    Exact<{ organisation: OrganisationType; newAuth0Connection: ConnectionType }>,
    DefaultContext,
    ApolloCache<any>
  >;
};

export const ConnectionsEditor = React.memo(
  ({ orgUpdate, mutationOptions = {}, ...otherProps }: ConnectionsEditorProps) => {
    const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

    const [updateConnection] = useMutation(UPDATE_CONNECTIONS, mutationOptions);

    const handleChangeConnection = useCallback(
      async (connection: GraphQLConnection) => {
        const shouldToggle = await openConfirmationDialog({
          action: 'switch',
          object: `${connection.name} in ${orgUpdate.name}`,
        });
        if (shouldToggle) {
          await updateConnection({
            variables: {
              organisation: {
                ...orgUpdate,
                id: orgUpdate.id as UUID,
              },
              newAuth0Connection: connection,
            },
            refetchQueries: [
              {
                query: GET_ORGANISATION,
                variables: { humanIdentifier: orgUpdate.humanIdentifier },
              },
              { query: GET_AUTH0_CONNECTIONS },
            ],
          });
        }
      },
      [openConfirmationDialog, orgUpdate, updateConnection],
    );
    return (
      <ConnectionsEditPanel
        orgUpdate={orgUpdate}
        confirmationDialog={confirmationDialog}
        handleChangeConnection={handleChangeConnection}
        {...otherProps}
      />
    );
  },
);

type ConnectionsEditPanelProps = Omit<ConnectionsEditorProps, 'mutationOptions'> & {
  confirmationDialog: JSX.Element | null;
  handleChangeConnection: (connection: GraphQLConnection) => void;
};

const ConnectionsEditPanel = React.memo(
  ({
    connections,
    confirmationDialog,
    handleChangeConnection,
    orgUpdate,
  }: ConnectionsEditPanelProps) => {
    return (
      <FormControl>
        {confirmationDialog}
        <RadioGroup>
          {connections.map(connection => (
            <FormControlLabel
              key={connection.id}
              value={connection.id}
              control={
                <Radio
                  onChange={() => handleChangeConnection(connection)}
                  checked={connection.name === orgUpdate.auth0ConnectionName}
                />
              }
              label={connection.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  },
);
