import React, { useCallback, useState } from 'react';

import Button from '@mui/material/Button';

import { RuleCard } from 'admin-client/app/components/ElementConfiguration/Card/rules/RuleCard';
import { isParameterUsedInRule } from 'common/rules/isParameterUsedInRule';
import { APIElement } from 'common/types/api';
import {
  ElementConfigurationRule as Rule,
  ElementConfigurationSpec,
} from 'common/types/elementConfiguration';
import { TypeConfigurationSpec } from 'common/types/typeConfiguration';

/**
 * We always expect a configuration in the RuleEditor, so we are explicit about
 * this with the custom APIElement type.
 */
type APIElementWithConfiguration = Omit<APIElement, 'configuration'> & {
  configuration: ElementConfigurationSpec;
};

export function RuleEditor(props: {
  rules: Rule[];
  element: APIElementWithConfiguration;
  defaultParameterName?: string;
  typeConfigurations: Record<string, TypeConfigurationSpec>;
  onChange: (rules: Rule[]) => void;
}) {
  const { rules, defaultParameterName, element, typeConfigurations, onChange } = props;

  const [initialRules] = useState(() => rules);

  const onAddRule = useCallback(
    () =>
      onChange([
        ...rules,
        {
          condition: {
            type: 'list',
            operator: 'or',
            childConditions: [{ type: 'const-true' }],
          },
          actions: [],
        },
      ]),
    [onChange, rules],
  );

  const onRemoveRule = useCallback(
    (ruleIndex: number) => onChange(rules.filter((_rule, index) => index !== ruleIndex)),
    [onChange, rules],
  );

  const onRuleChange = useCallback(
    (rule: Rule, index: number) => {
      const newRules = [...rules];
      newRules[index] = rule;
      onChange(newRules);
    },
    [onChange, rules],
  );

  return (
    <>
      {props.rules
        /**
         * We need to make sure to use the original index here before we filter, so the correct rule
         * in the rules array is modified when calling `onRuleChange` in the RuleCard.
         */
        .map((rule, index) => {
          return { rule, index };
        })
        .filter(
          rule =>
            !defaultParameterName ||
            isParameterUsedInRule(rule.rule, defaultParameterName) ||
            isNewlyAddedRule(rule.rule, initialRules),
        )
        .map(rule => (
          <RuleCard
            key={rule.index}
            rule={rule.rule}
            ruleIndex={rule.index}
            defaultParameterName={defaultParameterName}
            element={element}
            typeConfigurations={typeConfigurations}
            onRemoveRule={onRemoveRule}
            onRuleChange={onRuleChange}
          />
        ))}
      <Button variant="outlined" onClick={onAddRule}>
        Add rule
      </Button>
    </>
  );
}

/**
 * Slight hack. Only rules relevant to the current parameter should be displayed. But when
 * adding a new rule, it has no definition yet, so it would be filtered out because function
 * isParameterUsedInRule would return false.
 *
 *
 */
function isNewlyAddedRule(rule: Rule, initialRules: Rule[]) {
  return initialRules.every(initialRule => initialRule !== rule);
}
