import React, { useCallback, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

type Props = {
  onCopyConfigToNewBranch: () => {};
  onChangeBranchOrCommit?: () => {};
};

export default function BranchOptionsMenu({
  onCopyConfigToNewBranch,
  onChangeBranchOrCommit,
}: Props) {
  const [branchOptionsMenuAnchorElement, setBranchOptionsMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  const onShowBranchOptionsMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setBranchOptionsMenuAnchorElement(event.currentTarget);
    },
    [setBranchOptionsMenuAnchorElement],
  );

  const onHideBranchOptionsMenu = useCallback(() => {
    setBranchOptionsMenuAnchorElement(null);
  }, [setBranchOptionsMenuAnchorElement]);

  const handleOnChangeBranchOrCommit = useCallback(() => {
    onChangeBranchOrCommit?.();
    setBranchOptionsMenuAnchorElement(null);
  }, [onChangeBranchOrCommit]);

  const handleOnCopyConfigToNewBranch = useCallback(() => {
    onCopyConfigToNewBranch();
    setBranchOptionsMenuAnchorElement(null);
  }, [onCopyConfigToNewBranch]);

  return (
    <>
      <IconButton onClick={onShowBranchOptionsMenu} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={branchOptionsMenuAnchorElement}
        keepMounted
        open={!!branchOptionsMenuAnchorElement}
        onClose={onHideBranchOptionsMenu}
      >
        {onChangeBranchOrCommit && (
          <MenuItem onClick={handleOnChangeBranchOrCommit}>
            <Typography>Change branch or apply to latest commit</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleOnCopyConfigToNewBranch}>
          <Typography>Copy to new branch</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
