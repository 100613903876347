import React from 'react';

import Link from '@mui/material/Link';

import { useAdminUIContext } from 'admin-client/app/components/AdminUIContext';
import { ArrayElement, getAuth0ConnectionsQuery } from 'admin-client/app/gql/';

type ConnectionLinkProps = {
  connection: ArrayElement<getAuth0ConnectionsQuery['auth0Connections']>;
};

export const ConnectionLink = React.memo(({ connection }: ConnectionLinkProps) => {
  const { auth0Tenant } = useAdminUIContext();
  const path = `https://manage.auth0.com/dashboard/eu/${auth0Tenant}/connections/database/${connection.id}/settings`;

  return (
    <Link href={path} target="_blank" title={`Open ${path} in same window`}>
      {connection.name}
    </Link>
  );
});
