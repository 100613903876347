import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
  GET_USER_ROLES,
  MUTATION_UPDATE_USER_ROLE,
} from 'admin-client/app/api/gql/queries';
import { usePermissions } from 'admin-client/app/components/Permissions';
import { getUserQuery as GraphQLUser } from 'admin-client/app/gql';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { Stack } from 'common/ui/components/Stack';
import { UserRole } from 'common/ui/components/UserRole';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Role = {
  readonly __typename: 'Role';
  readonly id: string;
  readonly name: string;
  readonly description: string;
};

type UserTableProps = {
  user: GraphQLUser;
  roles: readonly Role[];
  availableRoles: readonly Role[];
  isEditable: boolean;
};
const defaultErrorMessage = 'Role update failed, please try again';
const successMessage = 'Successfully update role';
const NO_ROLE_COPY = 'No Role';

export const UserRoleTable = React.memo(
  ({ user, roles, availableRoles, isEditable }: UserTableProps) => {
    const classes = useStyles();
    const { currentUserHasPermission } = usePermissions();
    const hasUpdatePermissions = currentUserHasPermission('update:users');

    const snackbar = useSnackbarManager();

    const [updateCustomerRole] = useMutation(MUTATION_UPDATE_USER_ROLE, {
      refetchQueries: [
        {
          query: GET_USER_ROLES,
          variables: { id: user.user?.id },
        },
      ],
      onCompleted: _ => {
        snackbar.showSuccess(successMessage);
      },
      onError: _ => {
        snackbar.showError(defaultErrorMessage);
      },
    });

    const onRoleChange = useCallback(
      (roleId: string | null) =>
        updateCustomerRole({
          variables: {
            input: {
              userId: user.user!.id,
              currentRoleId: roles[0]?.id,
              newRoleId: roleId,
            },
          },
        }),
      [updateCustomerRole, user.user, roles],
    );

    const noRole = isEditable ? (
      <UserRole
        currentRole={null}
        availableRoles={hasUpdatePermissions && isEditable ? availableRoles : undefined}
        onChange={onRoleChange}
        variant="nullSelectable"
      />
    ) : (
      <Typography>{NO_ROLE_COPY} </Typography>
    );

    return (
      <Grid className={classes.root}>
        {roles.length > 0 ? (
          <ul className={classes.list}>
            <Stack spacing={4}>
              {roles.map(role => (
                <li key={role?.id ?? NO_ROLE_COPY} className={classes.list}>
                  <UserRole
                    currentRole={role}
                    availableRoles={
                      hasUpdatePermissions && isEditable ? availableRoles : undefined
                    }
                    onChange={onRoleChange}
                    variant="nullSelectable"
                  />
                </li>
              ))}
            </Stack>
          </ul>
        ) : (
          noRole
        )}
      </Grid>
    );
  },
);

const useStyles = makeStylesHook({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  list: {
    listStyleType: 'none',
    listStylePosition: 'inside',
    padding: 0,
    margin: 0,
  },
});
