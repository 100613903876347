import React from 'react';

import { useQuery } from '@apollo/client';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { GET_PLATE_TYPES } from 'admin-client/app/api/gql/queries';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type PlateTypesTableProps = {
  envHostName: string;
  orgHumanIdentifier: string;
  selectedPlateTypes?: string[];
  selectPlateTypes?: (plateType: string) => void;
};

export default React.memo(function PlateTypesTable(props: PlateTypesTableProps) {
  const { envHostName, orgHumanIdentifier, selectedPlateTypes, selectPlateTypes } = props;
  const classes = useStyles();

  const handleSelection = (event: React.MouseEvent<unknown>, type: string) => {
    if (selectPlateTypes) {
      selectPlateTypes(type);
    }
  };

  const plateTypes = useQuery(GET_PLATE_TYPES, {
    variables: {
      hostname: envHostName,
      orgHumanIdentifier: orgHumanIdentifier,
    },
  });

  return (
    <div>
      {plateTypes.loading && <Typography>Loading...</Typography>}
      {plateTypes.error && (
        <Typography color="error">Error: {plateTypes.error.message}</Typography>
      )}
      {plateTypes.data && (
        <Table size="small" className={classes.table} stickyHeader>
          <TableHead className={classes.head} />
          <TableBody>
            {[...plateTypes.data.plateTypes]
              .sort((pt1, pt2) => (pt1.type < pt2.type ? -1 : 1))
              .map(plateType => {
                const isChecked =
                  selectedPlateTypes?.find(type => type === plateType.type) !== undefined;
                return (
                  <TableRow
                    key={plateType.type}
                    onClick={
                      selectedPlateTypes
                        ? event => handleSelection(event, plateType.type)
                        : undefined
                    }
                  >
                    {selectedPlateTypes && (
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isChecked} />
                      </TableCell>
                    )}
                    <TableCell>
                      <Typography>{plateType.type}</Typography>
                      <Typography className={classes.plateTypeMame}>
                        {plateType.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      )}
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  // Prevent the sticky table header from scrolling underneath the AppBar
  head: {
    '& th': {
      top: `${theme.mixins.toolbar.minHeight}px`,
      paddingTop: theme.spacing(6),
    },
  },
  table: {
    marginTop: theme.spacing(6),
  },
  plateTypeMame: {
    color: Colors.GREY_60,
  },
}));
