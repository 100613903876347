import React from 'react';

import { GraphQLErrors } from '@apollo/client/errors';
import Typography from '@mui/material/Typography';
import { GraphQLErrorExtensions } from 'graphql/error';

import { GET_EXAMPLE_WORKFLOWS_TARGET_ENVIRONMENTS } from 'admin-client/app/api/gql/queries';
import { usePublishExampleWorkflows } from 'admin-client/app/components/ExampleWorkflows/hooks';
import { HasPermission } from 'admin-client/app/components/Permissions';
import { PublishContentUI } from 'admin-client/app/components/PublishContentUI';
import {
  PAGE_ROWS_DEFAULT,
  PublishHistoryUI,
} from 'admin-client/app/components/PublishHistoryUI';
import {
  Mutation,
  PublishExampleWorkflowsResultFailureEnvs,
  PublishExampleWorkflowsResultSuccessEnvs,
} from 'admin-client/app/gql';
import { Stack } from 'common/ui/components/Stack';

export function ExampleWorkflowsScreen() {
  const { onClick, loading } = usePublishExampleWorkflows();
  const pageState = React.useState(0);
  const pagePerRowsState = React.useState(PAGE_ROWS_DEFAULT);
  return (
    <div>
      <Typography variant="h2">Example Workflows</Typography>
      <Stack spacing={8}>
        <div>
          <Typography variant="body1">
            Example workflows are curated by the Synthace team to help users to:
            <ul>
              <li>
                Overcome blank page syndrome and help them get started running their
                experiment
              </li>
              <li>
                Understand how they can run common scientific experiments using Synthace
              </li>
              <li>Showcase the kind of experiments users can run using Synthace</li>
            </ul>
          </Typography>
        </div>
        <HasPermission permission="publish:exampleWorkflows">
          <PublishContentUI
            title="Publish Example Workflows"
            loading={loading}
            onClick={onClick}
            description={
              <>
                <Typography variant="body1">
                  Pick which environment you want to push to by using the example
                  workflows in the examples org on synthace.synthace.bio.
                </Typography>
                <Typography variant="body1">
                  If the org does not have access to the elements for the example
                  workflow, it will be automatically excluded.
                </Typography>
              </>
            }
            getTargetEnvironmentsQuery={GET_EXAMPLE_WORKFLOWS_TARGET_ENVIRONMENTS}
            mkOptions={(snackbarManager, setTargetEnvironments) => ({
              onCompleted: ({
                getExampleWorkflowsTargetEnvironments: { environmentURLs },
              }) => {
                setTargetEnvironments(environmentURLs.toSorted());
              },
              onError: _error => {
                snackbarManager.showWarning(
                  "Couldn't fetch target environments. Please refresh the page to retry.",
                );
              },
            })}
          />
        </HasPermission>
        <div>
          <Typography variant="h4">Publish Example Workflow History</Typography>
          <PublishHistoryUI<
            PublishExampleWorkflowsResultSuccessEnvs,
            PublishExampleWorkflowsResultFailureEnvs,
            { sourceEnvURL: string }
          >
            actionName="publishExampleWorkflows"
            actionItemMapper={({
              id,
              occurredAt,
              resultType,
              userEmail,
              responseBody,
            }) => {
              const successEnvs = (responseBody?.data as Mutation)?.[
                'publishExampleWorkflows'
              ].successEnvs;
              const failureEnvs = (responseBody?.data as Mutation)?.[
                'publishExampleWorkflows'
              ].failureEnvs;
              const sourceEnvURL = (responseBody?.data as Mutation)?.[
                'publishExampleWorkflows'
              ].sourceEnvURL;
              const errors = (responseBody?.errors as GraphQLErrors) || [];
              return {
                id,
                occurredAt,
                resultType,
                userEmail,
                errors,
                failureEnvs,
                successEnvs,
                sourceEnvURL,
                extras: { sourceEnvURL },
              };
            }}
            errorRenderer={renderExampleWorkflowsError}
            failureEnvRenderer={failureEnvs => {
              // Program defensively here because old actions might not have well-formed.
              return (
                <>
                  {failureEnvs?.map(({ envURL, message }, idx) => (
                    <div key={idx}>
                      {'- '}
                      <a href={envURL} target="_blank" rel="noopener noreferrer">
                        {envURL}
                      </a>
                      {` ${message}${idx < failureEnvs.length - 1 ? ', ' : ''}`}
                    </div>
                  ))}
                </>
              );
            }}
            successEnvRenderer={(
              successEnvs: PublishExampleWorkflowsResultSuccessEnvs[],
              { sourceEnvURL }: { sourceEnvURL: string },
            ) => {
              // Program defensively here because old actions might not have well-formed.
              return (
                <>
                  {successEnvs?.map(({ envURL, unsupportedWorkflowIds }, idx) => (
                    <div key={idx}>
                      -{' '}
                      <a
                        key={`${idx}${idx}`}
                        href={envURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {envURL}
                      </a>{' '}
                      {unsupportedWorkflowIds && unsupportedWorkflowIds.length === 0
                        ? ''
                        : 'unsupported workflows: '}
                      {unsupportedWorkflowIds?.map(({ id, reason, details }, idx) => {
                        return (
                          <>
                            <a
                              key={idx}
                              href={`${sourceEnvURL}#/workflows/${id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {id.slice(0, 8)}
                            </a>{' '}
                            ({reason}
                            {details && details.length > 0 ? ` - ${details}` : ''})
                            {idx < unsupportedWorkflowIds.length - 1 ? `, ` : ``}
                          </>
                        );
                      })}
                      {successEnvs &&
                      idx < successEnvs.length - 1 &&
                      successEnvs.length > 1
                        ? ', '
                        : ''}
                    </div>
                  ))}
                </>
              );
            }}
            paginationState={{ pageState, pagePerRowsState }}
          />
        </div>
      </Stack>
    </div>
  );
}

function renderExampleWorkflowsError(
  message: string,
  extensions: GraphQLErrorExtensions,
): React.ReactElement | null {
  function isSynthaceGraphQLError(
    extensions: unknown,
  ): extensions is { detail: { code: string; data: unknown } } {
    return (
      typeof extensions === 'object' &&
      extensions !== null &&
      'code' in extensions &&
      extensions.code === 'PUBLISH_EXAMPLE_WORKFLOWS_FAILED' &&
      'detail' in extensions &&
      typeof extensions.detail === 'object'
    );
  }

  function isDetailList(extensions: {
    detail: { code: string; data: unknown };
  }): extensions is {
    detail: { code: string; data: { id: string; url: string; message: string }[] };
  } {
    return (
      'data' in extensions.detail &&
      Array.isArray(extensions.detail.data) &&
      extensions.detail.data.every(x => 'id' in x && 'message' in x)
    );
  }

  if (isSynthaceGraphQLError(extensions)) {
    if (isDetailList(extensions)) {
      const renderedDetailList = extensions.detail.data.map(
        ({ id, message, url }, idx) => {
          return (
            <div key={idx}>
              {`- ${extensions.detail.code.replace(/^(.)/, (_, firstChar) =>
                firstChar.toUpperCase(),
              )} `}
              <a href={url} target="_blank" rel="noopener noreferrer">
                {id.slice(0, 8)}
              </a>
              : {message}
            </div>
          );
        },
      );
      return (
        <>
          <p>{message}</p>
          <>{renderedDetailList}</>
        </>
      );
    }
  }

  return null;
}
