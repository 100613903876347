import React, { useCallback } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

import { EditorType } from 'common/elementConfiguration/EditorType';
import { getEditorTypeProperties } from 'common/elementConfiguration/getEditorTypeInfo';
import Colors from 'common/ui/Colors';
import Checkbox from 'common/ui/components/Checkbox';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useCheckboxChange from 'common/ui/hooks/useCheckboxChange';

type Props = {
  editorTypes: EditorType[];
  onChange: (value: EditorType[]) => void;
};

const ALL_EDITOR_TYPES = Object.values(EditorType).sort();

export function EditorTypeSelector(props: Props) {
  const classes = useStyles();
  const handleEditorTypeChange = useCallback(
    (value: EditorType, include: boolean) => {
      // If checkbox value is true (i.e. include), means we should include this EditorType
      // otherwise, make sure to remove it if it's there.
      if (include) {
        const updatedEditors = props.editorTypes.includes(value)
          ? props.editorTypes
          : [...props.editorTypes, value];
        props.onChange(updatedEditors);
        return;
      }
      const updatedEditors = props.editorTypes.filter(editor => editor !== value);
      props.onChange(updatedEditors);
    },
    [props],
  );

  return (
    <FormControl component="fieldset">
      <FormGroup>
        {ALL_EDITOR_TYPES.map(editorType => {
          return (
            <EditorTypeSelectorCheckbox
              key={editorType}
              checked={props.editorTypes.includes(editorType)}
              editorType={editorType}
              onChange={handleEditorTypeChange}
            />
          );
        })}
      </FormGroup>
      {props.editorTypes.length === 0 && (
        <FormHelperText>
          <Typography className={classes.error} variant="subtitle1">
            At least one editor type must be selected
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
}

type EditorTypeSelectorCheckboxProps = {
  checked: boolean;
  editorType: EditorType;
  onChange: (editorType: EditorType, include: boolean) => void;
};

function EditorTypeSelectorCheckbox(props: EditorTypeSelectorCheckboxProps) {
  const classes = useStyles();
  const onClick = useCheckboxChange(value => {
    props.onChange(props.editorType, value);
  });

  const { displayName: editorDisplayName } = getEditorTypeProperties(
    props.editorType,
  ) ?? { displayName: props.editorType };

  return (
    <FormControlLabel
      key={props.editorType}
      control={<Checkbox checked={props.checked} onChange={onClick} />}
      label={
        <div className={classes.editorCheckBoxLabel}>
          <Typography>{props.editorType}</Typography>
          <Typography variant="subtitle1">
            {editorDisplayName && `(${editorDisplayName})`}
          </Typography>
        </div>
      }
    />
  );
}

const useStyles = makeStylesHook({
  editorCheckBoxLabel: {
    margin: '6px 0',
  },
  error: {
    color: Colors.ERROR,
  },
});
