import { useMemo } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import moment, { Moment } from 'moment';

import { GET_TYPE_CONFIGURATIONS_BY_NAME } from 'admin-client/app/api/gql/queries';
import { ArrayElement, getConfigurationsForTypeQuery } from 'admin-client/app/gql';
import { TypeName } from 'common/types/typeConfiguration';

type GraphQLTypeConfigurationRaw = ArrayElement<
  getConfigurationsForTypeQuery['typeConfigurations']
>;

type Result = {
  configurations: readonly GraphQLTypeConfiguration[];
  loading: boolean;
  error?: ApolloError;
};

/**
 * Shortcut for fetching type configurations, as it is used in multiple places.
 * This hook will also convert the raw GraphQL data to GraphTypeConfiguration so
 * the client components can work with a convenient data format, like string dates
 * converted to Moment.
 */
export function useTypeConfigurations(typeName: TypeName): Result {
  const { data, loading, error } = useQuery(GET_TYPE_CONFIGURATIONS_BY_NAME, {
    variables: { typeName },
  });
  const configurations = useMemo(() => convertData(data), [data]);
  return { loading, error, configurations };
}

function convertData(
  data: getConfigurationsForTypeQuery | undefined,
): readonly GraphQLTypeConfiguration[] {
  return data?.typeConfigurations.map(convertConfiguration) ?? [];
}

function convertConfiguration(
  configuration: GraphQLTypeConfigurationRaw,
): GraphQLTypeConfiguration {
  return {
    ...configuration,
    commitDate: moment(configuration.commitDate),
    createdAt: moment(configuration.createdAt),
    lastModifiedAt: moment(configuration.lastModifiedAt),
  };
}

export type GraphQLTypeConfiguration = Omit<
  GraphQLTypeConfigurationRaw,
  'commitDate' | 'createdAt' | 'lastModifiedAt'
> & {
  commitDate: Moment;
  createdAt: Moment;
  lastModifiedAt: Moment;
};
