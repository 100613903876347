import React, { useCallback, useMemo, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { BranchList } from 'admin-client/app/components/ElementConfiguration/BranchList';
import { CommitSelector } from 'admin-client/app/components/ElementConfiguration/CommitSelector';
import { getConfigurationCommits } from 'admin-client/app/components/ElementConfiguration/getConfigurationCommits';
import { useCommits } from 'admin-client/app/components/ElementConfiguration/useCommits';
import { Commits } from 'admin-common/src/commit';
import {
  Commit,
  CommonConfigurationFields,
  ConfigurationType,
} from 'common/types/commonConfiguration';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  configurations: readonly CommonConfigurationFields[];
  initialCommit: Commit | null;
  configurationType: ConfigurationType;
  isNewConfiguration: boolean;
  onSelectCommit: (commit: Commit | null) => void;
  onBranchTypeChange: (configurationType: ConfigurationType) => void;
};

export function ConfigurationCommitSelector({
  configurations,
  onSelectCommit,
  onBranchTypeChange,
  initialCommit,
  configurationType,
  isNewConfiguration,
}: Props) {
  const classes = useStyles();
  const { commits } = useCommits();
  const [selectedCommit, setSelectedCommit] = useState<Commit | null>(initialCommit);

  const handleBranchTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedCommit(null);
      onBranchTypeChange(e.target.value as ConfigurationType);
    },
    [onBranchTypeChange],
  );

  const onCommitChange = useCallback(
    (commit: Commit | null) => {
      setSelectedCommit(commit);
      onSelectCommit(commit);
    },
    [onSelectCommit],
  );

  return (
    <>
      {!isNewConfiguration && (
        <FormControl component="fieldset">
          <FormLabel component="legend">Where to apply</FormLabel>
          <RadioGroup
            name="branchType"
            value={configurationType}
            onChange={handleBranchTypeChange}
          >
            <FormControlLabel
              value={ConfigurationType.GLOBAL}
              control={<Radio color="primary" />}
              label="globally"
            />
            <FormControlLabel
              value={ConfigurationType.SINGLE_BRANCH}
              control={<Radio color="primary" />}
              label="only in specific branch"
            />
          </RadioGroup>
        </FormControl>
      )}
      <FormControl>
        <FormLabel component="legend">
          {configurationType === ConfigurationType.GLOBAL
            ? 'Starting at'
            : 'Choose branch'}
        </FormLabel>
        {selectedCommit || commits ? (
          <CommitSelector
            configurationType={configurationType}
            commits={commits}
            existingConfigurations={configurations}
            selectedCommit={selectedCommit}
            onChange={onCommitChange}
          />
        ) : (
          <CircularProgress className={classes.commitSelectorProgress} />
        )}
      </FormControl>
      {selectedCommit && commits && (
        <div className={classes.branchInfo}>
          <BranchInfo
            configurationType={configurationType}
            configurations={configurations}
            selectedCommit={selectedCommit}
            commits={commits}
          />
        </div>
      )}
    </>
  );
}

function BranchInfo({
  configurationType,
  configurations,
  selectedCommit,
  commits,
}: {
  configurationType: ConfigurationType;
  configurations: readonly CommonConfigurationFields[];
  selectedCommit: Commit;
  commits: Commits;
}) {
  const classes = useStyles();

  // In order to correctly predict on which branches the new configuration will be
  // applied, create list of all existing configurations and extend it with the new
  // configuration. Then use this list in getConfigurationCommits method.
  const newConfiguration: CommonConfigurationFields = useMemo(
    () => ({
      commitHash: selectedCommit.commitHash,
      commitDate: selectedCommit.commitDate,
      commitBranch: selectedCommit.commitBranch,
      configurationType,
    }),
    [
      configurationType,
      selectedCommit.commitBranch,
      selectedCommit.commitDate,
      selectedCommit.commitHash,
    ],
  );
  const allConfigurations = useMemo(
    () => [newConfiguration, ...configurations],
    [configurations, newConfiguration],
  );

  const configurationCommits = useMemo(
    () => getConfigurationCommits(newConfiguration, allConfigurations, commits),
    [newConfiguration, allConfigurations, commits],
  );

  const explanation =
    configurationType === ConfigurationType.GLOBAL
      ? `Configuration will be also applied in all internal branches for commits from ${selectedCommit.commitDate}.`
      : 'Configuration will be only applied on this branch, not affecting releng branches.';

  return (
    <div>
      <FormLabel component="legend">Affected branches:</FormLabel>
      <BranchList commits={configurationCommits} />
      <div className={classes.explanation}>{explanation}</div>
    </div>
  );
}

const useStyles = makeStylesHook({
  branchInfo: { marginTop: '2rem' },
  explanation: { fontStyle: 'italic' },
  commitSelectorProgress: {
    marginTop: '1rem',
  },
});
