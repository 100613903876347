import React, { ChangeEvent, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';

import { ArrayElement, getOrganisationsQuery } from 'admin-client/app/gql';

type Props = {
  org: ArrayElement<getOrganisationsQuery['organisations']>;
  onClose: (newName: string | null) => void;
};

// A dialog with OK, Cancel buttons
export const RenameOrganisationDialog = React.memo((props: Props) => {
  const classes = useStyles();
  const { org, onClose } = props;
  const [newName, setNewName] = useState<string>(org.name);

  const formIsValid = newName.trim().length > 0;

  const handleOK = () => {
    if (formIsValid) {
      onClose(newName.trim());
    }
  };

  const handleCancel = () => {
    onClose(null);
  };

  const handleNewNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewName(event.target.value);
  };

  return (
    <Dialog open onClose={handleCancel} maxWidth="xs">
      <DialogTitle>Rename Organisation</DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          required
          className={classes.textField}
          label="New name"
          id="new-name"
          value={newName}
          onChange={handleNewNameChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions data-test="confirmationDialogActions">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={!formIsValid} onClick={handleOK} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const useStyles = makeStyles({
  content: {
    whiteSpace: 'pre-line',
  },
  textField: {
    display: 'block',
    marginBottom: '1rem',
    width: '300px',
  },
});
