import React, { ChangeEvent, useCallback, useState } from 'react';

import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';

import { GET_AUTH0_CONNECTIONS } from 'admin-client/app/api/gql/queries';
import { isFQDN } from 'common/lib/strings';
import { Auth0ConnectionName } from 'common/types/auth0';

type NewOrganisationArgs = {
  name: Auth0ConnectionName;
  humanIdentifier: string;
  emailDomains: string[];
  isSynthaceInternal: boolean;
  auth0ConnectionName?: string;
};

type Props = {
  onClose: (newOrganisation: NewOrganisationArgs | null) => void;
};

// Human identifiers should be all lower-case characters with no space or
// punctuation.
const HUMAN_IDENTIFIER_REGEX = /^[a-z0-9]+$/;
const NULL_CONNECTION_NAME = 'NULL-CONNECTION';

export const NewOrganisationDialog = React.memo((props: Props) => {
  const classes = useStyles();
  const { onClose } = props;
  const [name, setName] = useState('');
  const [humanIdentifier, setHumanIdentifier] = useState('');
  const [emailDomains, setEmailDomains] = useState<string[]>([]);
  const [isSynthaceInternal, setIsSynthaceInternal] = useState(false);
  const [auth0ConnectionName, setAuth0ConnectionName] = useState(NULL_CONNECTION_NAME);
  const { data } = useQuery(GET_AUTH0_CONNECTIONS);
  const unusedAuth0Connections = data?.auth0Connections.filter(
    connection => connection.enabledClients.length === 0,
  );

  const humanIdentifierIsValid = HUMAN_IDENTIFIER_REGEX.test(humanIdentifier);
  const formIsValid =
    name.length > 0 &&
    humanIdentifierIsValid &&
    (isSynthaceInternal ||
      (emailDomains.length > 0 && emailDomains.every(domain => isFQDN(domain))));

  const handleOK = () => {
    if (formIsValid) {
      const _emailDomains = isSynthaceInternal ? ['synthace.com'] : emailDomains;
      const newOrgArgs: NewOrganisationArgs = {
        name: name.trim() as Auth0ConnectionName,
        humanIdentifier: humanIdentifier.trim(),
        isSynthaceInternal,
        emailDomains: _emailDomains,
      };
      if (auth0ConnectionName !== NULL_CONNECTION_NAME) {
        newOrgArgs.auth0ConnectionName = auth0ConnectionName;
      }
      onClose(newOrgArgs);
    }
  };

  const handleCancel = () => {
    onClose(null);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleHumanIdentifierChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHumanIdentifier(event.target.value);
  };

  const handleAuth0ConnectionNameChange = (event: SelectChangeEvent<string>) => {
    setAuth0ConnectionName(event.target.value);
  };

  const handleEmailDomainsChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEmailDomains(event.target.value.split(',').map(ed => ed.trim()));
    },
    [setEmailDomains],
  );

  const handleIsSynthaceInternalChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSynthaceInternal(event.target.checked);
  };

  return (
    <Dialog open onClose={handleCancel} maxWidth="xs">
      <DialogTitle>Add Organisation</DialogTitle>
      <DialogContent className={classes.content}>
        <form>
          <TextField
            required
            className={classes.textField}
            label="Name"
            placeholder="e.g. Bloggs Inc."
            id="new-org-name"
            value={name}
            onChange={handleNameChange}
            fullWidth
          />
          <TextField
            required
            error={humanIdentifier.length > 0 && !humanIdentifierIsValid}
            className={classes.textField}
            label="Human identifier"
            placeholder="e.g. bloggsinc"
            id="new-org-human-identifier"
            helperText="Lower-case alphanumeric characters only, no space or punctuation"
            value={humanIdentifier}
            onChange={handleHumanIdentifierChange}
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSynthaceInternal}
                onChange={handleIsSynthaceInternalChange}
              />
            }
            label="Synthace internal organisation"
          />
          <FormHelperText>
            Internal organisations are for Synthace employee use only. They use
            Synthace&apos;s G Suite SSO for authentication.
          </FormHelperText>
          {!isSynthaceInternal && (
            <>
              <TextField
                required
                className={classes.textField}
                label="Email domain(s)"
                placeholder="e.g. bloggsinc.com,bloggs.co.uk"
                id="new-org-email-domains"
                helperText="A comma-separated list of valid email domains for this org"
                value={emailDomains.join(',')}
                onChange={handleEmailDomainsChange}
                fullWidth
              />
              <FormControl>
                <InputLabel id="connection-select-label">
                  Advanced: Source of users
                </InputLabel>
                <Select
                  className={classes.select}
                  labelId="connection-select-label"
                  value={auth0ConnectionName}
                  onChange={handleAuth0ConnectionNameChange}
                >
                  <MenuItem value={NULL_CONNECTION_NAME}>
                    Create a new user database (default)
                  </MenuItem>
                  {unusedAuth0Connections?.map(conn => (
                    <MenuItem key={conn.name} value={conn.name}>
                      {conn.name} ({conn.strategy})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions data-test="confirmationDialogActions">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={!formIsValid} onClick={handleOK} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const useStyles = makeStyles({
  content: {
    whiteSpace: 'pre-line',
  },
  textField: {
    display: 'block',
    marginBottom: '1rem',
    width: '300px',
  },
  select: {
    minWidth: '300px',
  },
});
