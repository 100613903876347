import React, { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import AdminUIToolbar from 'admin-client/app/components/AdminUILayout/AdminUIToolbar';
import LeftSidebar from 'admin-client/app/components/LeftSidebar/LeftSidebar';
import { PermissionsProvider } from 'admin-client/app/components/Permissions';
import ScreensRoute from 'admin-client/app/components/ScreensRoute/ScreensRoute';
import { DialogManagerProvider } from 'common/ui/components/DialogManager';
import SnackbarManagerProvider from 'common/ui/components/SnackbarManager';
import ApolloProviderWithAuth from 'common/ui/graphql/ApolloProviderWithAuth';
import useRefCallback from 'common/ui/hooks/useRefCallback';

function AdminUILayout() {
  const classes = useStyles();

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (isLoading) {
        // calling getAccessTokenSilently during login process (e.g. loginWithRedirect)
        // leads to a race condition - it seems to record somewhere that the user isn't
        // logged in so if it finishes after a second call to getAccessTokenSilently
        // starts (with isLoading already false) then the second call fails even if
        // otherwise it would succeed.
        // That's why we skip this call while isLoading==true
        // This seems to only happen if third-party cookies are blocked.
        //
        // getAccessTokenSilently is also called in other places but it doesn't cause
        // this issue as far as I can tell because it isn't called instantly, unlike
        // here.
        return;
      }

      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (e) {
        console.error(e);
      }
    })();
    // I'm not sure if dependency on isAuthenticated is required - not sure if it can
    // change separately from isLoading. But having it here shouldn't cause any issues.
  }, [isLoading, isAuthenticated, getAccessTokenSilently, setAccessToken]);

  // We want to avoid at all cost the Apollo Client to be recreated, or we lose the cache.
  // So we make a magic never changing callback that will call the latest getAccessTokenSilently
  const stableGetAccessTokenSilently = useRefCallback(getAccessTokenSilently);

  return (
    <ApolloProviderWithAuth
      rootPath="/graphql/"
      getAccessTokenSilently={stableGetAccessTokenSilently}
    >
      <PermissionsProvider>
        <DialogManagerProvider>
          <SnackbarManagerProvider>
            <div className={classes.root}>
              <CssBaseline />
              <AdminUIToolbar />
              {accessToken && <LeftSidebar />}
              <main className={classes.content}>
                <div className={classes.toolbar} />
                {accessToken ? (
                  <ScreensRoute />
                ) : (
                  <Typography className={classes.logInMessage}>
                    {isLoading ? 'Checking credentials...' : 'Log in to get started'}
                  </Typography>
                )}
              </main>
            </div>
          </SnackbarManagerProvider>
        </DialogManagerProvider>
      </PermissionsProvider>
    </ApolloProviderWithAuth>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(6),
    },
    title: {
      flexGrow: 1,
    },
    logInMessage: {},
    toolbar: theme.mixins.toolbar,
  }),
);

export default AdminUILayout;
