import React from 'react';

import Box from '@mui/material/Box';
import { yellow } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

type InfoBoxProps = {
  children: React.ReactNode;
};

export const InfoBox = (props: InfoBoxProps) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <Box className={classes.info}>
      <Typography>{children}</Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: {
      padding: theme.spacing(5),
      backgroundColor: yellow[100],
      borderLeft: '3px solid ' + yellow[800],
      margin: '1rem 0',
    },
  }),
);
