import React from 'react';

import EnvironmentsTableContainer from 'admin-client/app/components/Environments/EnvironmentsTable';

export default function LandingPageContainer() {
  return <LandingPage />;
}

const LandingPage = React.memo(function LandingPage() {
  return <EnvironmentsTableContainer />;
});
