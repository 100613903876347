import { Moment } from 'moment';

import { AdditionalEditorProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { FileObject } from 'common/types/fileParameter';
import { OpaqueAlias } from 'common/types/OpaqueAlias';

// Types shared by Element and Type Configurations go here.

/** A full filetree path, including global org ID, but with the scheme prefix removed */
export type GlobalFiletreeLink = OpaqueAlias<string, 'GlobalFiletreeLink'>;

/** Like a FileObject, but with a Path that's a GlobalFiletreeLink */
export type GlobalFileObject = Omit<FileObject, 'Path'> & {
  Path: GlobalFiletreeLink;
};

export enum ConfigurationType {
  GLOBAL = 'GLOBAL',
  SINGLE_BRANCH = 'SINGLE_BRANCH',
}

export type Commit = {
  commitHash: string;
  commitDate: Moment;
  commitBranch: string;
};

/**
 * Fields of Configuration objects that identify the commit and configuration type.
 * Used internally for helper methods.
 */
export type CommonConfigurationFields = {
  commitHash: string;
  commitDate: Moment;
  commitBranch: string;
  configurationType: ConfigurationType;
};

/**
 * Overrides and settings for an element parameter.
 * To be used as part of element and type configurations,
 * in the latter case to define defaults.
 * */
export type ParameterEditorConfigurationSpec = {
  type: EditorType;
  placeholder: string;
  additionalProps?: AdditionalEditorProps | null;
};
