type Environment = {
  hostname: string;
};

const REGEXPS = [
  // Regular domain: return the TLD
  // (antha.ninja => ninja, antha.reviews => reviews, etc)
  new RegExp(/^antha\.(.+)$/),
  // Domain with CNAME: return the CNAME
  // (demo.antha.com => demo, beta.antha.ninja => beta, etc)
  new RegExp(/^(.+)\.antha\..+$/),
  // foo.synthace.bio -> foo
  new RegExp(/^(.+)\.synthace\.bio$/),
  // foo.synthace.io -> foo
  // We need to support kite.synthace.io until they move to .bio - https://synthace.atlassian.net/browse/CS-904
  new RegExp(/^(.+)\.synthace\.io$/),
];

export function getEnvironmentNickname(env: Environment) {
  for (const regexp of REGEXPS) {
    const result = regexp.exec(env.hostname);
    if (result && result.length > 1) {
      return result[1];
    }
  }
  return env.hostname;
}

export const isLocalDev = (location: Location): boolean =>
  ['antha.lol', 'localhost.antha.com'].includes(location.hostname);

type LogoColours = 'purple' | 'orange' | 'blue';
function getAdminEnvColour(env: string): LogoColours {
  switch (env) {
    case 'localhost':
      return 'purple';

    case 'dev':
      return 'orange';

    case 'production':
    default:
      return 'blue';
  }
}

type AdminEnvName = 'localhost' | 'dev' | 'production';

const adminHostToEnvName: { [key: string]: AdminEnvName } = {
  'antha.lol:8080': 'localhost',
  'localhost.antha.com': 'localhost',
  'admin-dev.synthace.io': 'dev',
  'admin.antha.com': 'production',
  'admin.synthace.io': 'production',
};

export function setColourForEnvironment(host: string = window.location.host) {
  const env = adminHostToEnvName[host];
  const colour = getAdminEnvColour(env);
  return colour;
}

export function setFaviconForAdminEnvironment(host: string = window.location.host) {
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  if (favicon) {
    const env = adminHostToEnvName[host];
    const colour = getAdminEnvColour(env);
    if (env === 'production') {
      favicon.href = `/app/assets/synthace-favicon.svg`;
    } else {
      favicon.href = `/app/assets/synthace-favicon-${colour}.svg`;
    }

    favicon.type = 'image/svg+xml';
  }
}
