import React, { useCallback, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { Moment } from 'moment';
import { useHistory } from 'react-router-dom';

import { SYNCHRONIZE_ELEMENT_CONFIGURATIONS } from 'admin-client/app/api/gql/queries';
import { BranchList } from 'admin-client/app/components/ElementConfiguration/BranchList';
import { ChooseCommitDialogResult } from 'admin-client/app/components/ElementConfiguration/ChooseElementConfigurationCommitDialog';
import { ConfigurationTypeLabel } from 'admin-client/app/components/ElementConfiguration/ConfigurationTypeLabel';
import { getConfigurationCommits } from 'admin-client/app/components/ElementConfiguration/getConfigurationCommits';
import { useChooseElementConfigurationCommitDialog } from 'admin-client/app/components/ElementConfiguration/useChooseElementConfigurationCommitDialog';
import { useCommits } from 'admin-client/app/components/ElementConfiguration/useCommits';
import {
  GraphQLElementConfiguration,
  useElementConfigurations,
} from 'admin-client/app/components/ElementConfiguration/useElementConfigurations';
import { Commits } from 'admin-common/src/commit';
import { ROUTES } from 'admin-common/src/routing/routes';
import { Commit } from 'common/types/commonConfiguration';
import { ElementName } from 'common/types/elementConfiguration';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  elementName: ElementName;
  initialCommit: Commit | null;
};

const TABLE_COLUMNS: {
  header: string;
  getValue: (
    configuration: GraphQLElementConfiguration,
    allConfigurations: readonly GraphQLElementConfiguration[],
    commits: Commits | null,
    classes: ReturnType<typeof useStyles>,
  ) => React.ReactNode;
}[] = [
  {
    header: 'Type',
    getValue: configuration => (
      <ConfigurationTypeLabel configurationType={configuration.configurationType} />
    ),
  },
  {
    header: 'Applied',
    getValue: (configuration, allConfigurations, commits) => {
      if (!commits) {
        return null;
      }
      const configurationCommits = getConfigurationCommits(
        configuration,
        allConfigurations,
        commits,
      );
      return <BranchList commits={configurationCommits} />;
    },
  },
  {
    header: 'Valid since',
    getValue: c => formatDate(c.commitDate),
  },
  {
    header: 'Created',
    getValue: (c, _, __, classes) => (
      <>
        <div>{formatDate(c.createdAt)}</div>
        <div className={classes.editorName}>{c.createdBy}</div>
      </>
    ),
  },
  {
    header: 'Last modified',
    getValue: (c, _, __, classes) => (
      <>
        <div>{formatDate(c.lastModifiedAt)}</div>
        <div className={classes.editorName}>{c.lastModifiedBy}</div>
      </>
    ),
  },
];

/**
 * Table showing configurations for the element.
 */
export function ElementConfigurationTable({ elementName, initialCommit = null }: Props) {
  const classes = useStyles();
  const history = useHistory();

  const { configurations } = useElementConfigurations(elementName);
  const { commits } = useCommits();

  const openChooseCommitDialog = useChooseElementConfigurationCommitDialog();
  const onCommitSelected = useCallback(
    (dialogResult: ChooseCommitDialogResult) => {
      const { commit, configurationType } = dialogResult;
      history.push(
        ROUTES.ELEMENT_CONFIGURATION.CREATE.getPath({
          elementName,
          commitBranch: commit.commitBranch,
          commitHash: commit.commitHash,
          configurationType,
        }),
      );
    },
    [elementName, history],
  );
  const onCreateConfiguration = useCallback(
    () =>
      openChooseCommitDialog({
        elementName,
        initialCommit,
        onCommitSelected,
        isNewConfiguration: true,
        dialogTitle: 'Choose branch to add a configuration to',
      }),
    [elementName, initialCommit, onCommitSelected, openChooseCommitDialog],
  );

  const onEditConfiguration = useCallback(
    (configuration: GraphQLElementConfiguration) =>
      history.push(
        ROUTES.ELEMENT_CONFIGURATION.EDIT.getPath({
          elementName,
          id: configuration.id,
        }),
      ),
    [elementName, history],
  );

  const [synchronize, synchronizeResult] = useMutation(
    SYNCHRONIZE_ELEMENT_CONFIGURATIONS,
  );
  const onSynchronize = useCallback(
    () => synchronize({ variables: { elementName } }),
    [elementName, synchronize],
  );

  const sortedConfiguratoins = useMemo(
    () => [...configurations].sort(compareConfigurations),
    [configurations],
  );

  return (
    <>
      <div className={classes.toolbar}>
        <Button variant="contained" color="secondary" onClick={onCreateConfiguration}>
          Create new configuration
        </Button>
        <Tooltip
          title={
            <div>
              <p>Push configuration for current element to all appservers.</p>
              <p>
                Normally this is not needed. Configurations are always pushed to all
                appservers immediately when saved or deleted. This feature exists to solve
                issues when some servers are down during the implicit synchronization.
              </p>
            </div>
          }
        >
          <Button
            disabled={synchronizeResult.loading}
            variant="outlined"
            onClick={onSynchronize}
          >
            <SyncIcon className={classes.syncIcon} />
            Synchronize
          </Button>
        </Tooltip>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            {TABLE_COLUMNS.map((column, index) => (
              <TableCell key={index}>{column.header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedConfiguratoins.map(configuration => (
            <ConfigurationTableRow
              key={configuration.id}
              className={classes.tableRow}
              commits={commits}
              configuration={configuration}
              allConfigurations={configurations}
              onRowClick={onEditConfiguration}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
}

/**
 * Helper component to render table row. The component is needed only because of
 * the onClick callback for entire row, otherwise it could be inlined.
 */
function ConfigurationTableRow({
  configuration,
  allConfigurations,
  commits,
  className,
  onRowClick,
}: {
  configuration: GraphQLElementConfiguration;
  allConfigurations: readonly GraphQLElementConfiguration[];
  commits: Commits | null;
  className: string;
  onRowClick: (configuration: GraphQLElementConfiguration) => void;
}) {
  const classes = useStyles();
  const onClick = useCallback(
    () => onRowClick(configuration),
    [configuration, onRowClick],
  );
  return (
    <TableRow className={className} onClick={onClick}>
      {TABLE_COLUMNS.map((column, index) => (
        <TableCell key={index}>
          {column.getValue(configuration, allConfigurations, commits, classes)}
        </TableCell>
      ))}
    </TableRow>
  );
}

/**
 * Comparator for sorting configurations in the table. Currently we sort
 * by commit date, descending (that's what the `-` does).
 */
function compareConfigurations(
  c1: GraphQLElementConfiguration,
  c2: GraphQLElementConfiguration,
): number {
  return -c1.commitDate.diff(c2.commitDate, 'seconds');
}

function formatDate(date: Moment): string {
  return date.format('lll');
}

const useStyles = makeStylesHook({
  toolbar: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableRow: {
    '&:hover': { backgroundColor: Colors.GREY_20, cursor: 'pointer' },
  },
  editorName: {
    color: Colors.GREY_40,
  },
  syncIcon: {
    marginRight: '4px',
  },
});
