export type AnthaHubID = string;
export type AnthaHubDeviceDisplayMetrics = {
  id: string;
  name: string;
  driverVersion: string | null;
  modelName: string;
};

/**
 * Represents a collection of metrics for a specific AnthaHub
 */
export type AnthaHubDisplayMetrics = {
  id: string;
  version: string;
  org: string;
  lastSeen: number;
  devices: AnthaHubDeviceDisplayMetrics[];
  settings: AnthaHubSettingsMetrics | null;
};

/**
 * Metrics relating to app settings (e.g. whether auto-updates are enabled), as
 * reported by AnthaHub.
 */
export type AnthaHubSettingsMetrics = {
  autoUpdatePeriodMins: number;
  anthahubUpdatesEnabled: boolean;
  driverUpdatesEnabled: boolean;
  scanBarcodesEnabled: boolean;
};

/**
 * A discriminating union representing a collection of metrics for a specific
 * environment (type:metrics), or the error message received when trying to
 * retrieve those metrics (type:error).
 */
export type AnthaHubMetricsResult =
  | {
      type: 'metrics';
      metrics: AnthaHubDisplayMetrics[];
    }
  | {
      type: 'error';
      errorMessage: string;
    };

export function sortMetrics(m: AnthaHubDisplayMetrics[]) {
  // Sort first by org, so each org's AnthaHubs are displayed together, then by last seen
  // (most recent first)
  m.sort((a, b) => {
    if (a.org !== b.org) {
      return a.org < b.org ? -1 : 1;
    }
    if (a.lastSeen > b.lastSeen) {
      return -1;
    } else if (a.lastSeen < b.lastSeen) {
      return 1;
    } else {
      return 0;
    }
  });
}
