import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';

import {
  DELETE_TYPE_CONFIGURATION,
  GET_TYPE_CONFIGURATIONS_BY_NAME,
  SAVE_TYPE_CONFIGURATION,
} from 'admin-client/app/api/gql/queries';
import { BranchList } from 'admin-client/app/components/ElementConfiguration/BranchList';
import BranchOptionsMenu from 'admin-client/app/components/ElementConfiguration/BranchOptionsMenu';
import AdditionalPropsEditor from 'admin-client/app/components/ElementConfiguration/Card/AdditionalPropsEditor';
import { ConfigurationTypeLabel } from 'admin-client/app/components/ElementConfiguration/ConfigurationTypeLabel';
import { getConfigurationCommits } from 'admin-client/app/components/ElementConfiguration/getConfigurationCommits';
import RowHeader from 'admin-client/app/components/ElementConfiguration/RowHeader';
import { ChooseCommitDialogResult } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/ChooseTypeConfigurationCommitDialog';
import { createTypeConfiguration } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/CreateTypeConfigurationScreen';
import { EditorTypeConfiguration } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/editorType';
import { EditorTypeSelector } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/EditorTypeSelector';
import { useAllCurrentTypeConfigurations } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useAllCurrentTypeConfigurations';
import { useChooseTypeConfigurationCommitDialog } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useChooseTypeConfigurationCommitDialog';
import { useTypeConfigurations } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useTypeConfigurations';
import { useCommits } from 'admin-client/app/components/ElementConfiguration/useCommits';
import { ROUTES } from 'admin-common/src/routing/routes';
import { AdditionalEditorProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { createParameterEditorConfigurationSpec } from 'common/elementConfiguration/createConfigurationSpec';
import { BACKUP_EDITOR_TYPE, EditorType } from 'common/elementConfiguration/EditorType';
import { getCommitFromConfiguration } from 'common/elementConfiguration/getCommitFromConfiguration';
import {
  getAdditionalEditorPropsForEditorType,
  getEditorTypeProperties,
} from 'common/elementConfiguration/getEditorTypeInfo';
import { validateTypeNameForCompoundConfig } from 'common/elementConfiguration/parameterUtils';
import {
  getDefaultPlaceholder,
  isArrayType,
  isCompoundType,
  isMapType,
} from 'common/elementConfiguration/parameterUtils';
import { TypeConfigurationSpec, TypeName } from 'common/types/typeConfiguration';
import Colors from 'common/ui/Colors';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  typeName: TypeName;
  configuration: EditorTypeConfiguration;
};

export function EditTypeConfiguration(props: Props) {
  const classes = useStyles();
  const history = useHistory();

  const { configuration, typeName: encodedTypeName } = props;
  const typeName = decodeURIComponent(encodedTypeName);

  const [spec, setSpec] = useState(() => deepCloneSpec(configuration.spec));

  const commits = useCommits();
  const { configurations } = useTypeConfigurations(typeName);
  const configurationCommit = useMemo(() => {
    return getCommitFromConfiguration(configuration);
  }, [configuration]);
  const { typeConfigurations: allTypeConfigurations } =
    useAllCurrentTypeConfigurations(configurationCommit);

  const typeNames = useMemo(
    () =>
      Object.keys(allTypeConfigurations)
        .map(name => name)
        .filter(name => name !== typeName),
    [allTypeConfigurations, typeName],
  );

  // TODO: remove when we figure out how to migrate type configs
  // It's possible that an editor type might be deleted. At the moment given
  // I have not figured out how to do migrations on the element and type
  // configurations, these associated types are manually removed which is
  // not fool (or Zahra)-proof. So we auto-tidy any type configs where one of
  // the associated editor types has disappeared.
  useEffect(() => {
    const validEditorTypes = Object.values(EditorType);
    const invalidEditorTypeOptionsInSpec = spec.editorTypeOptions.filter(
      type => !validEditorTypes.includes(type),
    );

    if (invalidEditorTypeOptionsInSpec.length === 0) {
      return;
    }

    const validEditorTypeOptionsInSpec = spec.editorTypeOptions.filter(
      type => !invalidEditorTypeOptionsInSpec.includes(type),
    );

    const isDefaultConfigurationValid = validEditorTypeOptionsInSpec.includes(
      spec.defaultEditorConfiguration.type,
    );

    let defaultEditorConfiguration = spec.defaultEditorConfiguration;

    if (!isDefaultConfigurationValid) {
      defaultEditorConfiguration = validEditorTypeOptionsInSpec[0]
        ? {
            ...spec.defaultEditorConfiguration,
            type: validEditorTypeOptionsInSpec[0],
            additionalProps: getAdditionalEditorPropsForEditorType(
              validEditorTypeOptionsInSpec[0],
              typeName,
            ),
            placeholder: getDefaultPlaceholder(typeName),
          }
        : { ...spec.defaultEditorConfiguration, type: BACKUP_EDITOR_TYPE };
    }

    setSpec({
      ...spec,
      editorTypeOptions: validEditorTypeOptionsInSpec,
      defaultEditorConfiguration,
    });
  }, [spec, typeName]);

  const {
    type: selectedEditorType,
    additionalProps: additionalEditorProps,
    placeholder,
  } = spec.defaultEditorConfiguration;

  const [unaliasedTypeNameValidationError, setUnaliasedTypeNameValidationError] =
    useState<string | null>();
  const [unaliasedTypeTextFieldValue, setUnaliasedTypeTextFieldValue] = useState<
    TypeName | undefined
  >(spec.unaliasedType);

  const shouldShowUnaliasedTypeNameEditor = useCallback(
    (editorType: EditorType | undefined) => {
      return (
        (editorType === EditorType.MAP || editorType === EditorType.ARRAY) &&
        !isCompoundType(typeName)
      );
    },
    [typeName],
  );

  const navigateToList = useCallback(
    () => history.push(ROUTES.TYPE_CONFIGURATION.LIST.getPath({ typeName })),
    [history, typeName],
  );

  const onUpdateEditorTypes = useCallback(
    (editorTypes: EditorType[]) => {
      setSpec({
        ...spec,
        editorTypeOptions: editorTypes,
      });
    },
    [spec],
  );

  const onUnaliasedTypeNameChange = useTextFieldChange(
    useCallback(
      (result: string) => {
        const updatedTypeName = result;
        setUnaliasedTypeTextFieldValue(updatedTypeName);
        try {
          validateTypeNameForCompoundConfig(updatedTypeName, typeNames);
          if (
            (isMapType(updatedTypeName) && selectedEditorType !== EditorType.MAP) ||
            (isArrayType(updatedTypeName) && selectedEditorType !== EditorType.ARRAY)
          ) {
            throw new Error("The selected editor can't be used with this antha type.");
          }
        } catch (err) {
          setUnaliasedTypeNameValidationError(err.message);
          return;
        }
        setUnaliasedTypeNameValidationError(null);
        const newSpec = createParameterEditorConfigurationSpec(updatedTypeName);
        setSpec({
          ...spec,
          defaultEditorConfiguration: {
            ...spec.defaultEditorConfiguration,
            additionalProps: newSpec.additionalProps,
          },
          unaliasedType: updatedTypeName,
        });
      },
      [selectedEditorType, spec, typeNames],
    ),
  );

  const onDefaultEditorTypeChange = useCallback(
    (editorType: EditorType | undefined) => {
      setUnaliasedTypeNameValidationError(
        shouldShowUnaliasedTypeNameEditor(editorType) ? 'No type specified' : null,
      );
      setUnaliasedTypeTextFieldValue('' as TypeName);
      setSpec({
        ...spec,
        unaliasedType: undefined,
        defaultEditorConfiguration: {
          ...spec.defaultEditorConfiguration,
          type: editorType ?? BACKUP_EDITOR_TYPE,
          additionalProps: getAdditionalEditorPropsForEditorType(
            editorType || BACKUP_EDITOR_TYPE,
            typeName,
          ),
          placeholder: getDefaultPlaceholder(typeName),
        },
      });
    },
    [shouldShowUnaliasedTypeNameEditor, spec, typeName],
  );

  const onAdditionalEditorPropsChange = useCallback(
    (additionalProps: AdditionalEditorProps) => {
      setSpec({
        ...spec,
        defaultEditorConfiguration: {
          ...spec.defaultEditorConfiguration,
          additionalProps: additionalProps,
        },
      });
    },
    [spec],
  );

  const canSetPlaceholder = useMemo(
    () => getEditorTypeProperties(selectedEditorType)?.canSetPlaceholder ?? false,
    [selectedEditorType],
  );

  const onPlaceholderChange = useTextFieldChange(
    useCallback(
      (placeholder: string) =>
        setSpec({
          ...spec,
          defaultEditorConfiguration: {
            ...spec.defaultEditorConfiguration,
            placeholder: placeholder,
          },
        }),
      [spec],
    ),
  );

  const defaultEditorTypeOptions: Option<EditorType>[] = useMemo(() => {
    return spec.editorTypeOptions.map((editor: EditorType) => ({
      label: editor,
      value: editor,
    }));
  }, [spec.editorTypeOptions]);

  const hasUserSelectedDefaultEditor = defaultEditorTypeOptions.some(
    option => option.label === selectedEditorType,
  );

  const configurationCommits = useMemo(
    () =>
      commits.commits
        ? getConfigurationCommits(
            configuration,
            // Remove current configuration from the list of stored configurations and
            // replace it with currently edited configuration, to render the 'future state'
            // after save.
            [...configurations.filter(c => c.id !== configuration.id), configuration],
            commits.commits,
          )
        : [],
    [commits, configuration, configurations],
  );

  const onInputtableTypesChange = useCallback(
    (_event: any, inputtableTypes: string[] | null) =>
      inputtableTypes &&
      setSpec({
        ...spec,
        inputtableTypes: inputtableTypes,
      }),
    [spec],
  );

  function renderAutoCompleteInput(params: AutocompleteRenderInputParams) {
    return (
      <TextField
        {...params}
        variant="standard"
        helperText="Selecting inputtable types will only work in cases where the element Go code permits it (e.g. wiring alias types with the same unaliased type)."
      />
    );
  }

  const [saveMutation] = useMutation(SAVE_TYPE_CONFIGURATION);
  const onSaveClick = useCallback(async () => {
    await saveMutation({
      variables: {
        id: configuration.id,
        spec: spec,
        commitHash: configuration.commitHash,
        commitDate: configuration.commitDate.toISOString(),
        commitBranch: configuration.commitBranch,
        configurationType: configuration.configurationType,
        typeName: typeName,
      },
      refetchQueries: [
        {
          query: GET_TYPE_CONFIGURATIONS_BY_NAME,
          variables: { typeName: typeName },
        },
      ],
    });
    if (configuration.isNew) {
      navigateToList();
    }
  }, [
    saveMutation,
    configuration.id,
    configuration.commitHash,
    configuration.commitDate,
    configuration.commitBranch,
    configuration.configurationType,
    configuration.isNew,
    spec,
    typeName,
    navigateToList,
  ]);

  const onCancelClick = useCallback(() => navigateToList(), [navigateToList]);

  const [deleteMutation] = useMutation(DELETE_TYPE_CONFIGURATION);
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const onDeleteClick = useCallback(async () => {
    const isConfirmed = await openConfirmationDialog({
      action: 'delete',
      isActionDestructive: true,
      object: 'type configuration',
      additionalMessage:
        'Type configurations are created automatically for all types, so if no type configuration exists after deletion of this one, a new one will be created automatically with default settings',
    });
    if (!isConfirmed) {
      return;
    }
    await deleteMutation({
      variables: {
        id: configuration.id,
      },
      refetchQueries: [
        {
          query: GET_TYPE_CONFIGURATIONS_BY_NAME,
          variables: { typeName: typeName },
        },
      ],
    });
    navigateToList();
  }, [
    configuration.id,
    deleteMutation,
    navigateToList,
    openConfirmationDialog,
    typeName,
  ]);

  const openChooseCommitDialog = useChooseTypeConfigurationCommitDialog();
  const saveConfigToNewBranch = useCallback(
    async (result: ChooseCommitDialogResult) => {
      const { commit, configurationType } = result;

      const newConfiguration = createTypeConfiguration(
        typeName,
        commit,
        configurationType,
        spec,
      );
      await saveMutation({
        variables: {
          id: newConfiguration.id,
          spec: spec,
          commitHash: commit.commitHash,
          commitDate: commit.commitDate.toISOString(),
          commitBranch: commit.commitBranch,
          configurationType: configurationType,
          typeName: typeName,
        },
        refetchQueries: [
          {
            query: GET_TYPE_CONFIGURATIONS_BY_NAME,
            variables: { typeName: typeName },
          },
        ],
      });
      history.push(
        ROUTES.TYPE_CONFIGURATION.EDIT.getPath({
          typeName: typeName,
          id: newConfiguration.id,
        }),
      );
    },
    [history, saveMutation, spec, typeName],
  );
  const onCopyConfigToNewBranch = useCallback(async () => {
    await openChooseCommitDialog({
      typeName: typeName,
      initialCommit: getCommitFromConfiguration(configuration),
      onCommitSelected: result => saveConfigToNewBranch(result),
      isNewConfiguration: false, // This triggers validation of compound types in the dialog for creating new configs, which isn't needed here.
      dialogTitle: 'Copy configuration to new branch',
      isOpen: true,
    });
  }, [configuration, openChooseCommitDialog, saveConfigToNewBranch, typeName]);

  const hasUserMadeChanges = useMemo(
    () => !isEqual(spec, configuration.spec),
    [configuration.spec, spec],
  );

  const shouldDisableSave =
    !configuration.isNew &&
    (spec.editorTypeOptions.length === 0 ||
      !hasUserMadeChanges ||
      !!unaliasedTypeNameValidationError ||
      !hasUserSelectedDefaultEditor);

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <Button
          onClick={onSaveClick}
          disabled={shouldDisableSave}
          color="secondary"
          variant="contained"
        >
          {configuration.isNew ? 'Create' : 'Save'}
        </Button>
        <Button onClick={onCancelClick}>Cancel</Button>
        {!configuration.isNew && <Button onClick={onDeleteClick}>Delete</Button>}
      </div>
      <Card>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <RowHeader>Configuration type</RowHeader>
                <TableCell className={classes.fullWidthColumn}>
                  <ConfigurationTypeLabel
                    configurationType={configuration.configurationType}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <RowHeader>Valid since</RowHeader>
                <TableCell>{configuration.commitDate.format('lll')}</TableCell>
              </TableRow>

              <TableRow>
                <RowHeader>Applied in</RowHeader>
                <TableCell>
                  <BranchList commits={configurationCommits} />
                  <BranchOptionsMenu onCopyConfigToNewBranch={onCopyConfigToNewBranch} />
                </TableCell>
              </TableRow>

              <TableRow>
                <RowHeader>Type editor options</RowHeader>
                <TableCell>
                  <EditorTypeSelector
                    editorTypes={spec.editorTypeOptions}
                    onChange={onUpdateEditorTypes}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <RowHeader>Default editor</RowHeader>
                <TableCell>
                  <Dropdown<EditorType>
                    valueLabel={selectedEditorType ?? ''}
                    placeholder="Select an editor type"
                    isRequired
                    options={defaultEditorTypeOptions}
                    onChange={onDefaultEditorTypeChange}
                    hasError={!hasUserSelectedDefaultEditor}
                  />
                  {!hasUserSelectedDefaultEditor && (
                    <Typography className={classes.error} variant="caption">
                      Default editor must be selected
                    </Typography>
                  )}
                  {shouldShowUnaliasedTypeNameEditor(selectedEditorType) && (
                    <div className={classes.unaliasedTypeDropdown}>
                      <Typography gutterBottom className={classes.unaliasedTypeHeader}>
                        Unaliased compound type
                      </Typography>
                      <TextField
                        placeholder="Compound type (e.g. map[string]string)"
                        fullWidth
                        value={unaliasedTypeTextFieldValue}
                        onChange={onUnaliasedTypeNameChange}
                        error={!!unaliasedTypeNameValidationError}
                        helperText={unaliasedTypeNameValidationError}
                      />
                    </div>
                  )}
                </TableCell>
              </TableRow>
              {selectedEditorType && additionalEditorProps && (
                <TableRow>
                  <AdditionalPropsEditor
                    editorProps={additionalEditorProps}
                    updateSpec={onAdditionalEditorPropsChange}
                    typeConfigurations={allTypeConfigurations}
                    anthaType={spec.unaliasedType ?? typeName}
                  />
                </TableRow>
              )}
              {canSetPlaceholder && (
                <TableRow>
                  <RowHeader>Placeholder</RowHeader>
                  <TableCell>
                    <TextField
                      fullWidth
                      value={placeholder}
                      onChange={onPlaceholderChange}
                    />
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <RowHeader>Inputtable types</RowHeader>
                <TableCell>
                  <Autocomplete
                    disableClearable
                    multiple
                    getOptionLabel={(typeName: string) => typeName}
                    options={typeNames}
                    renderInput={renderAutoCompleteInput}
                    onChange={onInputtableTypesChange}
                    size="small"
                    value={spec.inputtableTypes}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
        <CardActions />
        {confirmationDialog}
      </Card>
    </div>
  );
}

function deepCloneSpec(spec: TypeConfigurationSpec): TypeConfigurationSpec {
  return {
    ...spec,
    editorTypeOptions: spec.editorTypeOptions.map(editorType => {
      return editorType;
    }),
    inputtableTypes: [...spec.inputtableTypes],
  };
}

const useStyles = makeStylesHook({
  error: { color: Colors.ERROR_MAIN },
  fullWidthColumn: { width: '100%' },
  root: { marginTop: '1rem' },
  toolbar: {
    display: 'flex',
    margin: '1rem 0',
    '& > *': {
      '&:not(:first-child)': { marginLeft: '8px' },
    },
  },
  unaliasedTypeDropdown: { padding: '2em 0 0' },
  unaliasedTypeHeader: {
    color: Colors.GREY_60,
    fontSize: '0.85rem',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
  },
});
