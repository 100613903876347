import React, { useCallback, useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';
import Link from '@mui/material/Link';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import MdEditor from 'react-markdown-editor-lite';

import { Markdown, renderMarkdownUnsafe, stringToMarkdown } from 'common/lib/markdown';

type NotesPanelProps = {
  notes: string | null;
  onUpdate: (notes: string) => Promise<void>;
  editable?: boolean;
};

export const NotesPanel = React.memo((props: NotesPanelProps) => {
  const { notes, onUpdate, editable } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [editedMarkdown, setEditedMarkdown] = useState<Markdown>('' as Markdown);
  const classes = useStyles();

  const markdown = stringToMarkdown(notes ?? '');
  const html = renderMarkdownUnsafe(markdown);

  useEffect(() => {
    setEditedMarkdown(markdown);
  }, [markdown]);

  const enableEditing = useCallback(event => {
    event.preventDefault();
    setIsEditing(true);
  }, []);

  const cancelEditing = useCallback(() => {
    setEditedMarkdown(markdown);
    setIsEditing(false);
  }, [markdown]);

  const submitEdit = useCallback(async () => {
    await onUpdate(editedMarkdown);
    setIsEditing(false);
  }, [editedMarkdown, onUpdate]);

  const renderHTML = useCallback(
    (text: string) => renderMarkdownUnsafe(text as Markdown),
    [],
  );
  const handleEditorChange = useCallback(
    (data: { text: string }) => setEditedMarkdown(data.text as Markdown),
    [setEditedMarkdown],
  );

  return (
    <Box>
      {isEditing ? (
        <>
          <MdEditor
            value={editedMarkdown}
            renderHTML={renderHTML}
            onChange={handleEditorChange}
            style={{ minHeight: '200px' }}
          />
          <Button className={classes.button} variant="contained" onClick={cancelEditing}>
            Discard Changes
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={submitEdit}
          >
            Save Changes
          </Button>
        </>
      ) : (
        <Box className={markdown ? classes.noteDisplay : undefined}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
          {editable && (
            <Typography variant="caption">
              <Link href="#" onClick={enableEditing}>
                <EditIcon className={classes.editIcon} />
                {markdown ? 'Edit Notes' : 'Add Notes'}
              </Link>
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  noteDisplay: {
    padding: theme.spacing(3, 5),
    backgroundColor: blue[50],
  },
  editIcon: {
    fontSize: '10pt',
    textAlign: 'center',
    marginRight: theme.spacing(2),
  },
}));
