import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { GraphQLErrorExtensions } from 'graphql/error';

import {
  Action,
  PublishHistoryRow,
} from 'admin-client/app/components/PublishHistoryUI/PublishHistoryRow';

type Props<SuccessEnvT, FailureEnvT, ExtrasT> = {
  history: Action<SuccessEnvT, FailureEnvT, ExtrasT>[];
  successEnvRenderer: (
    successEnvs: SuccessEnvT[],
    extras: ExtrasT,
  ) => React.ReactElement | null;
  failureEnvRenderer: (
    failureEnvs: FailureEnvT[],
    extras: ExtrasT,
  ) => React.ReactElement | null;
  errorRenderer: (
    message: string,
    ext: GraphQLErrorExtensions,
  ) => React.ReactElement | null;
};

export function PushHistoryTable<SuccessEnvT, FailureEnvT, ExtrasT>({
  history,
  errorRenderer,
  successEnvRenderer,
  failureEnvRenderer,
}: Props<SuccessEnvT, FailureEnvT, ExtrasT>) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Pushed at</TableCell>
          <TableCell>Pushed by</TableCell>
          <TableCell>Outcome</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {history.map((action, idx) => {
          return (
            <PublishHistoryRow<SuccessEnvT, FailureEnvT, ExtrasT>
              key={idx}
              action={action}
              errorRenderer={errorRenderer}
              successEnvRenderer={successEnvRenderer}
              failureEnvRenderer={failureEnvRenderer}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}
