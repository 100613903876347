import { Commits, RelengCommit } from 'admin-common/src/commit';
import { findCurrentConfiguration } from 'common/elementConfiguration/findCurrentConfiguration';
import { getCommitFromConfiguration } from 'common/elementConfiguration/getCommitFromConfiguration';
import {
  Commit,
  CommonConfigurationFields,
  ConfigurationType,
} from 'common/types/commonConfiguration';

/**
 * Returns list of commits in which given configuration applies. This is not
 * exhaustive list of all commits, but rather a list of relevant commits to
 * be displayed in the UI.
 *
 * For configuration of type GLOBAL returns one commit per releng branch.
 * For configuration of type SINGLE_BRANCH returns only the starting commit.
 */
export function getConfigurationCommits(
  configuration: CommonConfigurationFields,
  allConfigurations: readonly CommonConfigurationFields[],
  commits: Commits,
): Commit[] {
  switch (configuration.configurationType) {
    case ConfigurationType.GLOBAL:
      return [
        getCommitFromConfiguration(configuration),
        ...commits.relengCommits
          .filter(relengCommit =>
            isConfigurationAppliedInCommit(
              configuration,
              relengCommit.parentMasterCommit,
              allConfigurations,
            ),
          )
          .sort(compareRelengCommitsByDateDesc)
          .map(relengCommit => relengCommit.commit),
      ];

    case ConfigurationType.SINGLE_BRANCH:
      return [getCommitFromConfiguration(configuration)];
  }
}

/**
 * Returns whether given configuration is applied in given commit.
 *
 * This is implemented by looking up actual configuration for the commit
 * and comparing its commit hash to the given configuration's commit hash.
 * There can only be one configuration per commit hash.
 */
function isConfigurationAppliedInCommit(
  configuration: CommonConfigurationFields,
  commit: Commit,
  allConfigurations: readonly CommonConfigurationFields[],
): boolean {
  const currentConfiguration = findCurrentConfiguration(commit, allConfigurations);
  return currentConfiguration?.commitHash === configuration.commitHash;
}

/**
 * Helper method to sort releng branches by commitDate of their parent master commit.
 * This is the order of branch names displayed in the UI.
 */
function compareRelengCommitsByDateDesc(c1: RelengCommit, c2: RelengCommit): number {
  return -c1.parentMasterCommit.commitDate.diff(
    c2.parentMasterCommit.commitDate,
    'seconds',
  );
}
