import React, { useCallback } from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { UPDATE_CONNECTION } from 'admin-client/app/api/gql/queries';
import { getOrganisationEnvironmentQuery } from 'admin-client/app/gql';
import { SUPPORT_CONNECTION_NAME } from 'common/constants/connections';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { Stack } from 'common/ui/components/Stack';

export const SupportConnection = ({
  supportConnection,
  orgEnv,
}: {
  supportConnection: getOrganisationEnvironmentQuery['supportConnection'];
  orgEnv: getOrganisationEnvironmentQuery['organisationEnvironment'];
}) => {
  const snackbarManager = useSnackbarManager();
  const [updateSupportConnection, { loading: mutationLoading }] = useMutation(
    UPDATE_CONNECTION,
    {
      onError: (error: ApolloError) => {
        console.error(error);
        snackbarManager.showError(error.message);
      },
    },
  );

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (supportConnection && orgEnv.auth0ClientId) {
        const enabledClients = event.target.checked
          ? [...supportConnection.enabledClients, orgEnv.auth0ClientId]
          : supportConnection.enabledClients.filter(
              clientId => clientId !== orgEnv.auth0ClientId,
            );

        await updateSupportConnection({
          variables: {
            connectionId: supportConnection.id,
            enabledClients,
          },
        });
      }
    },
    [orgEnv.auth0ClientId, supportConnection, updateSupportConnection],
  );

  if (supportConnection === null || !orgEnv.auth0ClientId) {
    return null;
  } else if (mutationLoading) {
    return (
      <section>
        <CircularProgress />
      </section>
    );
  }

  const isEnabled = supportConnection.enabledClients.includes(orgEnv.auth0ClientId);

  return (
    <section>
      <Typography variant="h4" gutterBottom>
        Support connection
      </Typography>
      <Stack spacing={2}>
        <Switch checked={isEnabled} onChange={handleChange} name="supportEnabled" />
        {isEnabled && (
          <>
            <Link
              href={`${orgEnv.environment.baseURL}?authconn=${SUPPORT_CONNECTION_NAME}`}
              variant="h5"
            >
              Synthace Platform login with support
            </Link>
            <br />
            <Link
              href={`${orgEnv.environment.baseURL}?authconn=${SUPPORT_CONNECTION_NAME}#/anthahub-login`}
              variant="h5"
            >
              SynthaceHub login with support
            </Link>
          </>
        )}
      </Stack>
    </section>
  );
};
