import React from 'react';

import { useQuery } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { GraphQLErrorExtensions } from 'graphql/error';

import { GET_ACTIONS } from 'admin-client/app/api/gql/queries';
import { PushHistoryTable } from 'admin-client/app/components/PublishHistoryUI/PublishHistoryTable';
import { TablePaginationControls } from 'admin-client/app/components/PublishHistoryUI/TablePaginationControls';
import { getExampleActionsQuery, ResultType } from 'admin-client/app/gql';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';

export const PAGE_ROWS_DEFAULT = 5;

type Props<SuccessEnvT, FailureEnvT, ExtrasT> = {
  actionName: string;
  successEnvRenderer: (
    successEnvs: SuccessEnvT[],
    extras: ExtrasT,
  ) => React.ReactElement | null;
  failureEnvRenderer: (
    failureEnvs: FailureEnvT[],
    extras: ExtrasT,
  ) => React.ReactElement | null;
  errorRenderer: (
    message: string,
    ext: GraphQLErrorExtensions,
  ) => React.ReactElement | null;
  actionItemMapper: (args: {
    id: number | null;
    resultType: ResultType;
    responseBody: ResponseBody | null;
    userEmail: string;
    occurredAt: DateTime;
  }) => {
    id: number | null;
    userEmail: string;
    occurredAt: DateTime;
    failureEnvs: FailureEnvT[] | null;
    successEnvs: SuccessEnvT[];
    errors: GraphQLErrors;
    resultType: ResultType;
    extras: ExtrasT;
  };
  paginationState: {
    pageState: [number, React.Dispatch<React.SetStateAction<number>>];
    pagePerRowsState: [number, React.Dispatch<React.SetStateAction<number>>];
  };
};

export function PublishHistoryUI<SuccessEnvT, FailureEnvT, ExtrasT>({
  actionName,
  errorRenderer,
  successEnvRenderer,
  failureEnvRenderer,
  actionItemMapper,
  paginationState,
}: Props<SuccessEnvT, FailureEnvT, ExtrasT>) {
  const actionsQuery = useQuery(GET_ACTIONS, {
    variables: {
      names: [actionName],
      pagingInfo: { pageSize: PAGE_ROWS_DEFAULT, after: undefined },
    },
  });

  const DataComponent = ({ data }: { data: getExampleActionsQuery }) => {
    const history = data.actions.items.map(actionItemMapper);
    return (
      <>
        <PushHistoryTable<SuccessEnvT, FailureEnvT, ExtrasT>
          history={history}
          errorRenderer={errorRenderer}
          successEnvRenderer={successEnvRenderer}
          failureEnvRenderer={failureEnvRenderer}
        />
        <TablePaginationControls
          fetchMore={actionsQuery.fetchMore}
          totalItems={data.actions.totalCount}
          pageInfo={data.actions.pageInfo}
          defaultNumPerPage={PAGE_ROWS_DEFAULT}
          paginationState={paginationState}
        />
      </>
    );
  };

  return (
    <RenderQuery
      query={actionsQuery}
      renderData={DataComponent}
      renderNoData={() => (
        <PushHistoryTable
          history={[]}
          errorRenderer={() => null}
          successEnvRenderer={() => null}
          failureEnvRenderer={() => null}
        />
      )}
      additionalDataProps={{ actionName }}
    />
  );
}
