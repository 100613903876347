// The <const> syntax here is a const assertion. This has a couple of cool
// properties, most notably that types don't get widened (e.g. no going from
// "hello" to `string`). This means we can use them to derive a string literal
// type (OnlineMigrationStage, below).
//
// See https://stackoverflow.com/a/54061487 for the rationale behind using this
// particular structure. Read more about const assertions at
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#const-assertions
//
// The elements of this array are ordered in the sequence a migration will go

import { switchFail } from 'common/assert';

// through from initial launch to completion.
export const orderedOnlineMigrationStages = <const>[
  // Killswitch state
  'off',
  // Backfill migration is ready to run (backfill migrations only)
  'run',
  // Writing to both stores but the old store is the source of truth (full
  // migrations only)
  'double-write-old-primary',
  // Writing to both stores but the new store is the source of truth, implying
  // backfill has happened and consistency was found to be acceptable (full
  // migrations only)
  'double-write-new-primary',
  // Full migrations only using the new store, backfill migrations when finished
  'finished',
];

// OnlineMigrationStage is a string literal type containing all the values from
// orderedOnlineMigrationStages
export type OnlineMigrationStage = typeof orderedOnlineMigrationStages[number];

/**
 * Determines from the migration stage which store is the source of truth.
 */
export function primaryStore(stage: OnlineMigrationStage): 'old' | 'new' {
  switch (stage) {
    case 'off':
    case 'double-write-old-primary':
    case 'run':
      return 'old';
    case 'double-write-new-primary':
    case 'finished':
      return 'new';
    default:
      return switchFail(stage);
  }
}

/**
 * Output of JSON endpoint which the UI calls to get the state of an
 * OnlineMigration.
 */
export type OnlineMigrationStateForUI = {
  sourceOfTruth: 'old' | 'new';
};
