import React, { useCallback } from 'react';

import TextField from '@mui/material/TextField';

import { SingleInputSelector } from 'admin-client/app/components/ElementConfiguration/Card/rules/common/InputSelector';
import { APIElement } from 'common/types/api';
import { AddParameterMessageAction } from 'common/types/elementConfiguration';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

export default function AddParameterMessageActionEditor(props: {
  action: AddParameterMessageAction;
  element: APIElement;
  onChange: (action: AddParameterMessageAction) => void;
}) {
  const { action, element, onChange } = props;
  const classes = useStyles();

  const onParameterChange = useCallback(
    (parameterName: string) => onChange({ ...action, parameterName }),
    [action, onChange],
  );

  const onMessageChange = useTextFieldChange((message: string) => {
    const messageUpdate =
      action.type === 'add-parameter-error' ? { errorMessage: message } : { message };
    return onChange({ ...action, ...messageUpdate });
  });

  const message =
    action.type === 'add-parameter-error' ? action.errorMessage : action.message;

  return (
    <div className={classes.container}>
      <div className={classes.parameter}>
        <SingleInputSelector
          element={element}
          selectedParameterName={action.parameterName}
          onChange={onParameterChange}
        />
      </div>
      <div className={classes.error}>
        <TextField fullWidth multiline onChange={onMessageChange} value={message} />
      </div>
    </div>
  );
}

const useStyles = makeStylesHook({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  parameter: {
    flex: 1,
  },
  error: {
    flex: 2,
    marginLeft: '8px',
  },
});
