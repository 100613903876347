import { logPageView } from 'common/ui/GoogleAnalyticsUtils';

const ADMIN_CLIENT = 'admin-client';

window.addEventListener('hashchange', () => {
  // Examples:
  //   #/jobs
  //   #/devices-library
  //   0 1
  const parts = window.location.hash.split('/');
  const appName = ADMIN_CLIENT;
  const screenName = parts[1] || 'landing_page';

  logPageView(appName, screenName);
});
