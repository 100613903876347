import React from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export function MultipleSelectChip({
  avaliableTargetEnvironments = [],
  setSelectedTargetEnvironments,
  selectedTargetEnvironments,
}: {
  avaliableTargetEnvironments: string[] | undefined;
  setSelectedTargetEnvironments: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;
  selectedTargetEnvironments: string[] | undefined;
}) {
  const handleChange = (event: SelectChangeEvent<typeof avaliableTargetEnvironments>) => {
    const {
      target: { value },
    } = event;
    setSelectedTargetEnvironments(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <FormControl sx={{ margin: 1, minWidth: 250, maxWidth: 550 }} variant="outlined">
        <Select
          multiple
          displayEmpty
          value={selectedTargetEnvironments ?? []}
          onChange={handleChange}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.length > 0
                ? selected.map(value => <Chip key={value} label={value} />)
                : open
                ? ''
                : 'All Environments'}
            </Box>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
                width: 550,
              },
            },
          }}
        >
          {avaliableTargetEnvironments.map(name => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
