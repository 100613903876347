import React, { useCallback, useState } from 'react';

import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';

import { isRelengBranch } from 'admin-common/src/parseCommits';
import { Commit } from 'common/types/commonConfiguration';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const DISPLAY_COUNT_WHEN_COLLAPSED = 3;

/**
 * Renders styled list of branch names.
 */
export function BranchList({ commits }: { commits: Commit[] }) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const onExpand = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsExpanded(true);
  }, []);

  const displayedCommits =
    isExpanded || commits.length <= DISPLAY_COUNT_WHEN_COLLAPSED
      ? commits
      : commits.slice(0, DISPLAY_COUNT_WHEN_COLLAPSED);

  const collapsedCount = commits.length - displayedCommits.length;

  return (
    <>
      {displayedCommits.map((commit, index) => (
        <Tooltip
          key={index}
          title={
            <div className={classes.tooltipRoot}>
              Configuration is valid on branch{' '}
              <span className={classes.branchName}>{commit.commitBranch}</span> from this
              commit:
              <ul className={classes.list}>
                <li>Commit: {commit.commitHash}</li>
                <li>Date: {commit.commitDate.format('llll')}</li>
                <li>Simulated from branch: {commit.commitBranch}</li>
              </ul>
            </div>
          }
        >
          <Chip label={getLabel(commit)} color="primary" className={classes.chip} />
        </Tooltip>
      ))}
      {collapsedCount > 0 && (
        <Link className={classes.expandLink} onClick={onExpand}>
          +{collapsedCount} more
        </Link>
      )}
    </>
  );
}

function getLabel(commit: Commit): string {
  if (isRelengBranch(commit)) {
    return commit.commitBranch;
  } else {
    return `${commit.commitBranch}/${commit.commitHash.substring(0, 8)}`;
  }
}

const useStyles = makeStylesHook({
  chip: {
    margin: '2px 4px 2px 0',
  },
  tooltipRoot: {
    margin: '8px',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    '& li': {
      whiteSpace: 'nowrap',
    },
  },
  branchName: {
    color: Colors.LIGHT_BLUE,
  },
  expandLink: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    padding: '0 8px',
  },
});
