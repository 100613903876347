import React from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import IconDelete from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import {
  ADD_REDIRECT,
  DELETE_REDIRECT,
  GET_REDIRECTS,
} from 'admin-client/app/api/gql/queries';
import { Redirect } from 'admin-client/app/api/Redirectinator';
import { NewRedirectDialog } from 'admin-client/app/components/Redirectinator/NewRedirectDialog';
import { zebraRowsNoPaddingStyles } from 'common/ui/commonStyles';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { useDialogManager } from 'common/ui/components/DialogManager';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import useDialog from 'common/ui/hooks/useDialog';

type RedirectDeleter = (redirect: Redirect) => Promise<void>;

export default function RedirectsTableContainer() {
  const classes = useStyles();
  const dialogManager = useDialogManager();
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  const snackbarManager = useSnackbarManager();

  const commonMutationOptions = {
    onError: (error: ApolloError) => {
      console.error(error);
      snackbarManager.showError(error.message);
    },
  };
  const [addRedirectMutation] = useMutation(ADD_REDIRECT, commonMutationOptions);
  const [deleteRedirectMutation] = useMutation(DELETE_REDIRECT, commonMutationOptions);

  const { loading, error, data } = useQuery(GET_REDIRECTS);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const redirects: Redirect[] = data?.redirects ?? [];

  const handleAddRedirectButtonClick = async () => {
    const newRedirect = await dialogManager.openDialogPromise(
      'ADD_REDIRECT',
      NewRedirectDialog,
      {
        existingRedirects: redirects,
      },
    );
    if (newRedirect !== null) {
      await addRedirectMutation({
        variables: newRedirect,
        refetchQueries: [
          {
            query: GET_REDIRECTS,
          },
        ],
      });
    }
  };

  const deleteRedirectHandler = async (redirect: Redirect) => {
    const shouldDelete = await openConfirmationDialog({
      action: 'delete',
      isActionDestructive: true,
      object: 'redirect',
      specificObject: `${redirect.shortcut}`,
    });
    if (shouldDelete) {
      await deleteRedirectMutation({
        variables: {
          id: redirect.id,
        },
        refetchQueries: [
          {
            query: GET_REDIRECTS,
          },
        ],
      });
    }
  };

  return (
    <>
      <section className={classes.header}>
        <Typography variant="h2" gutterBottom>
          Redirectinator
        </Typography>
        <Typography variant="body1">
          Save yourself and your coworkers time by creating URL redirects that avoid
          repetitive clicks and searches.
        </Typography>
        <Button
          className={classes.addButton}
          variant="contained"
          color="secondary"
          onClick={handleAddRedirectButtonClick}
          startIcon={<AddIcon />}
        >
          Add Redirect
        </Button>
      </section>
      <RedirectsTable
        classes={classes}
        redirects={redirects}
        deleteRedirectHandler={deleteRedirectHandler}
      />
      <Paper className={classes.about}>
        <section className={classes.aboutSection}>
          <Typography variant="h4">How does it work?</Typography>

          <Typography>
            The redirector works as a custom search engine for your browser. It matches
            what you type with a list of possible redirects and when it finds a match, it
            forwards you along.
          </Typography>
        </section>
        <section className={classes.aboutSection}>
          <Typography variant="h4">
            Why is it better than just using the URL bar history search?
          </Typography>

          <Typography>
            Redirects via this tool benefit everyone in the company. If you add a useful
            redirect, you&apos;ll save everyone time. Also, redirects setup here can be
            maintained by a single person, whereas relying on your browser history means
            that everyone has to keep track of anything that changes and remember what the
            &quot;new&quot; thing is versus the &quot;old&quot; thing. Lastly, this page
            serves as an index of important internal tools and URLs. When you can&apos;t
            remember what the thing is for this or that, you can find it here.
          </Typography>

          <Typography>
            What&apos;s more, this tool is equal parts URL shortener, bookmarking, and
            search mechanism. You can add redirects that just make something important
            easier to find. A good example is the redirect to Wifi Guest Password UI.
            It&apos;s not a search, it&apos;s just a way of remembering how to get to that
            tool. &quot;wifiguest&quot; is a lot easier to remember that
            &quot;wifi.synthace.us&quot;.
          </Typography>
        </section>
        <section className={classes.aboutSection}>
          <Typography variant="h4">How do I set it up?</Typography>
          <Typography variant="h5">Chrome</Typography>
          <ol>
            <li>
              <Typography>
                Navigate to{' '}
                <a href="chrome://settings/searchEngines">
                  chrome://settings/searchEngines
                </a>
              </Typography>
            </li>
            <li>
              <Typography>
                Add a search engine to your browser bar pointing to{' '}
                <strong>https://admin.antha.com/redirect?r=%s</strong>
              </Typography>
            </li>
            <li>
              <Typography>
                Type <strong>sr</strong> in the URL bar to use the redirector
              </Typography>
            </li>
          </ol>
          <Typography variant="h5">Firefox</Typography>
          <ol>
            <li>
              <Typography>
                Right-click on this textbox and choose{' '}
                <strong>Add a Keyword for this Search...</strong>{' '}
                <form method="GET" action="https://admin.antha.com/redirect">
                  <input name="r" placeholder="Right-click me!" />
                </form>
              </Typography>
            </li>
            <li>
              <Typography>
                Enter <strong>sr</strong> in the <strong>Keyword</strong> field (and
                change the name to <strong>Redirectinator</strong> if you like), then
                click <strong>Save</strong>.
              </Typography>
            </li>
            <li>
              <Typography>
                Type <strong>sr</strong> in the URL bar to use the redirector
              </Typography>
            </li>
          </ol>
        </section>
      </Paper>
      {confirmationDialog}
    </>
  );
}

type Props = {
  redirects: Redirect[];
  deleteRedirectHandler: RedirectDeleter;
} & WithStyles<any>;

const RedirectsTable = React.memo(function RedirectsTable(props: Props) {
  const { classes, redirects, deleteRedirectHandler } = props;

  return (
    <section style={{ display: 'block' }}>
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Shortcut</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>URL Pattern</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {redirects.map(redirect => {
              return (
                <RedirectRow
                  key={redirect.id}
                  redirect={redirect}
                  deleteRedirectHandler={deleteRedirectHandler}
                  classes={classes}
                />
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </section>
  );
});

type RedirectRowProps = {
  redirect: Redirect;
  deleteRedirectHandler: RedirectDeleter;
} & WithStyles<any>;

const RedirectRow = React.memo((props: RedirectRowProps) => {
  const { classes, redirect, deleteRedirectHandler } = props;

  const handleDelete = () => deleteRedirectHandler(redirect);

  return (
    <TableRow key={redirect.id} className={classes.fullRow}>
      <TableCell component="th" scope="row">
        {redirect.shortcut}
      </TableCell>
      <TableCell>{redirect.description}</TableCell>
      <TableCell>{redirect.urlPattern}</TableCell>
      <TableCell>
        <IconButton title="Delete" size="small" onClick={handleDelete}>
          <IconDelete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});

const useStyles = makeStyles({
  header: {
    marginBottom: '1rem',
  },
  addButton: {
    margin: '1rem 0',
  },
  about: {
    marginTop: '2rem',
    padding: '1rem',
    '& section + section': {
      marginTop: '2rem',
    },
  },
  aboutSection: {
    '& p': {
      marginTop: '1rem',
    },
  },
  ...zebraRowsNoPaddingStyles,
});
