import { getParameterGroups } from 'common/elementConfiguration/createConfigurationSpec';
import { APIElement } from 'common/types/api';
import {
  ElementConfigurationSpec,
  ParameterGroupConfigurationSpec,
} from 'common/types/elementConfiguration';

export function setInputGroupName(
  spec: ElementConfigurationSpec,
  groupIndex: number,
  groupName: string,
): ElementConfigurationSpec {
  return setInputGroupProperty(spec, groupIndex, 'groupName', groupName);
}

export function setInputGroupDescription(
  spec: ElementConfigurationSpec,
  groupIndex: number,
  groupDescription: string,
): ElementConfigurationSpec {
  return setInputGroupProperty(spec, groupIndex, 'groupDescription', groupDescription);
}

function setInputGroupProperty(
  spec: ElementConfigurationSpec,
  groupIndex: number,
  propKey: string,
  propValue: any,
): ElementConfigurationSpec {
  const inputGroups = deepCopyGroupSpecs(spec.inputGroups);
  inputGroups[groupIndex] = { ...inputGroups[groupIndex], [propKey]: propValue };

  return { ...spec, inputGroups };
}

export function addInputGroup(spec: ElementConfigurationSpec): ElementConfigurationSpec {
  const groupName = generateUniqueGroupName(spec);
  const inputGroups = [...spec.inputGroups, { groupName, parameterNames: [] }];

  return { ...spec, inputGroups };
}

export function deleteInputGroup(
  spec: ElementConfigurationSpec,
  groupIndex: number,
): ElementConfigurationSpec {
  const inputGroups = deepCopyGroupSpecs(spec.inputGroups);
  inputGroups.splice(groupIndex, 1);

  return { ...spec, inputGroups };
}

export function moveInputGroup(
  spec: ElementConfigurationSpec,
  indexFrom: number,
  indexTo: number,
): ElementConfigurationSpec {
  const inputGroups = deepCopyGroupSpecs(spec.inputGroups);
  const [groupSpec] = inputGroups.splice(indexFrom, 1);
  inputGroups.splice(indexTo, 0, groupSpec);

  return { ...spec, inputGroups };
}

export function resetInputGroups(
  spec: ElementConfigurationSpec,
  element: APIElement,
): ElementConfigurationSpec {
  return { ...spec, inputGroups: getParameterGroups(element.in_ports) };
}

/**
 * Changes position and group of the param. Executed when dragging element, whether within the
 * same group, or between groups.
 */
export function moveInputParam(
  spec: ElementConfigurationSpec,
  groupIndexFrom: number,
  parameterIndexFrom: number,
  groupIndexTo: number,
  parameterIndexTo: number,
): ElementConfigurationSpec {
  if (groupIndexFrom < 0 || groupIndexTo < 0) {
    return spec;
  }

  const inputGroups = deepCopyGroupSpecs(spec.inputGroups);

  // remove from old group and location
  const [parameterName] = inputGroups[groupIndexFrom].parameterNames.splice(
    parameterIndexFrom,
    1,
  );

  inputGroups[groupIndexTo].parameterNames.splice(parameterIndexTo, 0, parameterName);

  return { ...spec, inputGroups };
}
export function moveOutputParam(
  spec: ElementConfigurationSpec,
  paramIndexFrom: number,
  paramIndexTo: number,
): ElementConfigurationSpec {
  // create a copy
  const outputOrder = [...spec.outputOrder];

  // remove from old group and location
  const [parameterName] = outputOrder.splice(paramIndexFrom, 1);

  // insert into new group and location
  outputOrder.splice(paramIndexTo, 0, parameterName);

  return { ...spec, outputOrder };
}

/**
 * Creates a deep copy of ParameterGroupConfigurationSpec[] that can then be mutated.
 */
function deepCopyGroupSpecs(
  specs: ParameterGroupConfigurationSpec[],
): ParameterGroupConfigurationSpec[] {
  return specs.map(spec => ({
    ...spec,
    parameterNames: [...spec.parameterNames],
  }));
}

/**
 * Generates unique group name that is not yet present.
 */
function generateUniqueGroupName(spec: ElementConfigurationSpec): string {
  let groupName: string = '';
  let count = spec.inputGroups.length + 1;
  const existingNames = new Set(spec.inputGroups.map(group => group.groupName));
  do {
    groupName = `New group ${count}`;
    count++;
  } while (existingNames.has(groupName));
  return groupName;
}
