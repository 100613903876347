import React from 'react';

import { GraphQLErrors } from '@apollo/client/errors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { GraphQLErrorExtensions } from 'graphql/error';
import isEmpty from 'lodash/isEmpty';

import { getExampleActionsQuery, ResultType } from 'admin-client/app/gql';
import { zebraRowsStyles } from 'common/ui/commonStyles';

export type Action<SuccessEnvT, FailureEnvT, ExtrasT> = Pick<
  getExampleActionsQuery['actions']['items'][number],
  'id' | 'occurredAt' | 'resultType' | 'userEmail'
> & {
  errors: GraphQLErrors;
  successEnvs: SuccessEnvT[];
  failureEnvs: FailureEnvT[] | null;
  extras: ExtrasT;
};

export function PublishHistoryRow<SuccessEnvT, FailureEnvT, ExtrasT>({
  action: { errors, resultType, successEnvs, failureEnvs, occurredAt, userEmail, extras },
  errorRenderer,
  successEnvRenderer,
  failureEnvRenderer,
}: {
  action: Action<SuccessEnvT, FailureEnvT, ExtrasT>;
  successEnvRenderer: (
    successEnvs: SuccessEnvT[],
    extras: ExtrasT,
  ) => React.ReactElement | null;
  failureEnvRenderer: (
    failureEnvs: FailureEnvT[],
    extras: ExtrasT,
  ) => React.ReactElement | null;
  errorRenderer: (
    message: string,
    ext: GraphQLErrorExtensions,
  ) => React.ReactElement | null;
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [derivedType, derivedFailureEnvs = []] =
    resultType === 'SUCCESS'
      ? successEnvs.length === 0
        ? ['NOT_SUCCESS', failureEnvs ?? []]
        : failureEnvs && failureEnvs.length > 0
        ? [ResultType.MIXED, failureEnvs]
        : [ResultType.SUCCESS, undefined]
      : resultType === 'FAILURE'
      ? [ResultType.FAILURE, undefined]
      : [ResultType.UNKNOWN, undefined];

  console.log('DERIVED_TYPE', derivedType, occurredAt);

  return (
    <TableRow className={classes.fullRow} style={{ height: '50px' }}>
      <TableCell
        style={{
          width: '200px',
          maxWidth: '200px',
        }}
      >
        <span>{new Date(occurredAt).toLocaleString()}</span>
      </TableCell>
      <TableCell
        style={{
          width: '300px',
          maxWidth: '300px',
        }}
      >
        <span>{userEmail}</span>
      </TableCell>
      <TableCell
        style={{
          // maxWidth: '150px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <>
          <IconButton
            sx={{ borderRadius: '4px' }}
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <Typography variant="subtitle1">
              {derivedType === 'SUCCESS' ? (
                <span role="img" aria-label="success">
                  ☀️ Success
                </span>
              ) : derivedType === 'NOT_SUCCESS' ? (
                <span role="img" aria-label="failure">
                  🌥️ Failure
                </span>
              ) : derivedType === 'MIXED' ? (
                <span role="img" aria-label="partial-success">
                  🌤️ Partial success
                </span>
              ) : (
                <Tooltip title={errors.map(error => error.message).join(', ')}>
                  <span role="img" aria-label="unexpected-error">
                    ⛈️ Unexpected error
                  </span>
                </Tooltip>
              )}
            </Typography>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <p>
                {derivedType === 'SUCCESS' ? (
                  <>
                    {`Published successfully to ${successEnvs.length} environment${
                      successEnvs.length > 1 ? 's' : ''
                    }`}
                    {successEnvRenderer(successEnvs, extras)}
                  </>
                ) : derivedType === 'NOT_SUCCESS' ? (
                  <>
                    {`Publishing failed for ${derivedFailureEnvs.length} environment${
                      derivedFailureEnvs.length > 1 ? 's' : ''
                    }:`}
                    {failureEnvRenderer(derivedFailureEnvs, extras)}
                  </>
                ) : derivedType === 'MIXED' ? (
                  <>
                    <div>
                      {`Published successfully to ${successEnvs.length} environment${
                        successEnvs.length > 1 ? 's' : ''
                      }: `}
                      {successEnvRenderer(successEnvs, extras)}
                    </div>
                    <div>
                      {`Publishing failed for ${derivedFailureEnvs.length} environment${
                        derivedFailureEnvs.length > 1 ? 's' : ''
                      }:`}
                      {failureEnvRenderer(derivedFailureEnvs, extras)}
                    </div>
                  </>
                ) : derivedType === 'FAILURE' ? (
                  errors.map(e => {
                    const extensions = e.extensions;
                    const elements = errorRenderer(e.message, extensions);
                    if (elements !== null) {
                      return elements;
                    }

                    if (e.extensions.detail && !isEmpty(e.extensions.detail)) {
                      return `${e.message} (${e.extensions.detail})`;
                    }

                    return e.message;
                  })
                ) : (
                  errors
                    .map(e => {
                      if (e.extensions.detail) {
                        return `${e.message} (${e.extensions.detail})`;
                      }

                      return e.message;
                    })
                    .join(',')
                )}
              </p>
            </div>
          </Collapse>
        </>
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStyles({
  ...zebraRowsStyles,
});
