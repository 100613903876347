import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';

import { InfoBox } from 'admin-client/app/components/InfoBox';
import { getOrganisationEnvironmentQuery } from 'admin-client/app/gql';

type AddAllowedElementPathPanelProps = {
  env: getOrganisationEnvironmentQuery['organisationEnvironment']['environment'];
  addAllowedElementPathCallback: (allowedElementPath: string) => Promise<void>;
};

export function AddAllowedElementPathPanel(props: AddAllowedElementPathPanelProps) {
  const [validElementPaths, setValidElementPaths] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [elementPathPrefixes, setElementPathPrefixes] = useState<string[]>([]);
  const [pathPrefixSelection, setPathPrefixSelection] = useState<string | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { env, addAllowedElementPathCallback } = props;
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await axios.get(
          `/api/element-package-names?envHostname=${env.hostname}`,
          {
            headers: {
              authorization: `bearer ${accessToken}`,
            },
          },
        );
        if (response.status !== 200) {
          setError(`Error: ${response.statusText}`);
        } else {
          setValidElementPaths(response.data);
        }
      } catch (error) {
        setError(`${error}`);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [env.hostname, getAccessTokenSilently]);

  const handleElementSelection = useCallback(
    (_: ChangeEvent<{}>, newValue: string | null) => {
      if (newValue) {
        const elementPath = newValue;
        const pathPrefixes = [];
        const elementPathComponents = elementPath.split('/');
        for (let i = 1; i <= elementPathComponents.length; i++) {
          const prefix = elementPathComponents.slice(0, i).join('/') + '/';
          pathPrefixes.push(prefix);
        }
        setElementPathPrefixes(pathPrefixes);
      }
    },
    [],
  );

  const handlePathPrefixSelection = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      setPathPrefixSelection(event.target.value as string);
    },
    [setPathPrefixSelection],
  );

  const handleAddElementPath = useCallback(async () => {
    if (pathPrefixSelection) {
      await addAllowedElementPathCallback(pathPrefixSelection);
    }
  }, [addAllowedElementPathCallback, pathPrefixSelection]);

  if (validElementPaths.length === 0 && !error) {
    // Data is still loading; don't show anything yet.
    return null;
  }

  if (error) {
    return (
      <>
        <Box marginBottom={1}>
          <Typography>
            You can&apos;t add new element paths in the admin tool for this environment.
            Perhaps{' '}
            <a href="https://github.com/Synthace/antha-platform/commit/e3e3e9827f5b8b6f964929a05e59746404322e7e">
              this commit
            </a>{' '}
            hasn&apos;t been deployed there yet.
          </Typography>
        </Box>
        <Box marginBottom={1}>
          <Typography variant="subtitle2">(Error from server was: {error})</Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <InfoBox>
        Note: please take care to select the correct element, since{' '}
        <strong>removing access to an element path is not currently supported</strong>.{' '}
        <a href="https://github.com/Synthace/antha-platform/blob/d734920f7b25e92ac284e8cef514d45352296700/antha-com/appserver/src/services/element/InternalElementService.ts#L158">
          This code comment
        </a>{' '}
        has more details.
      </InfoBox>
      <section className={classes.section}>
        <Box marginBottom={1}>
          <Typography variant="overline" gutterBottom>
            First select an element:
          </Typography>
        </Box>
        <Box marginBottom={1}>
          <Autocomplete
            options={validElementPaths}
            renderInput={params => (
              <TextField {...params} label="Elements" variant="outlined" />
            )}
            onChange={handleElementSelection}
          />
        </Box>
      </section>
      {elementPathPrefixes.length > 0 && (
        <section className={classes.section}>
          <Box marginBottom={1}>
            <Typography variant="overline" gutterBottom>
              Now choose the path to allow access to:
            </Typography>
          </Box>
          <Box marginBottom={1}>
            <FormControl component="fieldset">
              <RadioGroup name="prefix" onChange={handlePathPrefixSelection}>
                {elementPathPrefixes.map((p, i) => {
                  return (
                    <FormControlLabel
                      key={p}
                      value={p}
                      control={<Radio />}
                      label={
                        i < elementPathPrefixes.length - 1
                          ? `Everything under ${p}`
                          : `Just ${p}`
                      }
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box marginBottom={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={!pathPrefixSelection}
              onClick={handleAddElementPath}
            >
              Allow Access
            </Button>
          </Box>
        </section>
      )}
    </>
  );
}

const useStyles = makeStyles({
  select: {
    minWidth: '600px',
  },
  section: {
    paddingBottom: '1rem',
  },
});
