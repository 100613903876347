import { ArrayElement, getAuth0ConnectionsQuery } from 'admin-client/app/gql';

export type GraphQLConnection = ArrayElement<
  getAuth0ConnectionsQuery['auth0Connections']
>;

export function onlyAdminServerEnabled(
  enabledConnections: readonly string[],
  adminServerClientId: string,
): boolean {
  return enabledConnections.length === 1 && enabledConnections[0] === adminServerClientId;
}
