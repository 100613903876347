import React, { useCallback } from 'react';

import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import cx from 'classnames';

import {
  ConditionEditor,
  ConditionEditorProps,
} from 'admin-client/app/components/ElementConfiguration/Card/rules/conditions/ConditionEditor';
import { BinaryOperator, ListCondition } from 'common/rules/types';
import {
  ElementConfigurationCondition as Condition,
  ElementConfigurationCondition,
} from 'common/types/elementConfiguration';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export function ListConditionEditor(
  props: {
    condition: ListCondition<ElementConfigurationCondition>;
  } & ConditionEditorProps,
) {
  const { condition, index, isRoot, defaultParameterName, element, onConditionChange } =
    props;
  const onAddCondition = useCallback(
    () =>
      onConditionChange(
        {
          ...condition,
          childConditions: [...condition.childConditions, { type: 'const-true' }],
        },
        index,
      ),
    [condition, index, onConditionChange],
  );

  const onChildConditionChange = useCallback(
    (childCondition: Condition, childConditionIndex: number) => {
      const childConditions = [...condition.childConditions];
      childConditions[childConditionIndex] = childCondition;
      onConditionChange({ ...condition, childConditions }, index);
    },
    [condition, index, onConditionChange],
  );
  const onChildConditionRemove = useCallback(
    (childConditionIndex: number) => {
      const childConditions = [...condition.childConditions];
      childConditions.splice(childConditionIndex, 1);
      onConditionChange({ ...condition, childConditions }, index);
    },
    [condition, index, onConditionChange],
  );

  const classes = useStyles();

  const onOperatorChange = useCallback(
    (_event: any, operator: BinaryOperator | null) =>
      operator && onConditionChange({ ...condition, operator }, index),
    [condition, index, onConditionChange],
  );

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          If
          <Autocomplete
            className={classes.binaryOperatorSelector}
            options={SELECTOR_OPTIONS}
            renderInput={renderInput}
            value={condition.operator}
            getOptionLabel={getOptionLabel}
            disableClearable
            size="small"
            onChange={onOperatorChange}
          />
          of the following conditions are met:
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={onAddCondition}>
            + New condition
          </Button>
        </Grid>
      </Grid>

      <Grid container className={cx(classes.listRoot)}>
        {condition.childConditions.map((childCondition, index) => (
          <React.Fragment key={index}>
            <Grid item xs>
              <ConditionEditor
                condition={childCondition}
                defaultParameterName={defaultParameterName}
                index={index}
                isRoot={isRoot}
                element={element}
                onConditionChange={onChildConditionChange}
                onConditionRemove={onChildConditionRemove}
              />
            </Grid>
            <Grid item xs={12} />
          </React.Fragment>
        ))}
      </Grid>
    </>
  );
}

const SELECTOR_OPTIONS: BinaryOperator[] = ['or', 'and'];

function renderInput(params: AutocompleteRenderInputParams) {
  return <TextField {...params} variant="outlined" />;
}

function getOptionLabel(operator: BinaryOperator) {
  return operator === 'or' ? 'any' : 'all';
}

const useStyles = makeStylesHook({
  listRoot: {
    marginTop: '0.5rem',
    paddingLeft: '1rem',
    borderLeft: '1px solid #eee',
  },
  binaryOperatorSelector: {
    display: 'inline-flex',
    margin: '0 8px',
  },
});
