import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { RouteComponentProps } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { EditorTypeConfiguration } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/editorType';
import { EditTypeConfiguration } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/EditTypeConfiguration';
import { useCommit } from 'admin-client/app/components/ElementConfiguration/useCommit';
import { createParameterEditorConfigurationSpec } from 'common/elementConfiguration/createConfigurationSpec';
import { BACKUP_EDITOR_OPTIONS } from 'common/elementConfiguration/EditorType';
import { Commit, ConfigurationType } from 'common/types/commonConfiguration';
import { TypeConfigurationSpec } from 'common/types/typeConfiguration';
import { TypeName } from 'common/types/typeConfiguration';

type Props = RouteComponentProps<{
  typeName: TypeName;
  commitBranch: string;
  commitHash: string;
  configurationType: ConfigurationType;
}>;

export function CreateTypeConfigurationScreen({ match }: Props) {
  const { typeName, commitBranch, commitHash, configurationType } = match.params;
  const [configuration, setConfiguration] = useState<EditorTypeConfiguration | null>(
    null,
  );

  const { commit } = useCommit(commitBranch, commitHash);
  useEffect(() => {
    if (configuration || !commit) {
      return;
    }
    setConfiguration(createTypeConfiguration(typeName, commit, configurationType));
  }, [commit, configuration, configurationType, typeName]);

  return configuration ? (
    <EditTypeConfiguration
      key={configuration.id}
      configuration={configuration}
      typeName={typeName}
    />
  ) : (
    <Box paddingTop={2}>
      <CircularProgress size={24} color="primary" />
    </Box>
  );
}

export function createTypeConfiguration(
  typeName: TypeName,
  commit: Commit,
  configurationType: ConfigurationType,
  initialSpec?: TypeConfigurationSpec,
): EditorTypeConfiguration {
  return {
    isNew: true,
    id: uuid(),
    spec: initialSpec || createTypeConfigurationSpec(typeName),
    commitBranch: commit.commitBranch,
    commitDate: commit.commitDate,
    commitHash: commit.commitHash,
    configurationType,
  };
}

function createTypeConfigurationSpec(typeName: TypeName): TypeConfigurationSpec {
  const spec = createParameterEditorConfigurationSpec(typeName);
  const editorTypes = spec.additionalProps?.editor
    ? [spec.additionalProps?.editor]
    : BACKUP_EDITOR_OPTIONS;
  return {
    editorTypeOptions: editorTypes,
    defaultEditorConfiguration: spec,
    inputtableTypes: [],
  };
}
