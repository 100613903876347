import React, { useCallback } from 'react';

import Grid from '@mui/material/Grid';
import cx from 'classnames';

import {
  ConditionEditor,
  ConditionEditorProps,
} from 'admin-client/app/components/ElementConfiguration/Card/rules/conditions/ConditionEditor';
import { NotCondition } from 'common/rules/types';
import { ElementConfigurationCondition as Condition } from 'common/types/elementConfiguration';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export function NotConditionEditor(
  props: {
    condition: NotCondition<Condition>;
  } & ConditionEditorProps,
) {
  const { condition, defaultParameterName, index, isRoot, element, onConditionChange } =
    props;

  const onChildConditionChange = useCallback(
    (childCondition: Condition) => {
      const newCondition: NotCondition<Condition> = {
        ...condition,
        childCondition,
      };
      onConditionChange(newCondition, index);
    },
    [condition, index, onConditionChange],
  );

  const classes = useStyles();

  return (
    <Grid container className={cx(classes.listRoot)}>
      <Grid item xs>
        <ConditionEditor
          condition={condition.childCondition}
          defaultParameterName={defaultParameterName}
          index={index}
          isRoot={isRoot}
          element={element}
          onConditionChange={onChildConditionChange}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStylesHook({
  listRoot: {
    marginTop: '0.5rem',
    paddingLeft: '1rem',
  },
});
