import React, { useCallback } from 'react';

import { ObservableQuery } from '@apollo/client';
import TablePagination from '@mui/material/TablePagination';

import { PageInfo } from 'admin-client/app/gql';

export function TablePaginationControls<TQuery>({
  totalItems,
  pageInfo,
  defaultNumPerPage,
  fetchMore,
  paginationState: {
    pageState: [page, setPage],
    pagePerRowsState: [rowsPerPage, setRowsPerPage],
  },
}: {
  totalItems: number;
  pageInfo: PageInfo;
  defaultNumPerPage: number;
  fetchMore: ObservableQuery<TQuery>['fetchMore'];
  paginationState: {
    pageState: [number, React.Dispatch<React.SetStateAction<number>>];
    pagePerRowsState: [number, React.Dispatch<React.SetStateAction<number>>];
  };
}) {
  const fetchNewData = useCallback(
    async (pageControls, rowsPerPage) => {
      await fetchMore({
        variables: {
          pagingInfo: { pageSize: rowsPerPage, ...pageControls },
        },

        updateQuery(_, { fetchMoreResult }) {
          return fetchMoreResult;
        },
      });
    },
    [fetchMore],
  );

  const handleChangePage = async (_: unknown, newPage: number) => {
    const pageControls =
      page < newPage ? { after: pageInfo.endCursor } : { before: pageInfo.startCursor };
    await fetchNewData(pageControls, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await fetchNewData({ after: undefined }, newRowsPerPage);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[
        defaultNumPerPage,
        defaultNumPerPage * 2,
        defaultNumPerPage * 4,
      ]}
      component="div"
      count={totalItems}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
