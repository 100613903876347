import { Commit, CommonConfigurationFields } from 'common/types/commonConfiguration';

export function getCommitFromConfiguration(
  configuration: CommonConfigurationFields,
): Commit {
  return {
    commitHash: configuration.commitHash,
    commitDate: configuration.commitDate,
    commitBranch: configuration.commitBranch,
  };
}
