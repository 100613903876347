import React from 'react';

import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import {
  EnvironmentCommonFragment as EnvironmentCommon,
  OrganisationCommonFragment as OrganisationCommon,
} from 'admin-client/app/gql';
import { ROUTES } from 'admin-common/src/routing/routes';

type OrganisationEnvironmentLinkProps = {
  org: OrganisationCommon;
  env: EnvironmentCommon;
  children?: React.ReactNode;
};

export const OrganisationEnvironmentLink = React.memo(
  (props: OrganisationEnvironmentLinkProps) => {
    const { org, env, children } = props;
    const path = ROUTES.ORGANIZATIONS.ENV_DEPLOYMENT.getPath({
      envHostname: env.hostname,
      orgHumanIdentifier: org.humanIdentifier,
    });
    const title = org.humanIdentifier + ' on ' + env.hostname;

    return (
      <Link component={RouterLink} to={path} title={title}>
        {children ?? 'deployment details'}
      </Link>
    );
  },
);
