import { DEFAULT_BRANCH_NAME } from 'admin-client/app/components/ElementConfiguration/ChooseElement';
import { Commits } from 'admin-common/src/commit';
import { isMasterBranch, isRelengBranch } from 'admin-common/src/parseCommits';
import { Commit } from 'common/types/commonConfiguration';

/**
 * When choosing the initalCommit for a configuration, we never want this commit to be on a master
 * or releng branch as we want users to test out their configurations on a test branch first, before
 * actively moving the configuration to the master or releng branch.
 */
export function chooseInitialCommit(
  commits: Commits | null,
  initialCommit: Commit | null,
) {
  const isInitialCommitNonGlobal =
    initialCommit && !isMasterBranch(initialCommit) && !isRelengBranch(initialCommit);
  return isInitialCommitNonGlobal
    ? initialCommit
    : commits?.latestCommits.find(
        commit => commit.commitBranch === DEFAULT_BRANCH_NAME,
      ) || initialCommit;
}
