import React, { useCallback } from 'react';

import { PluginProps } from 'react-markdown-editor-lite';

import ElementAndParameterSelector from 'admin-client/app/components/Markdown/plugins/ElementAndParameterSelector';
import { useDialogManager } from 'common/ui/components/DialogManager';

const NameLink = (props: PluginProps) => {
  const dialogManager = useDialogManager();
  const onClickDialog = useCallback(async () => {
    const elementAndParameter = await dialogManager.openDialogPromise(
      'NAME_LINK_SELECTOR',
      ElementAndParameterSelector,
      {
        currentCommit: props.config.currentCommit,
      },
    );
    if (elementAndParameter !== null) {
      const { element, parameter } = elementAndParameter;
      const textInsert = parameter ? `${element}.${parameter}` : `${element}`;
      props.editor.insertText(`{{${textInsert}}}`, true);
    }
  }, [dialogManager, props.config.currentCommit, props.editor]);

  return (
    <span
      className="button button-type-counter"
      title="namelinker"
      onClick={onClickDialog}
    >
      {'{{NameLink}}'}
    </span>
  );
};
// Additional plugin props. `pluginName` must be provided here.
NameLink.align = 'left';
NameLink.pluginName = 'nameLink';

export default NameLink;
