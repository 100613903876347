import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { GET_TYPE_NAMES } from 'admin-client/app/api/gql/queries';
import { unique } from 'common/lib/data';
import { Commit } from 'common/types/commonConfiguration';
import { TypeName } from 'common/types/typeConfiguration';

export function useTypeNames(commit?: Commit) {
  const { data, loading, error } = useQuery(GET_TYPE_NAMES, {
    variables: {
      commit: commit
        ? {
            ...commit,
            commitDate: commit.commitDate.toISOString(),
          }
        : null,
    },
  });

  const typeNames: TypeName[] | undefined = useMemo(
    () =>
      data
        ? unique(
            data.typeConfigurations.map(({ typeName }) => typeName),
            type => type,
          )
        : [],
    [data],
  );
  return { typeNames, loading, error };
}
