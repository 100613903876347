import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { TypeConfigurationsTable } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/TypeConfigurationsTable';
import { TypeName } from 'common/types/typeConfiguration';

type Props = RouteComponentProps<{ typeName: TypeName }>;

/** Renders list of type configurations for the type in the URL. */
export function ViewTypeConfigurations({ match }: Props) {
  const { typeName } = match.params;
  return <TypeConfigurationsTable typeName={decodeURIComponent(typeName)} />;
}
