import moment from 'moment';

import { Commit } from 'common/types/commonConfiguration';

type DateTimeISO8601 = string;

/**
 * Raw data returned by GraphQL.
 */
export type GraphQLCommit = {
  commitHash: string;
  commitDate: DateTimeISO8601;
  commitBranch: string;
};

/**
 * Helper to convert GraphQLCommit to Commit.
 */
export function convertGraphQLCommit(commit: GraphQLCommit): Commit {
  return {
    ...commit,
    commitDate: moment(commit.commitDate),
  };
}

/**
 * Data structure for easier work with commits and branches.
 */
export type Commits = {
  /**
   * Map of all commits by commit branch.
   */
  allCommits: { [commitBranch: string]: { [commitHash: string]: Commit } };

  /**
   * All commits on master branch.
   */
  masterCommits: Commit[];

  /**
   * Commits on releng branches. Only first (=oldest) commit for each branch is present.
   * Each releng commit has a closest previous master commit linked.
   */
  relengCommits: RelengCommit[];

  /**
   * Latest commit for each branch.
   */
  latestCommits: Commit[];
};

export type RelengCommit = {
  commit: Commit;
  parentMasterCommit: Commit;
};
