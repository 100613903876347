import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';

import {
  GET_LATEST_ANTHAHUB_INSTALLER_RELEASES,
  GET_LATEST_DEVICE_DRIVER_RELEASES,
} from 'admin-client/app/api/gql/queries';
import { ReleaseCommonFragment as ReleaseCommon } from 'admin-client/app/gql';
import Colors from 'common/ui/Colors';
import LinearProgress from 'common/ui/components/LinearProgress';

export default function AnthaHubReleasesContainer() {
  const anthaHubInstallers = useQuery(GET_LATEST_ANTHAHUB_INSTALLER_RELEASES);
  const deviceDrivers = useQuery(GET_LATEST_DEVICE_DRIVER_RELEASES);
  return (
    <>
      <Typography variant="h2" gutterBottom>
        SynthaceHub Software Releases
      </Typography>
      <ReleasesView
        title="Latest SynthaceHub installer releases"
        loading={anthaHubInstallers.loading}
        error={anthaHubInstallers.error}
        releases={anthaHubInstallers.data?.latestAnthaHubReleases}
      />
      <ReleasesView
        title="Latest device plugin releases"
        loading={deviceDrivers.loading}
        error={deviceDrivers.error}
        releases={deviceDrivers.data?.latestDeviceDriverReleases}
      />
    </>
  );
}

type ReleasesViewProps = {
  title: string;
  loading: boolean;
  error: ApolloError | undefined;
  releases?: readonly ReleaseCommon[];
};

function ReleasesView(props: ReleasesViewProps) {
  const classes = useStyles();
  const { title, loading, error, releases } = props;

  return (
    <section className={classes.summarySection}>
      <Card>
        <CardHeader title={title} />
        <LinearProgress hidden={!loading} />
        {error && (
          <Paper className={classes.errorContainer}>
            <Typography variant="body1" className={classes.errorText}>
              {error}
            </Typography>
          </Paper>
        )}
        {releases && <ReleasesTable releases={releases} />}
      </Card>
    </section>
  );
}

type ReleasesTableProps = {
  releases: readonly ReleaseCommon[];
};

function ReleasesTable(props: ReleasesTableProps) {
  const { releases } = props;

  return (
    <Paper>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>Version</Typography>
            </TableCell>
            <TableCell>
              <Typography>Created</Typography>
            </TableCell>
            <TableCell>
              <Typography>Download</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {releases.map(release => {
            return (
              <TableRow key={release.version}>
                <TableCell>
                  <Typography>
                    {release.version} (
                    <a href={release.releaseNotesURL} target="_blank" rel="noreferrer">
                      notes
                    </a>
                    )
                  </Typography>
                </TableCell>
                <TableCell title={release.createdAt}>
                  <Typography>{moment(release.createdAt).fromNow()}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {release.artifacts.map(artifact => {
                      return (
                        <div key={artifact.filename}>
                          <a href={artifact.downloadURL}>{artifact.filename}</a> (
                          {(artifact.sizeBytes / 1024 / 1024).toFixed(1)} MB)
                        </div>
                      );
                    })}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summarySection: {
      display: 'flex',
      marginBottom: theme.spacing(6),
    },
    errorContainer: {
      padding: theme.spacing(3),
    },
    errorText: {
      color: Colors.ERROR_MAIN,
    },
  }),
);
