import React, { useCallback } from 'react';

import Button from '@mui/material/Button';

import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  resetObjectName: string;
  additionalMessage?: string;
  disabled?: boolean;
  onClick: () => void;
};

export default function ResetButton({
  onClick,
  resetObjectName,
  additionalMessage,
  disabled,
}: Props) {
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const handleReset = useCallback(async () => {
    const reset = await openConfirmationDialog({
      action: 'reset',
      object: resetObjectName,
      additionalMessage,
      confirmButtonLabel: 'Yes',
    });
    if (reset) {
      onClick();
    }
  }, [additionalMessage, onClick, openConfirmationDialog, resetObjectName]);
  return (
    <>
      <Button onClick={handleReset} variant="outlined" disabled={disabled}>
        Reset
      </Button>
      {confirmationDialog}
    </>
  );
}
