import React, { useCallback, useMemo } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Moment } from 'moment';
import { useHistory } from 'react-router-dom';

import { BranchList } from 'admin-client/app/components/ElementConfiguration/BranchList';
import { ConfigurationTypeLabel } from 'admin-client/app/components/ElementConfiguration/ConfigurationTypeLabel';
import { getConfigurationCommits } from 'admin-client/app/components/ElementConfiguration/getConfigurationCommits';
import {
  GraphQLTypeConfiguration,
  useTypeConfigurations,
} from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useTypeConfigurations';
import { useCommits } from 'admin-client/app/components/ElementConfiguration/useCommits';
import { Commits } from 'admin-common/src/commit';
import { ROUTES } from 'admin-common/src/routing/routes';
import { TypeName } from 'common/types/typeConfiguration';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  typeName: TypeName;
};

const TABLE_COLUMNS: {
  header: string;
  getValue: (
    configuration: GraphQLTypeConfiguration,
    allConfigurations: readonly GraphQLTypeConfiguration[],
    commits: Commits | null,
    classes: ReturnType<typeof useStyles>,
  ) => React.ReactNode;
}[] = [
  {
    header: 'Type',
    getValue: configuration => (
      <ConfigurationTypeLabel configurationType={configuration.configurationType} />
    ),
  },
  {
    header: 'Applied',
    getValue: (configuration, allConfigurations, commits) => {
      if (!commits) {
        return null;
      }
      const configurationCommits = getConfigurationCommits(
        configuration,
        allConfigurations,
        commits,
      );
      return <BranchList commits={configurationCommits} />;
    },
  },
  {
    header: 'Valid since',
    getValue: c => formatDate(c.commitDate),
  },
  {
    header: 'Created',
    getValue: c => <div>{formatDate(c.createdAt)}</div>,
  },
  {
    header: 'Last modified',
    getValue: (c, _, __, classes) => (
      <>
        <div>{formatDate(c.lastModifiedAt)}</div>
        <div className={classes.editorName}>{c.lastModifiedBy}</div>
      </>
    ),
  },
];

/** Table showing configurations for the given type. */
export function TypeConfigurationsTable({ typeName }: Props) {
  const classes = useStyles();
  const history = useHistory();

  const { configurations } = useTypeConfigurations(typeName);
  const { commits } = useCommits();

  const onEditConfiguration = useCallback(
    (configuration: GraphQLTypeConfiguration) =>
      history.push(
        ROUTES.TYPE_CONFIGURATION.EDIT.getPath({
          typeName,
          id: configuration.id,
        }),
      ),
    [typeName, history],
  );

  const sortedConfigurations = useMemo(
    () => [...configurations].sort(compareConfigurations),
    [configurations],
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          {TABLE_COLUMNS.map((column, index) => (
            <TableCell key={index}>{column.header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedConfigurations.map(configuration => (
          <ConfigurationTableRow
            key={configuration.id}
            className={classes.tableRow}
            commits={commits}
            configuration={configuration}
            allConfigurations={configurations}
            onRowClick={onEditConfiguration}
          />
        ))}
      </TableBody>
    </Table>
  );
}

/**
 * Helper component to render table row. The component is needed only because of
 * the onClick callback for entire row, otherwise it could be inlined.
 */
function ConfigurationTableRow({
  configuration,
  allConfigurations,
  commits,
  className,
  onRowClick,
}: {
  configuration: GraphQLTypeConfiguration;
  allConfigurations: readonly GraphQLTypeConfiguration[];
  commits: Commits | null;
  className: string;
  onRowClick: (configuration: GraphQLTypeConfiguration) => void;
}) {
  const classes = useStyles();
  const onClick = useCallback(
    () => onRowClick(configuration),
    [configuration, onRowClick],
  );
  return (
    <TableRow className={className} onClick={onClick}>
      {TABLE_COLUMNS.map((column, index) => (
        <TableCell key={index}>
          {column.getValue(configuration, allConfigurations, commits, classes)}
        </TableCell>
      ))}
    </TableRow>
  );
}

/**
 * Comparator for sorting configurations in the table. Currently we sort
 * by commit date, descending (that's what the `-` does).
 */
function compareConfigurations(
  c1: GraphQLTypeConfiguration,
  c2: GraphQLTypeConfiguration,
): number {
  return -c1.commitDate.diff(c2.commitDate, 'seconds');
}

function formatDate(date: Moment): string {
  return date.format('lll');
}

const useStyles = makeStylesHook({
  toolbar: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableRow: {
    '&:hover': { backgroundColor: Colors.GREY_20, cursor: 'pointer' },
  },
  editorName: {
    color: Colors.GREY_40,
  },
});
