import React from 'react';

import { Permission, PermissionsContext } from 'common/ui/PermissionsProvider';

type HasPermissionProps<PermissionKeys> = {
  permission: Permission<PermissionKeys>;
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export const createHasPermission = <PermissionKeys,>(
  usePermissions: () => PermissionsContext<PermissionKeys>,
) => {
  return (props: HasPermissionProps<PermissionKeys>) => {
    const { permission, fallback, children } = props;
    const { currentUserHasPermission } = usePermissions();

    if (currentUserHasPermission(permission)) {
      return <div>{children}</div>;
    }
    return <div>{fallback}</div>;
  };
};
