import React, { useCallback, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Typography from '@mui/material/Typography';
import axios, { AxiosResponse } from 'axios';

import { GlobalFileObject } from 'common/types/commonConfiguration';
import { UploadInput } from 'common/types/filetree';
import Colors from 'common/ui/Colors';
import DirectUploadEditor from 'common/ui/components/ParameterEditors/DirectUploadEditor';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const NINJA_FILETREE_SCHEME = 'files.antha.ninja';
type Props = {
  value?: GlobalFileObject;
  onChange: (newValue: GlobalFileObject) => void;
  targetFolder?: string;
  isDisabled?: boolean;
};

export default function AdminFileUploadEditor(props: Props) {
  const styles = useStyles();
  const { getAccessTokenSilently } = useAuth0();

  const [errors, setErrors] = useState<string[]>([]);

  const upload = useCallback(
    async (uploadInput: UploadInput) => {
      const accessToken = await getAccessTokenSilently();
      const bodyFormData = new FormData();
      bodyFormData.set('indexPath', uploadInput.metadata.indexPath);
      bodyFormData.append('contentType', uploadInput.file.type);
      bodyFormData.append('file', uploadInput.file);

      const response: AxiosResponse<{
        globalFiletreeLink: GlobalFileObject;
        uploadErrors: string[];
      }> = await axios.post('/api/file/uploadAndIndexGlobally', bodyFormData, {
        headers: {
          authorization: `bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status !== 200) {
        setErrors([`File upload failed: ${response.statusText}`]);
      } else {
        setErrors(response.data.uploadErrors);
      }

      return response.data.globalFiletreeLink;
    },
    [getAccessTokenSilently],
  );

  const ninjaFileObject = props.value && {
    ...props.value,
    Path: `${NINJA_FILETREE_SCHEME}://${props.value.Path}`,
  };

  return (
    <>
      <DirectUploadEditor
        // We only need a copy of the file from one environment for the user to view and
        // download, and ninja is available in dev and production.
        value={ninjaFileObject ?? null}
        onChange={props.onChange}
        targetFolder={props.targetFolder}
        isDisabled={props.isDisabled}
        onUpload={upload}
      />
      {errors.map(error => (
        <Typography variant="caption" key={error} className={styles.errors}>
          {error}
        </Typography>
      ))}
    </>
  );
}

const useStyles = makeStylesHook({
  errors: {
    display: 'block',
    color: Colors.ERROR,
  },
});
