import React from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ConnectionLink } from 'admin-client/app/components/Connections/ConnectionLink';
import { GraphQLConnection } from 'admin-client/app/components/Connections/utils';
import { HasPermission } from 'admin-client/app/components/Permissions';

type ConnectionsViewerProps = {
  connections: GraphQLConnection[];
  handleChangeConnectionsClick: () => void;
};

export const ConnectionsViewer = React.memo(
  ({ connections, handleChangeConnectionsClick }: ConnectionsViewerProps) => (
    <>
      <ul>
        {connections.map(connection => (
          <li key={connection.id}>
            <Typography>
              <ConnectionLink connection={connection} />
            </Typography>
          </li>
        ))}
      </ul>
      <HasPermission permission="update:connections">
        <Button variant="contained" onClick={handleChangeConnectionsClick}>
          Change Connections
        </Button>
      </HasPermission>
    </>
  ),
);
