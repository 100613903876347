import React, { useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import FeatureToggleOverrideIcon from 'admin-client/app/components/FeatureToggles/FeatureToggleOverrideIcon';
import FeatureToggleSwitch from 'admin-client/app/components/FeatureToggles/FeatureToggleSwitch';
import {
  ArrayElement,
  FeatureToggleCommonFragment,
  featureTogglesForAllEnvsQuery,
} from 'admin-client/app/gql';
import { indexBy } from 'common/lib/data';
import { byName } from 'common/lib/strings';
import Autocomplete from 'common/ui/filaments/Autocomplete';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type FeatureToggle = ArrayElement<FeatureToggleCommonFragment['featureToggles']>;

type ShowFeatureTogglesForEnvProps = {
  data: featureTogglesForAllEnvsQuery;
};

export default React.memo(function ShowFeatureTogglesForEnv(
  props: ShowFeatureTogglesForEnvProps,
) {
  const { data } = props;
  const classes = useStyles();

  const [selectedEnvId, setSelectedEnvId] = useState('');
  const handleChangeEnv = useCallback((envId?: string) => {
    setSelectedEnvId(envId ?? '');
  }, []);

  const envOptions = data.environments.map(env => ({
    label: env.hostname,
    value: env.id,
  }));
  const labelOptionMap = indexBy(envOptions, 'value');

  let label;
  let currentEnv;
  let hostname = '';
  let selectedFeatureToggles;
  if (selectedEnvId) {
    label = labelOptionMap[selectedEnvId]?.label;
    currentEnv = data.environments.filter(env => env.id === selectedEnvId);
    hostname = currentEnv ? currentEnv[0].hostname : '';
    selectedFeatureToggles = currentEnv ? currentEnv[0].featureToggles : [];
  }

  return (
    <div className={classes.container}>
      <div className={classes.input}>
        <Autocomplete
          valueLabel={label}
          options={envOptions}
          onChange={handleChangeEnv}
          fullWidth
          label="Environment"
        />
      </div>

      {!currentEnv ? (
        <Box padding={2}>
          <Typography> No environment selected.</Typography>
        </Box>
      ) : selectedFeatureToggles && selectedFeatureToggles.length > 0 ? (
        <Table size="medium" className={classes.table} stickyHeader>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell>
                <Typography>Feature Name</Typography>
              </TableCell>
              <TableCell>
                <Typography>Description</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>Status</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>Override?</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <FeatureTable
            featureToggles={selectedFeatureToggles}
            environmentId={selectedEnvId ?? ''}
            hostname={hostname}
          />
        </Table>
      ) : (
        <Box padding={2}>
          <Typography> No feature toggles available for this env </Typography>
        </Box>
      )}
    </div>
  );
});

type FeatureTableProps = {
  featureToggles: readonly FeatureToggle[];
  environmentId: string;
  hostname: string;
};

const FeatureTable = function (props: FeatureTableProps) {
  const { featureToggles, environmentId, hostname } = props;

  return (
    <TableBody>
      {featureToggles
        .slice() // featureTogglesForEnv_environments_featureToggles is readonly.
        .sort(byName)
        .map(featureToggle => {
          return (
            <TableRow key={featureToggle.name}>
              <TableCell>
                <Typography>
                  {featureToggle.name} ({featureToggle.kind})
                </Typography>
              </TableCell>
              <TableCell>{featureToggle.description}</TableCell>
              <TableCell align="center">
                <FeatureToggleSwitch
                  featureToggle={featureToggle}
                  environmentId={environmentId}
                  hostname={hostname}
                />
              </TableCell>
              <TableCell align="center">
                <FeatureToggleOverrideIcon
                  featureToggle={featureToggle}
                  environmentId={environmentId}
                  hostname={hostname}
                />
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};

const useStyles = makeStylesHook(theme => ({
  container: {
    padding: theme.spacing(5),
  },
  // Prevent the sticky table header from scrolling underneath the AppBar
  head: {
    '& th': {
      top: `${theme.mixins.toolbar.minHeight}px`,
      paddingTop: theme.spacing(6),
    },
  },
  input: {
    margin: 'auto',
    width: '80%',
  },
  table: {
    marginTop: theme.spacing(6),
  },
}));
