import React, { useCallback, useMemo, useState } from 'react';

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import AdminFileUploadEditor from 'admin-client/app/components/ElementConfiguration/AdminFileUploadEditor';
import { useTypeNames } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useTypeNames';
import { MarkdownEditor } from 'admin-client/app/components/Markdown/MarkdownEditor';
import {
  AdditionalEditorProps,
  ArrayAdditionalProps,
  AutocompleteAdditionalProps,
  DropdownAdditionalProps,
  FileAdditionalProps,
  MapAdditionalProps,
  MeasurementAdditionalProps,
  PlateContentsAdditionalProps,
  SpreadsheetAdditionalProps,
  TipTypeAdditionalProps,
  UnitAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import {
  BACKUP_EDITOR_OPTIONS,
  EditorType,
} from 'common/elementConfiguration/EditorType';
import {
  getAdditionalEditorPropsForEditorType,
  getEditorTypeProperties,
} from 'common/elementConfiguration/getEditorTypeInfo';
import {
  getArrayTypeFromAnthaType,
  getEditorChoicesFromAnthaType,
  getKeyTypeFromAnthaType,
  getValueTypeFromAnthaType,
  isArrayType,
} from 'common/elementConfiguration/parameterUtils';
import doNothing from 'common/lib/doNothing';
import { Markdown } from 'common/lib/markdown';
import {
  GlobalFileObject,
  ParameterEditorConfigurationSpec,
} from 'common/types/commonConfiguration';
import { Option } from 'common/types/Option';
import {
  ColumnConfiguration,
  ColumnEditorType,
  DragToFillBehaviour,
  dragToFillBehaviours,
  SheetConfiguration,
  SpreadsheetRule,
} from 'common/types/spreadsheet';
import { COLUMN_DATA_TYPES, ColumnDataType } from 'common/types/spreadsheetEditor';
import { TypeConfigurationSpec } from 'common/types/typeConfiguration';
import Colors from 'common/ui/Colors';
import IconWithPopover from 'common/ui/components/IconWithPopover';
import { InlineTextEditor } from 'common/ui/components/InlineTextEditor';
import SpreadsheetEditor from 'common/ui/components/ParameterEditors/SpreadsheetEditor';
import {
  getSensibleMeasurementUnits,
  MeasurementType,
  measurementTypes,
} from 'common/ui/components/ParameterEditors/unitRegistry';
import Autocomplete from 'common/ui/filaments/Autocomplete';
import Dropdown from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useCheckboxChange from 'common/ui/hooks/useCheckboxChange';
import useMultilineStringArrayChange from 'common/ui/hooks/useMultilineStringArrayChange';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type BaseProps<T> = {
  editorProps: T;
  updateSpec: (additionalProps: T) => void;
  /**
   * For some editors, we display the options inline rather than giving them
   * a dedicated row when they're used as part of a compound type.
   * */
  isCompoundTypeItemEditor?: boolean;
};

/**
 * Additional props editors for compound editor types need a bit of extra info for their
 * nested editors.
 */
type CompoundProps<T> = BaseProps<T> & {
  /** Needed for compound types so we can get the item or key and value types. */
  anthaType: string;
  typeConfigurations: Record<string, TypeConfigurationSpec | undefined>;
};

type Props<T> = BaseProps<T> | CompoundProps<T>;

/**
 * A section for editing additional props for certain EditorTypes.
 * For example, dropdowns need options to be specified.
 * */
export default function AdditionalPropsEditor(props: Props<AdditionalEditorProps>) {
  const { editorProps, updateSpec, isCompoundTypeItemEditor } = props;
  switch (editorProps.editor) {
    case EditorType.AUTOCOMPLETE:
      return (
        <AutocompletePropsEditor
          editorProps={editorProps}
          updateSpec={updateSpec}
          isCompoundTypeItemEditor={isCompoundTypeItemEditor}
        />
      );
    case EditorType.DROPDOWN:
      return (
        <DropdownPropsEditor
          editorProps={editorProps}
          updateSpec={updateSpec}
          isCompoundTypeItemEditor={isCompoundTypeItemEditor}
        />
      );
    case EditorType.ARRAY: {
      const { anthaType, typeConfigurations } =
        props as CompoundProps<AdditionalEditorProps>;
      return (
        <ArrayPropsEditor
          editorProps={editorProps}
          updateSpec={updateSpec}
          anthaType={anthaType}
          typeConfigurations={typeConfigurations}
        />
      );
    }
    case EditorType.FILE:
      return (
        <FilePropsEditor
          editorProps={editorProps}
          updateSpec={updateSpec}
          isCompoundTypeItemEditor={isCompoundTypeItemEditor}
        />
      );
    case EditorType.MAP: {
      const { anthaType, typeConfigurations } =
        props as CompoundProps<AdditionalEditorProps>;
      return (
        <MapPropsEditor
          editorProps={editorProps}
          updateSpec={updateSpec}
          anthaType={anthaType}
          typeConfigurations={typeConfigurations}
        />
      );
    }
    case EditorType.MEASUREMENT:
      return (
        <MeasurementPropsEditor
          editorProps={editorProps}
          updateSpec={updateSpec}
          isCompoundTypeItemEditor={isCompoundTypeItemEditor}
        />
      );
    case EditorType.PLATE_CONTENTS: {
      const { anthaType, typeConfigurations } =
        props as CompoundProps<AdditionalEditorProps>;
      return (
        <PlateContentsPropsEditor
          editorProps={editorProps}
          updateSpec={updateSpec}
          typeConfigurations={typeConfigurations}
          anthaType={anthaType}
        />
      );
    }
    case EditorType.SPREADSHEET: {
      const { anthaType } = props as CompoundProps<AdditionalEditorProps>;
      return (
        <SpreadsheetPropsEditor
          editorProps={editorProps}
          updateSpec={updateSpec}
          anthaType={anthaType}
        />
      );
    }
    case EditorType.TIP_TYPE: {
      return (
        <TipTypePropsEditor
          editorProps={editorProps}
          updateSpec={updateSpec}
          isCompoundTypeItemEditor={isCompoundTypeItemEditor}
        />
      );
    }
    case EditorType.UNIT: {
      return (
        <UnitPropsEditor
          editorProps={editorProps}
          updateSpec={updateSpec}
          isCompoundTypeItemEditor={isCompoundTypeItemEditor}
        />
      );
    }
    case EditorType.TOGGLE_BUTTONS:
      // TODO: Add a specific editor for toggle buttons options.
      return null;
    case EditorType.PLATE_LAYOUT_LAYERS:
      // TODO: Implement this when we have are able to develop element config locally.
      return null;
    case EditorType.PLATE_TYPE:
      // This is currently not used in the admin tool
      return null;
  }
}

const AutocompletePropsEditor = (props: Props<AutocompleteAdditionalProps>) => {
  const { editorProps, updateSpec, isCompoundTypeItemEditor } = props;

  const updateCanAcceptCustomValues = useCheckboxChange((isChecked: boolean) => {
    updateSpec({ ...editorProps, canAcceptCustomValues: isChecked });
  });

  const updateUseDynamicOptions = useCheckboxChange((isChecked: boolean) => {
    updateSpec({ ...editorProps, useDynamicOptions: isChecked });
  });

  const updateprovideDefaultKey = useCheckboxChange((isChecked: boolean) => {
    updateSpec({ ...editorProps, provideDefaultKey: isChecked });
  });

  const updateAnthaTypeOverride = useTextFieldChange((newType: string) => {
    updateSpec({ ...editorProps, anthaTypeOverride: newType });
  });

  const {
    canAcceptCustomValues,
    useDynamicOptions,
    provideDefaultKey,
    staticOptions,
    anthaTypeOverride,
    additionalSourceTypes,
  } = editorProps;

  const [updatedOptions, optionsString, handleOptionsChange] =
    useMultilineStringArrayChange(staticOptions);

  const [
    updatedAdditionalSourceTypes,
    additionalSourceTypesString,
    handleAdditionalSourceTypesChange,
  ] = useMultilineStringArrayChange(additionalSourceTypes || []);

  const updateSpecAdditionalSourceTypes = () => {
    const { additionalSourceTypes: _, ...existingEditorProps } = editorProps;
    const hasAdditionalSourceTypes = updatedAdditionalSourceTypes.length > 0;
    updateSpec({
      ...existingEditorProps,
      ...(hasAdditionalSourceTypes && {
        additionalSourceTypes: updatedAdditionalSourceTypes,
      }),
    });
  };

  const updateSpecOptions = () => {
    updateSpec({
      ...editorProps,
      staticOptions: updatedOptions,
    });
  };

  const fields = (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={canAcceptCustomValues}
            onChange={updateCanAcceptCustomValues}
          />
        }
        label="Can accept custom values"
      />
      <FormControlLabel
        control={
          <Checkbox checked={useDynamicOptions} onChange={updateUseDynamicOptions} />
        }
        label="Use existing parameter values of the same type"
      />
      <FormControlLabel
        control={
          <Checkbox checked={provideDefaultKey} onChange={updateprovideDefaultKey} />
        }
        label="Include the 'default' key"
      />
      <InputLabel shrink>Antha type override</InputLabel>
      <TextField
        fullWidth
        value={anthaTypeOverride}
        onChange={updateAnthaTypeOverride}
        disabled={!useDynamicOptions}
      />
      <InputLabel shrink>Additional source types (add one per line)</InputLabel>
      <TextField
        fullWidth
        multiline
        value={additionalSourceTypesString}
        onChange={handleAdditionalSourceTypesChange}
        onBlur={updateSpecAdditionalSourceTypes}
      />
      <InputLabel shrink>Options (add one per line)</InputLabel>
      <TextField
        fullWidth
        multiline
        value={optionsString}
        onChange={handleOptionsChange}
        onBlur={updateSpecOptions}
      />
    </>
  );

  if (isCompoundTypeItemEditor) {
    return fields;
  }

  return <RowContents headerTitle="Autocomplete props">{fields}</RowContents>;
};

type DropdownOptionProps = {
  optionIndex: number;
  option: Option<string>;
  duplicateValue: boolean;
  duplicateLabel: boolean;
  onChange: (option: Option<string>, optionIndex: number) => void;
  onRemove: (optionIndex: number) => void;
};

const DropdownOptionEditor = (props: DropdownOptionProps) => {
  const classes = useStyles();
  const { option, onChange, onRemove, optionIndex } = props;

  const onChangeValue = useTextFieldChange((newValue: string) => {
    const updatedLabel = newValue ? option.label : '';
    onChange({ label: updatedLabel, value: newValue }, optionIndex);
  });

  const onChangeLabel = useTextFieldChange((newLabel: string) => {
    onChange({ label: newLabel, value: option.value }, optionIndex);
  });

  const onRemoveOption = useCallback(
    () => onRemove(optionIndex),
    [onRemove, optionIndex],
  );

  return (
    <div className={classes.dropdownOptionEditor}>
      <TextField
        className={classes.dropdownOptionTextField}
        label="Value"
        value={option.value}
        onChange={onChangeValue}
        error={props.duplicateValue}
        helperText={props.duplicateValue && 'Duplicate values not allowed'}
      />
      <TextField
        className={classes.dropdownOptionTextField}
        label="Label (optional)"
        value={option.label}
        disabled={!option.value}
        onChange={onChangeLabel}
        error={props.duplicateLabel}
        helperText={props.duplicateLabel && 'Duplicate labels not allowed'}
      />
      <IconButton onClick={onRemoveOption} size="large">
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

const DropdownPropsEditor = (props: Props<DropdownAdditionalProps>) => {
  const { editorProps, updateSpec, isCompoundTypeItemEditor } = props;

  // Make sure we will cut any potential blank label entries, which
  // should be made the same as the value.
  const optionsWithLabelFilled = props.editorProps.options.map(opt => {
    return { label: opt.label || opt.value, value: opt.value };
  });

  const [currentOptions, setCurrentOptions] = useState(optionsWithLabelFilled);

  const updateOptions = useCallback(
    (opts: Option<string>[]) =>
      updateSpec({
        ...editorProps,
        // Filter out any unspecified values here, and assign the value to any
        // unspecified labels.
        options: opts
          .filter(opt => opt.value)
          .map(opt => ({ label: opt.label || opt.value, value: opt.value })),
      }),
    [editorProps, updateSpec],
  );

  const onChangeOption = useCallback(
    (option: Option<string>, index: number) => {
      const newOptions = [...currentOptions];
      newOptions[index] = option;
      setCurrentOptions(newOptions);
      updateOptions(newOptions);
    },
    [currentOptions, updateOptions],
  );

  const onAddOption = useCallback(() => {
    const newOptions = [
      ...currentOptions,
      {
        label: '',
        value: '',
      },
    ];
    // We dont want to update the state of the props options here as a new dropdown
    // entry doesnt mean anything until it has been modified.
    setCurrentOptions(newOptions);
  }, [currentOptions]);

  const onRemoveValue = useCallback(
    (optionIndex: number) => {
      const filtered = currentOptions.filter((_rule, index) => index !== optionIndex);
      setCurrentOptions(filtered);
      updateOptions(filtered);
    },
    [currentOptions, updateOptions],
  );

  const updateUseDynamicOptions = useCheckboxChange((isChecked: boolean) => {
    updateSpec({ ...editorProps, useDynamicOptions: isChecked });
  });

  const updateAnthaTypeOverride = useTextFieldChange((newType: string) => {
    updateSpec({ ...editorProps, anthaTypeOverride: newType });
  });

  const [duplicateValues, duplicateLabels] = useMemo(() => {
    return [
      findDuplicateEntries(currentOptions.map(opt => opt.value)),
      findDuplicateEntries(currentOptions.map(opt => opt.label)),
    ];
  }, [currentOptions]);

  const fields = (
    <>
      <InputLabel shrink>Options</InputLabel>
      {currentOptions.map((option, idx) => (
        <DropdownOptionEditor
          key={idx}
          optionIndex={idx}
          option={option}
          onChange={onChangeOption}
          onRemove={onRemoveValue}
          duplicateValue={duplicateValues.includes(option.value)}
          duplicateLabel={duplicateLabels.includes(option.label)}
        />
      ))}
      <Button variant="outlined" onClick={onAddOption}>
        Add option
      </Button>
      <FormControlLabel
        control={
          <Checkbox
            checked={editorProps.useDynamicOptions}
            onChange={updateUseDynamicOptions}
          />
        }
        label="Include existing parameter values of the same type"
      />
      <InputLabel shrink>Antha type override</InputLabel>
      <TextField
        fullWidth
        value={editorProps.anthaTypeOverride}
        onChange={updateAnthaTypeOverride}
      />
    </>
  );

  if (isCompoundTypeItemEditor) {
    return fields;
  }

  return <RowContents headerTitle="Dropdown configuration">{fields}</RowContents>;
};

const TipTypePropsEditor = (props: Props<TipTypeAdditionalProps>) => {
  const fields = (
    <Typography variant="body1">
      Tip type props editing is not currently supported.
    </Typography>
  );

  if (props.isCompoundTypeItemEditor) {
    return fields;
  }
  return <RowContents headerTitle="Tip type props">{fields}</RowContents>;
};

/**
 * Returns all string items in arr that are found more than once.
 * Does not count an empty string as a valid value for comparison.
 */
const findDuplicateEntries = (arr: string[]) => {
  return arr.filter((val, idx) => val !== '' && arr.indexOf(val) !== idx);
};

const joinUnitsToString = (units: string[]) => {
  return units.join(', ');
};

const UnitPropsEditor = (props: Props<UnitAdditionalProps>) => {
  const { editorProps, updateSpec, isCompoundTypeItemEditor } = props;
  const { units } = editorProps;
  const classes = useStyles();

  const [currentMeasurement, setCurrentMeasurement] = useState('');
  const [currentUnits, setCurrentUnits] = useState(joinUnitsToString(units) ?? '');

  const onChangeMeasurementType = useCallback(
    (value?: string) => {
      const units = getSensibleMeasurementUnits(value as MeasurementType);
      setCurrentUnits(joinUnitsToString(units));
      updateSpec({
        ...editorProps,
        units: units,
      });
      setCurrentMeasurement(value || '');
    },
    [editorProps, updateSpec],
  );

  const onUnitListChange = useTextFieldChange((value: string) => {
    setCurrentUnits(value);
    updateSpec({
      ...editorProps,
      units: value
        .replace(/\n/g, '')
        .split(',')
        .map(char => char.trim()),
    });
  });

  const fields = (
    <>
      <InputLabel shrink>
        Type in measurement units as comma-separated values or select a measurement type
        to get some pre-defined units as a starting point.
      </InputLabel>
      <Dropdown
        valueLabel={currentMeasurement}
        onChange={onChangeMeasurementType}
        options={measurementTypes.map(value => ({
          label: value,
          value,
        }))}
        placeholder="Select measurement type"
        className={classes.marginBottom}
      />
      <TextField
        className={cx(classes.marginBottom, classes.measurementTextField)}
        fullWidth
        multiline
        value={currentUnits}
        onChange={onUnitListChange}
      />
    </>
  );

  if (isCompoundTypeItemEditor) {
    return fields;
  }

  return <RowContents headerTitle="Unit props">{fields}</RowContents>;
};

const MeasurementPropsEditor = (props: Props<MeasurementAdditionalProps>) => {
  const { editorProps, updateSpec, isCompoundTypeItemEditor } = props;
  const { defaultUnit, units } = editorProps;
  const defaultUnitOptions = [...new Set(units)].map(value => ({
    label: value,
    value,
  }));

  const onDefaultUnitChange = (unit?: string) => {
    updateSpec({
      ...editorProps,
      defaultUnit: unit,
    });
  };

  const updateSpecWithUnits = (additionalProps: UnitAdditionalProps) => {
    updateSpec({
      ...additionalProps,
      editor: EditorType.MEASUREMENT,
      defaultUnit,
    });
  };

  const unitEditorProps: UnitAdditionalProps = {
    editor: EditorType.UNIT,
    units,
  };

  const fields = (
    <>
      <UnitPropsEditor
        editorProps={unitEditorProps}
        updateSpec={updateSpecWithUnits}
        isCompoundTypeItemEditor
      />
      <Autocomplete
        placeholder="Select a default unit (optional)"
        valueLabel={defaultUnit ?? ''}
        options={defaultUnitOptions}
        onChange={onDefaultUnitChange}
      />
    </>
  );

  if (isCompoundTypeItemEditor) {
    return fields;
  }

  return <RowContents headerTitle="Measurement props">{fields}</RowContents>;
};

const ArrayPropsEditor = (
  props: Omit<CompoundProps<ArrayAdditionalProps>, 'isCompoundTypeItemEditor'>,
) => {
  const classes = useStyles();
  const { editorProps, updateSpec, anthaType, typeConfigurations } = props;
  const { itemEditor } = props.editorProps as Required<ArrayAdditionalProps>;

  const itemAnthaType = getArrayTypeFromAnthaType(anthaType);

  if (!anthaType || !itemEditor || !itemAnthaType) {
    return (
      <p>
        The item editor properties cannot be configured as there was a problem finding the
        relevant antha type or its associated type configuration entry.
      </p>
    );
  }

  const updateSpecItemEditor = (itemEditor: ParameterEditorConfigurationSpec) => {
    updateSpec({
      ...editorProps,
      itemEditor,
    });
  };

  return (
    <>
      <TableCell>
        <InputLabel shrink>Array props</InputLabel>
      </TableCell>
      <TableCell className={classes.fullWidth}>
        <NestedPropsEditor
          sectionName="Item"
          anthaType={itemAnthaType}
          editorSpec={itemEditor}
          updateEditorSpec={updateSpecItemEditor}
          typeConfigurations={typeConfigurations}
        />
      </TableCell>
    </>
  );
};

const MapPropsEditor = (
  props: Omit<CompoundProps<MapAdditionalProps>, 'isCompoundTypeItemEditor'>,
) => {
  const classes = useStyles();
  const { editorProps, updateSpec, anthaType, typeConfigurations } = props;
  const { keyEditor, valueEditor } = editorProps;

  const keyAnthaType = getKeyTypeFromAnthaType(anthaType);
  const valueAnthaType = getValueTypeFromAnthaType(anthaType);

  const updateSpecKeyEditor = (keyEditor: ParameterEditorConfigurationSpec) => {
    updateSpec({
      ...editorProps,
      keyEditor,
    });
  };

  const updateSpecValueEditor = (valueEditor: ParameterEditorConfigurationSpec) => {
    updateSpec({
      ...editorProps,
      valueEditor,
    });
  };

  return (
    <>
      <TableCell>
        <InputLabel shrink>Map props</InputLabel>
      </TableCell>
      <TableCell className={classes.fullWidth}>
        <Box display="flex" flexDirection="column">
          <Box flex={1} marginBottom={1}>
            {keyEditor ? (
              <NestedPropsEditor
                sectionName="Key"
                anthaType={keyAnthaType}
                editorSpec={keyEditor}
                updateEditorSpec={updateSpecKeyEditor}
                typeConfigurations={typeConfigurations}
              />
            ) : (
              <p>
                Insufficient information was found for the key editor to be configured.
              </p>
            )}
          </Box>
          <Box flex={1}>
            {valueEditor ? (
              <NestedPropsEditor
                sectionName="Value"
                anthaType={valueAnthaType}
                editorSpec={valueEditor}
                updateEditorSpec={updateSpecValueEditor}
                typeConfigurations={typeConfigurations}
              />
            ) : (
              <p>
                Insufficient information was found for the value editor to be configured.
              </p>
            )}
          </Box>
        </Box>
      </TableCell>
    </>
  );
};

const FilePropsEditor = (props: Props<FileAdditionalProps>) => {
  const { editorProps, updateSpec, isCompoundTypeItemEditor } = props;

  const onTemplateChange = useCallback(
    (template: GlobalFileObject) => {
      updateSpec({ ...editorProps, template });
    },
    [editorProps, updateSpec],
  );

  const fields = (
    <>
      <InputLabel shrink>Template file</InputLabel>
      <AdminFileUploadEditor
        value={editorProps.template as GlobalFileObject}
        onChange={onTemplateChange}
        targetFolder="parameterTemplates"
      />
    </>
  );

  if (isCompoundTypeItemEditor) {
    return fields;
  }

  return <RowContents headerTitle="File props">{fields}</RowContents>;
};

function getNextDefaultNameIncrement(existingNames: string[], prefix: string) {
  const existingDefaultNameSuffixes = existingNames
    .filter(name => name.startsWith(prefix))
    .map(name => parseInt(name.substring(prefix.length)))
    .filter(num => !isNaN(num));
  return existingDefaultNameSuffixes.length
    ? Math.max(...existingDefaultNameSuffixes) + 1
    : 1;
}

function getBlankSheet(existingSheetNames: string[]): SheetConfiguration {
  const increment = getNextDefaultNameIncrement(existingSheetNames, 'Sheet');
  return {
    name: 'Sheet' + increment,
    columns: [getBlankColumn()],
    canAddColumns: false,
  };
}

function getBlankColumn(existingColumnNames: string[] = []): ColumnConfiguration {
  const increment = getNextDefaultNameIncrement(existingColumnNames, 'Column');
  return {
    name: 'Column' + increment,
    dataType: 'string',
    anthaType: 'string',
    displayName: null,
    editor: { type: EditorType.STRING, additionalProps: null },
    description: '' as Markdown,
    hasTrailingGap: false,
    dragToFillBehaviour: 'copy',
  };
}

const dataTypeToEditorTypes: Record<ColumnDataType, ColumnEditorType[]> = {
  string: [
    EditorType.STRING,
    EditorType.AUTOCOMPLETE,
    EditorType.DNA,
    EditorType.DROPDOWN,
    EditorType.UNIT,
    EditorType.MEASUREMENT,
    EditorType.PLATE_TYPE,
    EditorType.POLICY,
  ],
  boolean: [EditorType.CHECKBOX, EditorType.TOGGLE],
  number: [EditorType.INT, EditorType.FLOAT],
};

const SpreadsheetPropsEditor = (
  props: Omit<Props<SpreadsheetAdditionalProps>, 'isCompoundTypeItemEditor'> &
    Pick<CompoundProps<SpreadsheetAdditionalProps>, 'anthaType'>,
) => {
  const { editorProps, updateSpec } = props;
  const { editor: _, ...configuration } = editorProps;
  const { sheets, rules } = editorProps;
  const classes = useStyles();

  const updateSheetSpecAndRules = (
    sheetIndex: number,
    updatedSheetSpec: SheetConfiguration | null,
    updatedRules?: SpreadsheetRule[],
  ) => {
    const updatedSheets = [...sheets];

    if (updatedSheetSpec) {
      updatedSheets.splice(sheetIndex, 1, updatedSheetSpec);
    } else {
      updatedSheets.splice(sheetIndex, 1);
    }

    updateSpec({
      ...editorProps,
      sheets: updatedSheets,
      rules: updatedRules,
    });
  };

  const onDeleteSheet = (sheetIndex: number) => {
    updateSheetSpecAndRules(sheetIndex, null);
  };

  const sheetNames = useMemo(() => sheets.map(sheet => sheet.name), [sheets]);

  const onAddSheet = () => {
    updateSpec({
      ...editorProps,
      sheets: [...sheets, getBlankSheet(sheetNames)],
    });
  };

  const onSheetDragEnd = useCallback(
    (result: DragEndEvent) => {
      if (!result.active || !result.over) {
        return;
      }

      const originalIndex = sheetNames.indexOf(String(result.active.id));
      const newIndex = sheetNames.indexOf(String(result.over.id));

      const updatedSheets = [...sheets];
      const sheetToMove = updatedSheets.splice(originalIndex, 1)[0];
      updatedSheets.splice(newIndex, 0, sheetToMove);

      updateSpec({
        ...editorProps,
        sheets: updatedSheets,
      });
    },
    [editorProps, sheetNames, sheets, updateSpec],
  );

  const onButtonTitleChange = useTextFieldChange((newTitle: string) => {
    updateSpec({
      ...editorProps,
      buttonTitle: newTitle,
    });
  });

  return (
    <RowContents headerTitle="Spreadsheet Configuration">
      <DndContext onDragEnd={onSheetDragEnd}>
        <SortableContext items={sheetNames}>
          <div className={classes.labelWithPopover}>
            <InputLabel shrink>Button title</InputLabel>
            <IconWithPopover
              disableRestoreFocus
              icon={<InfoOutlinedIcon fontSize="small" />}
              popoverContent={
                <Typography variant="subtitle2">
                  Optional. Customise the title of the button used to open the table.
                  Default is &quot;Edit Spreadsheet&quot;.
                </Typography>
              }
            />
          </div>
          <TextField
            value={editorProps.buttonTitle}
            onChange={onButtonTitleChange}
            fullWidth
            className={classes.marginBottom}
          />
          {editorProps.sheets.map((sheet: SheetConfiguration, index: number) => (
            <SheetPropsEditor
              key={sheet.name + index}
              editorProps={sheet}
              sheetName={sheet.name}
              rules={editorProps.rules}
              requiredColumns={
                rules?.flatMap(rule =>
                  rule.actions
                    .filter(
                      action =>
                        action.type === 'set-columns-required' &&
                        action.sheetName === sheet.name,
                    )
                    .flatMap(action => action.columnNames),
                ) ?? []
              }
              otherSheetNames={sheetNames.filter(sheetName => sheetName !== sheet.name)}
              updateSpecAndRules={updateSheetSpecAndRules.bind(null, index)}
              onDelete={onDeleteSheet.bind(null, index)}
            />
          ))}
        </SortableContext>
      </DndContext>
      <Box mt={2}>
        <div className={classes.spaceBetween}>
          <Button variant="outlined" onClick={onAddSheet}>
            Add sheet
          </Button>

          <SpreadsheetEditor
            shouldReturnArray={props.anthaType ? isArrayType(props.anthaType) : false}
            value={undefined}
            configuration={configuration}
            onChange={doNothing}
            buttonTitle="Preview spreadsheet"
          />
        </div>
      </Box>
    </RowContents>
  );
};

function SheetPropsEditor(
  props: Omit<Props<SheetConfiguration>, 'updateSpec' | 'isCompoundTypeItemEditor'> & {
    otherSheetNames: string[];
    requiredColumns: string[];
    sheetName: string;
    rules: SpreadsheetRule[] | undefined;
    updateSpecAndRules: (spec: SheetConfiguration, newRules?: SpreadsheetRule[]) => void;
    onDelete: () => void;
  },
) {
  const classes = useStyles();
  const {
    editorProps: sheetProps,
    otherSheetNames,
    requiredColumns,
    rules,
    sheetName,
    onDelete,
    updateSpecAndRules,
  } = props;
  const { name, columns } = sheetProps;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: name,
  });
  const columnNames = useMemo(() => columns.map(col => col.name), [columns]);

  const onColumnDragEnd = useCallback(
    (result: DragEndEvent) => {
      if (!result.active || !result.over) {
        return;
      }
      const originalIndex = columnNames.indexOf(String(result.active.id));
      const newIndex = columnNames.indexOf(String(result.over.id));

      const updatedColumns = [...columns];
      const columnToMove = updatedColumns.splice(originalIndex, 1)[0];
      updatedColumns.splice(newIndex, 0, columnToMove);

      updateSpecAndRules({
        ...sheetProps,
        columns: updatedColumns,
      });
    },
    [columnNames, columns, sheetProps, updateSpecAndRules],
  );

  const onNameChange = (newName: string) => {
    const newRules = rules?.flatMap(rule => ({
      ...rule,
      actions: rule.actions.map(action => {
        if (action.sheetName === sheetName) {
          return { ...action, sheetName: newName };
        }
        return action;
      }),
    }));

    updateSpecAndRules(
      {
        ...sheetProps,
        name: newName,
      },
      newRules,
    );
  };

  const onAddColumn = () => {
    updateSpecAndRules({
      ...sheetProps,
      columns: [...columns, getBlankColumn(columnNames)],
    });
  };

  const updateColumnSpecAndRules = (
    columnIndex: number,
    updatedColumnSpec: ColumnConfiguration | null,
    rules?: SpreadsheetRule[],
  ) => {
    const updatedColumns = [...columns];

    if (updatedColumnSpec) {
      updatedColumns.splice(columnIndex, 1, updatedColumnSpec);
    } else {
      if (columnIndex === 0) {
        // Given the first column is being deleted, let's add a real
        // isFixed value to the new first column.
        updatedColumns[0].isFixed = false;
      }
      if (columnIndex === columns.length - 1) {
        // If the last column is being deleted, the penultimate column will
        // become the new last column, and the last column should not have
        // a trailing gap.
        const penultimateColumn = { ...updatedColumns[columns.length - 2] };
        penultimateColumn.hasTrailingGap = false;
      }
      updatedColumns.splice(columnIndex, 1);
    }

    updateSpecAndRules(
      {
        ...sheetProps,
        columns: [...updatedColumns],
      },
      rules,
    );
  };

  const onDeleteColumn = (columnIndex: number) => {
    updateColumnSpecAndRules(columnIndex, null);
  };

  const getNameError = (newName: string) => {
    if (otherSheetNames.includes(newName)) {
      return 'Sheet names must be unique';
    }

    if (!newName) {
      return 'Please specify a sheet name';
    }

    return null;
  };

  const [isCollapsed, setIsCollapsed] = useState(otherSheetNames.length > 0);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onCanAddColumnsChange = useCheckboxChange((newCanAddColumns: boolean) => {
    updateSpecAndRules({
      ...sheetProps,
      canAddColumns: newCanAddColumns,
    });
  });

  return (
    <Card
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition ?? undefined,
      }}
      className={classes.card}
    >
      <CardHeader
        avatar={<MenuIcon {...attributes} {...listeners} className={classes.pointer} />}
        title={
          <InlineTextEditor
            value={name}
            onChange={onNameChange}
            getError={getNameError}
          />
        }
        titleTypographyProps={{ variant: 'body1' }}
        action={
          otherSheetNames.length !== 0 ? (
            <>
              <IconButton onClick={toggleCollapse} size="large">
                {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
              <IconButton onClick={onDelete} size="large">
                <DeleteIcon />
              </IconButton>
            </>
          ) : null
        }
      />
      <Collapse in={!isCollapsed}>
        <CardContent>
          <Box>
            <div className={classes.labelWithPopover}>
              <InputLabel shrink>Allow adding columns?</InputLabel>
              <IconWithPopover
                disableRestoreFocus
                icon={<InfoOutlinedIcon fontSize="small" />}
                popoverContent={
                  <Typography variant="subtitle2">
                    If checked, show a button that can be used to add a column to the
                    current sheet.
                  </Typography>
                }
              />
            </div>
            <Checkbox
              checked={sheetProps.canAddColumns}
              onChange={onCanAddColumnsChange}
            />
            <DndContext onDragEnd={onColumnDragEnd}>
              <SortableContext
                items={columns.map(({ name }) => name)}
                strategy={verticalListSortingStrategy}
              >
                {columns.map((column: ColumnConfiguration, columnIndex: number) => (
                  <SheetColumnPropsEditor
                    key={column.name + columnIndex}
                    isFirstColumn={columnIndex === 0}
                    isLastColumn={columnIndex === columns.length - 1}
                    otherColumnNames={columnNames.filter(
                      colName => colName !== column.name,
                    )}
                    isRequired={requiredColumns.includes(column.name)}
                    editorProps={column}
                    sheetName={sheetName}
                    rules={rules}
                    updateSpecAndRules={updateColumnSpecAndRules.bind(null, columnIndex)}
                    onDelete={onDeleteColumn.bind(null, columnIndex)}
                  />
                ))}
              </SortableContext>
            </DndContext>
            <Box mt={2}>
              <Button variant="outlined" onClick={onAddColumn}>
                Add column
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
}
const columnDataTypeOptions = COLUMN_DATA_TYPES.map(value => ({
  label: value,
  value,
}));

function SheetColumnPropsEditor(
  props: Omit<Props<ColumnConfiguration>, 'updateSpec' | 'isCompoundTypeItemEditor'> & {
    updateSpecAndRules: (spec: ColumnConfiguration, rules?: SpreadsheetRule[]) => void;
    sheetName: string;
    rules: SpreadsheetRule[] | undefined;
    isFirstColumn: boolean;
    isLastColumn: boolean;
    otherColumnNames: string[];
    isRequired: boolean;
    onDelete: () => void;
  },
) {
  const classes = useStyles();
  const {
    isFirstColumn,
    isLastColumn,
    otherColumnNames,
    editorProps: columnProps,
    onDelete,
    isRequired,
    sheetName,
    rules,
    updateSpecAndRules,
  } = props;
  const {
    name,
    dataType,
    anthaType,
    displayName,
    description,
    editor: { type: editorType, additionalProps },
    isFixed,
    dragToFillBehaviour,
    hasTrailingGap,
  } = columnProps;

  const { typeNames: allTypeNames } = useTypeNames();
  const typeOptions = useMemo(
    () => allTypeNames.map(type => ({ label: type, value: type })) ?? [],
    [allTypeNames],
  );

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: name,
  });

  const onDeleteColumnClick = () => {
    onDelete();
  };

  const onNameChange = (newName: string) => {
    const updatedRules: SpreadsheetRule[] | undefined = rules?.map(rule => ({
      ...rule,
      actions: rule.actions.map(action => {
        if (action.columnNames.includes(name)) {
          const updatedColumnNames = [...action.columnNames];
          updatedColumnNames.splice(updatedColumnNames.indexOf(name), 1, newName);
          return { ...action, columnNames: updatedColumnNames };
        }
        return action;
      }),
    }));

    updateSpecAndRules(
      {
        ...columnProps,
        name: newName,
      },
      updatedRules,
    );
  };

  const onDisplayNameChange = useTextFieldChange((newDisplayName: string) => {
    updateSpecAndRules({
      ...columnProps,
      displayName: newDisplayName,
    });
  });

  const columnEditorTypeOptions = useMemo(
    () =>
      (dataTypeToEditorTypes?.[dataType] ?? []).map((type: EditorType) => ({
        label: getEditorTypeProperties(type)?.displayName ?? type,
        value: type,
      })),
    [dataType],
  );

  const dragToFillBehaviourOptions = useMemo(
    () =>
      dragToFillBehaviours.map((behaviour: DragToFillBehaviour) => ({
        label: behaviour,
        value: behaviour,
      })),
    [],
  );

  const onDataTypeChange = (newDataType?: ColumnDataType) => {
    if (!newDataType) {
      return;
    }
    const newEditorType = dataTypeToEditorTypes[newDataType][0];
    updateSpecAndRules({
      ...columnProps,
      dataType: newDataType,
      editor: {
        type: newEditorType,
        additionalProps: getAdditionalEditorPropsForEditorType(newEditorType) ?? null,
      },
    });
  };

  const onAnthaTypeChange = (newAnthaType?: string) => {
    updateSpecAndRules({
      ...columnProps,
      anthaType: newAnthaType ?? '',
    });
  };

  const onDescriptionChange = (newDescription: Markdown) => {
    updateSpecAndRules({
      ...columnProps,
      description: newDescription,
    });
  };

  const onEditorTypeChange = (newType?: EditorType) => {
    if (!newType) {
      return;
    }
    updateSpecAndRules({
      ...columnProps,
      editor: {
        type: newType,
        additionalProps: getAdditionalEditorPropsForEditorType(newType) ?? null,
      },
    });
  };

  const onEditorAdditionalPropsChange = (newAddititonalProps: AdditionalEditorProps) => {
    updateSpecAndRules({
      ...columnProps,
      editor: {
        ...columnProps.editor,
        additionalProps: newAddititonalProps,
      },
    });
  };

  const onHasTrailingGapChange = useCheckboxChange((newHasTrailingGap: boolean) => {
    updateSpecAndRules({
      ...columnProps,
      hasTrailingGap: newHasTrailingGap,
    });
  });

  const onIsFixedChange = useCheckboxChange((newIsFixed: boolean) => {
    updateSpecAndRules({
      ...columnProps,
      isFixed: newIsFixed,
    });
  });

  const onDragToFillBehaviourChange = (newdragToFillBehaviour?: DragToFillBehaviour) => {
    updateSpecAndRules({
      ...columnProps,
      dragToFillBehaviour: newdragToFillBehaviour ?? 'copy',
    });
  };

  const editorTypeLabel = useMemo(
    () => getEditorTypeProperties(editorType)?.displayName ?? editorType,
    [editorType],
  );

  const onIsRequiredChange = useCheckboxChange((isRequired: boolean) => {
    // For the moment insted of building out the full rules UI we're providing limited
    // functionality in the form of a checkbox which can be used to set whether a column
    // is required. Under the hood, this adds or removes a rule where the condition is
    // AlwaysTrueCondition with the action as setting a single column to be required.
    // Those assumptions are woven into this code but once the rules section is expanded
    // (TABLE-56) it will be replaced with something less brittle.
    const updatedRules = [...(rules ?? [])];

    if (!isRequired) {
      const ruleIndexToRemove = updatedRules.findIndex(rule =>
        rule.actions.some(
          action => action.sheetName === sheetName && action.columnNames.includes(name),
        ),
      );
      updatedRules.splice(ruleIndexToRemove, 1);
    } else {
      const newRule: SpreadsheetRule = {
        condition: { type: 'const-true' },
        actions: [
          {
            sheetName,
            columnNames: [name],
            type: 'set-columns-required',
            revertIfConditionNotMet: true,
          },
        ],
      };
      updatedRules.push(newRule);
    }

    updateSpecAndRules(columnProps, updatedRules);
  });

  const getNameError = (newName: string) => {
    if (otherColumnNames.includes(newName)) {
      return 'Columns must have unique names';
    }

    if (!newName) {
      return 'Please specify a column name';
    }

    return null;
  };

  const [isCollapsed, setIsCollapsed] = useState(otherColumnNames.length > 0);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Card
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition ?? undefined,
      }}
      className={classes.card}
    >
      <CardHeader
        avatar={<MenuIcon {...attributes} {...listeners} className={classes.pointer} />}
        title={
          <InlineTextEditor
            value={name}
            onChange={onNameChange}
            getError={getNameError}
          />
        }
        titleTypographyProps={{ variant: 'body1' }}
        action={
          otherColumnNames.length !== 0 ? (
            <>
              <IconButton onClick={toggleCollapse} size="large">
                {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
              <IconButton onClick={onDeleteColumnClick} size="large">
                <DeleteIcon />
              </IconButton>
            </>
          ) : null
        }
      />
      <Collapse in={!isCollapsed}>
        <CardContent>
          <div className={classes.labelWithPopover}>
            <InputLabel shrink>Display name</InputLabel>
            <IconWithPopover
              disableRestoreFocus
              icon={<InfoOutlinedIcon fontSize="small" />}
              popoverContent={
                <Typography variant="subtitle2">
                  Optional. If defined, this value will be shown on the table instead of
                  the column name defined above. Note that the latter is the one sent to
                  Antha, and has to match what the element expects. The display name is
                  useful when the column name is not human readable.
                </Typography>
              }
            />
          </div>
          <TextField
            value={displayName ?? ''}
            onChange={onDisplayNameChange}
            fullWidth
            className={classes.marginBottom}
          />

          <div className={classes.labelWithPopover}>
            <InputLabel shrink>Antha type</InputLabel>
            <IconWithPopover
              disableRestoreFocus
              icon={<InfoOutlinedIcon fontSize="small" />}
              popoverContent={
                <Typography variant="subtitle2">
                  Optional. If provided, cell values from this column will be added to the
                  Autocomplete pool for this Antha Type. E.g. if `/wtype.Liquid` is
                  selected, every element in the workflow with an Autocomplete with the
                  same Antha Type will suggest values from this column. Likewise, if this
                  column is an Autocomplete, values from any element&quot;s parameters
                  with the same type will be suggested in the table.
                </Typography>
              }
            />
          </div>
          <Autocomplete
            valueLabel={anthaType}
            options={typeOptions}
            onChange={onAnthaTypeChange}
            className={classes.marginBottom}
            fullWidth
          />

          <div className={classes.labelWithPopover}>
            <InputLabel shrink>Data type</InputLabel>
            <IconWithPopover
              disableRestoreFocus
              icon={<InfoOutlinedIcon fontSize="small" />}
              popoverContent={
                <Typography variant="subtitle2">
                  The expected type of data for each cell in this column. Changing this
                  value will change the options available in the &quot;Input type&quot;
                  dropdown below.
                </Typography>
              }
            />
          </div>
          <Dropdown<ColumnDataType>
            valueLabel={dataType ?? ''}
            placeholder="Select a data type"
            isRequired
            options={columnDataTypeOptions}
            onChange={onDataTypeChange}
            className={classes.marginBottom}
          />

          <div className={classes.labelWithPopover}>
            <InputLabel shrink>Input type</InputLabel>
            <IconWithPopover
              disableRestoreFocus
              icon={<InfoOutlinedIcon fontSize="small" />}
              popoverContent={
                <Typography variant="subtitle2">
                  The UI component to be rendered in each cell.
                </Typography>
              }
            />
          </div>
          <Dropdown<EditorType>
            valueLabel={editorTypeLabel ?? ''}
            placeholder="Select an editor type"
            isRequired
            options={columnEditorTypeOptions}
            onChange={onEditorTypeChange}
            className={classes.marginBottom}
          />

          {additionalProps && (
            <Box mb={2}>
              <AdditionalPropsEditor
                editorProps={additionalProps}
                updateSpec={onEditorAdditionalPropsChange}
                isCompoundTypeItemEditor
              />
            </Box>
          )}

          <div className={classes.labelWithPopover}>
            <InputLabel shrink>Drag to fill behaviour</InputLabel>
            <IconWithPopover
              disableRestoreFocus
              icon={<InfoOutlinedIcon fontSize="small" />}
              popoverContent={
                <Typography variant="subtitle2">
                  Determine what happens when users drag to fill using the handle in the
                  bottom right corner of a selected cell. The default is copying (e.g.
                  Sample1, Sample1). &quot;Incremental&quot; increments the number (e.g.
                  Sample1, Sample2).
                </Typography>
              }
            />
          </div>
          <Dropdown<DragToFillBehaviour>
            valueLabel={dragToFillBehaviour ?? 'copy'}
            placeholder="Select an editor type"
            options={dragToFillBehaviourOptions}
            onChange={onDragToFillBehaviourChange}
            className={classes.marginBottom}
          />

          <Box mb={1}>
            <InputLabel shrink>Description</InputLabel>
            <MarkdownEditor value={description} onChange={onDescriptionChange} />
          </Box>

          <div className={classes.labelWithPopover}>
            <InputLabel shrink>Is required?</InputLabel>
            <IconWithPopover
              disableRestoreFocus
              icon={<InfoOutlinedIcon fontSize="small" />}
              popoverContent={
                <Typography variant="subtitle2">
                  If checked, the column will be marked as required.
                </Typography>
              }
            />
          </div>
          <Checkbox checked={isRequired} onChange={onIsRequiredChange} />

          {isFirstColumn && (
            <>
              <div className={classes.labelWithPopover}>
                <InputLabel shrink>Fix column?</InputLabel>
                <IconWithPopover
                  disableRestoreFocus
                  icon={<InfoOutlinedIcon fontSize="small" />}
                  popoverContent={
                    <Typography variant="subtitle2">
                      If checked, this column will stick to the left when scrolling
                      horizontally.
                    </Typography>
                  }
                />
              </div>
              <Checkbox checked={isFixed} onChange={onIsFixedChange} />
            </>
          )}

          {!isLastColumn && (
            <>
              <div className={classes.labelWithPopover}>
                <InputLabel shrink>Add trailing gap?</InputLabel>
                <IconWithPopover
                  disableRestoreFocus
                  icon={<InfoOutlinedIcon fontSize="small" />}
                  popoverContent={
                    <Typography variant="subtitle2">
                      If checked, adds a visual gap after this column. Useful for grouping
                      related columns together.
                    </Typography>
                  }
                />
              </div>

              <Checkbox checked={hasTrailingGap} onChange={onHasTrailingGapChange} />
            </>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
}

type NestedPropsEditorProps = {
  sectionName: 'Key' | 'Value' | 'Item';
  editorSpec: ParameterEditorConfigurationSpec;
  anthaType: string;
  updateEditorSpec: (newSpec: ParameterEditorConfigurationSpec) => void;
  typeConfigurations: Record<string, TypeConfigurationSpec | undefined>;
};

/**
 * Used to configure sub-editors that are nested with the additional props of
 * top-level editors, i.e. for array items and map keys and values.
 */
function NestedPropsEditor({
  sectionName,
  editorSpec,
  anthaType,
  updateEditorSpec,
  typeConfigurations,
}: NestedPropsEditorProps) {
  const classes = useStyles();
  const { type: editorType, placeholder, additionalProps } = editorSpec;

  const editorTypeProperties = getEditorTypeProperties(editorType);

  const typeConfiguration = typeConfigurations[anthaType];
  let editorTypes = getEditorChoicesFromAnthaType(anthaType, typeConfiguration);
  editorTypes = editorTypes?.length > 0 ? editorTypes : BACKUP_EDITOR_OPTIONS;

  const editorTypeOptionValues = editorTypes?.filter(
    type => type !== EditorType.CONNECTION_ONLY,
  );

  const editorTypeOptions = editorTypeOptionValues.map(makeEditorTypeOption);

  function onEditorTypeChange(type?: EditorType) {
    if (!type) {
      return;
    }
    const newAdditionalProps = getAdditionalEditorPropsForEditorType(type, anthaType);
    updateEditorSpec({
      ...editorSpec,
      type,
      additionalProps: newAdditionalProps,
    });
  }

  const onPlaceholderChange = useTextFieldChange((placeholder: string) => {
    updateEditorSpec({
      ...editorSpec,
      placeholder,
    });
  });

  function onAdditionalPropsChange(additionalProps: AdditionalEditorProps) {
    updateEditorSpec({
      ...editorSpec,
      additionalProps,
    });
  }

  return (
    <Box>
      <Typography gutterBottom className={classes.propertiesHeader}>
        {sectionName} properties
      </Typography>
      <InputLabel shrink>Editor type</InputLabel>
      <Dropdown<EditorType>
        valueLabel={editorTypeProperties?.displayName ?? editorType ?? ''}
        placeholder="Select an editor type"
        isRequired
        isDisabled={editorTypeOptions.length < 2}
        options={editorTypeOptions}
        onChange={onEditorTypeChange}
        className={classes.marginBottom}
      />
      {editorTypeProperties?.canSetPlaceholder && (
        <>
          <InputLabel shrink>Placeholder</InputLabel>
          <TextField
            fullWidth
            value={placeholder}
            onChange={onPlaceholderChange}
            className={classes.marginBottom}
          />
        </>
      )}
      {additionalProps && (
        <AdditionalPropsEditor
          editorProps={additionalProps}
          updateSpec={onAdditionalPropsChange}
          anthaType={anthaType}
          isCompoundTypeItemEditor
          typeConfigurations={typeConfigurations}
        />
      )}
    </Box>
  );
}

function PlateContentsPropsEditor({
  editorProps,
  updateSpec,
  anthaType,
  typeConfigurations,
}: Omit<CompoundProps<PlateContentsAdditionalProps>, 'isCompoundTypeItemEditor'>) {
  const classes = useStyles();

  const updateSpecValueEditor = useCallback(
    (valueEditor: ParameterEditorConfigurationSpec) => {
      updateSpec({ ...editorProps, contentEditor: valueEditor });
    },
    [editorProps, updateSpec],
  );

  const onTitleTemplateChange = useTextFieldChange((wellGroupTitleTemplate: string) => {
    updateSpec({ ...editorProps, wellGroupTitleTemplate });
  });

  const handleDoNotGroupChange = useCheckboxChange((isChecked: boolean) => {
    updateSpec({ ...editorProps, ignoreWhenGrouping: isChecked });
  });

  if (!anthaType) {
    return (
      <p>
        The plate contents editor properties cannot be configured as there was a problem
        finding the antha type for this parameter.
      </p>
    );
  }

  const keyAnthaType = getKeyTypeFromAnthaType(anthaType);

  return (
    <>
      <TableCell>
        <InputLabel shrink>Plate Contents props</InputLabel>
      </TableCell>
      <TableCell className={classes.fullWidth}>
        <Box mb={2}>
          <InputLabel shrink>Well title template</InputLabel>
          <TextField
            fullWidth
            value={editorProps.wellGroupTitleTemplate}
            onChange={onTitleTemplateChange}
            placeholder="{{ParameterName1}} {{ParameterName2}}"
            helperText="
              Specify a title to display for each group of wells in the plate contents
              editor. A parameter name in double braces will be replaced by the parameter
              value for that well. For example, if there is a Volume map parameter,
              {{Volume}} will be replaced by the Volume for a given well.
            "
          />
        </Box>
        <Tooltip
          title="
            If checked, wells within a group may have different values for this parameter.
            When editing a group of wells, this parameter will show as a map editor
            allowing a value to be set per well.
          "
        >
          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={editorProps.ignoreWhenGrouping}
                  onChange={handleDoNotGroupChange}
                />
              }
              label="Ignore when grouping wells"
            />
          </Box>
        </Tooltip>
        {editorProps.contentEditor && (
          <NestedPropsEditor
            sectionName="Value"
            anthaType={keyAnthaType}
            editorSpec={editorProps.contentEditor}
            updateEditorSpec={updateSpecValueEditor}
            typeConfigurations={typeConfigurations}
          />
        )}
      </TableCell>
    </>
  );
}

const RowContents = ({
  headerTitle,
  children,
}: {
  headerTitle: string;
  children: React.ReactNode;
}) => {
  return (
    <>
      <TableCell>
        <InputLabel shrink>{headerTitle}</InputLabel>
      </TableCell>
      <TableCell>{children}</TableCell>
    </>
  );
};

function makeEditorTypeOption(editorType: EditorType) {
  return {
    label: getEditorTypeProperties(editorType)?.displayName ?? editorType,
    value: editorType,
  };
}

const useStyles = makeStylesHook(theme => ({
  marginBottom: { marginBottom: '16px' },
  fullWidth: { width: '100%' },
  propertiesHeader: {
    color: Colors.GREY_60,
    fontSize: '0.85rem',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
  },
  dropdownOptionEditor: {
    'margin-bottom': '15px',
  },
  dropdownOptionTextField: {
    width: '300px',
    'margin-right': '10px',
  },
  measurementTextField: {
    'overflow-wrap': 'normal',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    marginBottom: theme.spacing(6),
  },
  pointer: {
    cursor: 'pointer',
  },
  labelWithPopover: {
    display: 'flex',
    alignItems: ' center',
  },
}));
