import React, { useState } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import { ADD_ORGANISATION, GET_ORGANISATIONS } from 'admin-client/app/api/gql/queries';
import { NewOrganisationDialog } from 'admin-client/app/components/Organisations/NewOrganisationDialog';
import { OrganisationLink } from 'admin-client/app/components/Organisations/OrganisationLink';
import { HasPermission } from 'admin-client/app/components/Permissions';
import { ArrayElement, getOrganisationsQuery } from 'admin-client/app/gql';
import { useDialogManager } from 'common/ui/components/DialogManager';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import useCheckboxChange from 'common/ui/hooks/useCheckboxChange';

type GraphQLOrganisation = ArrayElement<getOrganisationsQuery['organisations']>;

export default function OrganisationsTableContainer() {
  const classes = useStyles();
  const dialogManager = useDialogManager();
  const snackbarManager = useSnackbarManager();
  const [addOrganisationMutation] = useMutation(ADD_ORGANISATION, {
    onError: (error: ApolloError) => {
      console.error(error);
      snackbarManager.showError(error.message);
    },
  });

  const { loading, error, data } = useQuery(GET_ORGANISATIONS);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  const handleAddOrganisationButtonClick = async () => {
    const newOrg = await dialogManager.openDialogPromise(
      'ADD_ORGANISATION',
      NewOrganisationDialog,
      {},
    );
    if (newOrg !== null) {
      await addOrganisationMutation({
        variables: newOrg,
        refetchQueries: [
          {
            query: GET_ORGANISATIONS,
          },
        ],
      });
    }
  };

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Organisations
      </Typography>
      <HasPermission permission="create:organisations">
        <section className={classes.header}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddOrganisationButtonClick}
            startIcon={<AddIcon />}
          >
            Add Organisation
          </Button>
        </section>
      </HasPermission>
      {!data && <div>Error! Data is undefined</div>}
      {data && <OrganisationsTable organisations={data.organisations} />}
    </>
  );
}

type Props = {
  organisations: readonly GraphQLOrganisation[];
};

const OrganisationsTable = React.memo(function OrganisationsTable(props: Props) {
  const classes = useStyles();
  const { organisations } = props;
  const [showUnused, setShowUnused] = useState(false);
  const [showInternal, setShowInternal] = useState(false);

  const toggleShowUnused = useCheckboxChange(setShowUnused);
  const toggleShowInternal = useCheckboxChange(setShowInternal);

  // By default we'll hide organisations that aren't enabled in any
  // environments.
  const organisationsToDisplay = organisations
    .filter(o => showUnused || o.environmentConfigs?.some(ec => ec.isEnabled))
    .filter(o => showInternal || !o.isSynthaceInternal);

  return (
    <section style={{ display: 'block' }}>
      <div>
        <FormControlLabel
          control={<Checkbox checked={showUnused} onChange={toggleShowUnused} />}
          label="Show unused organisations"
        />
        <FormControlLabel
          control={<Checkbox checked={showInternal} onChange={toggleShowInternal} />}
          label="Show Synthace internal organisations"
        />
      </div>
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Name</Typography>
              </TableCell>
              <TableCell>
                <Typography>Human Identifier</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organisationsToDisplay.map(org => {
              return <OrganisationRow key={org.id} org={org} classes={classes} />;
            })}
          </TableBody>
        </Table>
      </Paper>
    </section>
  );
});

type OrganisationRowProps = {
  org: GraphQLOrganisation;
} & WithStyles<any>;

const OrganisationRow = React.memo((props: OrganisationRowProps) => {
  const { org } = props;

  return (
    <TableRow key={org.id}>
      <TableCell component="th" scope="row">
        <Typography>
          <OrganisationLink org={org} />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{org.humanIdentifier}</Typography>
      </TableCell>
    </TableRow>
  );
});

const useStyles = makeStyles({
  header: {
    paddingBottom: '1rem',
  },
});
