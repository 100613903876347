import React from 'react';

import { Route } from 'react-router-dom';

import AnthaHubReleasesContainer from 'admin-client/app/components/AnthaHub/AnthaHubReleases';
import AnthaHubMetricsContainer from 'admin-client/app/components/AnthaHub/AnthaHubsTable';
import ElementConfigurationContainer from 'admin-client/app/components/ElementConfiguration/ElementConfigurationContainer';
import TypeConfigurationContainer from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/TypeConfigurationContainer';
import EnvironmentDetailsContainer from 'admin-client/app/components/Environments/EnvironmentDetails';
import { ExampleWorkflowsScreen } from 'admin-client/app/components/ExampleWorkflows';
import FeatureTogglesContainer from 'admin-client/app/components/FeatureToggles/FeatureTogglesView';
import LandingPageContainer from 'admin-client/app/components/LandingPage/LandingPage';
import OnlineMigrationDetailsContainer from 'admin-client/app/components/OnlineMigrations/OnlineMigrationDetails';
import OnlineMigrationsContainer from 'admin-client/app/components/OnlineMigrations/OnlineMigrations';
import OrganisationEnvironmentDetailsContainer from 'admin-client/app/components/OrganisationEnvironments/OrganisationEnvironmentDetails';
import OrganisationDetailsContainer from 'admin-client/app/components/Organisations/OrganisationDetails';
import OrganisationsTableContainer from 'admin-client/app/components/Organisations/OrganisationsTable';
import PlateTypesContainer from 'admin-client/app/components/PlateTypes/PlateTypes';
import RedirectsTableContainer from 'admin-client/app/components/Redirectinator/RedirectsTable';
import UserDetailsContainer from 'admin-client/app/components/Roles/UserRolesDetails';
import { TutorialsScreen } from 'admin-client/app/components/Tutorials';
import { ROUTES } from 'admin-common/src/routing/routes';

export default function ScreensRoute() {
  return (
    <div>
      <Route exact path={ROUTES.HOME.pathTemplate} component={LandingPageContainer} />
      <Route
        exact
        path={ROUTES.ANTHA_HUB.METRICS.pathTemplate}
        component={AnthaHubMetricsContainer}
      />
      <Route
        exact
        path={ROUTES.ANTHA_HUB.RELEASES.pathTemplate}
        component={AnthaHubReleasesContainer}
      />
      <Route
        exact
        path={ROUTES.ENVIRONMENTS.VIEW.pathTemplate}
        component={EnvironmentDetailsContainer}
      />
      <Route
        exact
        path={ROUTES.ORGANIZATIONS.ROOT.pathTemplate}
        component={OrganisationsTableContainer}
      />
      <Route
        exact
        path={ROUTES.ORGANIZATIONS.VIEW.pathTemplate}
        component={OrganisationDetailsContainer}
      />
      <Route
        exact
        path={ROUTES.ORGANIZATIONS.ENV_DEPLOYMENT.pathTemplate}
        component={OrganisationEnvironmentDetailsContainer}
      />
      <Route
        exact
        path={ROUTES.FEATURETOGGLES.ROOT.pathTemplate}
        component={FeatureTogglesContainer}
      />
      <Route
        exact
        path={ROUTES.PLATE_TYPES.ROOT.pathTemplate}
        component={PlateTypesContainer}
      />
      <Route exact path={ROUTES.TUTORIALS.pathTemplate} component={TutorialsScreen} />
      <Route
        exact
        path={ROUTES.EXAMPLE_WORKFLOWS.pathTemplate}
        component={ExampleWorkflowsScreen}
      />
      <Route
        exact
        path={ROUTES.REDIRECTINATOR.pathTemplate}
        component={RedirectsTableContainer}
      />
      <Route
        path={ROUTES.ELEMENT_CONFIGURATION.ROOT.pathTemplate}
        component={ElementConfigurationContainer}
      />
      <Route
        path={ROUTES.TYPE_CONFIGURATION.ROOT.pathTemplate}
        component={TypeConfigurationContainer}
      />
      <Route
        exact
        path={ROUTES.MIGRATIONS.pathTemplate}
        component={OnlineMigrationsContainer}
      />
      <Route
        exact
        path={ROUTES.ROLES.VIEW.pathTemplate}
        component={UserDetailsContainer}
      />
      <Route
        exact
        path="/migration/:migrationName"
        component={OnlineMigrationDetailsContainer}
      />
    </div>
  );
}
