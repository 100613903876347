import React from 'react';

import Typography from '@mui/material/Typography';

import { ConfigurationType } from 'common/types/commonConfiguration';

type Props = {
  configurationType: ConfigurationType;
};

const DISPLAY_NAME = {
  [ConfigurationType.GLOBAL]: 'Global',
  [ConfigurationType.SINGLE_BRANCH]: 'Single branch',
};

const TITLE = {
  [ConfigurationType.GLOBAL]:
    'This configuration would impact the master branch on all environments from the selected commit date going forward. \n\nIt should be used for all merged changes and any customer facing edits needed.',
  [ConfigurationType.SINGLE_BRANCH]:
    "This configuration would impact a single branch from the selected commit date going forward. \n\nIt should be used for dev branches, which haven't been merged to master yet.",
};

export function ConfigurationTypeLabel({ configurationType }: Props) {
  return (
    <Typography display="inline" title={TITLE[configurationType]}>
      {DISPLAY_NAME[configurationType]}
    </Typography>
  );
}
