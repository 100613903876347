import React, { ChangeEvent, useCallback, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { EnvironmentDomainPrefixRegex } from 'admin-common/src/constants';
import DebouncedTextField from 'common/ui/components/DebouncedTextField';

type NewEnvironmentArgs = {
  domainPrefix: string;
};

type DomainStatus = 'init' | 'exists' | 'checking' | 'does-not-exist';

type Props = {
  onClose: (newEnvironment: NewEnvironmentArgs | null) => void;
};

export const NewEnvironmentDialog = React.memo((props: Props) => {
  const classes = useStyles();
  const { onClose } = props;
  const [domainPrefix, setDomainPrefix] = useState('');
  const [domainStatus, setDomainStatus] = useState<DomainStatus>('init');

  const domainPrefixIsValid = EnvironmentDomainPrefixRegex.test(domainPrefix);

  const handleOK = useCallback(() => {
    if (domainPrefixIsValid) {
      const newEnvArgs: NewEnvironmentArgs = {
        domainPrefix: domainPrefix.trim(),
      };
      onClose(newEnvArgs);
    }
  }, [domainPrefix, domainPrefixIsValid, onClose]);

  const handleCancel = () => {
    onClose(null);
  };

  const handleDomainPrefixChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const prefix = event.target.value;
      setDomainPrefix(prefix);
      setDomainStatus(prefix.length > 0 ? 'checking' : 'init');
    },
    [setDomainPrefix, setDomainStatus],
  );

  const checkNewDomainPrefix = useCallback(
    (value: string) => {
      if (value.length === 0) {
        return;
      }
      const url = `https://${value}.antha.com/`;
      fetch(url, { mode: 'no-cors' })
        .then(() => setDomainStatus('exists'))
        .catch(() => setDomainStatus('does-not-exist'));
    },
    [setDomainStatus],
  );

  return (
    <Dialog open onClose={handleCancel} maxWidth="xs">
      <DialogTitle>Add Environment</DialogTitle>
      <DialogContent className={classes.content}>
        <form>
          <DebouncedTextField
            required
            error={
              (domainPrefix.length > 0 && !domainPrefixIsValid) ||
              domainStatus === 'does-not-exist'
            }
            className={classes.textField}
            label="Domain name"
            placeholder="e.g. bloggsinc"
            id="new-env-domain-prefix"
            helperText="Lower-case alphanumeric characters only, no whitespace or punctuation. The domain must already exist."
            value={domainPrefix}
            onChange={handleDomainPrefixChange}
            onDebouncedChange={checkNewDomainPrefix}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">.antha.com</InputAdornment>,
            }}
          />
        </form>
        {domainStatus === 'does-not-exist' && (
          <Typography>
            {domainPrefix}.antha.com does not exist. The SRE team must create the
            environment in sauron before it can be added here.
          </Typography>
        )}
      </DialogContent>
      <DialogActions data-test="confirmationDialogActions">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          disabled={!domainPrefixIsValid || domainStatus !== 'exists'}
          onClick={handleOK}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const useStyles = makeStyles({
  content: {
    whiteSpace: 'pre-line',
  },
  textField: {
    marginBottom: '1rem',
  },
  select: {
    minWidth: '300px',
  },
});
