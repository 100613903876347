import React, { useCallback, useMemo } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import { AreParametersConnectedConditionEditor } from 'admin-client/app/components/ElementConfiguration/Card/rules/conditions/AreParametersConnectedConditionEditor';
import { AreParametersSetConditionEditor } from 'admin-client/app/components/ElementConfiguration/Card/rules/conditions/AreParametersSetConditionEditor';
import { ListConditionEditor } from 'admin-client/app/components/ElementConfiguration/Card/rules/conditions/ListConditionEditor';
import { NotConditionEditor } from 'admin-client/app/components/ElementConfiguration/Card/rules/conditions/NotConditionEditor';
import { ParameterValueCompareConditionEditor } from 'admin-client/app/components/ElementConfiguration/Card/rules/conditions/ParameterValueCompareConditionEditor';
import { APIElement } from 'common/types/api';
import { ElementConfigurationCondition as Condition } from 'common/types/elementConfiguration';
import { TypeConfigurationSpec } from 'common/types/typeConfiguration';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type ConditionTypeOption<T extends Condition> = {
  label: string;
  type: T['type'];
  editor: React.FunctionComponent<ConditionEditorProps & { condition: T }>;
  renderStyle: 'inline' | 'new-row';
  getDefaultCondition: (props: ConditionEditorProps) => T;
};

const OPTIONS: ConditionTypeOption<any>[] = [
  {
    type: 'const-true',
    label: 'Always',
    editor: () => null,
    renderStyle: 'inline',
    getDefaultCondition: () => ({ type: 'const-true' }),
  },
  {
    type: 'are-parameters-set',
    label: 'Parameters are set',
    editor: AreParametersSetConditionEditor,
    renderStyle: 'inline',
    getDefaultCondition: ({ defaultParameterName }) => ({
      type: 'are-parameters-set',
      parameterNames: defaultParameterName ? [defaultParameterName] : [],
    }),
  },
  {
    type: 'are-parameters-connected',
    label: 'Parameters are connected',
    editor: AreParametersConnectedConditionEditor,
    renderStyle: 'inline',
    getDefaultCondition: ({ defaultParameterName }) => ({
      type: 'are-parameters-connected',
      parameterNames: defaultParameterName ? [defaultParameterName] : [],
    }),
  },
  {
    type: 'parameter-value-compare',
    label: 'Parameter value comparison',
    editor: ParameterValueCompareConditionEditor,
    renderStyle: 'new-row',
    getDefaultCondition: ({ defaultParameterName }) => ({
      type: 'parameter-value-compare',
      parameterName: defaultParameterName,
      operator: 'equals',
      value: { type: 'const', constValue: undefined },
    }),
  },
  {
    type: 'not',
    label: 'Not',
    editor: NotConditionEditor,
    renderStyle: 'new-row',
    getDefaultCondition: () => ({
      type: 'not',
      childCondition: { type: 'const-true' },
    }),
  },
  {
    type: 'list',
    label: 'List',
    editor: ListConditionEditor,
    renderStyle: 'new-row',
    getDefaultCondition: () => ({
      type: 'list',
      operator: 'or',
      childConditions: [{ type: 'const-true' }],
    }),
  },
];

export type ConditionEditorProps = {
  condition: Condition;
  defaultParameterName?: string;
  element: APIElement;
  index: number;
  isRoot: boolean;
  typeConfigurations?: Record<string, TypeConfigurationSpec>;
  onConditionChange: (condition: Condition, index: number) => void;
  onConditionRemove?: (index: number) => void;
};

export function ConditionEditor(props: ConditionEditorProps) {
  const classes = useStyles();
  const {
    condition,
    isRoot,
    index,
    defaultParameterName,
    element,
    typeConfigurations,
    onConditionChange,
    onConditionRemove,
  } = props;

  const onConditionTypeChange = useCallback(
    (_event: any, option: ConditionTypeOption<any> | null) => {
      if (!option) {
        return;
      }
      onConditionChange(option.getDefaultCondition(props), index);
    },
    [index, onConditionChange, props],
  );

  const onRemoveClick = useCallback(
    () => onConditionRemove?.(index),
    [index, onConditionRemove],
  );

  const selectedOption = useMemo(
    () => OPTIONS.find(option => option.type === condition.type)!,
    [condition.type],
  );

  if (!selectedOption) {
    return null;
  }

  const Editor = selectedOption.editor;

  /**
   * Some editors are displayed inline (on the same line) with their parent editor,
   * others are better displayed on separate lines.
   */
  const renderInline = selectedOption.renderStyle === 'inline';

  return (
    <Grid container direction="column" alignItems="stretch">
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {!isRoot && (
            <Grid item>
              <Autocomplete
                options={OPTIONS}
                value={selectedOption}
                renderInput={params => <TextField {...params} variant="outlined" />}
                getOptionLabel={getOptionLabel}
                disableClearable
                onChange={onConditionTypeChange}
                size="small"
                className={classes.typeSelector}
              />
            </Grid>
          )}

          {renderInline && (
            <Grid item xs>
              <Editor
                condition={condition}
                defaultParameterName={defaultParameterName}
                index={index}
                isRoot={false}
                element={element}
                typeConfigurations={typeConfigurations}
                onConditionChange={onConditionChange}
              />
            </Grid>
          )}

          {onConditionRemove && (
            <Grid item>
              <IconButton onClick={onRemoveClick} size="large">
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      {!renderInline && (
        <Grid item xs>
          <Editor
            condition={condition}
            defaultParameterName={defaultParameterName}
            index={index}
            isRoot={false}
            element={element}
            typeConfigurations={typeConfigurations}
            onConditionChange={onConditionChange}
          />
        </Grid>
      )}
    </Grid>
  );
}

function getOptionLabel<T extends Condition>(option: ConditionTypeOption<T>): string {
  return option.label;
}

const useStyles = makeStylesHook({
  typeSelector: {
    minWidth: '275px',
    marginRight: '1rem',
  },
});
