import React, { useCallback, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { GET_ELEMENT_VERSION, GET_ELEMENTS } from 'admin-client/app/api/gql/queries';
import {
  getElementsQuery as GraphQLElements,
  getElementVersionQuery as GraphQLElementVersion,
} from 'admin-client/app/gql';
import { Commit } from 'common/types/commonConfiguration';
import { ElementName, ParameterName } from 'common/types/elementConfiguration';
import Colors from 'common/ui/Colors';
import { DialogProps } from 'common/ui/components/DialogManager';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  currentCommit: Commit;
  onClose: (result: ElementAndParameter | null) => void;
} & DialogProps;

const ElementAndParameterSelector = ({ onClose, currentCommit }: Props) => {
  const classes = useStyles();

  const [selectedElement, setSelectedElement] = useState<ElementName | null>(null);
  const [selectedParameter, setSelectedParameter] = useState<ParameterName | null>(null);

  // When selectedElement is updated, we want to reset selectedParameter
  const updateSelectedElementAndParameter = (value: ElementName | null) => {
    setSelectedElement(value);
    setSelectedParameter(null);
  };

  const onElementChange = useCallback(
    (_event: any, value: ElementName | null) =>
      value && updateSelectedElementAndParameter(value),
    [],
  );

  const onParameterChange = useCallback(
    (_event: any, value: ParameterName | null) => value && setSelectedParameter(value),
    [],
  );

  /**
   * We need to grab all other elements on this commit to display their
   * names and then get their parameter data
   * */
  const { data: elementsData, loading: loadingElements } = useQuery(GET_ELEMENTS, {
    variables: {
      commitHash: currentCommit.commitHash,
      commitBranch: currentCommit.commitBranch,
      commitDate: currentCommit.commitDate.toISOString(),
    },
  });

  /**
   * If no data is returned, the commit likely no longer exists.
   */
  const commitNoLongerExists = !loadingElements && !elementsData;

  const { data: elementVersionData, loading: loadingParameters } = useQuery(
    GET_ELEMENT_VERSION,
    {
      variables: {
        elementName: selectedElement ?? '',
        commitHash: currentCommit.commitHash ?? '',
      },
    },
  );

  /* We dont show configured names - only original element and parameter names.
   * If a global configuration is applied that changes names, this then means
   * its hard to distinguish these names for the user, but they should be aware
   * of the original element names.
   */
  const elementList = useMemo(() => getElementNames(elementsData), [elementsData]);
  const parametersList = useMemo(
    () => getParameterNames(elementVersionData),
    [elementVersionData],
  );

  const currentSelection = useMemo(() => {
    return { element: selectedElement, parameter: selectedParameter };
  }, [selectedElement, selectedParameter]);

  const onConfirm = useCallback(
    () => onClose(currentSelection),
    [currentSelection, onClose],
  );
  const onCancel = useCallback(() => onClose(null), [onClose]);

  return (
    <Dialog open maxWidth="sm" fullWidth onClose={onCancel}>
      <DialogTitle>Select element and parameter names</DialogTitle>
      {commitNoLongerExists && (
        <DialogContent className={classes.error}>
          The current configuration is not updated to reflect the latest commits on the
          branch, so up-to-date element data cannot be retrieved. Apply the configuration
          to the latest commit to use the NameLink.
        </DialogContent>
      )}
      <DialogContent>Select an element name</DialogContent>
      <Box marginBottom="1rem" marginTop="1rem">
        <Autocomplete
          className={classes.autocomplete}
          disableClearable
          disabled={commitNoLongerExists}
          loading={loadingElements}
          renderInput={params => (
            <TextField {...params} label="Element" variant="outlined" />
          )}
          onChange={onElementChange}
          options={elementList}
          value={selectedElement ?? undefined}
        />
      </Box>
      <DialogContent>Parameter name selection is optional</DialogContent>
      <Box marginBottom="1rem" marginTop="1rem">
        <Autocomplete
          className={classes.autocomplete}
          disableClearable
          disabled={!selectedElement}
          loading={loadingParameters}
          renderInput={params => (
            <TextField {...params} label="Parameter" variant="outlined" />
          )}
          onChange={onParameterChange}
          options={loadingParameters ? [] : parametersList}
          value={loadingParameters || !selectedParameter ? undefined : selectedParameter}
        />
      </Box>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button disabled={commitNoLongerExists} onClick={onConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ElementAndParameter = {
  element: ElementName | null;
  parameter: ParameterName | null;
};

function getElementNames(elementsData: GraphQLElements | undefined): ElementName[] {
  if (!elementsData) {
    return [];
  }
  return elementsData?.elements.map(e => {
    return e.name;
  });
}

function getParameterNames(element: GraphQLElementVersion | undefined): ParameterName[] {
  if (!element) {
    return [];
  }
  return [...element.element.version.in_ports, ...element.element.version.out_ports].map(
    p => {
      return p.name as ParameterName;
    },
  );
}

const useStyles = makeStylesHook({
  autocomplete: {
    width: '325px',
    margin: 'auto',
    alignItems: 'center',
  },
  error: {
    color: Colors.ERROR,
  },
});

export default ElementAndParameterSelector;
