import { useMemo } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import moment, { Moment } from 'moment';

import { GET_ELEMENT } from 'admin-client/app/api/gql/queries';
import { ArrayElement, getElementQuery } from 'admin-client/app/gql';
import { ElementName } from 'common/types/elementConfiguration';

type GraphQLElementConfigurationRaw = ArrayElement<
  getElementQuery['element']['configurations']
>;

type Result = {
  configurations: readonly GraphQLElementConfiguration[];
  loading: boolean;
  error?: ApolloError;
};

/**
 * Shortcut for fetching element configurations, as it is used in multiple places.
 * This hook will also convert the raw GraphQL data to GraphElementConfiguration so
 * the client components can work with a convenient data format, like string dates
 * converted to Moment.
 */
export function useElementConfigurations(elementName: ElementName): Result {
  const { data, loading, error } = useQuery(GET_ELEMENT, {
    variables: { elementName },
  });
  const configurations = useMemo(() => convertData(data), [data]);
  return { loading, error, configurations };
}

function convertData(
  data: getElementQuery | undefined,
): readonly GraphQLElementConfiguration[] {
  return data?.element.configurations.map(convertConfiguration) ?? [];
}

function convertConfiguration(
  configuration: GraphQLElementConfigurationRaw,
): GraphQLElementConfiguration {
  return {
    ...configuration,
    commitDate: moment(configuration.commitDate),
    createdAt: moment(configuration.createdAt),
    lastModifiedAt: moment(configuration.lastModifiedAt),
  };
}

export type GraphQLElementConfiguration = Omit<
  GraphQLElementConfigurationRaw,
  'commitDate' | 'createdAt' | 'lastModifiedAt'
> & {
  commitDate: Moment;
  createdAt: Moment;
  lastModifiedAt: Moment;
};
