import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { RouteComponentProps } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import {
  GET_ELEMENT_DEFAULTS,
  GET_ELEMENT_VERSION,
} from 'admin-client/app/api/gql/queries';
import { EditorElementConfiguration } from 'admin-client/app/components/ElementConfiguration/editorTypes';
import { ElementConfigurationCard } from 'admin-client/app/components/ElementConfiguration/ElementConfigurationCard';
import { useAllCurrentTypeConfigurations } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useAllCurrentTypeConfigurations';
import { useCommit } from 'admin-client/app/components/ElementConfiguration/useCommit';
import { createConfigurationSpec } from 'common/elementConfiguration/createConfigurationSpec';
import { APIElement } from 'common/types/api';
import { ParameterValueDict } from 'common/types/bundle';
import { Commit, ConfigurationType } from 'common/types/commonConfiguration';
import { ElementName } from 'common/types/elementConfiguration';
import { TypeConfigurationSpec } from 'common/types/typeConfiguration';

type Props = RouteComponentProps<{
  elementName: ElementName;
  commitBranch: string;
  commitHash: string;
  configurationType: ConfigurationType;
}>;

/**
 * Page for creating new element configuration.
 */
export function CreateElementConfiguration({ match }: Props) {
  const {
    elementName,
    commitHash,
    commitBranch: encodedCommitBranch,
    configurationType,
  } = match.params;
  const commitBranch = decodeURIComponent(encodedCommitBranch);

  const [configuration, setConfiguration] = useState<EditorElementConfiguration | null>(
    null,
  );

  const { data: elementVersionData } = useQuery(GET_ELEMENT_VERSION, {
    variables: { elementName, commitHash },
  });
  const { data: elementDefaultsData, loading: areElementDefaultsLoading } = useQuery(
    GET_ELEMENT_DEFAULTS,
    {
      variables: { elementName },
    },
  );

  const { commit } = useCommit(commitBranch, commitHash);
  const elementVersion = elementVersionData?.element.version;
  const elementDefaults = elementDefaultsData?.element.defaults ?? undefined;

  const { typeConfigurations, loading: areTypeConfigurationsLoading } =
    useAllCurrentTypeConfigurations(commit);

  // Initialize configuration when all of the necessary information has loaded
  useEffect(() => {
    if (
      configuration ||
      !commit ||
      !elementVersion ||
      areElementDefaultsLoading ||
      areTypeConfigurationsLoading
    ) {
      return;
    }
    setConfiguration(
      createConfiguration(
        elementVersion,
        commit,
        configurationType,
        elementDefaults,
        typeConfigurations,
      ),
    );
  }, [
    areElementDefaultsLoading,
    areTypeConfigurationsLoading,
    commit,
    configuration,
    configurationType,
    elementDefaults,
    elementVersion,
    typeConfigurations,
  ]);

  return configuration ? (
    <ElementConfigurationCard
      key={configuration.id}
      configuration={configuration}
      elementName={elementName}
    />
  ) : (
    <Box paddingTop={2}>
      <CircularProgress size={24} color="primary" />
    </Box>
  );
}

function createConfiguration(
  element: APIElement,
  commit: Commit,
  configurationType: ConfigurationType,
  defaults?: ParameterValueDict,
  typeConfigurations?: Record<string, TypeConfigurationSpec>,
): EditorElementConfiguration {
  return {
    isNew: true,
    id: uuid(),
    spec: createConfigurationSpec(
      element,
      element.configuration,
      defaults,
      typeConfigurations,
    ),
    commitBranch: commit.commitBranch,
    commitDate: commit.commitDate,
    commitHash: commit.commitHash,
    configurationType,
  };
}
