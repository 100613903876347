import { ConfigurationType } from 'common/types/commonConfiguration';
import { ElementConfigurationID, ElementName } from 'common/types/elementConfiguration';
import { TypeConfigurationID, TypeName } from 'common/types/typeConfiguration';
import { route } from 'common/ui/navigation';

/**
 * All routes used for the admin tool.
 *
 * Usage:
 *
 * 1) For Route component:
 *
 *   <Route path={ROUTES.HOME.pathTemplate} />
 *
 * 2) For navigation:
 *
 *     history.push(ROUTES.HOME.getPath())
 *
 *   or
 *
 *     <Link to={ROUTES.HOME.getPath()}>Home</Link>
 */
export const ROUTES = {
  HOME: route<void>('/'),

  ANTHA_HUB: {
    METRICS: route<void>('/synthacehub/metrics'),
    RELEASES: route<void>('/synthacehub/releases'),
  },

  ENVIRONMENTS: {
    VIEW: route<{ hostname: string }>('/environments/:hostname'),
  },

  ORGANIZATIONS: {
    ROOT: route<void>('/organisations'),
    VIEW: route<{ humanIdentifier: string }>('/organisations/:humanIdentifier'),
    ENV_DEPLOYMENT: route<{ envHostname: string; orgHumanIdentifier: string }>(
      '/organisations/:orgHumanIdentifier/:envHostname',
    ),
  },

  ROLES: {
    VIEW: route<{ humanIdentifier: string; userId: string }>(
      '/organisations/:humanIdentifier/users/:userId',
    ),
  },

  ELEMENT_CONFIGURATION: {
    ROOT: route<void>('/elements'),

    LIST: route<{
      elementName: ElementName;
    }>(`/elements/:elementName`),

    LIST_WITH_QUERY_PARAMS: route<{
      elementName: ElementName;
      commitBranch: string;
      commitHash: string;
      commitDate: string;
    }>(
      `/elements/:elementName/?commitBranch=:commitBranch&commitHash=:commitHash&commitDate=:commitDate`,
    ),

    EDIT: route<{ elementName: ElementName; id: ElementConfigurationID }>(
      '/elements/:elementName/edit/:id',
    ),

    CREATE: route<{
      elementName: ElementName;
      commitHash: string;
      commitBranch: string;
      configurationType: ConfigurationType;
    }>('/elements/:elementName/create/:commitHash/:commitBranch/:configurationType'),
  },

  TYPE_CONFIGURATION: {
    ROOT: route<void>('/types'),

    LIST: route<{
      typeName: TypeName;
    }>(`/types/:typeName`),

    EDIT: route<{ typeName: TypeName; id: TypeConfigurationID }>(
      '/types/:typeName/edit/:id',
    ),

    CREATE: route<{
      typeName: TypeName;
      commitHash: string;
      commitBranch: string;
      configurationType: ConfigurationType;
    }>('/types/:typeName/create/:commitHash/:commitBranch/:configurationType'),
  },

  FEATURETOGGLES: {
    ROOT: route<void>('/featureToggles'),
  },

  PLATE_TYPES: {
    ROOT: route<void>('/plateTypes'),
  },

  REDIRECTINATOR: route<void>('/redirectinator'),

  PERMISSIONS: route<void>('/permissions'),

  MIGRATIONS: route<void>('/migrations'),

  TUTORIALS: route<void>('/tutorials'),

  EXAMPLE_WORKFLOWS: route<void>('/exampleWorkflows'),
};
