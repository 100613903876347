import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';

import { GET_FEATURE_TOGGLES_FOR_ALL_ENVS } from 'admin-client/app/api/gql/queries';
import ShowFeatureTogglesByEnv from 'admin-client/app/components/FeatureToggles/ShowFeatureTogglesByEnv';
import ShowFeatureTogglesByFeature from 'admin-client/app/components/FeatureToggles/ShowFeatureTogglesByFeature';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';

enum FeatureToggleView {
  BY_ENV,
  BY_FEATURE,
}

export default React.memo(function FeatureTogglesContainer() {
  const tabsInfo: TabsInfo<FeatureToggleView> = [
    { label: 'By Environment', value: FeatureToggleView.BY_ENV },
    { label: 'By Feature', value: FeatureToggleView.BY_FEATURE },
  ];
  const [selectedTab, setSelectedTab] = useState(FeatureToggleView.BY_ENV);
  const { data, loading, error } = useQuery(GET_FEATURE_TOGGLES_FOR_ALL_ENVS);

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Feature Toggles
      </Typography>
      <Typography>
        Use this page to change the feature toggles. You can view features for a
        particular env or a feature across multiple environments. By default, each feature
        is enabled or disabled based on settings in the code deployed on a particular
        environment. Toggling the feature overrides that. The last column gives you an
        indicator of whether or not the feature is overridden.
      </Typography>
      <Tabs
        activeTab={selectedTab}
        onChangeTab={setSelectedTab}
        tabsInfo={tabsInfo}
        centered
      />
      {loading && <Typography>Loading...</Typography>}
      {error && <Typography color="error">Error: {error.message}</Typography>}
      {data ? (
        <>
          {selectedTab === FeatureToggleView.BY_ENV && (
            <ShowFeatureTogglesByEnv data={data} />
          )}
          {selectedTab === FeatureToggleView.BY_FEATURE && (
            <ShowFeatureTogglesByFeature data={data} />
          )}
        </>
      ) : (
        !loading && <Typography> No environments set up.</Typography>
      )}
    </>
  );
});
