import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import {
  GET_ELEMENT,
  GET_ELEMENT_VERSION,
  GET_ELEMENTS,
  SAVE_ELEMENT_CONFIGURATION,
} from 'admin-client/app/api/gql/queries';
import { BranchList } from 'admin-client/app/components/ElementConfiguration/BranchList';
import BranchOptionsMenu from 'admin-client/app/components/ElementConfiguration/BranchOptionsMenu';
import { RuleEditor } from 'admin-client/app/components/ElementConfiguration/Card/rules/RuleEditor';
import { ChooseCommitDialogResult } from 'admin-client/app/components/ElementConfiguration/ChooseElementConfigurationCommitDialog';
import { ConfigurationTypeLabel } from 'admin-client/app/components/ElementConfiguration/ConfigurationTypeLabel';
import { EditorElementConfiguration } from 'admin-client/app/components/ElementConfiguration/editorTypes';
import { getConfigurationCommits } from 'admin-client/app/components/ElementConfiguration/getConfigurationCommits';
import ResetButton from 'admin-client/app/components/ElementConfiguration/ResetButton';
import RowHeader from 'admin-client/app/components/ElementConfiguration/RowHeader';
import { useAllCurrentTypeConfigurations } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useAllCurrentTypeConfigurations';
import { useChooseElementConfigurationCommitDialog } from 'admin-client/app/components/ElementConfiguration/useChooseElementConfigurationCommitDialog';
import { useCommits } from 'admin-client/app/components/ElementConfiguration/useCommits';
import { useElementConfigurations } from 'admin-client/app/components/ElementConfiguration/useElementConfigurations';
import { MarkdownEditor } from 'admin-client/app/components/Markdown/MarkdownEditor';
import { isMasterBranch, isRelengBranch } from 'admin-common/src/parseCommits';
import { ROUTES } from 'admin-common/src/routing/routes';
import { createConfigurationSpec } from 'common/elementConfiguration/createConfigurationSpec';
import { getCommitFromConfiguration } from 'common/elementConfiguration/getCommitFromConfiguration';
import { Markdown } from 'common/lib/markdown';
import { APIElement } from 'common/types/api';
import {
  ElementConfigurationRule,
  ElementConfigurationSpec,
  ElementName,
} from 'common/types/elementConfiguration';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  element: APIElement;
  elementName: ElementName;
  configuration: EditorElementConfiguration;
  setConfiguration: React.Dispatch<React.SetStateAction<EditorElementConfiguration>>;
};

/**
 * Renders content of tab "Element"
 */
export function TabElement({
  element,
  elementName,
  configuration,
  setConfiguration,
}: Props) {
  const classes = useStyles();
  const history = useHistory();

  // helper callback so we can update the spec more easily
  const updateSpec = useCallback(
    (mutator: (prevSpec: ElementConfigurationSpec) => ElementConfigurationSpec) => {
      setConfiguration(prevConfiguration => ({
        ...prevConfiguration,
        spec: mutator(prevConfiguration.spec),
      }));
    },
    [setConfiguration],
  );

  const setDisplayName = useCallback(
    (elementDisplayName: string) => updateSpec(spec => ({ ...spec, elementDisplayName })),
    [updateSpec],
  );
  const onDisplayNameChange = useTextFieldChange(setDisplayName);

  const setDisplayDescription = useCallback(
    (elementDisplayDescription: Markdown) =>
      updateSpec(spec => ({ ...spec, elementDisplayDescription })),
    [updateSpec],
  );

  const onElementNameReset = useCallback(() => {
    const originalName = element.name ?? '';
    updateSpec(spec => ({ ...spec, elementDisplayName: originalName }));
  }, [element.name, updateSpec]);

  const onElementDescriptionReset = useCallback(() => {
    const originalDescription = element.description ?? '';
    updateSpec(spec => ({
      ...spec,
      elementDisplayDescription: originalDescription as Markdown,
    }));
  }, [element.description, updateSpec]);

  const { configurations } = useElementConfigurations(elementName);
  const { commits } = useCommits();
  const currentCommit = useMemo(
    () => ({
      commitBranch: configuration.commitBranch,
      commitDate: configuration.commitDate,
      commitHash: configuration.commitHash,
    }),
    [configuration],
  );

  const configurationCommits = useMemo(
    () =>
      commits
        ? getConfigurationCommits(
            configuration,
            // remove current configuration from the list of stored configurations and
            // replace it with currently edited configuration, to render the 'future state'
            // after save
            [...configurations.filter(c => c.id !== configuration.id), configuration],
            commits,
          )
        : [],
    [commits, configuration, configurations],
  );

  const { typeConfigurations } = useAllCurrentTypeConfigurations(currentCommit);

  const onRulesChange = useCallback(
    (rules: ElementConfigurationRule[]) => updateSpec(spec => ({ ...spec, rules })),
    [updateSpec],
  );

  // in order to include settings not yet confirmed in the dialog, override the configuration
  // passed to some components with the current configuration. This way editing the display name
  // immediately propagates down to components like InputSelector.
  const elementForEditor = useMemo(
    () => ({ ...element, configuration: configuration.spec }),
    [configuration.spec, element],
  );

  const latestCommitOnBranch = useMemo(() => {
    const latestCommits = commits?.latestCommits.filter(
      commit => commit.commitBranch === currentCommit.commitBranch,
    );
    return latestCommits ? latestCommits[0] : currentCommit;
  }, [commits, currentCommit]);

  const openChooseCommitDialog = useChooseElementConfigurationCommitDialog();
  const onChangeBranchOrCommit = useCallback(async () => {
    await openChooseCommitDialog({
      elementName,
      initialCommit: latestCommitOnBranch,
      initialConfigurationType: configuration.configurationType,
      isNewConfiguration: false,
      dialogTitle:
        'Choose branch to move this configuration to, or apply to the latest commit on the current branch',
      initialSpec: configuration.spec,
      onCommitSelected: result =>
        setConfiguration(configuration => ({
          ...configuration,
          spec: createConfigurationSpec(result.elementVersion, configuration.spec),
          commitHash: result.commit.commitHash,
          commitDate: result.commit.commitDate,
          commitBranch: result.commit.commitBranch,
          configurationType: result.configurationType,
        })),
    });
  }, [
    configuration.configurationType,
    configuration.spec,
    elementName,
    latestCommitOnBranch,
    openChooseCommitDialog,
    setConfiguration,
  ]);

  /**
   * When working on element test branches, it's likely the branch itself will be updated
   * during testing, and the config also might need to be updated to reflect the most recent elements.
   * Make sure to prompt the user in this case to indicate they should keep the config updated.
   * If no data is returned from this query, and a graphQLError returned, it means the commit is not the
   * latest as we only ever return data for the most recent commit.
   */
  const {
    data: elementsData,
    error,
    loading: loadingElements,
  } = useQuery(GET_ELEMENTS, {
    variables: {
      commitHash: currentCommit.commitHash,
      commitBranch: currentCommit.commitBranch,
      commitDate: currentCommit.commitDate.toISOString(),
    },
  });

  const isNoLongerAppliedToLatestCommit = useMemo(() => {
    const isCurrentCommitMasterOrReleng =
      isMasterBranch(currentCommit) || isRelengBranch(currentCommit);
    return (
      !loadingElements &&
      !elementsData &&
      error?.graphQLErrors &&
      !isCurrentCommitMasterOrReleng
    );
  }, [currentCommit, elementsData, error, loadingElements]);

  const [saveMutation] = useMutation(SAVE_ELEMENT_CONFIGURATION);

  const onSaveConfigToNewBranch = useCallback(
    async (result: ChooseCommitDialogResult) => {
      const {
        commit: { commitHash, commitDate, commitBranch },
        configurationType,
        elementVersion,
        updatedConfig,
      } = result;

      const spec = createConfigurationSpec(
        elementVersion,
        updatedConfig ?? configuration.spec,
      );
      const copyId = uuid();
      await saveMutation({
        variables: {
          id: copyId,
          spec,
          commitHash,
          commitDate: commitDate.toISOString(),
          commitBranch,
          configurationType,
          elementName: elementVersion.name,
        },
        refetchQueries: [
          {
            query: GET_ELEMENT,
            variables: { elementName: elementVersion.name },
          },
          {
            query: GET_ELEMENT_VERSION,
            variables: {
              elementName: elementVersion.name,
              commitHash: commitHash,
            },
          },
        ],
      });
      history.push(
        ROUTES.ELEMENT_CONFIGURATION.EDIT.getPath({
          elementName: elementVersion.name,
          id: copyId,
        }),
      );
    },
    [configuration.spec, history, saveMutation],
  );

  const onCopyConfigToNewBranch = useCallback(async () => {
    await openChooseCommitDialog({
      elementName,
      initialCommit: getCommitFromConfiguration(configuration),
      initialConfigurationType: configuration.configurationType,
      dialogTitle: 'Choose branch to copy existing configuration over to',
      onCommitSelected: result => onSaveConfigToNewBranch(result),
      isNewConfiguration: false,
      initialSpec: configuration.spec,
    });
  }, [configuration, elementName, onSaveConfigToNewBranch, openChooseCommitDialog]);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <RowHeader>Configuration type</RowHeader>
          <TableCell className={classes.fullWidthColumn}>
            <ConfigurationTypeLabel configurationType={configuration.configurationType} />
          </TableCell>
        </TableRow>

        <TableRow>
          <RowHeader>Valid since</RowHeader>
          <TableCell>{configuration.commitDate.format('lll')}</TableCell>
        </TableRow>

        <TableRow>
          <RowHeader>Applied in</RowHeader>
          <TableCell>
            <BranchList commits={configurationCommits} />
            <BranchOptionsMenu
              onChangeBranchOrCommit={onChangeBranchOrCommit}
              onCopyConfigToNewBranch={onCopyConfigToNewBranch}
            />
            {isNoLongerAppliedToLatestCommit && (
              <Tooltip title="The current configuration is not updated to reflect the latest commits on the branch, so up-to-date element data cannot be retrieved. Apply the configuration to the latest commit to update.">
                <WarningIcon className={classes.warningIcon} />
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <RowHeader>Element display name</RowHeader>
          <TableCell>
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                value={configuration.spec.elementDisplayName}
                onChange={onDisplayNameChange}
                className={classes.nameTextField}
              />
              <ResetButton
                disabled={element.name === configuration.spec.elementDisplayName}
                onClick={onElementNameReset}
                resetObjectName="element display name"
                additionalMessage="This cannot be undone unless you Cancel the config changes."
              />
            </Box>
          </TableCell>
        </TableRow>

        <TableRow>
          <RowHeader>Element description</RowHeader>
          <TableCell>
            <MarkdownEditor
              currentCommit={latestCommitOnBranch}
              onChange={setDisplayDescription}
              value={configuration.spec.elementDisplayDescription}
            />
            <ResetButton
              disabled={
                element.description === configuration.spec.elementDisplayDescription
              }
              onClick={onElementDescriptionReset}
              resetObjectName="element description"
              additionalMessage="This cannot be undone unless you Cancel the config changes."
            />
          </TableCell>
        </TableRow>

        <TableRow>
          <RowHeader>Parameter rules</RowHeader>
          <TableCell>
            <RuleEditor
              element={elementForEditor}
              rules={configuration.spec.rules ?? []}
              typeConfigurations={typeConfigurations}
              onChange={onRulesChange}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

const useStyles = makeStylesHook({
  fullWidthColumn: { width: '100%' },
  label: { whiteSpace: 'nowrap' },
  nameTextField: { paddingTop: '5px' },
  warningIcon: {
    'vertical-align': 'middle',
  },
});
