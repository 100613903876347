import React from 'react';

import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import { ArrayElement, getEnvironmentQuery } from 'admin-client/app/gql';
import { ROUTES } from 'admin-common/src/routing/routes';

type OrganisationLinkProps = {
  org: ArrayElement<
    getEnvironmentQuery['environment']['enabledOrganisationEnvironments']
  >['organisation'];
};

export const OrganisationLink = React.memo((props: OrganisationLinkProps) => {
  const { org } = props;
  const path = ROUTES.ORGANIZATIONS.VIEW.getPath({
    humanIdentifier: org.humanIdentifier,
  });

  return (
    <Link component={RouterLink} to={path} title={org.humanIdentifier}>
      {org.name}
    </Link>
  );
});
