import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { History } from 'history';

import history from 'admin-client/app/history';

type AdminUIContext = {
  currentScreenID: string;
  serverClientId: string;
  auth0Tenant: string;
};

const DEFAULT_CONTEXT: AdminUIContext = {
  currentScreenID: '',
  serverClientId: '',
  auth0Tenant: '',
};

const AdminUIContext = React.createContext<AdminUIContext>(DEFAULT_CONTEXT);

export function useAdminUIContext() {
  return useContext(AdminUIContext);
}

type AdminUIContextProps = {
  serverClientId: string;
  auth0Tenant: string;
};

function getScreenIDFromHistory(history: History) {
  return history.location.pathname.replace(/^\//, '');
}

export default function AdminUIContextProvider({
  auth0Tenant,
  serverClientId,
  children,
}: PropsWithChildren<AdminUIContextProps>) {
  const [currentScreenID, setCurrentScreenID] = useState<string>(
    getScreenIDFromHistory(history),
  );

  useEffect(() => {
    const unsubscribeFromHistory = history.listen(() => {
      setCurrentScreenID(getScreenIDFromHistory(history));
    });

    return () => {
      unsubscribeFromHistory();
    };
  }, []);

  const ctx = useMemo<AdminUIContext>(
    () => ({ currentScreenID, serverClientId, auth0Tenant }),
    [auth0Tenant, currentScreenID, serverClientId],
  );

  return <AdminUIContext.Provider value={ctx}>{children}</AdminUIContext.Provider>;
}
