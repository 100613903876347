import React, { useCallback, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';

import {
  DELETE_ELEMENT_CONFIGURATION,
  GET_ELEMENT,
  GET_ELEMENT_VERSION,
  SAVE_ELEMENT_CONFIGURATION,
} from 'admin-client/app/api/gql/queries';
import { TabElement } from 'admin-client/app/components/ElementConfiguration/Card/TabElement';
import { TabInputParameters } from 'admin-client/app/components/ElementConfiguration/Card/TabInputParameters';
import { TabOutputParameters } from 'admin-client/app/components/ElementConfiguration/Card/TabOutputParameters';
import { EditorElementConfiguration } from 'admin-client/app/components/ElementConfiguration/editorTypes';
import { ROUTES } from 'admin-common/src/routing/routes';
import { ElementName } from 'common/types/elementConfiguration';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  elementName: ElementName;
  configuration: EditorElementConfiguration;
};

export function ElementConfigurationCard(props: Props) {
  const classes = useStyles();
  const history = useHistory();

  const [configuration, setConfiguration] = useState<EditorElementConfiguration>(() => ({
    ...props.configuration,
  }));
  const hasChanges = useMemo(
    () => hasConfigurationChanges(configuration, props.configuration),
    [configuration, props.configuration],
  );

  const navigateToList: () => void = useCallback(
    () =>
      history.push(
        ROUTES.ELEMENT_CONFIGURATION.LIST.getPath({
          elementName: props.elementName,
        }),
      ),
    [history, props.elementName],
  );

  const [saveMutation] = useMutation(SAVE_ELEMENT_CONFIGURATION);
  const onSaveClick = useCallback(async () => {
    await saveMutation({
      variables: {
        id: configuration.id,
        spec: configuration.spec,
        commitHash: configuration.commitHash,
        commitDate: configuration.commitDate.toISOString(),
        commitBranch: configuration.commitBranch,
        configurationType: configuration.configurationType,
        elementName: props.elementName,
      },
      refetchQueries: [
        { query: GET_ELEMENT, variables: { elementName: props.elementName } },
        {
          query: GET_ELEMENT_VERSION,
          variables: {
            elementName: props.elementName,
            commitHash: configuration.commitHash,
          },
        },
      ],
    });
    if (configuration.isNew) {
      navigateToList();
    }
  }, [
    configuration.commitBranch,
    configuration.commitDate,
    configuration.commitHash,
    configuration.configurationType,
    configuration.id,
    configuration.isNew,
    configuration.spec,
    navigateToList,
    props.elementName,
    saveMutation,
  ]);
  const onCancelClick = useCallback(() => navigateToList(), [navigateToList]);

  const [deleteMutation] = useMutation(DELETE_ELEMENT_CONFIGURATION);
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const onDeleteClick = useCallback(async () => {
    const isConfirmed = await openConfirmationDialog({
      action: 'delete',
      isActionDestructive: true,
      object: 'element configuration',
    });
    if (!isConfirmed) {
      return;
    }
    await deleteMutation({
      variables: {
        id: configuration.id,
      },
      refetchQueries: [
        { query: GET_ELEMENT, variables: { elementName: props.elementName } },
      ],
    });
    navigateToList();
  }, [
    configuration.id,
    deleteMutation,
    navigateToList,
    openConfirmationDialog,
    props.elementName,
  ]);

  const [selectedTab, setSelectedTab] = useState(0);
  const onTabChange = useCallback((event, value: number) => setSelectedTab(value), []);

  const { data: elementVersionData } = useQuery(GET_ELEMENT_VERSION, {
    variables: {
      elementName: props.elementName,
      commitHash: props.configuration.commitHash,
    },
  });
  const element = elementVersionData?.element.version;

  const content = useMemo(() => {
    if (!element) {
      return <LinearProgress />;
    }
    switch (selectedTab) {
      case 0:
        return (
          <TabElement
            element={element}
            configuration={configuration}
            elementName={props.elementName}
            setConfiguration={setConfiguration}
          />
        );
      case 1:
        return (
          <TabInputParameters
            element={element}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        );
      case 2:
        return (
          <TabOutputParameters
            element={element}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        );
      default:
        return null;
    }
  }, [configuration, element, props.elementName, selectedTab]);

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <Button
          data-testid="btn-save-configuration"
          color="secondary"
          disabled={!hasChanges}
          onClick={onSaveClick}
          variant="contained"
        >
          {configuration.isNew ? 'Create' : 'Save changes'}
        </Button>
        <Button onClick={onCancelClick}>Cancel</Button>
        {!configuration.isNew && (
          <>
            <Divider orientation="vertical" flexItem />

            <Button onClick={onDeleteClick}>Delete</Button>
          </>
        )}
      </div>
      <Card>
        <Tabs value={selectedTab} onChange={onTabChange}>
          <Tab value={0} label="Element" />
          <Tab value={1} label="Inputs" />
          <Tab value={2} label="Outputs" />
        </Tabs>
        <CardContent>{content}</CardContent>
        <CardActions />
        {confirmationDialog}
      </Card>
    </div>
  );
}

const useStyles = makeStylesHook({
  root: { marginTop: '1rem' },
  toolbar: {
    display: 'flex',
    margin: '1rem 0',

    // give all child elements of toolbar margin to maintain safe distance
    // (this code was written during The Great Isolation 2020)
    '& > *': {
      '&:not(:first-child)': { marginLeft: '8px' },
      '&:not(:last-child)': { marginRight: '8px' },
    },
  },

  descriptionEditor: { marginTop: '1rem', width: '100%' },
});

/**
 * When comparing configuration with its original configuration, we only care
 * about some fields: spec and commitHash. There may be other fields present
 * (e.g. lastModifiedAt) which break the comparison if whole `configuration`
 * object is used.
 */
function getFieldsToCompare(configuration: EditorElementConfiguration) {
  const { spec, commitHash } = configuration;
  return { spec, commitHash };
}

function hasConfigurationChanges(
  configuration: EditorElementConfiguration,
  originalConfiguration: EditorElementConfiguration,
): boolean {
  if (configuration.isNew) {
    return true;
  }
  return !isEqual(
    getFieldsToCompare(configuration),
    getFieldsToCompare(originalConfiguration),
  );
}
