import React, { useState } from 'react';

import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

import { APIElement } from 'common/types/api';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';
import Autocomplete from 'common/ui/filaments/Autocomplete';

export default function ParameterChangeList({
  onChange,
  element,
  spec,
}: {
  onChange: (oldName: string, newName: string) => void;
  element: APIElement;
  spec: ElementConfigurationSpec;
}) {
  const elementInputs = element.in_ports.map(e => {
    return e.name;
  });

  const elementOutputs = element.out_ports.map(e => {
    return e.name;
  });

  const configurationInputParameterNames = spec
    ? spec.inputGroups
        .map(p => {
          return p.parameterNames;
        })
        .flat()
    : [];

  const inputParameterDifferences = configurationInputParameterNames.filter(
    param => !elementInputs.includes(param),
  );

  const outputParameterDifferences = spec?.outputOrder?.filter(
    param => !elementOutputs.includes(param),
  );

  const hasChanges =
    inputParameterDifferences.length > 0 || outputParameterDifferences.length > 0;

  return (
    (hasChanges && (
      <div>
        <p>
          The following parameters were not found in the element on the destination branch
          and might have been renamed or removed. If they have been renamed, please select
          the new parameter name to transfer the existing parameter config.
        </p>
        {inputParameterDifferences.length > 0 && (
          <>
            <FormLabel component="legend">
              Input parameters ({inputParameterDifferences.length} changed):
            </FormLabel>
            {inputParameterDifferences.map((param, index) => {
              return (
                <ParameterChange
                  key={index}
                  parameterName={param}
                  parameterSelection={elementInputs}
                  onChange={onChange}
                />
              );
            })}
          </>
        )}
        {outputParameterDifferences.length > 0 && (
          <>
            <FormLabel component="legend">
              Output parameters ({outputParameterDifferences.length} changed):
            </FormLabel>
            {outputParameterDifferences.map((param, index) => {
              return (
                <ParameterChange
                  key={index}
                  parameterName={param}
                  parameterSelection={elementOutputs}
                  onChange={onChange}
                />
              );
            })}
          </>
        )}
      </div>
    )) ||
    null
  );
}

function ParameterChange({
  parameterName,
  parameterSelection,
  onChange,
}: {
  parameterName: string;
  parameterSelection: string[];
  onChange: (oldName: string, newName: string) => void;
}) {
  const [selectedParameterName, setSelectedParameterName] = useState('');

  const onParameterNameChange = (name?: string) => {
    setSelectedParameterName(name ?? '');
    onChange(name ?? '', parameterName);
  };

  const options = parameterSelection.map(value => ({ label: value, value }));

  return (
    <Box my={1} marginBottom={4}>
      <Typography variant="subtitle1">{parameterName}</Typography>
      <Autocomplete
        valueLabel={selectedParameterName}
        options={options}
        onChange={onParameterNameChange}
      />
    </Box>
  );
}
