import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client/react/hooks/useMutation';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import Tooltip from '@mui/material/Tooltip';

import {
  GET_FEATURE_TOGGLES_FOR_ONE_ENV,
  UPDATE_FEATURE_TOGGLE_OVERRIDE,
} from 'admin-client/app/api/gql/queries';
import {
  ArrayElement,
  FeatureToggleCommonFragment,
  FeatureToggleOverride,
} from 'admin-client/app/gql';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type FeatureToggle = ArrayElement<FeatureToggleCommonFragment['featureToggles']>;

type Props = {
  environmentId: string;
  featureToggle: FeatureToggle;
  hostname: string;
};

export default function FeatureToggleOverrideIcon(props: Props) {
  const { environmentId, featureToggle, hostname } = props;
  const classes = useStyles();

  const [updateFeatureToggleMutation] = useMutation(UPDATE_FEATURE_TOGGLE_OVERRIDE);

  const handleReset = useCallback(
    async (featureToggleName: string) => {
      await updateFeatureToggleMutation({
        variables: {
          environmentId,
          name: featureToggleName,
          override: FeatureToggleOverride.NONE,
        },
        refetchQueries: [
          { query: GET_FEATURE_TOGGLES_FOR_ONE_ENV, variables: { hostname } },
        ],
      });
    },
    [environmentId, hostname, updateFeatureToggleMutation],
  );

  return featureToggle.config?.override === FeatureToggleOverride.ENABLED ||
    featureToggle.config?.override === FeatureToggleOverride.DISABLED ? (
    <Tooltip title="Click to reset override">
      <WarningOutlinedIcon
        color="secondary"
        onClick={() => handleReset(featureToggle.name)}
        className={classes.clickable}
      />
    </Tooltip>
  ) : null;
}

const useStyles = makeStylesHook({
  clickable: {
    cursor: 'pointer',
  },
});
