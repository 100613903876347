import React from 'react';

import AddParameterMessageActionEditor from 'admin-client/app/components/ElementConfiguration/Card/rules/actions/AddParameterMessageActionEditor';
import { SetParametersBooleanFieldActionEditor } from 'admin-client/app/components/ElementConfiguration/Card/rules/actions/SetParametersBooleanFieldActionEditor';
import { ActionTypeOption } from 'common/rules/ActionEditor';
import { ActionsEditor, Props as ActionsEditorProps } from 'common/rules/ActionsEditor';
import { ElementConfigurationAction } from 'common/types/elementConfiguration';

const OPTIONS: ActionTypeOption<ElementConfigurationAction>[] = [
  {
    type: 'set-parameters-visible',
    label: 'Show parameters',
    editor: SetParametersBooleanFieldActionEditor,
    getDefaultAction: prevAction => ({
      type: 'set-parameters-visible',
      revertIfConditionNotMet: true,
      parameterNames: getPreviousParameterNames(prevAction),
    }),
  },
  {
    type: 'set-parameters-not-visible',
    label: 'Hide parameters',
    editor: SetParametersBooleanFieldActionEditor,
    getDefaultAction: prevAction => ({
      type: 'set-parameters-not-visible',
      revertIfConditionNotMet: true,
      parameterNames: getPreviousParameterNames(prevAction),
    }),
  },
  {
    type: 'set-parameters-enabled',
    label: 'Enable parameters',
    editor: SetParametersBooleanFieldActionEditor,
    getDefaultAction: prevAction => ({
      type: 'set-parameters-enabled',
      revertIfConditionNotMet: true,
      parameterNames: getPreviousParameterNames(prevAction),
    }),
  },
  {
    type: 'set-parameters-not-enabled',
    label: 'Disable parameters',
    editor: SetParametersBooleanFieldActionEditor,
    getDefaultAction: prevAction => ({
      type: 'set-parameters-not-enabled',
      revertIfConditionNotMet: true,
      parameterNames: getPreviousParameterNames(prevAction),
    }),
  },
  {
    type: 'set-parameters-required',
    label: 'Require parameters',
    editor: SetParametersBooleanFieldActionEditor,
    getDefaultAction: prevAction => ({
      type: 'set-parameters-required',
      revertIfConditionNotMet: true,
      parameterNames: getPreviousParameterNames(prevAction),
    }),
  },
  {
    type: 'set-parameters-not-required',
    label: "Don't require parameters",
    editor: SetParametersBooleanFieldActionEditor,
    getDefaultAction: prevAction => ({
      type: 'set-parameters-not-required',
      revertIfConditionNotMet: true,
      parameterNames: getPreviousParameterNames(prevAction),
    }),
  },
  {
    type: 'add-parameter-error',
    label: 'Show error message for parameter',
    editor: AddParameterMessageActionEditor,
    getDefaultAction: prevAction => ({
      type: 'add-parameter-error',
      revertIfConditionNotMet: true,
      parameterName: getPreviousParameterNames(prevAction)[0],
      errorMessage: '',
    }),
  },
  {
    type: 'add-parameter-warning',
    label: 'Show warning message for parameter',
    editor: AddParameterMessageActionEditor,
    getDefaultAction: prevAction => ({
      type: 'add-parameter-warning',
      revertIfConditionNotMet: true,
      parameterName: getPreviousParameterNames(prevAction)[0],
      message: '',
    }),
  },
];

const DEFAULT_NEW_ACTION: ElementConfigurationAction = {
  type: 'set-parameters-visible',
  parameterNames: [],
  revertIfConditionNotMet: true,
};

type Props = Pick<
  ActionsEditorProps<ElementConfigurationAction>,
  'actions' | 'element' | 'onChange'
>;

export default function ElementConfigurationActionsEditor({
  actions,
  element,
  onChange,
}: Props) {
  return (
    <ActionsEditor
      options={OPTIONS}
      defaultNewAction={DEFAULT_NEW_ACTION}
      actions={actions}
      element={element}
      onChange={onChange}
    />
  );
}

function getPreviousParameterNames(action: ElementConfigurationAction | null): string[] {
  if (!action) {
    return [];
  }

  switch (action.type) {
    case 'set-parameters-visible':
    case 'set-parameters-not-visible':
    case 'set-parameters-required':
    case 'set-parameters-not-required':
    case 'set-parameters-enabled':
    case 'set-parameters-not-enabled':
      return action.parameterNames;
    case 'add-parameter-error':
    case 'add-parameter-warning':
      return [action.parameterName];
  }
}
