import { graphql } from 'admin-client/app/gql';

graphql(/* GraphQL */ `
  fragment OrganisationCommon on Organisation {
    id
    name
    humanIdentifier
    emailDomains
    isSynthaceInternal
    notes
  }
`);

graphql(/* GraphQL */ `
  fragment EnvironmentCommon on Environment {
    id
    hostname
    baseURL
    notes
  }
`);

graphql(/* GraphQL */ `
  fragment FeatureToggleCommon on Environment {
    featureToggles {
      name
      enabled
      description
      requiresReload
      kind
      config {
        override
      }
    }
  }
`);

graphql(/* GraphQL */ `
  fragment OnlineMigrationAdminStateCommon on OnlineMigrationAdminState {
    backfillEntitiesWritten
    backfillLockedUntil
    backfillVersionCompleted
    backfillVersionProgress
    codeVersion
    doubleReadFraction
    doubleReadFractionOverride
    entityCount
    name
    stage
    stageOverride
  }
`);

graphql(/* GraphQL */ `
  fragment OrganisationEnvironmentCommon on OrganisationEnvironment {
    environmentId
    organisationId
    auth0ClientId
    isEnabled
    notes
    environment {
      ...EnvironmentCommon
    }
  }
`);

graphql(/* GraphQL */ `
  fragment UserCommon on User {
    id
    givenName
    familyName
    email
    blocked
  }
`);

graphql(/* GraphQL */ `
  fragment AdminUserCommon on AdminUser {
    id
    email
    unseenNotificationsCount
  }
`);

graphql(/* GraphQL */ `
  fragment NotificationCommon on Notification {
    id
    message
    isRead
    isSeen
    url
    createdAt
  }
`);

graphql(/* GraphQL */ `
  fragment ReleaseArtifactCommon on ReleaseArtifact {
    filename
    sizeBytes
    downloadURL
    architecture
    type
  }
`);

graphql(/* GraphQL */ `
  fragment ReleaseCommon on Release {
    version
    releaseNotesURL
    createdAt
    artifacts {
      ...ReleaseArtifactCommon
    }
  }
`);

export const GET_ENVIRONMENTS = graphql(/* GraphQL */ `
  query getEnvironments {
    environments {
      ...EnvironmentCommon
    }
  }
`);

export const GET_ENVIRONMENTS_WITH_HEALTHCHECK = graphql(/* GraphQL */ `
  query getEnvironmentsWithHealthcheck {
    environments {
      ...EnvironmentCommon
      canAuthenticateWithAppserver
      anthaHubAutoUpdateEnabled
      anthaHubReleaseVersion
    }
  }
`);

export const GET_LATEST_ANTHAHUB_INSTALLER_RELEASES = graphql(/* GraphQL */ `
  query getLatestAnthaHubReleases {
    latestAnthaHubReleases {
      ...ReleaseCommon
    }
  }
`);

export const GET_LATEST_DEVICE_DRIVER_RELEASES = graphql(/* GraphQL */ `
  query getLatestDeviceDriverReleases {
    latestDeviceDriverReleases {
      ...ReleaseCommon
    }
  }
`);

export const GET_ALL_RELEASES_FOR_PROJECT = graphql(/* GraphQL */ `
  query getAllReleasesForProject(
    $projectName: String!
    $includePreReleaseVersions: Boolean!
  ) {
    allReleasesForProject(
      projectName: $projectName
      includePreReleaseVersions: $includePreReleaseVersions
    ) {
      ...ReleaseCommon
    }
  }
`);

export const ADD_ENVIRONMENT = graphql(/* GraphQL */ `
  mutation addEnvironment($domainPrefix: String!) {
    addEnvironment(input: { domainPrefix: $domainPrefix }) {
      ...EnvironmentCommon
    }
  }
`);

export const SET_ENVIRONMENT_ANTHA_HUB_RELEASE_VERSION = graphql(/* GraphQL */ `
  mutation setAnthaHubReleaseVersion(
    $environmentId: String!
    $anthaHubReleaseVersion: String!
  ) {
    setAnthaHubReleaseVersion(
      input: {
        environmentId: $environmentId
        anthaHubReleaseVersion: $anthaHubReleaseVersion
      }
    ) {
      ...EnvironmentCommon
    }
  }
`);

export const SET_ENVIRONMENT_DEVICE_DRIVER_RELEASE_VERSION = graphql(/* GraphQL */ `
  mutation setDeviceDriverReleaseVersion(
    $environmentId: String!
    $deviceDriverReleaseVersion: String!
    $deviceClass: String!
  ) {
    setDeviceDriverReleaseVersion(
      input: {
        environmentId: $environmentId
        deviceDriverReleaseVersion: $deviceDriverReleaseVersion
        deviceClass: $deviceClass
      }
    ) {
      ...EnvironmentCommon
    }
  }
`);

export const GET_ONLINE_MIGRATION_ADMIN_STATES = graphql(/* GraphQL */ `
  query getOnlineMigrationAdminStates {
    environments {
      ...EnvironmentCommon
      onlineMigrationAdminStates {
        ...OnlineMigrationAdminStateCommon
      }
    }
  }
`);

export const GET_ORGANISATIONS = graphql(/* GraphQL */ `
  query getOrganisations {
    organisations {
      ...OrganisationCommon
      environmentConfigs {
        ...OrganisationEnvironmentCommon
      }
    }
  }
`);

export const GET_ORGANISATION = graphql(/* GraphQL */ `
  query getOrganisation($humanIdentifier: String!) {
    organisation(humanIdentifier: $humanIdentifier) {
      ...OrganisationCommon

      auth0ConnectionId
      auth0ConnectionName
      auth0ConnectionStrategy
      environmentConfigs {
        ...OrganisationEnvironmentCommon
      }
      hasUserDatabase
      users {
        ...UserCommon
        lastLogin
        loginsCount
        addedBy
      }
    }
  }
`);

export const GET_ENVIRONMENT = graphql(/* GraphQL */ `
  query getEnvironment($hostname: String!) {
    environment(hostname: $hostname) {
      id
      hostname
      baseURL
      additionalURLs
      notes
      anthaHubReleaseVersion
      anthaHubAutoUpdateEnabled
      deviceDriverVersions {
        deviceClass
        version
        autoUpdateEnabled
      }
      enabledOrganisationEnvironments {
        organisation {
          ...OrganisationCommon
        }
      }
    }
  }
`);

export const GET_ORGANISATION_ENVIRONMENT = graphql(/* GraphQL */ `
  query getOrganisationEnvironment($orgHumanIdentifier: String!, $envHostname: String!) {
    organisationEnvironment(
      orgHumanIdentifier: $orgHumanIdentifier
      envHostname: $envHostname
    ) {
      ...OrganisationEnvironmentCommon
      allowedElementPaths
      organisation {
        ...OrganisationCommon
      }
    }
    supportConnection {
      id
      enabledClients
    }
  }
`);

export const UPDATE_CONNECTION = graphql(/* GraphQL */ `
  mutation updateConnection(
    $connectionId: String!
    $name: String
    $strategy: String
    $enabledClients: [String!]
  ) {
    updateConnection(
      input: {
        id: $connectionId
        name: $name
        strategy: $strategy
        enabledClients: $enabledClients
      }
    ) {
      id
      enabledClients
    }
  }
`);

export const GET_AUTH0_CONNECTIONS = graphql(/* GraphQL */ `
  query getAuth0Connections {
    auth0Connections {
      id
      name
      strategy
      enabledClients
    }
  }
`);

export const GET_ELEMENTS = graphql(/* GraphQL */ `
  query getElements($commitHash: String!, $commitBranch: String!, $commitDate: String!) {
    elements(commitHash: $commitHash, commitBranch: $commitBranch) {
      name
      currentConfiguration(
        commitHash: $commitHash
        commitBranch: $commitBranch
        commitDate: $commitDate
      ) {
        id
        spec
      }
      configurations {
        id
        configurationType
      }
    }
  }
`);

export const GET_ELEMENT = graphql(/* GraphQL */ `
  query getElement($elementName: String!) {
    element(elementName: $elementName) {
      name
      configurations {
        id
        spec
        commitHash
        commitDate
        commitBranch
        configurationType
        createdAt
        createdBy
        lastModifiedAt
        lastModifiedBy
      }
    }
  }
`);

export const GET_ELEMENT_VERSION = graphql(/* GraphQL */ `
  query getElementVersion($elementName: String!, $commitHash: String!) {
    element(elementName: $elementName) {
      version(commitHash: $commitHash)
    }
  }
`);

export const GET_ELEMENT_DEFAULTS = graphql(/* GraphQL */ `
  query getElementDefaults($elementName: String!) {
    element(elementName: $elementName) {
      defaults
    }
  }
`);

export const GET_COMMITS = graphql(/* GraphQL */ `
  query getCommits {
    commits {
      commitHash
      commitDate
      commitBranch
    }
  }
`);

export const SAVE_ELEMENT_CONFIGURATION = graphql(/* GraphQL */ `
  mutation saveElementConfiguration(
    $id: String!
    $elementName: String!
    $spec: ElementConfigurationSpecBlob!
    $commitHash: String!
    $commitDate: DateTime!
    $commitBranch: String!
    $configurationType: ConfigurationType!
  ) {
    saveElementConfiguration(
      input: {
        id: $id
        elementName: $elementName
        spec: $spec
        commitHash: $commitHash
        commitDate: $commitDate
        commitBranch: $commitBranch
        configurationType: $configurationType
      }
    ) {
      id
    }
  }
`);

export const DELETE_ELEMENT_CONFIGURATION = graphql(/* GraphQL */ `
  mutation deleteElementConfiguration($id: String!) {
    deleteElementConfiguration(input: { id: $id }) {
      id
    }
  }
`);

export const SYNCHRONIZE_ELEMENT_CONFIGURATIONS = graphql(/* GraphQL */ `
  mutation synchronizeElementConfigurations($elementName: String!) {
    synchronizeElementConfigurations(input: { elementName: $elementName }) {
      elementName
    }
  }
`);

export const GET_TYPE_NAMES = graphql(/* GraphQL */ `
  query getTypeNames($commit: CommitInputFields) {
    typeConfigurations(commit: $commit) {
      typeName
    }
  }
`);

export const GET_TYPE_CONFIGURATIONS_BY_NAME = graphql(/* GraphQL */ `
  query getConfigurationsForType($typeName: String) {
    typeConfigurations(typeName: $typeName) {
      id
      spec
      commitHash
      commitDate
      commitBranch
      configurationType
      createdAt
      lastModifiedAt
      lastModifiedBy
    }
  }
`);

export const GET_ALL_CURRENT_TYPE_CONFIGURATIONS = graphql(/* GraphQL */ `
  query getAllCurrentTypeConfigurations(
    $commit: CommitInputFields
    $returnLatestConfigurationOnly: Boolean
  ) {
    typeConfigurations(
      commit: $commit
      returnLatestConfigurationOnly: $returnLatestConfigurationOnly
    ) {
      typeName
      id
      spec
      commitBranch
      commitDate
      commitHash
      configurationType
      createdAt
      lastModifiedAt
      lastModifiedBy
    }
  }
`);

export const SAVE_TYPE_CONFIGURATION = graphql(/* GraphQL */ `
  mutation saveTypeConfiguration(
    $id: String!
    $typeName: String!
    $spec: TypeConfigurationSpecBlob!
    $commitHash: String!
    $commitDate: DateTime!
    $commitBranch: String!
    $configurationType: ConfigurationType!
  ) {
    saveTypeConfiguration(
      input: {
        id: $id
        typeName: $typeName
        spec: $spec
        commitHash: $commitHash
        commitDate: $commitDate
        commitBranch: $commitBranch
        configurationType: $configurationType
      }
    ) {
      id
    }
  }
`);

export const DELETE_TYPE_CONFIGURATION = graphql(/* GraphQL */ `
  mutation deleteTypeConfiguration($id: String!) {
    deleteTypeConfiguration(input: { id: $id }) {
      id
    }
  }
`);

export const ENABLE_ORG_IN_ENV = graphql(/* GraphQL */ `
  mutation enableOrg($orgId: String!, $envId: String!) {
    enableOrganisationInEnvironment(
      input: { environmentId: $envId, organisationId: $orgId }
    ) {
      ...OrganisationEnvironmentCommon
    }
  }
`);

export const DISABLE_ORG_IN_ENV = graphql(/* GraphQL */ `
  mutation disableOrg($orgId: String!, $envId: String!) {
    disableOrganisationInEnvironment(
      input: { environmentId: $envId, organisationId: $orgId }
    ) {
      ...OrganisationEnvironmentCommon
    }
  }
`);

export const SET_ALLOWED_ELEMENT_PATHS = graphql(/* GraphQL */ `
  mutation setAllowedElementPaths(
    $organisationId: String!
    $environmentId: String!
    $allowedElementPaths: [String!]!
  ) {
    setAllowedElementPaths(
      input: {
        organisationId: $organisationId
        environmentId: $environmentId
        allowedElementPaths: $allowedElementPaths
      }
    ) {
      ...OrganisationEnvironmentCommon
      allowedElementPaths
    }
  }
`);

export const UPDATE_CONNECTIONS = graphql(/* GraphQL */ `
  mutation updateConnections(
    $organisation: OrganisationType!
    $newAuth0Connection: ConnectionType!
  ) {
    updateConnections(
      input: { organisation: $organisation, newAuth0Connection: $newAuth0Connection }
    ) {
      newAuth0Connection {
        id
        name
        strategy
      }
    }
  }
`);

export const ADD_ORGANISATION = graphql(/* GraphQL */ `
  mutation addOrganisation(
    $name: String!
    $humanIdentifier: String!
    $emailDomains: [String!]!
    $isSynthaceInternal: Boolean!
    $auth0ConnectionName: String
  ) {
    addOrganisation(
      input: {
        name: $name
        humanIdentifier: $humanIdentifier
        emailDomains: $emailDomains
        isSynthaceInternal: $isSynthaceInternal
        auth0ConnectionName: $auth0ConnectionName
      }
    ) {
      ...OrganisationCommon
    }
  }
`);

export const RENAME_ORGANISATION = graphql(/* GraphQL */ `
  mutation renameOrganisation($organisationId: String!, $newName: String!) {
    renameOrganisation(input: { organisationId: $organisationId, newName: $newName }) {
      ...OrganisationCommon
    }
  }
`);

export const ADD_USER_TO_ORG = graphql(/* GraphQL */ `
  mutation addUser(
    $orgId: String!
    $givenName: String!
    $familyName: String!
    $email: String!
    $roleId: String
  ) {
    addUser(
      input: {
        organisationId: $orgId
        roleId: $roleId
        user: { givenName: $givenName, familyName: $familyName, email: $email }
      }
    ) {
      ...UserCommon
    }
  }
`);

export const BLOCK_USER_IN_ORG = graphql(/* GraphQL */ `
  mutation blockUser($orgId: String!, $email: String!) {
    blockUser(input: { organisationId: $orgId, email: $email }) {
      ...UserCommon
    }
  }
`);

export const UNBLOCK_USER_IN_ORG = graphql(/* GraphQL */ `
  mutation unblockUser($orgId: String!, $email: String!) {
    unblockUser(input: { organisationId: $orgId, email: $email }) {
      ...UserCommon
    }
  }
`);

export const GET_NOTIFICATIONS = graphql(/* GraphQL */ `
  query getNotifications($after: String) {
    me {
      ...AdminUserCommon
      notifications(includeRead: true, after: $after) {
        items {
          ...NotificationCommon
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`);

export const MARK_ALL_NOTIFICATIONS_AS_SEEN = graphql(/* GraphQL */ `
  mutation markAllNotificationsAsSeen {
    markAllNotificationsAsSeen {
      ...AdminUserCommon
    }
  }
`);

export const MARK_NOTIFICATION_AS_READ = graphql(/* GraphQL */ `
  mutation markNotificationAsRead($notificationId: String!) {
    markNotificationAsRead(input: { notificationId: $notificationId }) {
      ...NotificationCommon
    }
  }
`);

export const GET_FEATURE_TOGGLES_FOR_ALL_ENVS = graphql(/* GraphQL */ `
  query featureTogglesForAllEnvs {
    environments {
      id
      hostname
      ...FeatureToggleCommon
    }
  }
`);
export const GET_FEATURE_TOGGLES_FOR_ONE_ENV = graphql(/* GraphQL */ `
  query featureTogglesForEnv($hostname: String!) {
    environment(hostname: $hostname) {
      id
      hostname
      ...FeatureToggleCommon
    }
  }
`);
export const UPDATE_FEATURE_TOGGLE_OVERRIDE = graphql(/* GraphQL */ `
  mutation updateFeatureToggle(
    $environmentId: String!
    $name: String!
    $override: FeatureToggleOverride!
  ) {
    updateFeatureToggle(
      input: { environmentId: $environmentId, name: $name, override: $override }
    ) {
      enabled
      config {
        override
      }
    }
  }
`);

export const GET_PLATE_TYPES = graphql(/* GraphQL */ `
  query plateTypes($hostname: String!, $orgHumanIdentifier: String!) {
    plateTypes(hostname: $hostname, orgHumanIdentifier: $orgHumanIdentifier) {
      type
      name
    }
  }
`);

export const COPY_PLATE_TYPES = graphql(/* GraphQL */ `
  mutation copyPlateTypes(
    $sourceHostname: String!
    $sourceOrgHumanIdentifier: String!
    $destinationHostname: String!
    $destinationOrgHumanIdentifier: String!
    $plateTypes: [String!]!
  ) {
    copyPlateTypes(
      input: {
        sourceHostname: $sourceHostname
        sourceOrgHumanIdentifier: $sourceOrgHumanIdentifier
        destinationHostname: $destinationHostname
        destinationOrgHumanIdentifier: $destinationOrgHumanIdentifier
        plateTypes: $plateTypes
      }
    ) {
      type
      name
    }
  }
`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RedirectCommonFragment = graphql(/* GraphQL */ `
  fragment RedirectCommon on Redirect {
    id
    shortcut
    description
    urlPattern
  }
`);

export const GET_REDIRECTS = graphql(/* GraphQL */ `
  query getRedirects {
    redirects {
      ...RedirectCommon
    }
  }
`);

export const ADD_REDIRECT = graphql(/* GraphQL */ `
  mutation addRedirect($shortcut: String!, $description: String, $urlPattern: String!) {
    addRedirect(
      input: { shortcut: $shortcut, description: $description, urlPattern: $urlPattern }
    ) {
      ...RedirectCommon
    }
  }
`);

export const DELETE_REDIRECT = graphql(/* GraphQL */ `
  mutation deleteRedirect($id: String!) {
    deleteRedirect(input: { id: $id }) {
      deleted
    }
  }
`);

export const SET_ORGANISATION_NOTES = graphql(/* GraphQL */ `
  mutation setOrganisationNotes($organisationId: String!, $notes: String!) {
    setOrganisationNotes(input: { organisationId: $organisationId, notes: $notes }) {
      ...OrganisationCommon
    }
  }
`);

export const SET_ENVIRONMENT_NOTES = graphql(/* GraphQL */ `
  mutation setEnvironmentNotes($environmentId: String!, $notes: String!) {
    setEnvironmentNotes(input: { environmentId: $environmentId, notes: $notes }) {
      ...EnvironmentCommon
    }
  }
`);

export const SET_ORGANISATION_ENVIRONMENT_NOTES = graphql(/* GraphQL */ `
  mutation setOrganisationEnvironmentNotes(
    $organisationId: String!
    $environmentId: String!
    $notes: String!
  ) {
    setOrganisationEnvironmentNotes(
      input: {
        organisationId: $organisationId
        environmentId: $environmentId
        notes: $notes
      }
    ) {
      ...OrganisationEnvironmentCommon
    }
  }
`);

export const GET_USER_ROLES = graphql(/* GraphQL */ `
  query getUserRoles($id: String!) {
    userRole(id: $id) {
      internalRoles {
        id
        name
        description
      }
      customerRole {
        id
        name
        description
      }
    }
  }
`);

export const GET_USER = graphql(/* GraphQL */ `
  query getUser($id: String!) {
    user(id: $id) {
      id
      givenName
      familyName
      email
    }
  }
`);

export const GET_AVAILABLE_ROLES = graphql(/* GraphQL */ `
  query getAvailableRoles {
    availableRoles {
      internalRoles {
        id
        name
        description
      }
      customerRoles {
        id
        name
        description
      }
    }
  }
`);

export const MUTATION_UPDATE_USER_ROLE = graphql(/* GraphQL */ `
  mutation UpdateCustomerRole($input: UpdateCustomerRoleInput!) {
    updateCustomerRole(input: $input) {
      id
      customerRole {
        id
        name
      }
    }
  }
`);

export const DELETE_ORGANISATION = graphql(/* GraphQL */ `
  mutation deleteOrganisation($organisationId: String!) {
    deleteOrganisation(input: { organisationId: $organisationId }) {
      ...OrganisationCommon
    }
  }
`);

export const DELETE_ENVIRONMENT = graphql(/* GraphQL */ `
  mutation deleteEnvironment($environmentId: String!) {
    deleteEnvironment(input: { environmentId: $environmentId }) {
      ...EnvironmentCommon
    }
  }
`);

export const GET_ACTIONS = graphql(/* GraphQL */ `
  query getExampleActions($names: [String!], $pagingInfo: PagingInfo) {
    actions(names: $names, pagingInfo: $pagingInfo) {
      items {
        id
        resultType
        responseBody
        userEmail
        occurredAt
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
    }
  }
`);

export const PUBLISH_TUTORIALS = graphql(/* GraphQL */ `
  mutation publishTutorials($targetEnvironmentBaseURLs: [String!]) {
    publishTutorials(input: { targetEnvironmentBaseURLs: $targetEnvironmentBaseURLs }) {
      successEnvs
      failureEnvs {
        envURL
        message
      }
    }
  }
`);

export const PUBLISH_EXAMPLE_WORKFLOWS = graphql(/* GraphQL */ `
  mutation publishExampleWorkflows($targetEnvironmentBaseURLs: [String!]) {
    publishExampleWorkflows(
      input: { targetEnvironmentBaseURLs: $targetEnvironmentBaseURLs }
    ) {
      sourceEnvURL
      successEnvs {
        envURL
        supportedWorkflowIds
        unsupportedWorkflowIds {
          id
          reason
          details
        }
      }
      failureEnvs {
        envURL
        message
      }
    }
  }
`);

export const GET_TUTORIALS_TARGET_ENVIRONMENTS = graphql(/* GraphQL */ `
  query GetTutorialsTargetEnvironments {
    getTutorialsTargetEnvironments {
      environmentURLs
    }
  }
`);

export const GET_EXAMPLE_WORKFLOWS_TARGET_ENVIRONMENTS = graphql(/* GraphQL */ `
  query GetExampleWorkflowsTargetEnvironments {
    getExampleWorkflowsTargetEnvironments {
      environmentURLs
    }
  }
`);
