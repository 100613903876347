import React, { useCallback, useMemo, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { FilterOptionsState } from '@mui/material/useAutocomplete';

import { Commits } from 'admin-common/src/commit';
import { isMasterBranch } from 'admin-common/src/parseCommits';
import {
  Commit,
  CommonConfigurationFields,
  ConfigurationType,
} from 'common/types/commonConfiguration';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { isNotNull } from 'common/utils';

/**
 * Selector component for commit. User can choose from different commits based
 * on configurationType.
 *
 * When 'GLOBAL', user chooses releng branch which is the first releng branch where
 * the configuration will apply. Releng branches are sorted desc by date since expected
 * use case is to start "from the latest branch". It's also possible to selected the
 * commit has from which to apply this configuration from, if the latest branch is not
 * the desired start point.
 *
 * When 'SINGLE_BRANCH', user chooses from any non-releng branch. Those are sorted
 * alphabetically.
 */
export function CommitSelector({
  commits,
  configurationType,
  selectedCommit,
  existingConfigurations,
  onChange,
}: {
  commits: Commits | null;
  configurationType: ConfigurationType;
  existingConfigurations: readonly CommonConfigurationFields[];
  selectedCommit: Commit | null;
  onChange: (commit: Commit | null) => void;
}) {
  const classes = useStyles();

  const allCommitOptions = useMemo<SelectorOption[]>(
    () => (commits ? getOptions(configurationType, commits) : []),
    [commits, configurationType],
  );

  const masterCommitHashOptions = useMemo<SelectorOption[]>(
    () =>
      commits
        ? commits.masterCommits
            .map(commit => {
              return { label: commit.commitBranch, commit: commit };
            })
            .sort(compareOptionsByDateDesc)
        : [],
    [commits],
  );

  const selectedOption = useMemo(
    () =>
      (selectedCommit &&
        [...allCommitOptions, ...masterCommitHashOptions].find(
          (option: SelectorOption) =>
            option.commit.commitHash === selectedCommit.commitHash,
        )) ??
      null,
    [allCommitOptions, masterCommitHashOptions, selectedCommit],
  );

  const [applyToLatestMaster, setApplyToLatestMaster] = useState(true);
  const handleApplyToLatestMasterChange = () => {
    setApplyToLatestMaster(!applyToLatestMaster);
    !applyToLatestMaster &&
      onChange(
        allCommitOptions.find(option => isMasterBranch(option.commit))?.commit ?? null,
      );
  };

  const onAutocompleteChange = useCallback(
    (_event: any, option: SelectorOption | null) => onChange(option?.commit ?? null),
    [onChange],
  );

  // only one configuration per commit is allowed, disable options for commits that
  // already have a configuration attached
  const usedCommits = useMemo(
    () => new Set(existingConfigurations.map(getCommitBranchAndHash)),
    [existingConfigurations],
  );
  const getOptionDisabled = useCallback(
    (option: SelectorOption) => usedCommits.has(getCommitBranchAndHash(option.commit)),
    [usedCommits],
  );

  return (
    <>
      <Autocomplete
        className={classes.autocomplete}
        disableClearable
        filterOptions={filterAllCommitOptions}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={option => option.label}
        options={allCommitOptions}
        renderInput={params => (
          <TextField {...params} label="Commit" variant="outlined" />
        )}
        value={selectedOption ?? undefined}
        onChange={onAutocompleteChange}
      />
      {selectedOption?.commit && isMasterBranch(selectedOption?.commit) && (
        <>
          <Autocomplete
            className={classes.autocomplete}
            disableClearable
            filterOptions={filterMasterCommitHashOptions}
            getOptionDisabled={getOptionDisabled}
            getOptionLabel={option => option.commit.commitHash}
            options={masterCommitHashOptions}
            renderInput={params => (
              <TextField {...params} label="Master commit hash" variant="outlined" />
            )}
            value={selectedOption}
            onChange={onAutocompleteChange}
            defaultValue={selectedOption}
            disabled={applyToLatestMaster}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={applyToLatestMaster}
                onChange={handleApplyToLatestMasterChange}
              />
            }
            label="Apply to latest commit"
          />
        </>
      )}
    </>
  );
}

const useStyles = makeStylesHook({
  autocomplete: { width: '300px', marginTop: '1rem' },
  optionRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '48px',
    verticalAlign: 'center',
  },
  secondaryLabel: { fontSize: '12px' },
});

type SelectorOption = {
  label: string;
  commit: Commit;
};

function getOptions(
  configurationType: ConfigurationType,
  commits: Commits,
): SelectorOption[] {
  switch (configurationType) {
    case ConfigurationType.GLOBAL: {
      const relengCommits = commits.relengCommits
        .map(({ commit, parentMasterCommit }) => {
          // label is the name of the releng branch, but in background the configuration
          // will be valid from the closest previous master commit on all branches
          return {
            label: commit.commitBranch,
            commit: parentMasterCommit,
          };
        })
        .filter(isNotNull)
        .sort(compareOptionsByDateDesc);

      const masterCommit = commits.latestCommits.find(commit => isMasterBranch(commit));
      const masterCommits = masterCommit
        ? [{ label: masterCommit.commitBranch, commit: masterCommit }]
        : [];

      return [...masterCommits, ...relengCommits];
    }

    case ConfigurationType.SINGLE_BRANCH:
      return commits.latestCommits
        .filter(commit => !isMasterBranch(commit))
        .map(commit => ({
          label: commit.commitBranch,
          commit,
        }))
        .sort(compareOptionsByNameAsc);
  }
}

function filterAllCommitOptions(
  options: SelectorOption[],
  state: FilterOptionsState<SelectorOption>,
): SelectorOption[] {
  const query = state.inputValue.toLocaleLowerCase();
  return options.filter(option =>
    option.commit.commitBranch.toLocaleLowerCase().includes(query),
  );
}

function filterMasterCommitHashOptions(
  options: SelectorOption[],
  state: FilterOptionsState<SelectorOption>,
): SelectorOption[] {
  const query = state.inputValue.toLocaleLowerCase();
  return options.filter(option =>
    option.commit.commitHash.toLocaleLowerCase().startsWith(query),
  );
}

function compareOptionsByDateDesc(o1: SelectorOption, o2: SelectorOption): number {
  return -o1.commit.commitDate.diff(o2.commit.commitDate, 'seconds');
}

function compareOptionsByNameAsc(o1: SelectorOption, o2: SelectorOption): number {
  return o1.commit.commitBranch.localeCompare(o2.commit.commitBranch);
}

function getCommitBranchAndHash(commit: Commit) {
  return commit.commitBranch + '_' + commit.commitHash;
}
