import {
  ElementConfigurationAction,
  ElementConfigurationCondition,
  ElementConfigurationRule,
} from 'common/types/elementConfiguration';

export function isParameterUsedInRule(
  rule: ElementConfigurationRule,
  parameterName: string,
): boolean {
  return (
    isParameterUsedInCondition(rule.condition, parameterName) ||
    rule.actions.some(action => isParameterUsedInAction(action, parameterName))
  );
}

export function isParameterUsedInCondition(
  condition: ElementConfigurationCondition,
  parameterName: string,
): boolean {
  switch (condition.type) {
    case 'parameter-value-compare':
      return condition.parameterName === parameterName;
    case 'are-parameters-connected':
      return condition.parameterNames.some(n => n === parameterName);
    case 'are-parameters-set':
      return condition.parameterNames.some(n => n === parameterName);
    case 'const-true':
      return false;
    case 'list':
      return condition.childConditions.some(childCondition =>
        isParameterUsedInCondition(childCondition, parameterName),
      );
    case 'not':
      return isParameterUsedInCondition(condition.childCondition, parameterName);
  }
}

export function isParameterUsedInAction(
  action: ElementConfigurationAction,
  parameterName: string,
): boolean {
  switch (action.type) {
    case 'add-parameter-error':
    case 'add-parameter-warning':
      return action.parameterName === parameterName;
    case 'set-parameters-enabled':
    case 'set-parameters-not-enabled':
    case 'set-parameters-required':
    case 'set-parameters-not-required':
    case 'set-parameters-visible':
    case 'set-parameters-not-visible':
      return action.parameterNames.some(n => n === parameterName);
  }
}
