import React, { useCallback } from 'react';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { RouteComponentProps } from 'react-router-dom';

import { ChooseCommitDialogResult } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/ChooseTypeConfigurationCommitDialog';
import { useChooseTypeConfigurationCommitDialog } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useChooseTypeConfigurationCommitDialog';
import { useTypeNames } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useTypeNames';
import { ROUTES } from 'admin-common/src/routing/routes';
import { TypeName } from 'common/types/typeConfiguration';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = RouteComponentProps<{}>;

/**
 * Main page of the Type Configuration tool.
 */
export function ChooseType({ history }: Props) {
  const classes = useStyles();

  const { loading, typeNames } = useTypeNames();

  const onTypeNameChange = useCallback(
    (_event: any, typeName: TypeName | null) =>
      history.push(
        typeName
          ? ROUTES.TYPE_CONFIGURATION.LIST.getPath({
              typeName,
            })
          : ROUTES.TYPE_CONFIGURATION.ROOT.getPath(),
      ),
    [history],
  );

  const openChooseCommitDialog = useChooseTypeConfigurationCommitDialog();
  const onCommitSelected = useCallback(
    (dialogResult: ChooseCommitDialogResult) => {
      const { commit, configurationType, typeName } = dialogResult;
      history.push(
        ROUTES.TYPE_CONFIGURATION.CREATE.getPath({
          typeName,
          commitBranch: commit.commitBranch,
          commitHash: commit.commitHash,
          configurationType,
        }),
      );
    },
    [history],
  );

  const onCreateConfiguration = useCallback(
    () =>
      openChooseCommitDialog({
        typeName: null,
        initialCommit: null,
        onCommitSelected,
        isNewConfiguration: true,
        dialogTitle: 'Create new type configuration',
        isOpen: true,
      }),
    [onCommitSelected, openChooseCommitDialog],
  );

  return (
    <>
      <div className={classes.toolbar}>
        <Button variant="contained" color="secondary" onClick={onCreateConfiguration}>
          Create type configuration
        </Button>
      </div>
      <Typography variant="body1" gutterBottom className={classes.title}>
        Select a type to view and update its configurations across all branches and
        environments.
      </Typography>

      <Box>
        <Autocomplete
          disableClearable
          filterOptions={createFilterOptions()}
          loading={loading}
          options={typeNames || []}
          renderInput={params => (
            <TextField {...params} label="Type name" variant="outlined" />
          )}
          value={undefined}
          onChange={onTypeNameChange}
        />
      </Box>
    </>
  );
}

const useStyles = makeStylesHook({
  title: { marginTop: '1rem' },
  toolbar: {
    marginTop: '1em',
  },
});
