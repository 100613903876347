import React, { useContext } from 'react';

import { Permission as AdminPermission } from 'admin-common/src/auth/permissions';
import { createHasPermission } from 'common/ui/components/HasPermission';
import {
  createPermissionsProvider,
  DEFAULT_CONTEXT,
  PermissionsContext,
} from 'common/ui/PermissionsProvider';

const permissionContext = React.createContext<PermissionsContext<AdminPermission>>(
  DEFAULT_CONTEXT as PermissionsContext<AdminPermission>,
);

export function usePermissions() {
  return useContext(permissionContext);
}

export const PermissionsProvider = createPermissionsProvider(permissionContext);

export const HasPermission = createHasPermission<AdminPermission>(usePermissions);
