import { useMemo } from 'react';

import { ApolloError, useQuery } from '@apollo/client';

import { GET_COMMITS } from 'admin-client/app/api/gql/queries';
import { Commits } from 'admin-common/src/commit';
import { parseCommits } from 'admin-common/src/parseCommits';

/**
 * Hook for fetching and parsing commits into Commits structure.
 */
export function useCommits(): {
  commits: Commits | null;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { loading, data, error } = useQuery(GET_COMMITS);
  const commits = useMemo(
    () => (data?.commits ? parseCommits(data?.commits) : null),
    [data],
  );
  return { commits, loading, error };
}
