import React, { HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { RouteComponentProps } from 'react-router-dom';

import { GET_ELEMENTS } from 'admin-client/app/api/gql/queries';
import { useCommits } from 'admin-client/app/components/ElementConfiguration/useCommits';
import { ArrayElement, getElementsQuery } from 'admin-client/app/gql';
import { ROUTES } from 'admin-common/src/routing/routes';
import { pluralize } from 'common/lib/format';
import { Commit, ConfigurationType } from 'common/types/commonConfiguration';
import { ElementName } from 'common/types/elementConfiguration';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type GraphQLElement = ArrayElement<getElementsQuery['elements']>;

/**
 * The default branch on which test configurations should be copied/moved to
 * before being copied/moved to a master (Global) branch.
 */
export const DEFAULT_BRANCH_NAME = '00-Config-Preview';

type Props = RouteComponentProps<{}>;

/**
 * Main page of the Element Configuration tool.
 */
export function ChooseElement({ history }: Props) {
  const classes = useStyles();
  const { commits, loading: loadingCommits } = useCommits();
  const [selectedCommit, setSelectedCommit] = useState<Commit | null>(null);

  useEffect(() => {
    if (selectedCommit || !commits?.latestCommits?.length) {
      return;
    }
    const defaultCommit = commits.latestCommits.find(
      commit => commit.commitBranch === DEFAULT_BRANCH_NAME,
    );
    if (defaultCommit) {
      setSelectedCommit(defaultCommit);
    }
  }, [commits, selectedCommit]);

  const { data: elementsData, loading } = useQuery(GET_ELEMENTS, {
    variables: {
      commitHash: selectedCommit?.commitHash ?? '',
      commitBranch: selectedCommit?.commitBranch ?? '',
      commitDate: selectedCommit?.commitDate.toISOString() ?? '',
    },
    skip: !selectedCommit,
  });

  // cache options since their creation takes a little bit of time
  const elementOptions = useMemo(
    () => elementsData?.elements.map(createElementOption) ?? [],
    [elementsData],
  );

  const onElementChange = useCallback(
    (_event: any, element: ElementOption | null) =>
      history.push(
        element && selectedCommit
          ? ROUTES.ELEMENT_CONFIGURATION.LIST_WITH_QUERY_PARAMS.getPath({
              elementName: element.name,
              commitBranch: selectedCommit.commitBranch,
              commitDate: selectedCommit.commitDate.toString(),
              commitHash: selectedCommit.commitHash,
            })
          : ROUTES.ELEMENT_CONFIGURATION.ROOT.getPath(),
      ),
    [history, selectedCommit],
  );

  const onBranchChanged = useCallback(
    (_event: any, value: Commit | null) => value && setSelectedCommit(value),
    [],
  );

  return (
    <>
      <Typography variant="body1" gutterBottom className={classes.title}>
        Select an element to view and create its configurations across all branches and
        environments.
      </Typography>

      <Box marginBottom="1rem" marginTop="1rem">
        <Autocomplete
          disableClearable
          filterOptions={createFilterOptions()}
          getOptionLabel={getCommitLabel}
          loading={loadingCommits}
          options={commits?.latestCommits ?? []}
          renderInput={params => (
            <TextField {...params} label="Branch" variant="outlined" />
          )}
          value={selectedCommit ?? undefined}
          onChange={onBranchChanged}
        />
      </Box>
      <Box>
        <Autocomplete
          disableClearable
          filterOptions={createFilterOptions()}
          getOptionLabel={getElementLabel}
          loading={loading}
          options={elementOptions}
          renderInput={params => (
            <TextField {...params} label="Element" variant="outlined" />
          )}
          value={undefined}
          onChange={onElementChange}
          renderOption={renderElementOption}
        />
      </Box>
    </>
  );
}

function getCommitLabel(commit: Commit) {
  return commit.commitBranch;
}

type ElementOption = {
  name: ElementName;
  primaryText: string;
  secondaryText: string;
};

function createElementOption(element: GraphQLElement): ElementOption {
  return {
    name: element.name,
    primaryText: getElementPrimaryText(element),
    secondaryText: getElementSecondaryText(element),
  };
}

function getElementPrimaryText(element: GraphQLElement) {
  return element.currentConfiguration?.spec.elementDisplayName ?? element.name;
}

function getElementSecondaryText(element: GraphQLElement) {
  const globalCount = element.configurations.filter(
    c => c.configurationType === ConfigurationType.GLOBAL,
  ).length;
  const singleCount = element.configurations.filter(
    c => c.configurationType === ConfigurationType.SINGLE_BRANCH,
  ).length;

  if (globalCount === 0 && singleCount === 0) {
    return 'no configurations';
  }

  return `${pluralize(globalCount, 'global configuration')}, ${pluralize(
    singleCount,
    'single-branch configuration',
  )}`;
}

function getElementLabel(option: ElementOption) {
  return option.primaryText;
}

function renderElementOption(
  props: HTMLAttributes<HTMLLIElement>,
  option: ElementOption,
) {
  return (
    <ListItem dense {...props}>
      <ListItemText primary={option.primaryText} secondary={option.secondaryText} />
    </ListItem>
  );
}

const useStyles = makeStylesHook({
  title: { marginTop: '1rem' },
});
