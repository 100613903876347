import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { RouteComponentProps } from 'react-router-dom';

import { ElementConfigurationCard } from 'admin-client/app/components/ElementConfiguration/ElementConfigurationCard';
import { useElementConfigurations } from 'admin-client/app/components/ElementConfiguration/useElementConfigurations';
import { ElementConfigurationID, ElementName } from 'common/types/elementConfiguration';

type Props = RouteComponentProps<{
  elementName: ElementName;
  id: ElementConfigurationID;
}>;

/**
 * Page for editing existing element configuration.
 */
export function EditElementConfiguration({ match }: Props) {
  const { id: configurationID, elementName } = match.params;
  const { configurations } = useElementConfigurations(elementName);

  // element is fetched with list of all its configurations. We will need this
  // list in ElementConfigurationCard to prevent creating multiple configurations
  // attached to the same commit, so there is no point in optimizing this to fetch
  // just the single configuration for editing. We'll need them all soon.
  const configuration = useMemo(
    () => configurations.find(configuration => configuration.id === configurationID),
    [configurationID, configurations],
  );

  return configuration ? (
    <ElementConfigurationCard
      key={configuration.id}
      elementName={elementName}
      configuration={configuration}
    />
  ) : (
    <Box paddingTop={2}>
      <CircularProgress size={24} color="primary" />
    </Box>
  );
}
