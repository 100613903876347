import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AdditionalPropsEditor from 'admin-client/app/components/ElementConfiguration/Card/AdditionalPropsEditor';
import { RuleEditor } from 'admin-client/app/components/ElementConfiguration/Card/rules/RuleEditor';
import DefaultValueEditor from 'admin-client/app/components/ElementConfiguration/DefaultValueEditor';
import ResetButton from 'admin-client/app/components/ElementConfiguration/ResetButton';
import RowHeader from 'admin-client/app/components/ElementConfiguration/RowHeader';
import { MarkdownEditor } from 'admin-client/app/components/Markdown/MarkdownEditor';
import { MeasurementAdditionalProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { AdditionalEditorProps } from 'common/elementConfiguration/AdditionalEditorProps';
import canSpecifyDefaultValueForEditor from 'common/elementConfiguration/canSpecifyDefaultValueForEditor';
import { getDefaultsForParameterEditor } from 'common/elementConfiguration/createConfigurationSpec';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { BACKUP_EDITOR_OPTIONS } from 'common/elementConfiguration/EditorType';
import {
  getAdditionalEditorPropsForEditorType,
  getEditorTypeProperties,
} from 'common/elementConfiguration/getEditorTypeInfo';
import {
  getEditorChoicesFromAnthaType,
  isCompoundType,
} from 'common/elementConfiguration/parameterUtils';
import { isDefined } from 'common/lib/data';
import { Markdown } from 'common/lib/markdown';
import { APIElement } from 'common/types/api';
import { Commit } from 'common/types/commonConfiguration';
import {
  ElementConfigurationRule,
  ElementConfigurationSpec,
  ParameterConfigurationSpec,
} from 'common/types/elementConfiguration';
import { TypeConfigurationSpec } from 'common/types/typeConfiguration';
import getMeasurementFromString from 'common/ui/components/ParameterEditors/unitRegistry';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useCheckboxChange from 'common/ui/hooks/useCheckboxChange';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

/**
 * Renders editor for single parameter configuration
 */
export function ParameterEditorDialog({
  element,
  isOpen,
  parameterName,
  parameterType,
  anthaType,
  initialSpec,
  currentCommit,
  typeConfigurations,
  onClose,
}: {
  element: APIElement;
  isOpen: boolean;
  parameterName: string;
  parameterType: 'input' | 'output';
  anthaType: string;
  initialSpec: ElementConfigurationSpec;
  currentCommit: Commit;
  typeConfigurations: Record<string, TypeConfigurationSpec>;
  onClose: (spec: ElementConfigurationSpec | null) => void;
}) {
  const classes = useStyles();
  const typeConfiguration = typeConfigurations[anthaType];

  const [spec, setSpec] = useState(() => deepCloneSpec(initialSpec));

  /** spec must be updated if initialSpec changes to ensure we always are working
   * with the latest values from props
   */
  useEffect(() => {
    setSpec(deepCloneSpec(initialSpec));
  }, [initialSpec]);

  const hasEditorTypeChanged = checkIfTypeHasChanged(
    anthaType,
    spec.parameters[parameterName],
    typeConfiguration,
  );

  const parameterConfig = hasEditorTypeChanged
    ? updateEditorTypeValue(spec.parameters[parameterName], anthaType, typeConfiguration)
    : spec.parameters[parameterName];
  if (hasEditorTypeChanged) {
    setSpec({
      ...spec,
      parameters: {
        ...spec.parameters,
        [parameterName]: parameterConfig,
      },
    });
  }

  const editorTypeOptions: Option<EditorType>[] = useMemo(() => {
    let types = getEditorChoicesFromAnthaType(anthaType, typeConfiguration);
    types = types.length > 0 ? types : BACKUP_EDITOR_OPTIONS;

    return types.filter(isDefined).map((editor: EditorType) => ({
      label: getEditorTypeProperties(editor)?.displayName ?? editor,
      value: editor,
    }));
  }, [anthaType, typeConfiguration]);

  const {
    type: selectedEditorType,
    additionalProps: additionalEditorProps,
    placeholder,
  } = parameterConfig.editor;

  const selectedEditorTypeProperties = useMemo(() => {
    return selectedEditorType ? getEditorTypeProperties(selectedEditorType) : undefined;
  }, [selectedEditorType]);

  const updateConfig = useCallback(
    (mutator: (config: ParameterConfigurationSpec) => ParameterConfigurationSpec) =>
      setSpec({
        ...spec,
        parameters: {
          ...spec.parameters,
          [parameterName]: mutator(spec.parameters[parameterName]),
        },
      }),
    [parameterName, spec],
  );

  const onNameChange = useTextFieldChange(
    useCallback(
      (displayName: string) => updateConfig(config => ({ ...config, displayName })),
      [updateConfig],
    ),
  );
  const onDescriptionChange = useCallback(
    (displayDescription: Markdown) =>
      updateConfig(config => ({ ...config, displayDescription })),
    [updateConfig],
  );

  const originalParameterInfo = useMemo(() => {
    const parameterInfo =
      parameterType === 'input' ? element.in_ports : element.out_ports;
    return parameterInfo.find(p => p.name === parameterName);
  }, [element.in_ports, element.out_ports, parameterType, parameterName]);

  const onDescriptionReset = useCallback(() => {
    const originalDescription = originalParameterInfo?.description ?? '';
    updateConfig(config => ({
      ...config,
      displayDescription: originalDescription as Markdown,
    }));
  }, [originalParameterInfo, updateConfig]);

  const onParameterNameReset = useCallback(() => {
    const originalName = originalParameterInfo?.name ?? '';
    updateConfig(config => ({ ...config, displayName: originalName }));
  }, [originalParameterInfo, updateConfig]);

  const onIsVisibleChange = useCheckboxChange(
    useCallback(
      (isVisible: boolean) =>
        updateConfig(config => ({
          ...config,
          isVisible,
        })),
      [updateConfig],
    ),
  );

  const onIsConnectableChange = useCheckboxChange(
    useCallback(
      (isConnectable: boolean) =>
        updateConfig(config => ({ ...config, isConnectable: isConnectable })),
      [updateConfig],
    ),
  );

  const onIsInternalOnlyChange = useCheckboxChange(
    useCallback(
      (isInternalOnly: boolean) =>
        updateConfig(config => ({
          ...config,
          isInternalOnly,
        })),
      [updateConfig],
    ),
  );

  const onIsDOEableChange = useCheckboxChange(
    useCallback(
      (isDOEable: boolean) =>
        updateConfig(config => ({
          ...config,
          isDOEable,
        })),
      [updateConfig],
    ),
  );

  const onShortDescriptionChange = useTextFieldChange(
    useCallback(
      (shortDescription: string) =>
        updateConfig(config => ({
          ...config,
          shortDescription,
        })),
      [updateConfig],
    ),
  );

  const onDefaultValueChange = useCallback(
    (defaultValue: any) => updateConfig(config => ({ ...config, defaultValue })),
    [updateConfig],
  );

  const onConfirm = useCallback(() => onClose(spec), [onClose, spec]);
  const onCancel = useCallback(() => {
    onClose(null);
    setSpec(initialSpec);
  }, [initialSpec, onClose]);

  const onRulesChange = useCallback(
    (rules: ElementConfigurationRule[]) => setSpec(spec => ({ ...spec, rules })),
    [],
  );

  // in order to include settings not yet confirmed in the dialog, override the configuration
  // passed to some components with the current configuration. This way editing the display name
  // immediately propagates down to components like InputSelector.
  const elementForEditor = useMemo(
    () => ({ ...element, configuration: spec }),
    [element, spec],
  );

  const onPlaceholderChange = useTextFieldChange(
    useCallback(
      (placeholder: string) =>
        updateConfig(config => ({
          ...config,
          editor: { ...config.editor, placeholder },
        })),
      [updateConfig],
    ),
  );

  const onEditorTypeChange = useCallback(
    (type?: EditorType) => {
      if (!type) {
        return;
      }
      const additionalProps = getAdditionalEditorPropsForEditorType(type, anthaType);
      updateConfig(config => ({
        ...config,
        editor: { ...config.editor, type, additionalProps: additionalProps },
      }));
    },
    [anthaType, updateConfig],
  );

  const onAdditionalEditorPropsChange = useCallback(
    (additionalProps: AdditionalEditorProps) => {
      let defaultValue = parameterConfig.defaultValue;
      /**
       * In the case of the Measurement editor, we want to make sure that the default
       * value unit is always taken from the selected units that the user changes so
       * we check here if this is included and if not, remove it from the default value
       */
      if (selectedEditorType === EditorType.MEASUREMENT) {
        const measurement = getMeasurementFromString(parameterConfig.defaultValue ?? '');
        // parameterConfig.defaultValue might not have a numerical value set (e.g. when the user is first editing the unit selection)
        // and getMeasurementFromString() will return NaN for this. This is an invalid measurement, so set this to undefined.
        if (isNaN(measurement.value)) {
          defaultValue = undefined;
        } else {
          const measurementProps = additionalProps as MeasurementAdditionalProps;
          const updatedUnit = measurementProps.units.includes(measurement.unit)
            ? measurement.unit
            : '';
          defaultValue = `${measurement.value}${updatedUnit}`;
        }
      }

      updateConfig(config => ({
        ...config,
        editor: { ...config.editor, additionalProps },
        defaultValue,
      }));
    },
    [parameterConfig.defaultValue, selectedEditorType, updateConfig],
  );

  if (!parameterConfig) {
    return null;
  }

  const isInput = parameterType === 'input';

  const canSpecifyDefaultValue = selectedEditorType
    ? canSpecifyDefaultValueForEditor(
        selectedEditorType,
        additionalEditorProps ?? undefined,
      )
    : false;

  /**
   * An undefined checkbox or toggle looks the same as one that has had
   * the value set to false explicitly and can be confusing for a user to
   * understand if the value is undefined or false. In this case, we allow
   * the user to clear the value explicitly.
   */
  const isBooleanEditor =
    selectedEditorType === EditorType.CHECKBOX ||
    selectedEditorType === EditorType.TOGGLE;

  const defaultValueIsUndefined =
    parameterConfig.defaultValue === undefined && isBooleanEditor;

  const onClickRemoveDefaultValue = () => {
    onDefaultValueChange(undefined);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(_, reason) => {
        if (reason === 'escapeKeyDown') {
          onCancel();
        }
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        Parameter {parameterConfig.displayName}{' '}
        {parameterName !== parameterConfig.displayName ? ` (${parameterName})` : null}
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <RowHeader>Display name</RowHeader>
              <TableCell className={classes.fullWidthColumn}>
                <Box display="flex" alignItems="center">
                  <TextField
                    fullWidth
                    value={parameterConfig.displayName}
                    onChange={onNameChange}
                    className={classes.nameTextField}
                  />
                  {originalParameterInfo && (
                    <ResetButton
                      disabled={
                        originalParameterInfo.name === parameterConfig.displayName
                      }
                      onClick={onParameterNameReset}
                      resetObjectName="parameter display name"
                      additionalMessage="This cannot be undone unless you Cancel the parameter changes."
                    />
                  )}
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <RowHeader>Description</RowHeader>
              <TableCell>
                <MarkdownEditor
                  currentCommit={currentCommit}
                  value={parameterConfig.displayDescription}
                  onChange={onDescriptionChange}
                />
                {originalParameterInfo && (
                  <ResetButton
                    disabled={
                      originalParameterInfo.description ===
                      parameterConfig.displayDescription
                    }
                    onClick={onDescriptionReset}
                    resetObjectName="parameter description"
                    additionalMessage="This cannot be undone unless you Cancel the parameter changes."
                  />
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <RowHeader>Is active (untick to deprecate)</RowHeader>
              <TableCell>
                <Checkbox
                  checked={parameterConfig.isVisible}
                  onChange={onIsVisibleChange}
                />
              </TableCell>
            </TableRow>

            {parameterConfig.isVisible && (
              <TableRow>
                <RowHeader>Is connectable</RowHeader>
                <TableCell>
                  <Checkbox
                    checked={parameterConfig.isConnectable ?? true}
                    onChange={onIsConnectableChange}
                  />
                </TableCell>
              </TableRow>
            )}

            {isInput && (
              <TableRow>
                <RowHeader>Is internal only</RowHeader>
                <TableCell>
                  <Checkbox
                    checked={parameterConfig.isInternalOnly ?? false}
                    onChange={onIsInternalOnlyChange}
                  />
                </TableCell>
              </TableRow>
            )}

            {isInput && (
              <TableRow>
                <RowHeader>Is DOE-able</RowHeader>
                <TableCell>
                  <Checkbox
                    checked={parameterConfig.isDOEable ?? false}
                    onChange={onIsDOEableChange}
                  />
                </TableCell>
              </TableRow>
            )}

            {isInput && (
              <TableRow>
                <RowHeader>
                  Description that will show up on the Element Instance Panel
                </RowHeader>
                <TableCell className={classes.fullWidthColumn}>
                  <TextField
                    fullWidth
                    value={parameterConfig.shortDescription ?? ''}
                    onChange={onShortDescriptionChange}
                  />
                </TableCell>
              </TableRow>
            )}

            {isInput && (
              <TableRow>
                <RowHeader>Rules</RowHeader>
                <TableCell>
                  <RuleEditor
                    element={elementForEditor}
                    rules={spec.rules ?? []}
                    defaultParameterName={parameterName}
                    typeConfigurations={typeConfigurations}
                    onChange={onRulesChange}
                  />
                </TableCell>
              </TableRow>
            )}
            {isInput && (
              <TableRow>
                <RowHeader>Editor selection</RowHeader>
                <TableCell>
                  {!typeConfiguration?.editorTypeOptions.length &&
                    !isCompoundType(anthaType) && (
                      <p>
                        No editor choices have been added to the type configuration for
                        the &quot;{anthaType}&quot; type.
                      </p>
                    )}
                  <Dropdown<EditorType>
                    valueLabel={selectedEditorTypeProperties?.displayName ?? ''}
                    placeholder="Select an editor type"
                    isRequired
                    isDisabled={editorTypeOptions.length < 1}
                    options={editorTypeOptions}
                    onChange={onEditorTypeChange}
                  />
                </TableCell>
              </TableRow>
            )}
            {isInput && selectedEditorType && additionalEditorProps && (
              <TableRow>
                <AdditionalPropsEditor
                  editorProps={additionalEditorProps}
                  updateSpec={onAdditionalEditorPropsChange}
                  anthaType={anthaType}
                  typeConfigurations={typeConfigurations}
                />
              </TableRow>
            )}
            {isInput && selectedEditorType && canSpecifyDefaultValue && (
              <TableRow>
                <RowHeader>Default value</RowHeader>
                <TableCell>
                  <DefaultValueEditor
                    anthaType={anthaType}
                    editorType={selectedEditorType}
                    editorProps={additionalEditorProps ?? undefined}
                    value={parameterConfig.defaultValue}
                    onChange={onDefaultValueChange}
                  />

                  {isBooleanEditor && (
                    <>
                      <Button
                        variant="outlined"
                        disabled={defaultValueIsUndefined}
                        onClick={onClickRemoveDefaultValue}
                      >
                        Clear default value
                      </Button>
                      <Typography variant="body1" align="left">
                        Default value is currently:{' '}
                        {parameterConfig.defaultValue?.toString() ?? `undefined`}
                      </Typography>
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
            {isInput && selectedEditorTypeProperties?.canSetPlaceholder && (
              <TableRow>
                <RowHeader>Placeholder</RowHeader>
                <TableCell>
                  <TextField
                    fullWidth
                    value={placeholder}
                    onChange={onPlaceholderChange}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function checkIfTypeHasChanged(
  anthaType: string | undefined,
  parameterConfig: ParameterConfigurationSpec,
  typeConfiguration?: TypeConfigurationSpec,
) {
  if (!anthaType || !parameterConfig.editor.type) {
    return false;
  }
  // If the editor in the parameterConfig isn't in our list of default editor choices
  // for the anthaType (from the type configuration or the backup editor list)
  // the type must have changed in the element code, or the type in the existing config
  // is no longer present in the editor choices from the typeConfiguration.
  const editorChoices = getEditorChoicesFromAnthaType(anthaType, typeConfiguration);
  return editorChoices.length > 0
    ? !editorChoices.includes(parameterConfig.editor.type)
    : false;
}

/**
 * It's possible the type associated with the editor in the parameterConfig has
 * changed in the element code, or has been removed as an option from the typeConfiguration,
 * so in that case we should update the parameterConfig editor to be the default for the new type.
 * @param parameterConfig
 * @param anthaType
 */
function updateEditorTypeValue(
  parameterConfig: ParameterConfigurationSpec,
  anthaType: string | undefined,
  typeConfiguration?: TypeConfigurationSpec,
) {
  if (!anthaType || !parameterConfig.editor.type) {
    return parameterConfig;
  }
  const { defaultEditor, additionalProps, placeholder } = getDefaultsForParameterEditor(
    anthaType,
    typeConfiguration,
  );
  return {
    ...parameterConfig,
    defaultValue: undefined, // The new defaultEditor might be incompatible with an existing defaultValue, so clear it.
    editor: {
      ...parameterConfig.editor,
      type: defaultEditor,
      placeholder: placeholder,
      additionalProps: additionalProps,
    },
  };
}

const useStyles = makeStylesHook({
  fullWidthColumn: { width: '100%' },
  nameTextField: { paddingTop: '5px' },
});

function deepCloneSpec(spec: ElementConfigurationSpec): ElementConfigurationSpec {
  return {
    ...spec,
    inputGroups: spec.inputGroups.map(group => ({
      ...group,
      parameterNames: [...group.parameterNames],
    })),
    outputOrder: [...spec.outputOrder],
    parameters: { ...spec.parameters },
  };
}
