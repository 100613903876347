import React, { useCallback, useMemo } from 'react';

import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { APIElement, APIElementPort } from 'common/types/api';

export function MultiInputSelector({
  element,
  selectedParameterNames,
  className,
  onChange,
}: {
  element: APIElement;
  selectedParameterNames: string[];
  className?: string;
  onChange: (parameterNames: string[]) => void;
}) {
  const onParametersChange = useCallback(
    (_event: any, parameterNames: string[] | null) =>
      parameterNames && onChange(parameterNames),
    [onChange],
  );

  const parameterNames = useMemo(
    () => element.in_ports.map(port => port.name).sort(),
    [element.in_ports],
  );

  // map from parameterName to displayName
  const parameterDisplayNames: { [parameterName: string]: string } = useMemo(
    () =>
      parameterNames.reduce(
        (acc, parameterName) => ({
          ...acc,
          [parameterName]:
            element.configuration?.parameters?.[parameterName]?.displayName ??
            parameterName,
        }),
        {},
      ),
    [element.configuration, parameterNames],
  );

  const getOptionLabel = useCallback(
    (parameterName: string) => parameterDisplayNames[parameterName] ?? parameterName,
    [parameterDisplayNames],
  );

  return (
    <Autocomplete
      multiple
      className={className}
      disableClearable
      getOptionLabel={getOptionLabel}
      options={parameterNames}
      renderInput={renderInput}
      size="small"
      value={selectedParameterNames}
      onChange={onParametersChange}
    />
  );
}

export function SingleInputSelector({
  element,
  selectedParameterName,
  className,
  onChange,
  filter,
}: {
  element: APIElement;
  selectedParameterName?: string;
  className?: string;
  onChange: (parameterName: string) => void;
  filter?: (element: APIElement, port: APIElementPort) => boolean;
}) {
  const onParameterChange = useCallback(
    (_event: any, parameterName: string | null) =>
      parameterName && onChange(parameterName),
    [onChange],
  );

  const parameterNames = useMemo(() => {
    return element.in_ports
      .filter(port => filter?.(element, port) ?? true)
      .map(port => port.name)
      .sort();
  }, [element, filter]);

  // map from parameterName to displayName
  const parameterDisplayNames: { [parameterName: string]: string } = useMemo(
    () =>
      parameterNames.reduce(
        (acc, parameterName) => ({
          ...acc,
          [parameterName]:
            element.configuration?.parameters?.[parameterName]?.displayName ??
            parameterName,
        }),
        {},
      ),
    [element.configuration, parameterNames],
  );

  const getOptionLabel = useCallback(
    (parameterName: string) => parameterDisplayNames[parameterName] ?? parameterName,
    [parameterDisplayNames],
  );

  return (
    <Autocomplete
      className={className}
      disableClearable
      getOptionLabel={getOptionLabel}
      options={parameterNames}
      renderInput={renderInput}
      size="small"
      value={selectedParameterName}
      onChange={onParameterChange}
    />
  );
}

function renderInput(params: AutocompleteRenderInputParams) {
  return <TextField {...params} variant="outlined" />;
}
