import React, { useCallback } from 'react';

import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

import { GraphQLNotification } from 'admin-client/app/api/Notifications';
import Colors from 'common/ui/Colors';

type Props = {
  notifications: GraphQLNotification[];
  clickHandler?: (notificationId: string) => void;
};

export const NotificationsTable = React.memo(function NotificationsTable(props: Props) {
  const { notifications, clickHandler } = props;

  return (
    <section style={{ display: 'block' }}>
      <Paper elevation={0}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Notifications</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.map(notification => {
              return (
                <NotificationRow
                  key={notification.id}
                  notification={notification}
                  clickHandler={clickHandler}
                />
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </section>
  );
});

type NotificationRowProps = {
  notification: GraphQLNotification;
  clickHandler?: (notificationId: string) => void;
};

const NotificationRow = React.memo((props: NotificationRowProps) => {
  const classes = useStyles();
  const { clickHandler, notification } = props;
  const createdAt = notification.createdAt ? moment(notification.createdAt) : undefined;

  const notificationClickHandler = useCallback(() => {
    if (clickHandler && notification.id) {
      clickHandler(notification.id);
    }
  }, [clickHandler, notification.id]);

  const linkContents = (
    <Typography
      variant="body2"
      className={notification.isRead ? classes.readTitle : classes.unreadTitle}
      onClick={notificationClickHandler}
    >
      {notification.message}
    </Typography>
  );

  const link = (url => {
    if (/https?:\/\//.test(url)) {
      return <Link href={url}>{linkContents}</Link>;
    } else if (url) {
      return (
        <Link component={RouterLink} to={url}>
          {linkContents}
        </Link>
      );
    }
    return linkContents;
  })(notification.url ?? 'Link unavailable');

  return (
    <TableRow key={notification.id}>
      <TableCell className={notification.isRead ? classes.read : classes.unread}>
        {link}
        <Typography variant="subtitle2" className={classes.timestamp}>
          {createdAt ? createdAt.fromNow() : 'Notification creation time unavailable'}
        </Typography>
      </TableCell>
    </TableRow>
  );
});

const useStyles = makeStyles({
  read: {
    backgroundColor: '#eee',
  },
  unread: {},
  readTitle: {},
  unreadTitle: {
    fontWeight: 'bold',
  },
  timestamp: {
    color: Colors.GREY_40,
  },
});
