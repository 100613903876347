/**
 * When built locally rather than through CI, AnthaHub has a version of 0.0.0.0.
 *
 * This is defined here (the BUILD_BUILDID env variable is set during a CI build
 * but not when building locally):
 * https://github.com/Synthace/AnthaHub/blob/7d2146fd5f5756753bbd9f5893a479774b6fcbb9/AnthaHub/AnthaHub/AnthaHub.csproj#L29-L30
 */
export const AnthaHubDeveloperBuildVersion = '0.0.0.0';

/**
 * The minimum possible AnthaHub build version. This also happens to be the
 * version that developer builds use, but that's just a coincidence.
 */
export const AnthaHubMinimumBuildVersion = '0.0.0.0';
