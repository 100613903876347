import React from 'react';

import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';

import { HasPermission } from 'admin-client/app/components/Permissions';
import { ArrayElement, getOrganisationQuery } from 'admin-client/app/gql';
import { ROUTES } from 'admin-common/src/routing/routes';
import { zebraRowsStyles } from 'common/ui/commonStyles';
import { linkNoStyle } from 'common/ui/commonStyles';

type GraphQLOrganisationUser = ArrayElement<
  getOrganisationQuery['organisation']['users']
>;

type Props = {
  users: readonly GraphQLOrganisationUser[];
  toggleUserBlockedStatusHandler: (user: GraphQLOrganisationUser) => void;
};

export const OrganisationUsersTable = React.memo(function OrganisationUsersTable(
  props: Props,
) {
  const { users, toggleUserBlockedStatusHandler } = props;
  const sortedUsers = [...users].sort(
    (a: GraphQLOrganisationUser, b: GraphQLOrganisationUser) => {
      // If family names are the same, sort by given name...
      if (a.familyName.toLocaleLowerCase() === b.familyName.toLocaleLowerCase()) {
        return a.givenName
          .toLocaleLowerCase()
          .localeCompare(b.givenName.toLocaleLowerCase());
      }
      // ...else sort by family name
      return a.familyName
        .toLocaleLowerCase()
        .localeCompare(b.familyName.toLocaleLowerCase());
    },
  );

  return (
    <section style={{ display: 'block' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Given Name</TableCell>
            <TableCell>Family Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Logins</TableCell>
            <TableCell>Last Login</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsers.map(user => (
            <OrganisationUserRow
              key={user.email}
              user={user}
              toggleUserBlockedStatusHandler={toggleUserBlockedStatusHandler}
            />
          ))}
        </TableBody>
      </Table>
    </section>
  );
});

type OrganisationUserRowProps = {
  user: GraphQLOrganisationUser;
  toggleUserBlockedStatusHandler: (user: GraphQLOrganisationUser) => void;
};

const OrganisationUserRow = React.memo((props: OrganisationUserRowProps) => {
  const classes = useStyles();
  const { user, toggleUserBlockedStatusHandler } = props;

  const toggleUserBlockedStatus = () => {
    toggleUserBlockedStatusHandler(user);
  };

  const addedByText = `${user.givenName} ${user.familyName} added by ${
    user.addedBy || 'unknown'
  }`;

  type userRolePathEntry = {
    userId: string;
    path: string;
  };
  const { humanIdentifier } = useParams();

  const userRolePath: userRolePathEntry = {
    userId: user.id,
    path: ROUTES.ROLES.VIEW.getPath({
      humanIdentifier: humanIdentifier,
      userId: user.id,
    }),
  };

  return (
    <TableRow key={user.email} className={classes.fullRow}>
      <TableCell>
        <Tooltip title={addedByText}>
          <span>{user.givenName}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={addedByText}>
          <span>{user.familyName}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={addedByText}>
          <span>{user.email}</span>
        </Tooltip>
      </TableCell>
      <TableCell>{user.loginsCount}</TableCell>
      <TableCell>
        {user.lastLogin && (
          <Tooltip title={user.lastLogin}>
            <span>{moment(user.lastLogin).fromNow()}</span>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <Link
          to={{
            pathname: userRolePath.path,
            state: { humanIdentifier: humanIdentifier, user: user },
          }}
          className={classes.link}
        >
          <Button variant="contained">View Roles</Button>
        </Link>
      </TableCell>
      <TableCell>
        <HasPermission permission="update:users">
          <Switch checked={!user.blocked} onChange={toggleUserBlockedStatus} />
        </HasPermission>
      </TableCell>
    </TableRow>
  );
});

const useStyles = makeStyles({
  ...zebraRowsStyles,
  link: linkNoStyle.style,
});
