import React, { useCallback, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';

import {
  GET_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_SEEN,
  MARK_NOTIFICATION_AS_READ,
} from 'admin-client/app/api/gql/queries';
import { GraphQLNotification } from 'admin-client/app/api/Notifications';
import { NotificationsTable } from 'admin-client/app/components/Notifications/NotificationsTable';
import { getNotificationsQuery } from 'admin-client/app/gql';
import { isLocalDev, setColourForEnvironment } from 'admin-client/app/lib/utils';

const HELP_DOCUMENT_URL =
  'https://paper.dropbox.com/doc/Admin-Tool-User-Guide--Asy5mXXdtOXx~pNUw5ZL2r_GAQ-iB03pVm3yD5vr1XRVReB5';

export default function AdminUIToolbar() {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();

  const notificationsQueryResult = useQuery(GET_NOTIFICATIONS, {
    skip: !isAuthenticated,

    // refetch notifications every 15s
    pollInterval: isLocalDev(window.location) ? 0 : 15_000,
  });
  const notificationsPage = notificationsQueryResult?.data?.me?.notifications;
  const notifications = notificationsPage?.items ?? [];
  const endCursor = notificationsPage?.pageInfo.endCursor;
  const hasMoreNotifications = notificationsPage?.pageInfo.hasNextPage ?? false;
  const unseenNotificationsCount =
    notificationsQueryResult.data?.me?.unseenNotificationsCount ?? 0;

  const onFetchMoreNotifications = useCallback(async () => {
    if (!endCursor) {
      return;
    }
    await notificationsQueryResult.fetchMore({
      variables: { after: endCursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }
        // use the new fetchMoreResult as base, and merge previous notifications into it
        // in order to use cursor from fetchMoreResult, not from previousResult
        return updateNotifications(fetchMoreResult, newNotifications =>
          (previousResult.me ? [...previousResult.me.notifications.items] : []).concat(
            newNotifications ? [...newNotifications] : [],
          ),
        );
      },
    });
  }, [endCursor, notificationsQueryResult]);
  const { loginWithRedirect, logout, user, isLoading } = useAuth0();
  const [accountPopoverAnchorElement, setAccountPopoverAnchorElement] =
    useState<HTMLButtonElement | null>(null);

  const [markAllNotificationsAsSeenMutation] = useMutation(
    MARK_ALL_NOTIFICATIONS_AS_SEEN,
    {
      // Optimistically update the cache to mark all notifications as seen
      update: cache => {
        const cacheEntry = cache.readQuery({
          query: GET_NOTIFICATIONS,
        });
        if (cacheEntry) {
          const updatedCacheEntry = updateNotifications(cacheEntry, notifications =>
            (notifications ?? []).map(notif => ({ ...notif, isSeen: true })),
          );
          cache.writeQuery({
            query: GET_NOTIFICATIONS,
            data: updatedCacheEntry,
          });
        }
      },
    },
  );

  const [markAsReadMutation] = useMutation(MARK_NOTIFICATION_AS_READ);

  const loginHandler = async () => {
    await loginWithRedirect({
      appState: {
        // Restore the current URL in the browser location bar after logging in.
        returnTo: window.location.href,
      },
    });
  };
  const logoutHandler = async () => {
    await logout({ logoutParams: { returnTo: window.location.href } });
  };

  const handleAccountButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAccountPopoverAnchorElement(event.currentTarget);
  };

  const handleAccountPopoverClose = () => setAccountPopoverAnchorElement(null);

  const accountPopoverIsOpen = Boolean(accountPopoverAnchorElement);
  const accountPopoverId = accountPopoverIsOpen ? 'account-popover' : undefined;

  const [notificationsPopoverAnchorElement, setNotificationsPopoverAnchorElement] =
    useState<HTMLButtonElement | null>(null);

  const handleNotificationButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setNotificationsPopoverAnchorElement(event.currentTarget);
    await markAllNotificationsAsSeenMutation();
  };

  const handleNotificationPopoverClose = () => {
    setNotificationsPopoverAnchorElement(null);
  };

  const handleHelpButtonClick = () => {
    window.open(HELP_DOCUMENT_URL, '_blank');
  };

  const notificationsClickHandler = async (notificationId: string) => {
    // Dismiss the popover
    setNotificationsPopoverAnchorElement(null);

    await markAsReadMutation({
      variables: { notificationId },

      // Optimistically update the cache to mark this notifications as read
      update: cache => {
        const cacheEntry = cache.readQuery({
          query: GET_NOTIFICATIONS,
        });
        if (cacheEntry) {
          const updatedMe = updateNotifications(cacheEntry, notifications =>
            (notifications ?? []).map(notif =>
              notif.id === notificationId ? { ...notif, isRead: true } : { ...notif },
            ),
          );
          cache.writeQuery({
            query: GET_NOTIFICATIONS,
            data: updatedMe,
          });
        }
      },
    });
  };

  const notificationsPopoverIsOpen = Boolean(notificationsPopoverAnchorElement);
  const notificationsPopoverId = notificationsPopoverIsOpen
    ? 'simple-popover'
    : undefined;

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      style={{ background: setColourForEnvironment() }}
    >
      <Toolbar>
        <Typography variant="h6" noWrap className={classes.title}>
          <Link to="/" className={classes.titleLink}>
            Synthace Admin Tool
          </Link>
        </Typography>
        {user ? (
          <>
            <IconButton color="inherit" onClick={handleHelpButtonClick} size="large">
              <HelpIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={handleNotificationButtonClick}
              size="large"
            >
              <Badge color="secondary" badgeContent={unseenNotificationsCount}>
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Button color="inherit" onClick={handleAccountButtonClick}>
              <AccountCircle />
              {user.given_name}
            </Button>

            <Popover
              open={accountPopoverIsOpen}
              id={accountPopoverId}
              anchorEl={accountPopoverAnchorElement}
              onClose={handleAccountPopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div className={classes.userProfile}>
                <Typography>
                  Logged in as {user.given_name} {user.family_name}
                </Typography>
                <Typography variant="subtitle2">({user.email})</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={logoutHandler}
                  className={classes.logoutButton}
                >
                  Logout
                </Button>
              </div>
            </Popover>

            <Popover
              open={notificationsPopoverIsOpen}
              id={notificationsPopoverId}
              anchorEl={notificationsPopoverAnchorElement}
              onClose={handleNotificationPopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div className={classes.notiicationsTablePopoverRoot}>
                <NotificationsTable
                  notifications={notifications}
                  clickHandler={notificationsClickHandler}
                />
                <Button
                  disabled={!hasMoreNotifications}
                  disableRipple
                  fullWidth
                  onClick={onFetchMoreNotifications}
                >
                  Load more
                </Button>
              </div>
            </Popover>
          </>
        ) : isLoading ? (
          ''
        ) : (
          <Button color="inherit" onClick={loginHandler}>
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      flexGrow: 1,
    },
    titleLink: {
      textDecoration: 'none',
      color: 'inherit',
    },
    toolbar: theme.mixins.toolbar,
    userProfile: {
      padding: theme.spacing(6),
    },
    logoutButton: {
      marginTop: theme.spacing(5),
    },
    notiicationsTablePopoverRoot: {
      // Set a fixed width, so that the popover gets positioned correctly on
      // first render, before its content has loaded.
      width: '400px',
    },
  }),
);

/**
 * Updates deep field me.notifications.items. This is useful for updating existing
 * data. Used both for paging and for optimistic updates on write.
 */
function updateNotifications(
  adminUser: getNotificationsQuery,
  mutator: (notifications?: GraphQLNotification[]) => GraphQLNotification[],
): getNotificationsQuery {
  return {
    __typename: 'Query',
    me: adminUser.me
      ? {
          ...adminUser.me,
          notifications: {
            ...adminUser.me.notifications,
            items: mutator(adminUser.me.notifications.items),
          },
        }
      : null,
  };
}
