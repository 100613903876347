import React, { useCallback, useMemo, useState } from 'react';

import { ApolloCache, DefaultContext, MutationHookOptions } from '@apollo/client';

import { useAdminUIContext } from 'admin-client/app/components/AdminUIContext';
import { ConnectionsEditor } from 'admin-client/app/components/Connections/ConnectionsEditor';
import { ConnectionsViewer } from 'admin-client/app/components/Connections/ConnectionsViewer';
import {
  GraphQLConnection,
  onlyAdminServerEnabled,
} from 'admin-client/app/components/Connections/utils';
import {
  ConnectionType,
  Exact,
  getOrganisationQuery,
  OrganisationType,
} from 'admin-client/app/gql';

type ConnectionsProps = {
  connections: GraphQLConnection[];
  org: getOrganisationQuery['organisation'];
  mutationOptions?: MutationHookOptions<
    any,
    Exact<{ organisation: OrganisationType; newAuth0Connection: ConnectionType }>,
    DefaultContext,
    ApolloCache<any>
  >;
};

export const Connections = React.memo(
  ({ org, connections, mutationOptions }: ConnectionsProps) => {
    const [isEditingConnections, setIsEditingConnections] = useState(false);
    const { serverClientId } = useAdminUIContext();

    const connectionOptions = connections.filter(
      connection =>
        connection.enabledClients.length === 0 ||
        connection.id === org.auth0ConnectionId ||
        onlyAdminServerEnabled(connection.enabledClients, serverClientId),
    );

    const enabledConnection = useMemo(
      () => connections.filter(connection => connection.id === org.auth0ConnectionId),
      [connections, org.auth0ConnectionId],
    );

    const enableConnectionEditMode = useCallback(() => {
      setIsEditingConnections(true);
    }, []);

    if (isEditingConnections) {
      return (
        <ConnectionsEditor
          orgUpdate={org}
          mutationOptions={mutationOptions}
          connections={connectionOptions}
        />
      );
    }
    return (
      <ConnectionsViewer
        connections={enabledConnection}
        handleChangeConnectionsClick={enableConnectionEditMode}
      />
    );
  },
);
