import {
  isParameterUsedInAction,
  isParameterUsedInCondition,
} from 'common/rules/isParameterUsedInRule';
import {
  ElementConfigurationAction,
  ElementConfigurationCondition,
  ElementConfigurationRule,
} from 'common/types/elementConfiguration';

export function replaceParameterInRule(
  rule: ElementConfigurationRule,
  oldParameterName: string,
  newParameterName: string,
): ElementConfigurationRule {
  return {
    actions: rule.actions.map(action => {
      return replaceActionParameterName(action, oldParameterName, newParameterName);
    }),
    condition: replaceConditionParameterName(
      rule.condition,
      oldParameterName,
      newParameterName,
    ),
  };
}

function replaceConditionParameterName(
  condition: ElementConfigurationCondition,
  oldParameterName: string,
  newParameterName: string,
): ElementConfigurationCondition {
  if (!isParameterUsedInCondition(condition, oldParameterName)) {
    return condition;
  }
  const newCondition: ElementConfigurationCondition = { ...condition };

  // Only one type of condition has a single string here for parameterName, the
  // rest have parameterNames arrays, or contains childCondition(s).
  // For childCondition(s), we recursively check these as these will be conditions
  // themselves.
  if (newCondition.type === 'parameter-value-compare') {
    newCondition.parameterName = newParameterName;
    // For the value compare, make sure to update the name of the parameter being compared.
    if (newCondition.value.type === 'parameter') {
      newCondition.value.parameterName = newParameterName;
    }
  } else if (
    newCondition.type === 'are-parameters-connected' ||
    newCondition.type === 'are-parameters-set'
  ) {
    newCondition.parameterNames = newCondition.parameterNames.map(paramName => {
      return paramName === oldParameterName ? newParameterName : paramName;
    });
  } else if (newCondition.type === 'list') {
    newCondition.childConditions = newCondition.childConditions.map(child => {
      return replaceConditionParameterName(child, oldParameterName, newParameterName);
    });
  } else if (newCondition.type === 'not') {
    newCondition.childCondition = replaceConditionParameterName(
      newCondition.childCondition,
      oldParameterName,
      newParameterName,
    );
  }

  return newCondition;
}

function replaceActionParameterName(
  action: ElementConfigurationAction,
  oldParameterName: string,
  newParameterName: string,
): ElementConfigurationAction {
  if (!isParameterUsedInAction(action, oldParameterName)) {
    return action;
  }
  const newAction = { ...action };

  if (
    newAction.type === 'add-parameter-error' ||
    newAction.type === 'add-parameter-warning'
  ) {
    newAction.parameterName = newParameterName;
  } else {
    newAction.parameterNames = newAction.parameterNames.map(paramName => {
      return paramName === oldParameterName ? newParameterName : paramName;
    });
  }
  return newAction;
}
