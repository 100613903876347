import React, { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { Route, Switch } from 'react-router-dom';

import { ChooseElement } from 'admin-client/app/components/ElementConfiguration/ChooseElement';
import { CreateElementConfiguration } from 'admin-client/app/components/ElementConfiguration/CreateElementConfiguration';
import { EditElementConfiguration } from 'admin-client/app/components/ElementConfiguration/EditElementConfiguration';
import { useElementConfigurations } from 'admin-client/app/components/ElementConfiguration/useElementConfigurations';
import { ViewElementConfigurations } from 'admin-client/app/components/ElementConfiguration/ViewElementConfigurations';
import { NavigationLinks, Node } from 'admin-client/app/components/NavigationLinks';
import { ROUTES } from 'admin-common/src/routing/routes';
import { ElementConfigurationID, ElementName } from 'common/types/elementConfiguration';

/** Route tree for the NavigationLinks component. */
const ROUTE_TREE: Node[] = [
  {
    route: ROUTES.ELEMENT_CONFIGURATION.ROOT,
    getTitle: (_params: {}) => 'All elements',
    children: [
      {
        route: ROUTES.ELEMENT_CONFIGURATION.LIST,
        getTitle: (params: { elementName: ElementName }) => params.elementName,
        children: [
          {
            route: ROUTES.ELEMENT_CONFIGURATION.EDIT,
            getTitle: (params: {
              elementName: ElementName;
              id: ElementConfigurationID;
            }) => (
              <EditElementConfigurationLink
                elementName={params.elementName}
                configurationID={params.id}
              />
            ),
            children: [],
          },
          {
            route: ROUTES.ELEMENT_CONFIGURATION.CREATE,
            getTitle: (_params: {}) => 'New configuration',
            children: [],
          },
        ],
      },
    ],
  },
];

/**
 * Entry point for the Element Configuration tool.
 */
export default function ElementConfigurationContainer() {
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Element configuration
      </Typography>
      <NavigationLinks routeTree={ROUTE_TREE} />
      <Switch>
        <Route
          exact
          path={ROUTES.ELEMENT_CONFIGURATION.ROOT.pathTemplate}
          component={ChooseElement}
        />
        <Route
          exact
          path={ROUTES.ELEMENT_CONFIGURATION.CREATE.pathTemplate}
          component={CreateElementConfiguration}
        />
        <Route
          exact
          path={ROUTES.ELEMENT_CONFIGURATION.EDIT.pathTemplate}
          component={EditElementConfiguration}
        />
        <Route
          path={[
            ROUTES.ELEMENT_CONFIGURATION.LIST.pathTemplate,
            ROUTES.ELEMENT_CONFIGURATION.LIST_WITH_QUERY_PARAMS.pathTemplate,
          ]}
          component={ViewElementConfigurations}
        />
      </Switch>
    </>
  );
}

/**
 * For link to "Edit configuration" it's nice to render more details about the configuration,
 * in this case the date since which it's valid. To do that, fetch the configuration and get the date.
 *
 * We don't have endpoint to fetch a single configuration, we have endpoint to fetch all configurations
 * for an element and from those we find the one we care about.
 */
function EditElementConfigurationLink({
  configurationID,
  elementName,
}: {
  configurationID: ElementConfigurationID;
  elementName: ElementName;
}) {
  const { configurations } = useElementConfigurations(elementName);

  // element is fetched with list of all its configurations. We will need this
  // list in ElementConfigurationCard to prevent creating multiple configurations
  // attached to the same commit, so there is no point in optimizing this to fetch
  // just the single configuration for editing. We'll need them all soon.
  const configuration = useMemo(
    () => configurations.find(configuration => configuration.id === configurationID),
    [configurationID, configurations],
  );

  return configuration ? (
    <>Edit configuration ({configuration.commitDate.format('lll')})</>
  ) : (
    <>Edit configuration</>
  );
}
