import React from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';

import { EnvironmentCommonFragment as EnvironmentCommon } from 'admin-client/app/gql';
import { ROUTES } from 'admin-common/src/routing/routes';

type EnvironmentLinkProps = {
  env: EnvironmentCommon;
};

export const EnvironmentLink = React.memo((props: EnvironmentLinkProps) => {
  const { env } = props;
  const path = ROUTES.ENVIRONMENTS.VIEW.getPath({ hostname: env.hostname });
  const classes = useStyles();

  return (
    <>
      <Link component={RouterLink} to={path} title={env.hostname}>
        {env.hostname}
      </Link>
      <Link
        href={env.baseURL}
        target="_blank"
        title={`Open ${env.baseURL} in a new window`}
      >
        <OpenInNewIcon className={classes.openInNewIcon} />
      </Link>
    </>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    openInNewIcon: {
      fontSize: theme.typography.fontSize,
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(2),
    },
  }),
);
