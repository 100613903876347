import React, { useCallback, useMemo, useState } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { SingleInputSelector } from 'admin-client/app/components/ElementConfiguration/Card/rules/common/InputSelector';
import { ConditionEditorProps } from 'admin-client/app/components/ElementConfiguration/Card/rules/conditions/ConditionEditor';
import DefaultValueEditor from 'admin-client/app/components/ElementConfiguration/DefaultValueEditor';
import { EditorType } from 'common/elementConfiguration/EditorType';
import {
  getDefaultEditorForAnthaType,
  getEditorChoicesFromAnthaType,
} from 'common/elementConfiguration/parameterUtils';
import { APIElement, APIElementPort } from 'common/types/api';
import {
  isConstValueType,
  isParameterValueType,
  ParameterValueCompareCondition,
  ValueExpression,
} from 'common/types/elementConfiguration';
import { TypeConfigurationSpec } from 'common/types/typeConfiguration';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type ComparisonOperator = ParameterValueCompareCondition['operator'];

type Props = {
  condition: ParameterValueCompareCondition;
} & ConditionEditorProps;

const COMPARABLE_EDITOR_TYPES = [
  EditorType.AUTOCOMPLETE,
  EditorType.CHECKBOX,
  EditorType.DROPDOWN,
  EditorType.FLOAT,
  EditorType.INT,
  EditorType.LIQUID,
  EditorType.PLATE_TYPE,
  EditorType.POLICY,
  EditorType.SIMULATION,
  EditorType.SIMULATION_RESULTS,
  EditorType.STRING,
  EditorType.TIP_TYPE,
  EditorType.TOGGLE,
];

const QUANTITATIVE_EDITOR_TYPES = [EditorType.INT, EditorType.FLOAT];

const QUALITATIVE_OPERATOR_LABELS = {
  equals: 'Equal to',
  'not-equals': 'Not equal to',
};

const QUANTITATIVE_OPERATOR_LABELS = {
  greater: 'Greater than',
  'greater-or-equal': 'Greater than or equal to',
  less: 'Less than',
  'less-or-equal': 'Less than or equal to',
};

const QUALITATIVE_OPERATOR_OPTIONS = Object.entries(QUALITATIVE_OPERATOR_LABELS).map(
  ([key, label]) => ({
    label,
    value: key as ComparisonOperator,
  }),
);

const ALL_OPERATOR_LABELS: Record<ComparisonOperator, string> = {
  ...QUALITATIVE_OPERATOR_LABELS,
  ...QUANTITATIVE_OPERATOR_LABELS,
};

const isQuantitativeOperator = (operator: ComparisonOperator) =>
  Object.keys(QUANTITATIVE_OPERATOR_LABELS).includes(operator);

const isQuantitativeEditorType = (editorType?: EditorType) =>
  editorType ? QUANTITATIVE_EDITOR_TYPES.includes(editorType) : false;

const ALL_OPERATOR_OPTIONS: Option<ComparisonOperator>[] = Object.entries(
  ALL_OPERATOR_LABELS,
).map(([key, label]) => ({
  label,
  value: key as ComparisonOperator,
}));

const DEFAULT_OPERATOR = 'equals';

function filterByComparability(
  element: APIElement,
  parameter: APIElementPort,
  typeConfiguration?: TypeConfigurationSpec,
) {
  const configuredEditorType =
    element.configuration?.parameters[parameter.name]?.editor.type;
  const editorType =
    configuredEditorType ??
    getDefaultEditorForAnthaType(parameter.type, typeConfiguration);
  return editorType ? COMPARABLE_EDITOR_TYPES.includes(editorType) : false;
}

// Showing greater than and such only makes sense if the value is quantitative.
function canUseAllOperatorsWithEditorType(editorType?: EditorType) {
  return editorType ? QUANTITATIVE_EDITOR_TYPES.includes(editorType) : false;
}

function getParameterEditorTypeFromName(
  element: APIElement,
  name: string,
  typeName?: string,
  typeConfiguration?: TypeConfigurationSpec,
) {
  const configuredEditor = element.configuration?.parameters[name]?.editor.type;
  if (configuredEditor) {
    return configuredEditor;
  }

  return getDefaultEditorForAnthaType(typeName ?? '', typeConfiguration) ?? undefined;
}

export function ParameterValueCompareConditionEditor(props: Props) {
  const classes = useStyles();
  const { condition, index, element, typeConfigurations, onConditionChange } = props;

  const [selectedValueType, setSelectedValueType] = useState<'const' | 'parameter'>(
    'const',
  );
  const selectedOperatorLabel = ALL_OPERATOR_LABELS[condition.operator];

  const getParameterType = useCallback(
    (parameterName: string) => {
      return [...element.in_ports, ...element.out_ports].find(
        port => port.name === parameterName,
      )?.type;
    },
    [element.in_ports, element.out_ports],
  );

  const parameterAnthaType = getParameterType(condition.parameterName);

  const parameterTypeConfiguration = parameterAnthaType
    ? typeConfigurations?.[parameterAnthaType]
    : undefined;

  const parameterEditorType = getParameterEditorTypeFromName(
    element,
    condition.parameterName,
    parameterAnthaType,
    parameterTypeConfiguration,
  );

  const isPrimaryParameterComparable = parameterEditorType
    ? COMPARABLE_EDITOR_TYPES.includes(parameterEditorType)
    : false;

  const canUseAllOperators = canUseAllOperatorsWithEditorType(parameterEditorType);

  /**
   * Returns true if the target parameter can be compared to the given selectedParameterName
   * or currently selected primary parameter if a selectedParameterName is not supplied.
   * */
  const filterByParameterComparability = useCallback(
    (
      element: APIElement,
      targetParameter: APIElementPort,
      selectedParameterName?: string,
    ) => {
      // Don't compare to self
      if (targetParameter.name === (selectedParameterName ?? condition.parameterName)) {
        return false;
      }

      // Check if the parameter is comparable to anything
      if (!filterByComparability(element, targetParameter)) {
        return false;
      }

      // Check if this parameter is comparable to the one that is already part of the
      // condition
      return parameterEditorType
        ? getEditorChoicesFromAnthaType(
            targetParameter.type,
            typeConfigurations?.[targetParameter.type],
          ).includes(parameterEditorType)
        : false;
    },
    [condition.parameterName, parameterEditorType, typeConfigurations],
  );

  /**
   * The primary parameter can be compared to another if there are any others that
   * are of an antha type which can be set to use the same editor type.
   */
  const isPrimaryParameterComparableToAnotherParameter = useMemo(
    () =>
      [...element.in_ports, ...element.out_ports].filter(port =>
        filterByParameterComparability(element, port),
      ).length >= 1,
    [element, filterByParameterComparability],
  );

  /** Change the primary parameter used as part of the condition */
  const onParameterChange = useCallback(
    (parameterName: string) => {
      const selectedParameterType = getParameterType(parameterName);

      const selectedParameterTypeConfiguration = selectedParameterType
        ? typeConfigurations?.[selectedParameterType]
        : undefined;

      const newEditorType = getParameterEditorTypeFromName(
        element,
        parameterName,
        selectedParameterType,
        selectedParameterTypeConfiguration,
      );

      // If switching from a quantitative parameter to a qualitative one and the current
      // operator is quantitative, reset the operator to a default qualitative type.
      const newOperator =
        !isQuantitativeOperator(condition.operator) ||
        isQuantitativeEditorType(newEditorType)
          ? condition.operator
          : DEFAULT_OPERATOR;

      const isAnotherValueAvailableForComparison =
        [...element.in_ports, ...element.out_ports].filter(port =>
          filterByParameterComparability(element, port, parameterName),
        ).length > 0;

      // If the value that the parameter is being compared to is another parameter, and the
      // new parameter value can't be compared to a second parameter, switch the target to
      // a constant value.
      const newValueType =
        selectedValueType === 'parameter' && isAnotherValueAvailableForComparison
          ? 'const'
          : selectedValueType;

      const value: ValueExpression =
        newValueType === 'const'
          ? { type: 'const', constValue: undefined }
          : { type: 'parameter', parameterName: '' };

      const newCondition: ParameterValueCompareCondition = {
        ...condition,
        parameterName,
        operator: newOperator,
        value,
      };
      onConditionChange(newCondition, index);
    },
    [
      condition,
      element,
      filterByParameterComparability,
      getParameterType,
      index,
      onConditionChange,
      selectedValueType,
      typeConfigurations,
    ],
  );

  /** Change the operator being used to compare the parameter value to the target */
  const onOperatorChange = useCallback(
    (operator?: ComparisonOperator) => {
      if (!operator) {
        return;
      }
      const newCondition: ParameterValueCompareCondition = {
        ...condition,
        operator,
      };
      onConditionChange(newCondition, index);
    },
    [condition, index, onConditionChange],
  );

  /** Switch the target type that we are comparing the parameter to */
  const onValueTypeChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setSelectedValueType(value as 'const' | 'parameter');
  };

  /** When comparing parameter to a constant, updates the constant  */
  const onConstValueChange = useCallback(
    (value: any) => {
      const newCondition: ParameterValueCompareCondition = {
        ...condition,
        value: { type: 'const', constValue: value },
      };
      onConditionChange(newCondition, index);
    },
    [condition, index, onConditionChange],
  );

  /** When comparing parameter to a second parameter, updates the second parameter  */
  const onParameterValueChange = useCallback(
    (parameterName: string) => {
      const newCondition: ParameterValueCompareCondition = {
        ...condition,
        value: { type: 'parameter', parameterName },
      };
      onConditionChange(newCondition, index);
    },
    [condition, index, onConditionChange],
  );

  return (
    <div className={classes.container}>
      <SingleInputSelector
        className={classes.parameterSelector}
        element={element}
        filter={filterByComparability}
        selectedParameterName={condition.parameterName}
        onChange={onParameterChange}
      />
      {!isPrimaryParameterComparable && (
        <span>
          Value comparison isn&apos;t supported for this parameter&apos;s editor type
        </span>
      )}

      {isPrimaryParameterComparable && (
        <div className={classes.operator}>
          <Dropdown
            valueLabel={selectedOperatorLabel ?? ALL_OPERATOR_LABELS[DEFAULT_OPERATOR]}
            options={
              canUseAllOperators ? ALL_OPERATOR_OPTIONS : QUALITATIVE_OPERATOR_OPTIONS
            }
            onChange={onOperatorChange}
          />
        </div>
      )}

      {isPrimaryParameterComparableToAnotherParameter && (
        <RadioGroup value={selectedValueType} onChange={onValueTypeChange}>
          <FormControlLabel control={<Radio />} value="const" label="a constant" />
          <FormControlLabel
            control={<Radio />}
            value="parameter"
            label="another parameter"
          />
        </RadioGroup>
      )}

      {isPrimaryParameterComparable &&
        selectedValueType === 'const' &&
        parameterEditorType &&
        parameterAnthaType && (
          <div className={classes.constValue}>
            <DefaultValueEditor
              anthaType={parameterAnthaType}
              editorType={parameterEditorType}
              value={
                isConstValueType(condition.value) ? condition.value.constValue : undefined
              }
              onChange={onConstValueChange}
            />
          </div>
        )}

      {isPrimaryParameterComparableToAnotherParameter &&
        selectedValueType === 'parameter' && (
          <SingleInputSelector
            className={classes.parameterSelector}
            element={element}
            filter={filterByParameterComparability}
            selectedParameterName={
              isParameterValueType(condition.value)
                ? condition.value.parameterName
                : undefined
            }
            onChange={onParameterValueChange}
          />
        )}
    </div>
  );
}

const useStyles = makeStylesHook({
  parameterSelector: {
    marginRight: '1rem',
    width: '300px',
  },
  operator: {
    marginRight: '1rem',
    width: '200px',
  },
  constValue: {
    flex: 1,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
});
