import React, { useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';

import { Redirect } from 'admin-client/app/api/Redirectinator';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type NewRedirectArgs = {
  shortcut: string;
  description: string;
  urlPattern: string;
};

type Props = {
  existingRedirects: Redirect[];
  onClose: (newRedirect: NewRedirectArgs | null) => void;
};

export const NewRedirectDialog = React.memo((props: Props) => {
  const classes = useStyles();
  const { existingRedirects, onClose } = props;
  const [shortcut, setShortcut] = useState('');
  const [description, setDescription] = useState('');
  const [urlPattern, setURLPattern] = useState('');
  const handleShortcutChange = useTextFieldChange(setShortcut);
  const handleDescriptionChange = useTextFieldChange(setDescription);
  const handleURLPatternChange = useTextFieldChange(setURLPattern);

  const shortcutAlreadyInUse = useMemo(() => {
    for (const r of existingRedirects) {
      if (r.shortcut === shortcut) {
        return true;
      }
    }
    return false;
  }, [existingRedirects, shortcut]);

  const formIsValid =
    shortcut.length > 0 &&
    urlPattern.length > 0 &&
    description.length > 0 &&
    !shortcutAlreadyInUse;

  const handleOK = () => {
    if (formIsValid) {
      const newRedirectArgs: NewRedirectArgs = {
        shortcut,
        description,
        urlPattern,
      };
      onClose(newRedirectArgs);
    }
  };

  const handleCancel = () => {
    onClose(null);
  };

  return (
    <Dialog open onClose={handleCancel} maxWidth="xs">
      <DialogTitle>Add Redirect</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            required
            className={classes.textField}
            label="Shortcut"
            placeholder="e.g. workflow"
            value={shortcut}
            onChange={handleShortcutChange}
            fullWidth
            error={shortcutAlreadyInUse}
            helperText={shortcutAlreadyInUse ? 'Shortcut already in use' : ''}
          />
          <TextField
            required
            className={classes.textField}
            label="Description"
            placeholder="e.g. Open a workflow using its ID"
            value={description}
            onChange={handleDescriptionChange}
            fullWidth
          />
          <TextField
            required
            helperText="Use %s in place of arguments"
            className={classes.textField}
            label="URL Pattern"
            placeholder="e.g. https://antha.com/#/workflow/%s"
            value={urlPattern}
            onChange={handleURLPatternChange}
            fullWidth
          />
        </form>
        <DialogContentText className={classes.explanatoryText}>
          <div>
            The URL pattern can contain the special placeholder <code>%s</code>, which
            will be replaced during redirection.
          </div>
          <div>
            If the shortcut is a regular expression it should contain a single capture
            group surrounded by parentheses. <code>%s</code> will be replaced by the value
            of this capture group.
          </div>
          <div>
            Otherwise, <code>%s</code> will be replaced by the argument following the
            shortcut.
          </div>
          <div>
            <strong>Examples</strong>
          </div>
          <div>
            Shortcut is <code>a(\d+)</code>, user types <code>sr a123</code>.{' '}
            <code>%s</code> will be replaced with <strong>123</strong>.
          </div>
          <div>
            Shortcut is <code>b</code>, user types <code>sr b 123</code>. <code>%s</code>{' '}
            will be replaced with <strong>123</strong>.
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions data-test="confirmationDialogActions">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={!formIsValid} onClick={handleOK} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const useStyles = makeStyles({
  textField: {
    marginBottom: '1rem',
  },
  explanatoryText: {
    '& code': {
      border: '1px solid #ccc',
      padding: '0.1rem 0.3rem',
      backgroundColor: '#eee',
      borderRadius: '2px',
    },
    '& div + div': {
      marginTop: '1rem',
    },
  },
});
