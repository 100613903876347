import React, { ChangeEvent, useState } from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';

import { ArrayElement, getOrganisationsQuery } from 'admin-client/app/gql';
import { Stack } from 'common/ui/components/Stack';
import { Role, UserRole } from 'common/ui/components/UserRole';

type Props = {
  org: ArrayElement<getOrganisationsQuery['organisations']>;
  roles: readonly Role[];
  onClose: (newUser: User | null) => void;
};

type User = {
  givenName: string;
  familyName: string;
  email: string;
  blocked: boolean;
  roleId: string | null;
};

export const checkEmail = (email: string, orgEmailDomains: readonly string[]) =>
  orgEmailDomains.some(domain => email.slice(email.indexOf('@') + 1).endsWith(domain));

// A dialog with OK, Cancel buttons
export const NewUserDialog = React.memo((props: Props) => {
  const classes = useStyles();
  const { org, onClose } = props;
  const [givenName, setGivenName] = useState<string>('');
  const [familyName, setFamilyName] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [email, setEmail] = useState<string>('');
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [canAddUnrecognisedEmailDomain, setCanAddUnrecognisedEmailDomain] =
    useState<boolean>(false);

  const emailDomainIsRecognised = checkEmail(email, org.emailDomains);

  const formIsValid =
    givenName.length > 0 &&
    familyName.length > 0 &&
    emailIsValid &&
    (emailDomainIsRecognised || canAddUnrecognisedEmailDomain);

  const handleOK = () => {
    if (formIsValid) {
      onClose({
        givenName: givenName.trim(),
        familyName: familyName.trim(),
        email: email.trim(),
        blocked: false,
        roleId: selectedRole?.id ?? null,
      });
    }
  };

  const handleCancel = () => {
    onClose(null);
  };

  const handleGivenNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGivenName(event.target.value);
  };

  const handleFamilyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFamilyName(event.target.value);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);

    // The field has `type="email"`, so we can use its validity prop to ensure
    // the value is a valid email address. Sweet!
    setEmailIsValid(event.target.validity.valid);
  };

  const handleCanAddUnrecognisedEmailDomainChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setCanAddUnrecognisedEmailDomain(event.target.checked);
  };

  const handleRoleChange = (roleId: string | null) => {
    const role = props.roles.find(role => role.id === roleId);
    setSelectedRole(role ?? null);
  };

  const emailHelperText = 'Valid domains are: ' + org.emailDomains.join(', ');

  return (
    <Dialog open onClose={handleCancel} maxWidth="xs">
      <DialogTitle>Add User</DialogTitle>
      <DialogContent className={classes.content}>
        <form>
          <Stack spacing={1}>
            <TextField
              required
              className={classes.textField}
              label="Given name"
              placeholder="e.g. Joan"
              id="new-user-given-name"
              value={givenName}
              onChange={handleGivenNameChange}
              fullWidth
            />
            <TextField
              required
              className={classes.textField}
              label="Family name"
              placeholder="e.g. Smith"
              id="new-user-family-name"
              value={familyName}
              onChange={handleFamilyNameChange}
              fullWidth
            />
            <UserRole
              currentRole={selectedRole}
              availableRoles={props.roles}
              label="Role"
              onChange={handleRoleChange}
            />
            <TextField
              required
              error={email.length > 0 && !emailIsValid}
              className={classes.textField}
              label="Email"
              placeholder="e.g. joan.smith@example.org"
              id="new-user-email"
              value={email}
              onChange={handleEmailChange}
              helperText={emailHelperText}
              type="email"
              fullWidth
            />
            {/*
            If they've entered a valid email but it doesn't use a recognised
            domain, show a warning and make sure they confirm that it's OK to
            add this user.
          */}
            {emailIsValid && !emailDomainIsRecognised && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={canAddUnrecognisedEmailDomain}
                    onChange={handleCanAddUnrecognisedEmailDomainChange}
                  />
                }
                label={
                  'This email uses an unrecognised domain. Tick to confirm you ' +
                  'have authorised adding this user with the adminstrator of ' +
                  'this organisation.'
                }
              />
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions data-test="confirmationDialogActions">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button disabled={!formIsValid} onClick={handleOK} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const useStyles = makeStyles({
  content: {
    whiteSpace: 'pre-line',
  },
  textField: {
    display: 'block',
    marginBottom: '1rem',
    width: '300px',
  },
});
