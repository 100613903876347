import React from 'react';

import InputLabel from '@mui/material/InputLabel';
import TableCell from '@mui/material/TableCell';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default function RowHeader({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return (
    <TableCell className={classes.label}>
      <InputLabel shrink>{children}</InputLabel>
    </TableCell>
  );
}

const useStyles = makeStylesHook({
  label: { whiteSpace: 'nowrap' },
});
