import React, { useCallback } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';

import { MultiInputSelector } from 'admin-client/app/components/ElementConfiguration/Card/rules/common/InputSelector';
import { APIElement } from 'common/types/api';
import { SetBooleanFieldAction } from 'common/types/elementConfiguration';

export function SetParametersBooleanFieldActionEditor(props: {
  action: SetBooleanFieldAction;
  element: APIElement;
  onChange: (action: SetBooleanFieldAction) => void;
}) {
  const { action, element, onChange } = props;
  const onParametersChange = useCallback(
    (parameterNames: string[]) => onChange({ ...action, parameterNames }),
    [action, onChange],
  );

  const onInvertChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      onChange({ ...action, revertIfConditionNotMet: e.target.checked }),
    [action, onChange],
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <MultiInputSelector
          element={element}
          selectedParameterNames={action.parameterNames}
          onChange={onParametersChange}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              onChange={onInvertChange}
              checked={action.revertIfConditionNotMet}
            />
          }
          label={<InputLabel shrink>{getRevertLabel(action)}</InputLabel>}
        />
      </Grid>
    </Grid>
  );
}

function getRevertLabel(action: SetBooleanFieldAction) {
  switch (action.type) {
    case 'set-parameters-enabled':
      return 'Disable parameters when condition is not met';
    case 'set-parameters-not-enabled':
      return 'Enable parameters when condition is not met';
    case 'set-parameters-visible':
      return 'Hide parameters when condition is not met';
    case 'set-parameters-not-visible':
      return 'Show parameters when condition is not met';
    case 'set-parameters-required':
      return "Don't require parameters when condition is not met";
    case 'set-parameters-not-required':
      return 'Require parameters when condition is not met';
  }
}
