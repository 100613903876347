import React from 'react';

import Typography from '@mui/material/Typography';
import { Route, Switch } from 'react-router-dom';

import { ChooseType } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/ChooseType';
import { CreateTypeConfigurationScreen } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/CreateTypeConfigurationScreen';
import { EditTypeConfigurationScreen } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/EditTypeConfigurationScreen';
import { ViewTypeConfigurations } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/ViewTypeConfigurations';
import { NavigationLinks, Node } from 'admin-client/app/components/NavigationLinks';
import { ROUTES } from 'admin-common/src/routing/routes';
import { TypeName } from 'common/types/typeConfiguration';

/** Route tree for the NavigationLinks component. */
const ROUTE_TREE: Node[] = [
  {
    route: ROUTES.TYPE_CONFIGURATION.ROOT,
    getTitle: (_params: {}) => 'All types',
    children: [
      {
        route: ROUTES.TYPE_CONFIGURATION.LIST,
        getTitle: (params: { typeName: TypeName }) => decodeURIComponent(params.typeName),
        children: [
          {
            route: ROUTES.TYPE_CONFIGURATION.EDIT,
            getTitle: (_params: {}) => 'Edit type configuration',
            children: [],
          },
        ],
      },
    ],
  },
];

/** Entry point for the Type Configuration tool. */
export default function TypeConfigurationContainer() {
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Type configuration
      </Typography>
      <NavigationLinks routeTree={ROUTE_TREE} />
      <Switch>
        <Route
          exact
          path={ROUTES.TYPE_CONFIGURATION.ROOT.pathTemplate}
          component={ChooseType}
        />
        <Route
          exact
          path={ROUTES.TYPE_CONFIGURATION.LIST.pathTemplate}
          component={ViewTypeConfigurations}
        />
        <Route
          exact
          path={ROUTES.TYPE_CONFIGURATION.EDIT.pathTemplate}
          component={EditTypeConfigurationScreen}
        />
        <Route
          exact
          path={ROUTES.TYPE_CONFIGURATION.CREATE.pathTemplate}
          component={CreateTypeConfigurationScreen}
        />
      </Switch>
    </>
  );
}
