import React, { useCallback } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import {
  ADD_ENVIRONMENT,
  GET_ENVIRONMENTS,
  GET_ENVIRONMENTS_WITH_HEALTHCHECK,
} from 'admin-client/app/api/gql/queries';
import { EnvironmentLink } from 'admin-client/app/components/Environments/EnvironmentLink';
import { NewEnvironmentDialog } from 'admin-client/app/components/Environments/NewEnvironmentDialog';
import { HasPermission } from 'admin-client/app/components/Permissions';
import { ArrayElement, getEnvironmentsWithHealthcheckQuery } from 'admin-client/app/gql';
import Colors from 'common/ui/Colors';
import { useDialogManager } from 'common/ui/components/DialogManager';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function EnvironmentsTableContainer() {
  const { loading, error, data } = useQuery(GET_ENVIRONMENTS_WITH_HEALTHCHECK);
  const classes = useStyles();
  const dialogManager = useDialogManager();
  const snackbarManager = useSnackbarManager();
  const [addEnvironmentMutation] = useMutation(ADD_ENVIRONMENT, {
    onError: (error: ApolloError) => {
      console.error(error);
      snackbarManager.showError(error.message);
    },
  });
  const handleAddEnvironmentButtonClick = useCallback(async () => {
    const newEnv = await dialogManager.openDialogPromise(
      'ADD_ENVIRONMENT',
      NewEnvironmentDialog,
      {},
    );
    if (newEnv !== null) {
      await addEnvironmentMutation({
        variables: newEnv,
        refetchQueries: [
          {
            query: GET_ENVIRONMENTS,
          },
        ],
      });
    }
  }, [addEnvironmentMutation, dialogManager]);

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <Typography>Error! {error.message}</Typography>;
  }
  return (
    <>
      <Typography variant="h2" gutterBottom>
        Environments
      </Typography>{' '}
      <HasPermission permission="create:environments">
        <section className={classes.header}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddEnvironmentButtonClick}
            startIcon={<AddIcon />}
          >
            Add Environment
          </Button>
        </section>
      </HasPermission>
      <EnvironmentsTable environments={data?.environments ?? []} />
    </>
  );
}

type Environment = ArrayElement<getEnvironmentsWithHealthcheckQuery['environments']>;

type Props = {
  environments: readonly Environment[];
};

const EnvironmentsTable = React.memo(function EnvironmentsTable(props: Props) {
  const classes = useStyles();
  const { environments } = props;

  return (
    <section style={{ display: 'block' }}>
      <Paper>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Hostname</Typography>
              </TableCell>
              <TableCell>
                <Typography>Health check (appserver)</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {environments.map(env => {
              return <EnvironmentRow key={env.id} env={env} classes={classes} />;
            })}
          </TableBody>
        </Table>
      </Paper>
    </section>
  );
});

type EnvironmentRowProps = {
  env: ArrayElement<getEnvironmentsWithHealthcheckQuery['environments']>;
} & WithStyles<any>;

const EnvironmentRow = React.memo((props: EnvironmentRowProps) => {
  const classes = useStyles();
  const { env } = props;

  const getIndicator = useCallback(
    (isOK: boolean | null, path: string) => {
      return isOK ? (
        <Tooltip title={`Calling ${path} on this environment succeeded`}>
          <CheckCircleIcon className={classes.isOK} />
        </Tooltip>
      ) : (
        <Tooltip title={`Calling ${path} on this environment failed`}>
          <ReportProblemIcon className={classes.isNotOK} />
        </Tooltip>
      );
    },
    [classes.isNotOK, classes.isOK],
  );

  const appserverHealthIndicator = getIndicator(
    env.canAuthenticateWithAppserver,
    '/web/graphql',
  );

  return (
    <TableRow key={env.id} className={classes.fullRow}>
      <TableCell>
        <Typography>
          <EnvironmentLink env={env} />
        </Typography>
      </TableCell>
      <TableCell>{appserverHealthIndicator}</TableCell>
    </TableRow>
  );
});

const useStyles = makeStyles({
  header: {
    paddingBottom: '1rem',
  },
  table: {},
  fullRow: {},
  isOK: {
    color: Colors.SUCCESS_MAIN,
  },
  isNotOK: {
    color: Colors.ERROR,
  },
  subtitle: {
    fontSize: '0.85em',
    color: Colors.GREY_60,
  },
  editIcon: {
    fontSize: '0.85em',
  },
});
