import React, { useCallback } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { ActionEditor, ActionTypeOption } from 'common/rules/ActionEditor';
import { ActionDiscriminant } from 'common/rules/types';
import { APIElement } from 'common/types/api';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type Props<Action extends ActionDiscriminant> = {
  actions: Action[];
  options: ActionTypeOption<Action>[];
  defaultNewAction: Action;
  element: APIElement;
  onChange: (actions: Action[]) => void;
};

export function ActionsEditor<Action extends ActionDiscriminant>(props: Props<Action>) {
  const classes = useStyles();
  const { actions, options, element, onChange, defaultNewAction } = props;

  const onAddAction = useCallback(
    () => onChange([...actions, defaultNewAction]),
    [actions, defaultNewAction, onChange],
  );

  const onActionChange = useCallback(
    (action: Action, actionIndex: number) => {
      const newActions = [...actions];
      newActions[actionIndex] = action;
      onChange(newActions);
    },
    [actions, onChange],
  );

  const onActionRemove = useCallback(
    (actionIndex: number) => {
      const newActions = [...actions];
      newActions.splice(actionIndex, 1);
      onChange(newActions);
    },
    [actions, onChange],
  );

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <Typography gutterBottom>take these actions:</Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={onAddAction}>
            + New action
          </Button>
        </Grid>
      </Grid>

      <div className={cx(classes.listRoot)}>
        {actions.map((action, index) => (
          <React.Fragment key={index}>
            <ActionEditor
              options={options}
              action={action}
              index={index}
              element={element}
              onActionChange={onActionChange}
              onActionRemove={onActionRemove}
            />
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

const useStyles = makeStylesHook({
  root: {
    marginTop: '1rem',
  },
  listRoot: {
    marginTop: '0.5rem',
    paddingLeft: '1rem',
    borderLeft: '1px solid #eee',
    display: 'flex',
  },
});
