import React, { useCallback, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import FeatureToggleOverrideIcon from 'admin-client/app/components/FeatureToggles/FeatureToggleOverrideIcon';
import FeatureToggleSwitch from 'admin-client/app/components/FeatureToggles/FeatureToggleSwitch';
import {
  ArrayElement,
  FeatureToggleCommonFragment,
  featureTogglesForAllEnvsQuery,
} from 'admin-client/app/gql';
import Autocomplete from 'common/ui/filaments/Autocomplete';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const NOT_AVAILABLE = 'Not Available';

type FeatureToggle = ArrayElement<FeatureToggleCommonFragment['featureToggles']>;

type EnvironmentMapping = {
  environmentId: string;
  environmentHostname: string;
  featureToggles: FeatureToggle | typeof NOT_AVAILABLE;
};

type ShowFeatureTogglesForFeatureProps = {
  data: featureTogglesForAllEnvsQuery;
};

export default React.memo(function ShowFeatureTogglesByFeature(
  props: ShowFeatureTogglesForFeatureProps,
) {
  const classes = useStyles();
  const { data } = props;

  const [selectedFeatureName, setSelectedFeatureName] = useState('');
  const handleChangeFeature = useCallback((featureName?: string) => {
    setSelectedFeatureName(featureName ?? '');
  }, []);

  // Get the list of all features across all envs.
  const allFeatures = useMemo(() => {
    const allFeatures = new Set<string>();
    data.environments.forEach((env, _) => {
      env.featureToggles.forEach((feature, _) => {
        allFeatures.add(feature.name);
      });
    });
    return Array.from(allFeatures).sort();
  }, [data.environments]);

  // Map each feature with the env since we currently don't get the information that way.
  const featuresToEnv: {
    [featureName: string]: EnvironmentMapping[];
  } = useMemo(() => {
    const featuresToEnv: {
      [featureName: string]: EnvironmentMapping[];
    } = {};
    data.environments.forEach((env, _) => {
      const availableFeatures = env.featureToggles;
      allFeatures.forEach((feature, _) => {
        const found = availableFeatures.find(
          currentFeature => currentFeature.name === feature,
        );
        const currentFeatureState: EnvironmentMapping = {
          environmentId: env.id,
          environmentHostname: env.hostname,
          featureToggles: found ?? NOT_AVAILABLE,
        };
        if (featuresToEnv[feature]) {
          featuresToEnv[feature] = [currentFeatureState, ...featuresToEnv[feature]];
        } else {
          featuresToEnv[feature] = [currentFeatureState];
        }
      });
    });
    return featuresToEnv;
  }, [allFeatures, data.environments]);

  const featureOptions = allFeatures.map(feature => ({
    label: feature,
    value: feature,
  }));

  const selectedFeatureEnvs = useMemo(
    () => (selectedFeatureName ? featuresToEnv[selectedFeatureName] : undefined),
    [featuresToEnv, selectedFeatureName],
  );

  return (
    <div className={classes.container}>
      <div className={classes.input}>
        <Autocomplete
          valueLabel={selectedFeatureName}
          options={featureOptions}
          onChange={handleChangeFeature}
          fullWidth
          label="Feature"
        />
      </div>

      {!selectedFeatureName ? (
        <Box padding={2}>
          <Typography> No feature selected.</Typography>
        </Box>
      ) : selectedFeatureEnvs ? (
        <Table size="medium" className={classes.table} stickyHeader>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell>
                <Typography>Environment Name</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>Status</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>Override?</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <EnvironmentsTable environments={selectedFeatureEnvs} />
        </Table>
      ) : (
        <Typography> No environments.</Typography>
      )}
    </div>
  );
});

type EnvironmentsTableProps = {
  environments: EnvironmentMapping[];
};

const EnvironmentsTable = React.memo(function (props: EnvironmentsTableProps) {
  const { environments } = props;

  return (
    <TableBody>
      {environments
        .sort((a, b) => a.environmentHostname.localeCompare(b.environmentHostname))
        .map(env => {
          return (
            <TableRow key={env.environmentId}>
              <TableCell>
                <Typography>
                  {env.environmentHostname} ({env.environmentId})
                </Typography>
              </TableCell>
              {env.featureToggles === NOT_AVAILABLE ? (
                <>
                  <TableCell align="center">
                    <Typography> Not available </Typography>
                  </TableCell>
                  <TableCell />
                </>
              ) : (
                <>
                  <TableCell align="center">
                    <FeatureToggleSwitch
                      featureToggle={env.featureToggles}
                      environmentId={env.environmentId}
                      hostname={env.environmentHostname}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <FeatureToggleOverrideIcon
                      featureToggle={env.featureToggles}
                      environmentId={env.environmentId}
                      hostname={env.environmentHostname}
                    />
                  </TableCell>
                </>
              )}
            </TableRow>
          );
        })}
    </TableBody>
  );
});

const useStyles = makeStylesHook(theme => ({
  container: {
    padding: theme.spacing(5),
  },
  // Prevent the sticky table header from scrolling underneath the AppBar
  head: {
    '& th': {
      top: `${theme.mixins.toolbar.minHeight}px`,
      paddingTop: theme.spacing(6),
    },
  },
  input: {
    margin: 'auto',
    width: '80%',
  },
  table: {
    marginTop: theme.spacing(6),
  },
}));
