import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { default as axios } from 'axios';
import moment from 'moment';

import { AnthaHubDeveloperBuildVersion } from 'common/constants/anthahub';
import { byName } from 'common/lib/strings';
import { AnthaHubMetricsResult, sortMetrics } from 'common/types/anthahub';
import Colors from 'common/ui/Colors';

export default function AnthaHubMetricsTableContainer() {
  const [metrics, setMetrics] = useState<{
    [env: string]: AnthaHubMetricsResult;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await axios.get('/api/anthahub/metrics', {
          headers: {
            authorization: `bearer ${accessToken}`,
          },
        });
        if (response.status !== 200) {
          setError(`Error: ${response.statusText}`);
        } else {
          setMetrics(response.data);
        }
      } catch (error) {
        setError(`${error}`);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [getAccessTokenSilently]);

  if (error) {
    return <Typography>{error}</Typography>;
  }
  if (!metrics) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        SynthaceHubs
      </Typography>
      <AnthaHubsTable metrics={metrics} />
    </>
  );
}

type Props = {
  metrics: { [env: string]: AnthaHubMetricsResult };
};

function AnthaHubsTable(props: Props) {
  const { metrics } = props;
  const classes = useStyles();
  const [selectedOrg, setSelectedOrg] = useState<string>('');

  const envs = useMemo(() => Object.keys(metrics), [metrics]);

  const orgs = useMemo(() => {
    const orgs = new Set<string>();
    for (const envMetrics of Object.values(metrics)) {
      if (envMetrics.type === 'metrics') {
        envMetrics.metrics.forEach(m => {
          orgs.add(m.org);
        });
      }
    }
    return Array.from(orgs).sort();
  }, [metrics]);

  const metricsForDisplay = useMemo(() => {
    const result = { ...metrics };
    Object.keys(result).forEach(env => {
      const envMetrics = result[env];
      if (envMetrics.type === 'metrics') {
        sortMetrics(envMetrics.metrics);
        if (selectedOrg) {
          result[env] = {
            type: 'metrics',
            metrics: envMetrics.metrics.filter(m => m.org === selectedOrg),
          };
        }
      }
    });
    return result;
  }, [metrics, selectedOrg]);

  const handleOrgChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setSelectedOrg(event.target.value);
    },
    [setSelectedOrg],
  );

  const labelForBooleanSetting = useCallback(
    (value: boolean | undefined) => {
      if (value === undefined) {
        return 'unknown';
      }
      if (value) {
        return <span className={classes.enabledLabel}>enabled</span>;
      }
      return <span className={classes.disabledLabel}>disabled</span>;
    },
    [classes.disabledLabel, classes.enabledLabel],
  );

  return (
    <>
      <Box marginBottom={2}>
        {envs.map(env => {
          const envMetrics = metricsForDisplay[env];
          return envMetrics?.type === 'metrics' ? null : (
            <Typography variant="body2">
              Error fetching from {env}: {envMetrics.errorMessage}
            </Typography>
          );
        })}
      </Box>

      <Box marginBottom={2}>
        <FormControl className={classes.orgPicker}>
          <InputLabel id="org-selector-label">Show SynthaceHubs from</InputLabel>
          <Select labelId="org-selector-label" onChange={handleOrgChange}>
            <MenuItem value="">All orgs</MenuItem>
            {orgs.map(org => (
              <MenuItem key={org} value={org}>
                {org}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Paper>
        <Table size="small" stickyHeader>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell>
                <Typography variant="body2">Id</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Environment</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Organisation</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Version</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Last Seen</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Auto-Updates</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Driver Auto-Updates</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Barcode Scanning</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Devices</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {envs.map(env => {
              const envMetrics = metricsForDisplay[env];
              return envMetrics.type === 'error'
                ? null
                : envMetrics.metrics.map((m, i) => (
                    <TableRow key={i}>
                      {/* We put nowrap on several columns to force the wrapping to occur on the
                            device column, which is most likely to look reasonable when wrapped. */}
                      <TableCell className={classes.nowrap}>
                        <Typography variant="body2">{m.id}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{env}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{m.org}</Typography>
                      </TableCell>
                      <TableCell className={classes.nowrap}>
                        <Typography variant="body2">
                          {m.version === AnthaHubDeveloperBuildVersion ? (
                            m.version
                          ) : (
                            <a
                              href={`https://github.com/Synthace/AnthaHub/releases/tag/anthahub/${m.version}`}
                            >
                              {m.version}
                            </a>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.nowrap}>
                        <Typography variant="body2">
                          <span
                            title={moment.unix(m.lastSeen).format('D MMM YYYY HH:mm:ss')}
                          >
                            {moment.unix(m.lastSeen).format('D MMM YYYY')}
                          </span>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {labelForBooleanSetting(m.settings?.anthahubUpdatesEnabled)}
                          {m.settings && ` (${m.settings?.autoUpdatePeriodMins} mins)`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {labelForBooleanSetting(m.settings?.driverUpdatesEnabled)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {labelForBooleanSetting(m.settings?.scanBarcodesEnabled)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          <ul className={classes.deviceList}>
                            {m.devices.sort(byName).map(d => {
                              return (
                                <li key={d.id}>
                                  {d.name} ({d.modelName},{' '}
                                  {d.driverVersion ? (
                                    <a
                                      href={`https://github.com/Synthace/AnthaHub/releases/tag/device-drivers/${d.driverVersion}`}
                                    >
                                      {d.driverVersion}
                                    </a>
                                  ) : (
                                    'unknown driver version'
                                  )}
                                  )
                                </li>
                              );
                            })}
                          </ul>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ));
            })}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    environment: {
      marginBottom: theme.spacing(6),
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    orgPicker: {
      minWidth: '200px',
    },
    deviceList: {
      paddingLeft: '0px',
    },
    enabledLabel: {
      color: Colors.GREEN_80,
    },
    disabledLabel: {
      color: Colors.RED,
      textDecoration: 'line-through',
    },

    // Prevent the sticky table header from scrolling underneath the AppBar
    head: {
      '& th': {
        top: `${theme.mixins.toolbar.minHeight}px`,
        paddingTop: '20px',
      },
    },
  }),
);
