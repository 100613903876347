import { useCallback } from 'react';

import {
  ChooseCommitDialogResult,
  ChooseElementConfigurationCommitDialog,
} from 'admin-client/app/components/ElementConfiguration/ChooseElementConfigurationCommitDialog';
import { Commit, ConfigurationType } from 'common/types/commonConfiguration';
import { ElementConfigurationSpec, ElementName } from 'common/types/elementConfiguration';
import { useDialogManager } from 'common/ui/components/DialogManager';

/**
 * Helper hook to choose a commit for given element.
 */
export function useChooseElementConfigurationCommitDialog() {
  const dialogManager = useDialogManager();

  const onSelectCommit = useCallback(
    async ({
      elementName,
      initialConfigurationType,
      initialCommit,
      dialogTitle,
      onCommitSelected,
      isNewConfiguration,
      initialSpec,
    }: {
      elementName: ElementName;
      initialConfigurationType?: ConfigurationType;
      initialCommit?: Commit | null;
      dialogTitle?: string;
      onCommitSelected: (result: ChooseCommitDialogResult) => void;
      isNewConfiguration: boolean;
      initialSpec?: ElementConfigurationSpec;
    }) => {
      const result = await dialogManager.openDialogPromise(
        'CHOOSE_COMMIT',
        ChooseElementConfigurationCommitDialog,
        {
          elementName,
          initialCommit,
          initialConfigurationType,
          isNewConfiguration,
          dialogTitle,
          initialSpec,
        },
      );
      if (result) {
        onCommitSelected(result);
      }
    },
    [dialogManager],
  );

  return onSelectCommit;
}
