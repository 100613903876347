import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { GET_ACTIONS, PUBLISH_EXAMPLE_WORKFLOWS } from 'admin-client/app/api/gql/queries';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export const usePublishExampleWorkflows = () => {
  const snackbarManager = useSnackbarManager();

  const [publishExampleWorkflows, { loading, client }] = useMutation(
    PUBLISH_EXAMPLE_WORKFLOWS,
    {
      onError: async error => {
        snackbarManager.showError(error.message);
        await client.refetchQueries({ include: [GET_ACTIONS] });
      },

      onCompleted: async ({ publishExampleWorkflows: { successEnvs, failureEnvs } }) => {
        await client.refetchQueries({ include: [GET_ACTIONS] });

        if (failureEnvs && failureEnvs.length > 0) {
          snackbarManager.showWarning(
            `Pushing examples failed for ${failureEnvs.length} environment${
              failureEnvs.length > 1 ? 's' : ''
            }`,
          );
        } else {
          snackbarManager.showSuccess(
            `Successfully pushed examples to ${successEnvs.length} environment${
              successEnvs.length > 1 ? 's' : ''
            }`,
          );
        }
      },
    },
  );

  const onClick = useCallback(
    selectedTargetEnvironments => async () => {
      await publishExampleWorkflows({
        variables: selectedTargetEnvironments
          ? {
              targetEnvironmentBaseURLs: selectedTargetEnvironments,
            }
          : undefined,
      });
    },
    [publishExampleWorkflows],
  );

  return { loading, onClick };
};
