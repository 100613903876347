import React, { ReactNode } from 'react';

import {
  DocumentNode,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { MultipleSelectChip } from 'admin-client/app/components/PublishContentUI/MultipleSelectChip';
import {
  SnackbarManager,
  useSnackbarManager,
} from 'common/ui/components/SnackbarManager';
import { Stack } from 'common/ui/components/Stack';

type Props = {
  title: string;
  description: ReactNode | string;
  loading: boolean;
  onClick: (environments?: string[]) => () => void;
  getTargetEnvironmentsQuery: DocumentNode | TypedDocumentNode;
  mkOptions: (
    snackbarManager: SnackbarManager,
    setTargetEnvironments: React.Dispatch<React.SetStateAction<string[] | undefined>>,
  ) => QueryHookOptions;
};

export function PublishContentUI({
  title,
  description,
  loading,
  onClick,
  getTargetEnvironmentsQuery,
  mkOptions,
}: Props) {
  const snackbarManager = useSnackbarManager();

  const [targetEnvironments, setTargetEnvironments] = React.useState<
    string[] | undefined
  >(undefined);
  const [selectedTargetEnvironments, setSelectedTargetEnvironments] = React.useState<
    string[] | undefined
  >(undefined);

  useQuery(getTargetEnvironmentsQuery, mkOptions(snackbarManager, setTargetEnvironments));

  return (
    <div>
      <Typography variant="h4">{title}</Typography>
      <Stack spacing={4}>
        <Typography variant="h6">Pick environment(s)</Typography>
        {description}
        <MultipleSelectChip
          selectedTargetEnvironments={selectedTargetEnvironments}
          setSelectedTargetEnvironments={setSelectedTargetEnvironments}
          avaliableTargetEnvironments={targetEnvironments}
        />
        <Typography variant="body1">
          ℹ️ If you don&apos;t pick any environment the push will be to all environments.
        </Typography>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={!loading ? onClick(selectedTargetEnvironments) : undefined}
        >
          {loading ? (
            <CircularProgress size={16} style={{ marginRight: 4 }} />
          ) : (
            <SaveIcon style={{ marginRight: 4 }} />
          )}
          {title}
        </Button>
      </Stack>
    </div>
  );
}
