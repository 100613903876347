import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { GET_ALL_RELEASES_FOR_PROJECT } from 'admin-client/app/api/gql/queries';
import {
  AnthaHubUpdatesDisabledVersion,
  AnthaHubUpdatesLatestVersion,
} from 'admin-common/src/constants';
import useCheckboxChange from 'common/ui/hooks/useCheckboxChange';

type Props = {
  projectName: string;
  currentReleaseVersion: string;
  onClose: (newReleaseVersion: string | null) => void;
};

export const ReleasePickerDialog = React.memo((props: Props) => {
  const { currentReleaseVersion, projectName, onClose } = props;
  const [newReleaseVersion, setNewReleaseVersion] =
    useState<string>(currentReleaseVersion);
  const [includePreReleaseVersions, setIncludePreReleaseVersions] =
    useState<boolean>(false);
  const onIncludePreReleaseVersions = useCheckboxChange(setIncludePreReleaseVersions);
  const releases = useQuery(GET_ALL_RELEASES_FOR_PROJECT, {
    variables: { projectName, includePreReleaseVersions },
  });

  const handleOK = () => {
    onClose(newReleaseVersion);
  };

  const handleCancel = () => {
    onClose(null);
  };

  const handleReleaseChange = (event: SelectChangeEvent<string>) => {
    setNewReleaseVersion(event.target.value);
  };

  return (
    <Dialog open onClose={handleCancel} maxWidth="xs">
      <DialogTitle>Choose {projectName} Release Version</DialogTitle>
      {releases.loading ? (
        <CircularProgress />
      ) : (
        <>
          <DialogContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includePreReleaseVersions}
                  onChange={onIncludePreReleaseVersions}
                />
              }
              label="Include pre-release versions"
            />
            <Select
              required
              label="New version"
              id="new-version"
              value={newReleaseVersion}
              onChange={handleReleaseChange}
              fullWidth
            >
              <MenuItem
                key={AnthaHubUpdatesLatestVersion}
                value={AnthaHubUpdatesLatestVersion}
              >
                Latest
              </MenuItem>
              <MenuItem
                key={AnthaHubUpdatesDisabledVersion}
                value={AnthaHubUpdatesDisabledVersion}
              >
                Auto-update disabled
              </MenuItem>
              {releases.data?.allReleasesForProject?.map(release => (
                <MenuItem key={release.version} value={release.version}>
                  {release.version}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleOK} color="primary">
              OK
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
});
