import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { RouteComponentProps } from 'react-router-dom';

import { EditTypeConfiguration } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/EditTypeConfiguration';
import { useTypeConfigurations } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useTypeConfigurations';
import { TypeConfigurationID, TypeName } from 'common/types/typeConfiguration';

type Props = RouteComponentProps<{
  typeName: TypeName;
  id: TypeConfigurationID;
}>;

export function EditTypeConfigurationScreen({ match }: Props) {
  const { id: configurationID, typeName: encodedTypeName } = match.params;
  const typeName = decodeURIComponent(encodedTypeName);
  const { configurations } = useTypeConfigurations(typeName);

  // The type is fetched with list of all its configurations. We will need this
  // list in EditTypeConfiguration to prevent creating multiple configurations
  // attached to the same commit, so there is no point in optimizing this to fetch
  // just the single configuration for editing. We'll need them all soon.
  const configuration = useMemo(
    () => configurations.find(configuration => configuration.id === configurationID),
    [configurationID, configurations],
  );

  return configuration ? (
    <EditTypeConfiguration
      key={configuration.id}
      typeName={typeName}
      configuration={configuration}
    />
  ) : (
    <Box paddingTop={2}>
      <CircularProgress size={24} color="primary" />
    </Box>
  );
}
