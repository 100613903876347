import React, { useMemo } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, matchPath, useLocation } from 'react-router-dom';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { NavigationRoute } from 'common/ui/navigation';

type NavigationItem = { title: React.ReactNode; path: string };

export type Node = {
  route: NavigationRoute<any>;
  getTitle: (params: any) => React.ReactNode;
  children: Node[];
};

/**
 * The algorithm simply traverses the route tree from the top. When route path matches
 * passed path, new breadcrumb for the route is added to result and algorithm moves to its
 * children.
 *
 * This way the breadcrumbs are always sorted from root to leaf. Only breadcrumbs that
 * match the current path are added.
 */
function getNavigationItems(routeTree: Node[], pathname: string): NavigationItem[] {
  const buffer = [routeTree];
  const result = [];

  for (let i = 0; i < buffer.length; i++) {
    for (const node of buffer[i]) {
      const match = matchPath(pathname, node.route.pathTemplate);
      if (match) {
        buffer.push(node.children);
        result.push({
          path: node.route.getPath(match.params),
          title: node.getTitle(match.params),
        });
        break;
      }
    }
  }

  return result;
}

/** Renders links from root route to current route based on the route tree */
export function NavigationLinks({ routeTree }: { routeTree: Node[] }) {
  const classes = useStyles();
  const location = useLocation();
  const items = useMemo(
    () => getNavigationItems(routeTree, location.pathname),
    [location.pathname, routeTree],
  );
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      {items.map((item, index) => (
        <Link key={index} to={item.path} className={classes.link}>
          {item.title}
        </Link>
      ))}
    </Breadcrumbs>
  );
}

const useStyles = makeStylesHook({
  link: {
    textDecoration: 'none',
  },
});
