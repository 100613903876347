import {
  Commit,
  CommonConfigurationFields,
  ConfigurationType,
} from 'common/types/commonConfiguration';

/**
 * Finds the best configuration for element or type in specific commit.
 *
 * There are just few simple rules for which a configuration is applied for a given
 * commit:
 *
 * Rules when a configuration is potentially applicable:
 *  - Rule for commitDate: configuration must be created on or before commit.commitDate
 *  - Rule for commitBranch:
 *    - configuration of type SINGLE_BRANCH is only applicable for commits on the same
 *      branch
 *    - configuration of type GLOBAL is applicable on all branches
 *
 * Priorities between two applicable configurations by configuration type:
 *  - different type: configuration of type SINGLE_BRANCH is preferred to one of type GLOBAL
 *  - both have the same type: configuration with greater commitDate (created later) is preferred
 *
 * If there is no applicable configuration, returns null.
 *
 * @param commit Commit representing version of the element or type
 * @param configurations List of possible configurations for the element or type
 */
export function findCurrentConfiguration<
  TConfiguration extends CommonConfigurationFields,
>(commit: Commit, configurations: readonly TConfiguration[]): TConfiguration | null {
  let result: TConfiguration | null = null;
  for (const configuration of configurations) {
    if (
      !isApplicableByBranchName(commit, configuration) ||
      !isApplicableByDate(commit, configuration)
    ) {
      continue;
    }

    if (!result) {
      result = configuration;
    } else {
      result = getPreferredConfiguration(result, configuration);
    }
  }
  return result;
}

/**
 * Configuration must be created before the commit to be applicable for that
 * commit. We also compare by commit hash to deal with the fact that the commitDate
 * might be inferred from element_set.last_modified_at (temporary hack).
 */
export function isApplicableByDate(
  commit: Commit,
  configuration: CommonConfigurationFields,
): boolean {
  return (
    commit.commitHash === configuration.commitHash ||
    commit.commitDate.isSameOrAfter(configuration.commitDate)
  );
}

/**
 * Configuration of type GLOBAL is applicable for all commits.
 * Configuration of type SINGLE_BRANCH is only applicable for commits on the branch
 * configuration.commitBranch.
 */
export function isApplicableByBranchName(
  commit: Commit,
  configuration: CommonConfigurationFields,
): boolean {
  switch (configuration.configurationType) {
    case ConfigurationType.GLOBAL:
      return true;
    case ConfigurationType.SINGLE_BRANCH:
      return commit.commitBranch === configuration.commitBranch;
  }
}

/**
 * Returns preferred configuration from two applicable configurations. When they have the
 * same type, the later (=newer) configuration is used. When they don't, the one of type
 * SINGLE_BRANCH is preferred.
 */
function getPreferredConfiguration<TConfiguration extends CommonConfigurationFields>(
  c1: TConfiguration,
  c2: TConfiguration,
) {
  if (c1.configurationType === c2.configurationType) {
    return c1.commitDate.isAfter(c2.commitDate) ? c1 : c2;
  } else {
    return c1.configurationType === ConfigurationType.SINGLE_BRANCH ? c1 : c2;
  }
}
