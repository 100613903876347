import { AnthaHubMinimumBuildVersion } from 'common/constants/anthahub';

/**
 * The anthahub_release_version column in the admin.environments table stores
 * the maximum installer release to which an AnthaHub in that environment should
 * be upgraded. To disable upgrades, we simply set this field to a value
 * guaranteed to be lower than any version deployed in the field.
 */
export const AnthaHubUpdatesDisabledVersion = AnthaHubMinimumBuildVersion;

/**
 * When an environment always just wants to be on the latest AnthaHub or device
 * driver release, we use the special pseudo-version string "latest"
 */
export const AnthaHubUpdatesLatestVersion = 'latest';

/**
 * For generic methods dealing with releases, we use "project name" to mean
 * either the device class, for device plugins, or "AnthaHub", for AnthaHub
 * installers.
 */
export const AnthaHubInstallersProjectName = 'AnthaHub';

/**
 * The regex for validating environment domain prefixes, i.e. the foo in
 * foo.antha.com.
 *
 * This regex allows values composed of digits, lowercase letters and hyphens.
 * Leading hyphens, trailing hypens and multiple contiguous hyphens are not
 * allowed.
 *
 * (While uppercase letters are technically legal in a domain name, domain names
 * are case insensitive, so no distinction is made between foo.com and FOO.com.
 * So to keep things simple, we only allow lowercase domain prefixes.)
 */
export const EnvironmentDomainPrefixRegex = /^[a-z0-9]+(-[a-z0-9]+)*$/;
