import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ComputerIcon from '@mui/icons-material/Computer';
import DirectionsIcon from '@mui/icons-material/Directions';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import UpdateIcon from '@mui/icons-material/Update';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom';

import { TEST_DOMAIN as AUTH0_TEST_DOMAIN } from 'admin-client/app/lib/auth0';
import { isLocalDev } from 'admin-client/app/lib/utils';
import { ROUTES } from 'admin-common/src/routing/routes';
import { Auth0Config } from 'common/types/auth0';
import Colors from 'common/ui/Colors';
import { ExampleWorkflowIcon } from 'common/ui/icons';
import { PlateIcon } from 'common/ui/icons/Plate';
import { getAuth0Config } from 'common/ui/lib/auth0';

export default function LeftSidebarContainer() {
  return <LeftSidebar />;
}

type SidebarEntry = {
  label: string;
  path: string;
  icon: JSX.Element;
  useExactMatch?: boolean;
};

export type SidebarSection = {
  label: string;
  entries: SidebarEntry[];
};

const homeEntry: SidebarEntry = {
  label: 'Home',
  path: ROUTES.HOME.getPath(),
  icon: <HomeIcon />,
  useExactMatch: true,
};

export function defineSidebar(
  domain: string | undefined,
  isDev: boolean,
): SidebarSection[] {
  if (domain === undefined) {
    return [];
  }

  const commonSidebarSections: SidebarSection[] = [
    {
      label: 'Customer Success',
      entries: [
        {
          label: 'Organisations',
          path: ROUTES.ORGANIZATIONS.ROOT.getPath(),
          icon: <BusinessIcon />,
        },
        {
          label: 'Feature Toggles',
          path: ROUTES.FEATURETOGGLES.ROOT.getPath(),
          icon: <ToggleOnIcon />,
        },
        {
          label: 'PlateTypes',
          path: ROUTES.PLATE_TYPES.ROOT.getPath(),
          icon: <PlateIcon />,
        },
        {
          label: 'Tutorials',
          path: ROUTES.TUTORIALS.getPath(),
          icon: <SchoolIcon />,
        },
        {
          label: 'Example Workflows',
          path: ROUTES.EXAMPLE_WORKFLOWS.getPath(),
          icon: <ExampleWorkflowIcon />,
        },
      ],
    },
    {
      label: 'Productivity',
      entries: [
        {
          label: 'Redirectinator',
          path: ROUTES.REDIRECTINATOR.getPath(),
          icon: <DirectionsIcon />,
        },
      ],
    },
    {
      label: 'Dev Tools',
      entries: [
        {
          label: 'Online Migrations',
          path: ROUTES.MIGRATIONS.getPath(),
          icon: <UpdateIcon />,
        },
      ],
    },
  ];

  // Element and Type configurations are applied globally, while
  // SynthaceHub releases are only pertinent to the production admin env.
  // We only want these features to appear in the UI when
  // working in the local dev/production admin tool environments.
  if (domain !== AUTH0_TEST_DOMAIN || isDev) {
    commonSidebarSections[0].entries.splice(
      1,
      0,
      {
        label: 'Elements',
        path: ROUTES.ELEMENT_CONFIGURATION.ROOT.getPath(),
        icon: <DynamicFeedIcon />,
      },
      {
        label: 'Types',
        path: ROUTES.TYPE_CONFIGURATION.ROOT.getPath(),
        icon: <SettingsInputComponentIcon />,
      },
    );
    commonSidebarSections.splice(1, 0, {
      label: 'SynthaceHub',
      entries: [
        {
          label: 'Metrics',
          path: ROUTES.ANTHA_HUB.METRICS.getPath(),
          icon: <ComputerIcon />,
        },
        {
          label: 'Releases',
          path: ROUTES.ANTHA_HUB.RELEASES.getPath(),
          icon: <CloudDownloadIcon />,
        },
      ],
    });
  }
  return commonSidebarSections;
}

type SidebarEntryProps = {
  entry: SidebarEntry;
};

const SidebarEntry = React.memo(function SidebarEntry(props: SidebarEntryProps) {
  const { entry } = props;
  const classes = useStyles();
  return (
    <NavLink
      activeClassName={classes.menuItemActive}
      className={classes.menuItem}
      exact={entry.useExactMatch}
      to={entry.path}
    >
      <ListItem>
        <ListItemIcon>{entry.icon}</ListItemIcon>
        <ListItemText primary={entry.label} />
      </ListItem>
    </NavLink>
  );
});

type SidebarSectionProps = {
  section: SidebarSection;
};

const SidebarSection = React.memo(function SidebarSection(props: SidebarSectionProps) {
  const {
    section: { entries, label },
  } = props;
  const classes = useStyles();

  return entries.length > 0 ? (
    <>
      <Divider component="li" className={classes.divider} />
      <li>
        <Typography
          className={classes.dividerLabel}
          color="textSecondary"
          display="block"
          variant="caption"
        >
          {label}
        </Typography>
      </li>
      {entries.map(entry => (
        <SidebarEntry key={entry.path} entry={entry} />
      ))}
    </>
  ) : null;
});

const LeftSidebar = React.memo(function LeftSidebar() {
  const classes = useStyles();

  const [auth0Config, setAuth0Config] = React.useState<Auth0Config | null>(null);

  React.useEffect(() => {
    (async () => {
      const config = await getAuth0Config();
      setAuth0Config(config);
    })();
  }, []);

  const sidebarSections = defineSidebar(auth0Config?.domain, isLocalDev(window.location));

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <List>
        <SidebarEntry entry={homeEntry} />
        {sidebarSections.map((section, index) => (
          <SidebarSection key={index} section={section} />
        ))}
      </List>
    </Drawer>
  );
});

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    divider: {
      marginTop: theme.spacing(5),
    },
    dividerLabel: {
      margin: theme.spacing(2, 0, 0, 5),
    },
    toolbar: theme.mixins.toolbar,
    menuItem: {
      color: 'inherit',
      textDecoration: 'none',
      outline: 0,
      '& :hover': {
        backgroundColor: Colors.GREY_10,
      },
    },
    menuItemActive: {
      '& li ': {
        backgroundColor: Colors.GREY_20,
      },
    },
  }),
);
