import React, { useMemo } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import Chip from '@mui/material/Chip';
import { Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Colors from 'common/ui/Colors';

type OnlineMigrationStatusChipProps = {
  label: string;
  overridden?: boolean;
  /**
   * On the details page for a single migration, if a backfill is in progress
   * this contains a string such as 'Backfill progress: 2172 entities (86% of
   * 2517)'
   */
  backfillProgress?: string;
};

export const OnlineMigrationStatusChip = React.memo(function OnlineMigrationStatusChip(
  props: OnlineMigrationStatusChipProps,
) {
  const { label, overridden, backfillProgress } = props;
  const classes = useStyles();
  const color = overridden ? 'secondary' : 'primary';
  const chip = useMemo(() => {
    return (
      <Chip
        className={classes.statusChip}
        icon={overridden ? <InfoIcon /> : undefined}
        label={label}
        color={color}
      />
    );
  }, [classes.statusChip, color, label, overridden]);
  const chipWithTooltip = overridden ? (
    <Tooltip title="The stage for this migration is overridden in the database">
      {chip}
    </Tooltip>
  ) : (
    chip
  );
  return (
    <>
      <div>{chipWithTooltip}</div>
      {backfillProgress ? (
        <Typography className={classes.backfillProgress} variant="subtitle2">
          {backfillProgress}
        </Typography>
      ) : null}
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  backfillProgress: {
    color: Colors.GREY_40,
  },
  statusChip: {
    margin: theme.spacing(2),
  },
}));
