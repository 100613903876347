import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';

import { GET_ONLINE_MIGRATION_ADMIN_STATES } from 'admin-client/app/api/gql/queries';
import { OnlineMigrationStatusChip } from 'admin-client/app/components/OnlineMigrations/OnlineMigrationStatusChip';
import { getOnlineMigrationAdminStatesQuery } from 'admin-client/app/gql';
import { getEnvironmentNickname } from 'admin-client/app/lib/utils';
import { orderedOnlineMigrationStages } from 'common/types/onlineMigration';

export default function OnlineMigrationsContainer() {
  const { loading, error, data } = useQuery(GET_ONLINE_MIGRATION_ADMIN_STATES);
  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }
  if (!data) {
    return <div>Error! Data is undefined</div>;
  }
  return <OnlineMigrations environments={data.environments} />;
}

type OnlineMigrationsProps = {
  environments: getOnlineMigrationAdminStatesQuery['environments'];
};

type EnvironmentInfo = {
  hostname: string;
  overridden: boolean;
};

const OnlineMigrations = React.memo(function OnlineMigrations(
  props: OnlineMigrationsProps,
) {
  const { environments } = props;
  const allMigrationNames = useMemo(() => {
    const namesSet = new Set(
      environments.flatMap(env => env.onlineMigrationAdminStates.map(om => om.name)),
    );
    const names = [...namesSet.values()];
    names.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    return names;
  }, [environments]);

  // Build a nested dictionary mapping migration name => stage => EnvironmentInfo[]
  const envMigrationMap = useMemo(() => {
    const result: {
      [key: string]: {
        [key: string]: EnvironmentInfo[];
      };
    } = {};
    for (const env of environments) {
      for (const migration of env.onlineMigrationAdminStates) {
        const stage = migration.stageOverride || migration.stage;
        result[migration.name] = result[migration.name] ?? {};
        result[migration.name][stage] = result[migration.name][stage] ?? [];
        result[migration.name][stage].push({
          hostname: env.hostname,
          overridden: !!migration.stageOverride,
        });
      }
    }
    return result;
  }, [environments]);

  return (
    <div>
      <Typography variant="h2" gutterBottom>
        Online Migrations
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {orderedOnlineMigrationStages.map(stage => (
              <TableCell key={stage}>
                <Typography>{stage}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {allMigrationNames.map(migrationName => (
            <TableRow key={migrationName}>
              <TableCell>
                <Typography>
                  <Link component={RouterLink} to={`/migration/${migrationName}`}>
                    {migrationName}
                  </Link>
                </Typography>
              </TableCell>
              {orderedOnlineMigrationStages.map(stage => (
                <TableCell key={`${migrationName}.${stage}`}>
                  {envMigrationMap[migrationName]?.[stage]?.map(envInfo => (
                    <OnlineMigrationStatusChip
                      key={envInfo.hostname}
                      label={getEnvironmentNickname(envInfo)}
                      overridden={envInfo.overridden}
                    />
                  ))}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography variant="caption">
        If an environment does not show at all in the row for a migration, it means the
        migration has not yet been deployed to the environment.
      </Typography>
    </div>
  );
});
