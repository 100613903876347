import React, { useCallback } from 'react';

import { MultiInputSelector } from 'admin-client/app/components/ElementConfiguration/Card/rules/common/InputSelector';
import { ConditionEditorProps } from 'admin-client/app/components/ElementConfiguration/Card/rules/conditions/ConditionEditor';
import { AreParametersConnectedCondition } from 'common/types/elementConfiguration';

export function AreParametersConnectedConditionEditor(
  props: {
    condition: AreParametersConnectedCondition;
  } & ConditionEditorProps,
) {
  const { condition, index, element, onConditionChange } = props;

  const onParametersChange = useCallback(
    (parameterNames: string[]) => {
      const newCondition: AreParametersConnectedCondition = {
        ...condition,
        parameterNames,
      };
      onConditionChange(newCondition, index);
    },
    [condition, index, onConditionChange],
  );

  return (
    <MultiInputSelector
      element={element}
      selectedParameterNames={condition.parameterNames}
      onChange={onParametersChange}
    />
  );
}
