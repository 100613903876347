import { useQuery } from '@apollo/client';
import moment from 'moment';

import { GET_ALL_CURRENT_TYPE_CONFIGURATIONS } from 'admin-client/app/api/gql/queries';
import { GraphQLTypeConfiguration } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useTypeConfigurations';
import { getAllCurrentTypeConfigurationsQuery } from 'admin-client/app/gql';
import { groupBy } from 'common/lib/data';
import { Commit } from 'common/types/commonConfiguration';
import { TypeConfigurationSpec } from 'common/types/typeConfiguration';

type GraphQLTypeConfigurationRaw = NonNullable<
  getAllCurrentTypeConfigurationsQuery['typeConfigurations'][number]
>;

export function useAllCurrentTypeConfigurations(commit?: Commit) {
  const { data, loading, error } = useQuery(GET_ALL_CURRENT_TYPE_CONFIGURATIONS, {
    variables: {
      commit: commit
        ? {
            ...commit,
            commitDate: commit.commitDate.toISOString(),
          }
        : null,
      returnLatestConfigurationOnly: true,
    },
  });

  const configurations = data?.typeConfigurations.map(convertConfiguration) ?? [];

  const typeConfigurations: Record<string, TypeConfigurationSpec> = {};
  const typeConfigurationsGrouped =
    configurations.length > 0 ? groupBy(configurations, '__typename') : {};
  for (const typeName in typeConfigurationsGrouped) {
    // We only need the first entry as our query returns just one
    // type config, but using groupBy converts the data to an array
    typeConfigurations[typeName] = typeConfigurationsGrouped[typeName]?.[0]?.spec;
  }

  return { loading, error, typeConfigurations };
}

function convertConfiguration(
  configuration: GraphQLTypeConfigurationRaw,
): GraphQLTypeNameConfiguration {
  return {
    ...configuration,
    commitDate: moment(configuration.commitDate),
    createdAt: moment(configuration.createdAt),
    lastModifiedAt: moment(configuration.lastModifiedAt),
  };
}

type GraphQLTypeNameConfiguration = GraphQLTypeConfiguration & {
  __typename: string;
};
