import React, { useCallback } from 'react';

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { moveOutputParam } from 'admin-client/app/components/ElementConfiguration/Card/input-mutators';
import { ParameterEditor } from 'admin-client/app/components/ElementConfiguration/Card/ParameterEditor';
import { EditorElementConfiguration } from 'admin-client/app/components/ElementConfiguration/editorTypes';
import { APIElement } from 'common/types/api';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';

type Props = {
  element: APIElement;
  configuration: EditorElementConfiguration;
  setConfiguration: React.Dispatch<React.SetStateAction<EditorElementConfiguration>>;
};

/**
 * Renders content of tab "Outputs"
 */
export function TabOutputParameters({ element, configuration, setConfiguration }: Props) {
  const onSpecChange = useCallback(
    (spec: ElementConfigurationSpec) =>
      setConfiguration(configuration => ({ ...configuration, spec })),
    [setConfiguration],
  );

  const { spec } = configuration;

  const onDragEnd = useCallback(
    (result: DragEndEvent) => {
      if (!result.active || !result.over) {
        return;
      }

      const originalIndex = spec.outputOrder.indexOf(String(result.active.id));
      const newIndex = spec.outputOrder.indexOf(String(result.over.id));

      onSpecChange(moveOutputParam(spec, originalIndex, newIndex));
    },
    [onSpecChange, spec],
  );

  const currentCommit = {
    commitBranch: configuration.commitBranch,
    commitDate: configuration.commitDate,
    commitHash: configuration.commitHash,
  };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext items={spec.outputOrder} strategy={verticalListSortingStrategy}>
        {spec.outputOrder.map(parameterName => (
          <ParameterEditor
            key={`key:${parameterName}`}
            element={element}
            parameterType="output"
            parameterName={parameterName}
            spec={spec}
            currentCommit={currentCommit}
            onSpecChange={onSpecChange}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}
