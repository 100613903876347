import { Markdown } from 'common/lib/markdown';
import { replaceParameterInRule } from 'common/rules/replaceParametersInRules';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';
import { ElementConfigurationRule } from 'common/types/elementConfiguration';
import { ParameterConfigurationSpec } from 'common/types/elementConfiguration';

/**
 * Use for migration of ElementConfigurationSpec to new branch in cases where
 * parameter names have changed. This should update all the references to the
 * given parameter names in oldToNewName.
 *
 * @param oldToNewName
 * @param spec
 * @param elementName
 */
export default function replaceParameterNames(
  oldToNewName: { [key: string]: string },
  spec: ElementConfigurationSpec,
  elementName: string,
) {
  return {
    ...spec,
    outputOrder: replaceParameterNamesInArray(spec.outputOrder, oldToNewName),
    inputGroups: spec.inputGroups.map(group => {
      return {
        ...group,
        parameterNames: replaceParameterNamesInArray(group.parameterNames, oldToNewName),
      };
    }),
    elementDisplayDescription: replaceParameterNamesInMarkdown(
      spec.elementDisplayDescription,
      elementName,
      oldToNewName,
    ),
    rules: replaceParameterNamesInRules(spec.rules ?? [], oldToNewName),
    parameters: replaceParameterNamesInParameterSpec(
      spec.parameters,
      elementName,
      oldToNewName,
    ),
  };
}

function replaceParameterNamesInArray(
  originalNames: string[],
  oldToNewName: { [key: string]: string },
) {
  const namesToReplace = Object.keys(oldToNewName);
  return originalNames.map(name =>
    namesToReplace.includes(name) ? oldToNewName[name] : name,
  );
}

/**
 * Replaces any NameLink descriptions for element and parameters.
 * Parameters would be in the format {{ElementName.ParameterName}}.
 * This should always be the original ElementName (not the displayName!).
 * Doesn't replace identical parameters that might come from other elements.
 *
 * @param originalText
 * @param elementName
 * @param oldToNewName
 */
function replaceParameterNamesInMarkdown(
  originalText: Markdown,
  elementName: string,
  oldToNewName: { [key: string]: string },
) {
  let newText = originalText;
  for (const [oldName, newName] of Object.entries(oldToNewName)) {
    newText = newText?.replace(
      `{{${elementName}.${oldName}}}`,
      `{{${elementName}.${newName}}}`,
    ) as Markdown;
  }
  return newText;
}

function replaceParameterNamesInRules(
  originalRules: ElementConfigurationRule[],
  oldToNewName: { [key: string]: string },
) {
  let newRules = [...originalRules];
  for (const [oldName, newName] of Object.entries(oldToNewName)) {
    newRules = newRules.map(rule => {
      return replaceParameterInRule(rule, oldName, newName);
    });
  }
  return newRules;
}

function replaceParameterNamesInParameterSpec(
  originalSpecs: {
    [parameterName: string]: ParameterConfigurationSpec;
  },
  elementName: string,
  oldToNewName: { [key: string]: string },
) {
  const newSpec = { ...originalSpecs };

  // For every oldName to newName, we need to check every one of the parameters
  // here to update any references in each of the descriptions.
  for (const [oldName, newName] of Object.entries(oldToNewName)) {
    for (const paramName of Object.keys(newSpec)) {
      newSpec[paramName] = {
        ...newSpec[paramName],
        displayDescription: replaceParameterNamesInMarkdown(
          newSpec[paramName].displayDescription,
          elementName,
          oldToNewName,
        ),
        // Because we are ranging over the parameters here anyway, and because parameter names
        // are never duplicated, it should be fine to check if the display name needs to be updated here.
        // (i.e. we would only ever expect 1 instance of 'oldName')
        displayName:
          newSpec[paramName].displayName === oldName
            ? newName
            : newSpec[paramName].displayName,
      };
    }
    newSpec[newName] = newSpec[oldName];
    delete newSpec[oldName];
  }
  return newSpec;
}
