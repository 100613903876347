import React, { useCallback, useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { chooseInitialCommit } from 'admin-client/app/components/ElementConfiguration/chooseInitialCommit';
import { ConfigurationCommitSelector } from 'admin-client/app/components/ElementConfiguration/ConfigurationCommitSelector';
import { useTypeConfigurations } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useTypeConfigurations';
import { useTypeNames } from 'admin-client/app/components/ElementConfiguration/TypeConfiguration/useTypeNames';
import { useCommits } from 'admin-client/app/components/ElementConfiguration/useCommits';
import { isMasterBranch, isRelengBranch } from 'admin-common/src/parseCommits';
import { validateTypeNameForCompoundConfig } from 'common/elementConfiguration/parameterUtils';
import { Commit, ConfigurationType } from 'common/types/commonConfiguration';
import { TypeName } from 'common/types/typeConfiguration';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

export type ChooseCommitDialogResult = {
  typeName: TypeName;
  configurationType: ConfigurationType;
  commit: Commit;
};

type Props = DialogProps<ChooseCommitDialogResult | null> & {
  typeName: TypeName | null;
  isNewConfiguration: boolean;
  initialConfigurationType?: ConfigurationType;
  initialCommit?: Commit | null;
  dialogTitle?: string;
};

/**
 * In this dialog the user selects a configuration type, which determines which circumstances the
 * configuration they are going to make should be applied. They can choose between global and single-branch
 * options.
 *
 * When the user chooses the 'global' configuration type, they can choose which commit the configuration is
 * valid from. To make the UI simple, the user can only choose from releng branches. That basically says that from
 * releng branch X we use this configuration. In the background we find the parent master commit for the
 * selected releng branch and apply configuration since this commit. This saves the user from having to deal with
 * this implementation detail.
 *
 * When the user chooses 'single-branch', they can then select any non-releng/non-master branch. The configuration
 * is then applied to the first commit in that branch. Again, to make things simpler for them, we
 * automatically choose the first commit in the branch, even though backend supports choosing any commit.
 *
 * If this is a new configuration, we provide input for setting the type name. We only allow compound types
 * of existing base types and we provide some validation for this.
 */
export function ChooseTypeConfigurationCommitDialog({
  typeName,
  initialConfigurationType,
  isNewConfiguration,
  initialCommit = null,
  dialogTitle,
  isOpen,
  onClose,
}: Props) {
  const classes = useStyles();

  const { commits } = useCommits();
  const { typeNames } = useTypeNames();

  const initialSelectedCommit = useMemo(
    () => chooseInitialCommit(commits, initialCommit),
    [commits, initialCommit],
  );
  const [selectedCommit, setSelectedCommit] = useState<Commit | null>(
    initialSelectedCommit,
  );
  const [selectedTypeName, setSelectedTypeName] = useState<TypeName>(
    typeName ?? ('' as TypeName),
  );
  const [hasTypeNameBeenValidated, setHasTypeNameBeenValidated] = useState<boolean>(
    !isNewConfiguration, // New configs will not have their type names validated, but existing configs should.
  );
  const [validationError, setValidationError] = useState<string | null>();

  const { configurations } = useTypeConfigurations(selectedTypeName);

  const isInitialCommitNonGlobal =
    selectedCommit && !isMasterBranch(selectedCommit) && !isRelengBranch(selectedCommit);

  const initialConfigurationTypeForConfig =
    initialConfigurationType ?? (isInitialCommitNonGlobal || isNewConfiguration)
      ? ConfigurationType.SINGLE_BRANCH
      : ConfigurationType.GLOBAL;

  const [configurationType, setConfigurationType] = useState<ConfigurationType>(
    initialConfigurationTypeForConfig,
  );

  const onTypeNameChange = useTextFieldChange(
    useCallback(
      (result: string) => {
        const updatedTypeName = result;
        setSelectedTypeName(updatedTypeName);
        setHasTypeNameBeenValidated(false);
        try {
          validateTypeNameForCompoundConfig(updatedTypeName, typeNames);
        } catch (err) {
          setValidationError(err.message);
          return;
        }
        setValidationError(null);
        setHasTypeNameBeenValidated(true);
      },
      [typeNames],
    ),
  );
  const onConfirm = useCallback(() => {
    selectedCommit &&
      onClose({
        commit: selectedCommit,
        configurationType,
        typeName: selectedTypeName,
      });
  }, [onClose, selectedCommit, configurationType, selectedTypeName]);

  const onCancel = useCallback(() => onClose(null), [onClose]);

  const hasValidationErrors =
    selectedTypeName === '' || !hasTypeNameBeenValidated || !selectedCommit;

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle>{dialogTitle ?? 'Choose commit'}</DialogTitle>
      <DialogContent>
        {isNewConfiguration && (
          <Typography variant="body2">
            Type configurations are not created automatically for compound types (arrays
            or maps). By default, any compound types will use the existing map or array
            editors, and for each of their base types, the editors specified for their
            base types. Use this tool to specify a type configuration for any compound
            type that should use its own editor.
          </Typography>
        )}
        {isNewConfiguration && (
          <div className={classes.typeNameValidation}>
            <TextField
              placeholder="Compound type (e.g. map[string]string)"
              fullWidth
              value={selectedTypeName}
              onChange={onTypeNameChange}
              error={!!validationError}
              helperText={validationError}
            />
          </div>
        )}
        {selectedTypeName && hasTypeNameBeenValidated && (
          <ConfigurationCommitSelector
            configurations={configurations}
            initialCommit={selectedCommit}
            configurationType={configurationType}
            isNewConfiguration={isNewConfiguration}
            onSelectCommit={setSelectedCommit}
            onBranchTypeChange={setConfigurationType}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm} disabled={hasValidationErrors} color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStylesHook({
  typeNameValidation: { padding: '2em 0' },
});
